import Button from 'form5/react/Button';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import type { WalledRouteParams } from '…/app/route-params.mts';

import { deleteAudiences } from './deleteAudiences.op.mts';

import type {
  Audience,
} from './Audience.d.ts';

export function DeleteAudienceForm({
  audience: { id, name },
  afterSubmit,
}: {
  audience: Audience,
  afterSubmit(): void,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const { workspaceId } = useParams() as WalledRouteParams;

  function onDelete() {
    setIsSaving(true);
    const promise = deleteAudiences(workspaceId, [id])
      .then((success: boolean) => {
        if (!success) {
          throw new Error('Failed to delete Audience');
        }
        return success;
      })
      .then(afterSubmit)
      .finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: 'Audience deletion failed',
      loading: 'Deleting audience…',
      success: `“${name}” deleted`,
    });

    return promise;
  }

  return (
    <>
      <h1>Delete “{name}”</h1>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving}
        isSubmitting={isSaving}
        onClick={onDelete}
      >Delete
      </SubmitButton>
    </>
  );
}
DeleteAudienceForm.displayName = 'DeleteAudienceForm';
