export const GET_ENGAGEMENT_FEEDBACK_FORMS = `
query GetEngagementFeedbackForms(
  $after: String
  $before: String
  $engagementId: ID!
  $filters: [FeedbackFormsFilter]
  $first: Int
  $last: Int
  $search: String
  $sort: FeedbackFormsSorting
  $workspaceId: ID!
) {
  workspace(workspaceId: $workspaceId) {
    engagement(id: $engagementId) {
      feedbackForms(
        after: $after
        before: $before
        filters: $filters
        first: $first
        last: $last
        search: $search
        sort: $sort
      ) {
        nodes {
          id
          createdAt
          member {
            id
          }
          optInForm {
            id
            fields {
              email
              fullName
              role
              company
            }
          }
          fields {
            nps
            matchRating
            testimonial
            advice
          }
          answers {
            stepName
            selectedOptions
            text
          }
          match {
            members {
              id
              email
              fields {
                fullName
                role
                company
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        totalCount
      }
    }
  }
}`;
