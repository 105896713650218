import type { PropertyPath } from 'lodash-es';
import _get from 'lodash-es/get.js';
import _merge from 'lodash-es/merge.js';
import _set from 'lodash-es/set.js';
import type { DateTime } from 'luxon';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';
import {
  adjustTimezoneToUTC,
  isApproachingTargetTime,
} from '…/app/common/dateUtils.mts';

import type { Engagement } from '../../../Engagement.d.ts';

import type { ScheduleState } from './composeScheduleData.mts';


export function getDefaultValuesFromOptInCloses(
  prev: ScheduleState,
  product: Engagement['product'],
) {
  const isConvoDateDT = product !== PRODUCT_TYPES.ONE_TO_ONE;
  const newValues: Partial<ScheduleState> = {};
  let hasNewValue = false;

  if (prev.conversationDates[0] == null) { // No convo dates are set
    hasNewValue = true;
    const hour = isConvoDateDT ? 12 : 0;
    // Dates are anchored to Friday of the following week, so make everything relative to that
    const default1idx = product === PRODUCT_TYPES.TOP_N ? 0 : 1; // top-n has only 1 convo
    const default1value = prev.optInClosesAt
      ?.set({
        hour,
        weekday: 5, // Friday
      })
      .plus({ week: 1 });

    if (default1idx) { // 2fer: determine whether the 2nd date is necessary and where to put it
      const default2value = default1value?.minus({ days: 1 });

      // This date occurs before date1, so insert it before it in the list
      _set(newValues, 'conversationDates[0]', default2value);
    }

    _set(newValues, `conversationDates[${default1idx}]`, default1value);
  }

  const { chaser, invite } = prev.oneToOneEmails;
  if (!chaser.isDisabled && chaser.sendAt == null) {
    hasNewValue = true;
    const defaultValue = prev.optInClosesAt
      ?.set({
        hour: 11,
        minute: 0, // 🐒
        second: 0, // 🐒
      })
      || null;
    _set(newValues, 'oneToOneEmails.chaser.sendAt', defaultValue);
  }
  if (!invite.isDisabled && invite.sendAt == null) {
    hasNewValue = true;
    const defaultValue = prev.optInClosesAt
      ?.minus({ days: 1 })
      .set({
        hour: 10,
        minute: 0, // 🐒
        second: 0, // 🐒
      })
      || null;
    _set(newValues, 'oneToOneEmails.invite.sendAt', defaultValue);
  }

  _set(newValues, 'matchesGenerated', prev.optInClosesAt?.plus({ days: 1 }));

  if (!prev.matchIntroAt) {
    _set(newValues, 'matchIntroAt', (prev.matchesGenerated ?? newValues.matchesGenerated)?.plus({ days: 2 }));
  }

  if (prev.isEditMatchesEnabled) {
    hasNewValue = true;
    _set(newValues, 'matchEditingCloses', (prev.matchIntroAt ?? newValues.matchIntroAt)!.minus({ hours: 24 }));
  }

  return hasNewValue
    ? _merge({}, prev, newValues)
    : prev;
}

export const DEFAULT_APPROACHING_THRESHOLD = -15;

export function disableFieldApproachingTime(
  dates: ScheduleState,
  fieldName: PropertyPath,
  threshold = DEFAULT_APPROACHING_THRESHOLD,
) {
  if (!dates.timezoneIso) return false;
  const val = _get(dates, fieldName);
  if (!val || !isDateRealistic(val)) return false;

  const isoDate = prepValForSave(val.toISO(), fieldName, dates.timezoneIso);
  if (!isoDate) return false;

  return isApproachingTargetTime(
    isoDate as ISO_8601,
    { threshold },
  );
}

/**
 * 🐒 The way Puppeteer / Curious George enters dates into fields results in slightly different
 * behaviour in how change events are dispatched (one per digit) than IRL and via jsdom (once for
 * the whole year). That can cause a date to be technically valid (the year `2`, then `20`, then
 * `202`, then finally `2023`), so DateTime.toISO() will return an ISO-8601 string (instead of
 * return null). So we need to check that the date is realistic.
 */
export function isDateRealistic(dt: null | DateTime) {
  return dt && +dt > 0; // Very quick n dirty check the date is after 1970 (`0` in computer time).
}

type RegistrationTime = Readonly<{
  hour: Int,
  minute: Int,
  second: Int,
}>;
export const datesToDateTimes = {
  optInClosesAt: {
    hour: 23,
    minute: 59,
    second: 59,
  } as RegistrationTime,
  optInOpensAt: {
    hour: 0,
    minute: 0,
    second: 0,
  } as RegistrationTime,
};

export function prepValForSave(
  val: unknown,
  key: PropertyPath,
  tz: TimezoneISO,
) {
  if (val == null) return val;

  switch (typeof val) {
    case 'boolean': return val;
    case 'string': {
      if (key.includes('timezone')) return val;

      const times = datesToDateTimes[key];
      const fakeUTC = adjustTimezoneToUTC(val, tz, times);

      return fakeUTC
        ? `${fakeUTC}Z`
        : fakeUTC;
    }
    default: return prepDataForSave(val, tz);
  }
}

export function prepDataForSave(data: ScheduleState, tz: TimezoneISO) {
  const output = Array.isArray(data) ? [] : {};

  for (const key of Object.keys(data)) {
    const val = data[key];

    output[key] = prepValForSave(val, key, tz);
  }

  return output;
}
