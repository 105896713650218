._Bureau_c50ln_1 {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches */
}

._Drawer_c50ln_5 {
  background: var(--neutral-lightest);
  padding: 0;
}

._Content_c50ln_10 {
  display: flex;
  flex: 3;
  flex-direction: column;
}

._SectionSplit_c50ln_16 {
  display: flex;
  flex: 1;
  flex-direction: column;
}

._Instructions_c50ln_22 {
  align-items: left;
  flex: 2;
}

._Instructions_c50ln_22 h3,
._Instructions_c50ln_22 p {
  margin-bottom: 1rem;
}

._ApiKey_c50ln_32 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

._IntegrationName_c50ln_39 {
  align-items: center;
  display: flex;
  gap: 0.375rem;
}

._IconButton_c50ln_45,
._IconButtonDelete_c50ln_46,
._IconButtonConnect_c50ln_47 {
  background-color: transparent;
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  color: var(--neutral-dark);
  cursor: pointer;
  margin: 0 0.125rem;
  padding: 0.5rem;
}

._IconButton_c50ln_45:hover {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--brand-accent-primary);
  color: var(--brand-accent-primary-hover);
}

._IconButtonConnect_c50ln_47 {
  background-color: var(--status-success-lightest);
  border: 1px solid var(--status-success-dark);
  color: var(--status-success-darker);
}

._IconButtonDelete_c50ln_46:hover {
  border: 1px solid var(--status-error);
  color: var(--status-error);
}

._SubText_c50ln_74 {
  color: var(--neutral-medium);
  display: block;
  font-size: 0.75rem;
  line-height: 1em;
  margin-top: 0.375rem;
}
