import { clsx } from 'clsx';
import _memo from 'lodash-es/memoize.js';
import { useEffect, useState } from 'react';

import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { IIcon, type IIconProps } from '…/app/w/workspace/common/Icon/Icon.tsx';

import { ago } from '…/app/common/dateUtils.mts';

import {
  type STATUS,
  STATUSES,
  STATUSES_ORDERED,
} from '../../constants.mts';
import { ScheduleState } from '../setup/schedule/composeScheduleData.mts';

import classes from './EditingStatus.module.css';


export function EditingStatus(props: EditingStatusProps) {
  const [meta, setMeta] = useState(memoizedGetMessageMeta(props));
  const {
    icon,
    shaking,
    text,
  } = meta;

  useEffect(function checkForUpdatedStatus() {
    let id: NodeJS.Timeout;

    if (
      text !== EDITING_DISABLED
      && text !== MATCHING_CLOSED
    ) {
      id = setInterval(() => {
        const newMeta = memoizedGetMessageMeta(props);

        if (!Object.is(meta, newMeta)) setMeta(newMeta);
      }, 300_000);
    }
    return () => clearInterval(id);
  }, []);

  return (
    <Popover
      anchor={({ className, ...others}) => (
        <IIcon
          className={clsx(className, { [classes.Shaking]: shaking })}
          {...others}
          name={icon}
        />
      )}
      minimal
      position={Popover.POSITIONS.LEFT}
      type={Popover.TYPES.TOOLTIP}
    >
      {text}
    </Popover>
  );
}
EditingStatus.displayName = 'EditingStatus';

export interface EditingStatusProps {
  closesAt: ScheduleState['matchEditingCloses'],
  editingEnabled: boolean,
  status: STATUS,
  size: Int,
}

function getMessageMeta({
  closesAt,
  editingEnabled,
  status,
  size,
}: EditingStatusProps): StatusMeta {
  if (STATUSES_ORDERED.indexOf(status) > MATCHING_IDX) {
    return {
      icon: 'not editable',
      text: MATCHING_CLOSED,
    };
  }
  if (!editingEnabled) {
    return {
      icon: 'not editable',
      text: EDITING_DISABLED,
    };
  }

  if (status === STATUSES.MATCHING && !size) {
    return {
      icon: 'not editable',
      text: MATCHES_GENERATING,
    };
  }

  const hoursBetween = (+closesAt - +Date.now()) / 3.6e+6;
  const relativeTime = ago(closesAt);

  if (hoursBetween < 0) {
    return {
      icon: 'not editable',
      text: `Editing closed ${relativeTime}`,
    };
  }
  if (hoursBetween < 24) {
    return {
      icon: 'alarm clock',
      shaking: true,
      text: `Editing closes ${relativeTime}`,
    };
  }

  return {
    icon: 'alarm clock',
    text: `Editing closes ${relativeTime}`,
  };
}

const memoizedGetMessageMeta = _memo(getMessageMeta);

interface StatusMeta {
  icon: IIconProps['name'],
  shaking?: boolean,
  text: string,
}

const MATCHING_CLOSED = 'Editing has closed';
const EDITING_DISABLED = 'Editing is not enabled';
const MATCHES_GENERATING = 'Matches are generating and aren’t available yet';
const MATCHING_IDX = STATUSES_ORDERED.indexOf(STATUSES.MATCHING);
