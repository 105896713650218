mutation UpdateSignupQuestion(
  $workspaceId: ID!,
  $engagementId: ID!,
  $questionId: ID!,
  $question: EngagementQuestionInput!
) {
  question: updateEngagementOptInQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    optInQuestionId: $questionId
    optInQuestion: $question
  ) {
    id
  }
}
