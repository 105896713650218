.OptionsCardHeaderContainer,
.OptionTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.OptionsItemsWrapper {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.OptionWrapper {
  margin-bottom: 0.75rem;
}

.OptionTextContainer {
  margin-bottom: 0.125rem;
}

.OptionText,
.OptionTextLight {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
}

.OptionText {
  color: var(--brand-subtle-dark);
}

.OptionTextLight {
  color: var(--neutral-dark);
}

.MoreButtonContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
