export default {
  "SubPage": "_SubPage_xlgdz_1",
  "Section": "_Section_xlgdz_13",
  "SplitContent": "_SplitContent_xlgdz_21",
  "Header": "_Header_xlgdz_29",
  "HeaderButtons": "_HeaderButtons_xlgdz_34",
  "Title": "_Title_xlgdz_40",
  "NavBack": "_NavBack_xlgdz_49",
  "EditableSection": "_EditableSection_xlgdz_55",
  "Sidebar": "_Sidebar_xlgdz_75"
};import "ni:sha-256;qAQKNurkjueHVubjlZZxn-lwWhsR2eU2OT9UUYePcxg";