import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import type { PropsWithChildren, ReactNode } from 'react';
import { useEffect, useRef } from 'react';

import classes from './Dialog.module.css';


type DialogProps = PropsWithChildren<{
  backdropped?: boolean,
  CloseButton?: ReactNode,
  className?: string,
  open?: boolean,
  setOpen: (open: boolean) => void,
}>;

export function Dialog({
  backdropped,
  children,
  className,
  CloseButton,
  open,
  setOpen,
  ...props
}: DialogProps) {
  const self = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (!backdropped) return;

    const method = open ? 'showModal' : 'close';

    self?.current?.[method]();
  }, [open]);

  return (
    <dialog
      aria-hidden={!open}
      autoFocus
      className={clsx(classes.Dialog, className, { [classes.Backdropped]: backdropped })}
      ref={self}
      {...props}
    >
      {CloseButton === null
        ? null
        : CloseButton || <DialogCloseButton setOpen={setOpen} />
      }

      {children}
    </dialog>
  );
}
Dialog.displayName = 'Dialog';

function DialogCloseButton({ setOpen }: { setOpen: DialogProps['setOpen'] }) {
  return (
    <Button
      appearance="basic"
      className={classes.CloseButton}
      onClick={() => setOpen(false)}
      title="close"
      variant={Button.VARIANTS.GLYPH}
    >
      ×
    </Button>
  );
}
DialogCloseButton.displayName = 'DialogCloseButton';
