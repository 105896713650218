import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { SCHEDULE_ENGAGEMENT } from './scheduleEngagement.gql.mjs';

export function scheduleEngagement({ engagementId, workspaceId }) {
  return gqlOperation(SCHEDULE_ENGAGEMENT, {
    engagementId,
    workspaceId,
  })
    .then((data) => data.engagement);
}
