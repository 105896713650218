import engagementFrag from './engagement.frag.gql.mjs';

export const SCHEDULE_ENGAGEMENT = `
mutation ScheduleEngagement($workspaceId: ID!, $engagementId: ID!) {
  engagement: scheduleEngagement(workspaceId: $workspaceId, engagementId: $engagementId) {
    ...engagementFields
  }
}

${engagementFrag}
`;
