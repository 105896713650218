/* eslint-disable react/jsx-max-depth */

import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import { useState } from 'react';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import type { Engagement } from '@/app/w/workspace/engagements/Engagement.d.ts';

import { CheckboxTw } from './CheckboxTw.tsx';
import { SectionTitle } from './SectionTitle.tsx';

import classes from './EngagementSettingsPage.module.css';

export function MatchingNotificationsForm({
  disabled,
  engagement,
  onSubmit,
}: {
  disabled: boolean;
  engagement: Engagement;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: (delta: Record<string, any>) => Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  return (
    <Form
      name="form-matching-notifications"
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={(delta) => {
        setIsSubmitting(true);
        return onSubmit(delta).then(() => {
          setIsSubmitting(false);
        });
      }}
    >
      {/* @ts-ignore */}
      <fieldset>
        <section className={clsx('Card', classes.SectionCard)}>
          <div className={clsx(classes.Content)}>
            <SectionTitle>
              Matching Notifications
            </SectionTitle>

            <div className="space-y-5">
              <CheckboxTw
                description="Sends default no-show emails to the Victim & Offender - NOTE: Only for 1:1 Engagements"
                disabled={disabled}
                label="Send No-Show Emails (Victim + Offender)"
                value={engagement?.sendNoshowEmail || false}
                variableName="sendNoshowEmail"
              />

              <CheckboxTw
                disabled={disabled}
                label="Send Emails to Unmatched Participants"
                value={engagement?.sendNotMatchedEmail || false}
                variableName="sendNotMatchedEmail"
              />

            </div>

            <div className="flex justify-end">
              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                disabled={!isDirty || isSubmitting}
                isSubmitting={isSubmitting}
                type="submit"
              >Save changes
              </SubmitButton>
            </div>
          </div>
        </section>
      </fieldset>
    </Form>
  );
}

MatchingNotificationsForm.displayName = 'MatchingNotificationsForm';
