import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Audience } from './Audience.d.ts';
import { RENAME_AUDIENCE } from './renameAudience.gql.mjs';


export async function renameAudience(
  workspaceId: ObjectId,
  audienceId: Audience['id'],
  name: Audience['name'],
) {
  const { audience } = await gqlOperation<{ audience: Audience }>(RENAME_AUDIENCE, {
    audienceId,
    name,
    workspaceId,
  });

  return audience;
}
