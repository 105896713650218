.StatusPopoverContainer {
  display: inline-block;
}

.StatusIndicator {
  cursor: default;
  display: inline-block;
  width: 0.8em;
}

.StatusIndicator::after {
  aspect-ratio: 1;
  border-radius: 100%;
  content: "";
  display: block;
}

.StatusIndicator[aria-label="DRAFT"]::after {
  background-color: var(--engagement-status-draft);
}

.StatusIndicator[aria-label="REVIEW"]::after {
  background-color: var(--engagement-status-review);
}

.StatusIndicator[aria-label="SCHEDULED"]::after {
  background-color: var(--engagement-status-scheduled);
}

.StatusIndicator[aria-label="OPTINSOPEN"]::after {
  background-color: var(--engagement-status-optins);
}

.StatusIndicator[aria-label="MATCHING"]::after {
  background-color: var(--engagement-status-matching);
}

.StatusIndicator[aria-label="MATCHED"]::after {
  background-color: var(--engagement-status-matched);
}

.StatusIndicator[aria-label="MEETING"]::after {
  background-color: var(--engagement-status-meeting);
}

.StatusIndicator[aria-label="COMPLETED"]::after {
  background-color: var(--engagement-status-completed);
}
