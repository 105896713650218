export default {
  "Page": "_Page_1v8dp_1",
  "WithSidebar": "_WithSidebar_1v8dp_8",
  "WithoutSidebar": "_WithoutSidebar_1v8dp_15",
  "Editor": "_Editor_1v8dp_29",
  "Sidebar": "_Sidebar_1v8dp_34",
  "Unmatched": "_Unmatched_1v8dp_48",
  "Group": "_Group_1v8dp_53",
  "GroupSummary": "_GroupSummary_1v8dp_61",
  "Matches": "_Matches_1v8dp_66",
  "Loading": "_Loading_1v8dp_79",
  "UnmatchedWarning": "_UnmatchedWarning_1v8dp_83",
  "Btn": "_Btn_1v8dp_87",
  "AnnotatedText": "_AnnotatedText_1v8dp_91"
};import "ni:sha-256;eCkJcNWYPYS79_RAsB7gTH6VIeICdgWeB30xXw4VoFg";