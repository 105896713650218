._Meter_1auzd_1 {
  display: block;
  height: 2rem;
  width: 100%;
}

._Meter_1auzd_1::-webkit-meter-bar,
._Meter_1auzd_1::-moz-meter-bar {
  background: var(--brand-subtle-light);
}

._Meter_1auzd_1::-webkit-meter-optimum-value {
  background: var(--brand-accent-primary);
}

._Meter_1auzd_1::-webkit-meter-suboptimum-value {
  background: var(--brand-accent-dark);
}

._Meter_1auzd_1::-webkit-meter-even-less-good-value {
  background: var(--brand-accent-dark);
}
