import settingsFields from './settings.frag.gql';


export const UPDATE_WORKSPACE_SETTINGS = `
mutation UpdateWorkspaceSettings($workspaceId: ID!, $settings: WorkspaceSettingsInput!) {
  workspace: updateWorkspaceSettings(workspaceId: $workspaceId, settings: $settings) {
    id
    ...settingsFields
  }
}

${settingsFields}
`;
