import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import _set from 'lodash-es/set.js';
import type { DateTime } from 'luxon';
import {
  useCallback,
  useState,
} from 'react';
import {
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'sonner';

import {
  adjustUTCtoTimezone,
  getNow,
} from '…/app/common/dateUtils.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import type { ScheduledEngagement } from '…/app/w/workspace/engagements/Engagement.d.ts';
import { STATUSES } from '…/app/w/workspace/engagements/constants.mts';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import { updateEngagement } from '../../gql/updateEngagement.op.mts';

import { ScheduleForm } from './ScheduleForm.tsx';
import { ScheduleTimeline } from './ScheduleTimeline.tsx';
import { composeScheduleState } from './composeScheduleData.mts';
import {
  getDefaultValuesFromOptInCloses,
  isDateRealistic,
  prepDataForSave,
} from './utils.mts';
import { validations } from './validations.mts';

import subpageClasses from '../EngagementSubPage.module.css';
import { FieldProps } from 'form5/react/Field';
import type { FormProps } from 'form5/react/Form';


const formId = 'schedule';

const postEditingStatus = new Set<ScheduledEngagement['status']>([
  STATUSES.MATCHING,
  STATUSES.MEETING,
  STATUSES.COMPLETED,
]);

export function EngagementSchedulePage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState<ScheduledEngagement>();
  const {
    engagementId,
    workspaceId,
  } = params;
  const navigate = useNavigate();
  const isEditingDisabled = postEditingStatus.has(engagement.status);

  const [isDirty, setDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [state, setState] = useState(() => composeScheduleState(engagement));

  const onConvoDateAdd = useCallback(function onConvoDateAdd() {
    setState((prev) => ({
      ...prev,
      conversationDates: [...prev.conversationDates, undefined],
    }));
  }, [setState]);

  const onConvoDateRemove = useCallback(function onConvoDateRemove(idx: Int) {
    setState((prev) => {
      const conversationDates = [...prev.conversationDates];
      conversationDates.splice(idx, 1); // ! splice returns the removed items, not the mutated array

      return {
        ...prev,
        conversationDates,
      };
    });
    setDirty(true);
  }, [setDirty, setState]);

  const onConvoDateBlur = useCallback<FieldProps['onBlur']>(function onConvoDateBlur({ target }) {
    let err = '';
    try {
      for (const validator of validations.conversationDates) {
        validator(state.conversationDates);
      }
    } catch ({ message }) { err = message }

    target.setCustomValidity(err);

    if (target.checkValidity()) {
      setState((prev) => {
        const newState = { ...prev };
        newState.conversationDates.sort();

        return newState;
      });
    }
  }, [setState]);

  const onOptInClosesChange = useCallback<FieldProps['onBlur']>(function onOptInClosesChange({ target }) {
    const value: DateTime = adjustUTCtoTimezone(target.value, state.timezoneIso);

    let err = '';

    try {
      for (const validator of validations.optInCloses) {
        validator(value, engagement.product);
      }
    } catch ({ message }) { err = message }

    target.setCustomValidity(err);

    setState((prev) => {
      if (!isDateRealistic(prev.optInClosesAt)) return prev;

      return getDefaultValuesFromOptInCloses(prev, engagement.product);
    });
  }, [setState]);

  const onTimezoneBlur = useCallback<FieldProps['onBlur']>(function onTimezoneBlur({ currentTarget }) {
    const value = currentTarget.value as TimezoneISO;
    let err = '';

    if (value) {
      try {
        for (const validator of validations.timezone) {
          validator(value);
        }
      } catch ({ message }) { err = message }
    }

    currentTarget.setCustomValidity(err);

    setState((prev) => ({
      ...prev,
      timezoneIso: err ? '' : value,
    }));
  }, [setState]);

  const onChange = useCallback<FieldProps['onChange']>(function onChange(
    { id, value },
    { currentTarget: { type } },
  ) {
    let v = value as DateTime | string;

    if (DATE_LIKE_FIELDS.has(type)) {
      const dt = adjustUTCtoTimezone(v, state.timezoneIso);

      if (dt.get('year') > getNow().get('year')) v = dt;
    }

    setState((prev) => composeScheduleState(_set({ ...prev }, id, v)));
  }, [setState]);

  const onReset = useCallback(function onReset() {
    setState(({ timezoneIso }) => composeScheduleState({ timezoneIso } as ScheduledEngagement));
  }, [setState]);

  const onSubmit = useCallback<FormProps['onSubmit']>(function onSubmit(diff, { timezoneIso }) {
    if (isEditingDisabled) return;

    setIsSubmitting(true);
    return updateEngagement({
      engagement: prepDataForSave(diff, timezoneIso),
      engagementId,
      workspaceId,
    })
      .then((result) => {
        setPageProps((prev) => ({
          ...prev,
          engagement: result,
        }));
        setIsSubmitting(false);
        toast.success('Schedule saved!');
        navigate('..');
      });
  }, [navigate, setPageProps]);

  return (
    <main className={clsx(className, subpageClasses.SubPage)}>
      <section className={clsx(subpageClasses.EditableSection, subpageClasses.Section)}>
        <header className={clsx(subpageClasses.SplitContent, subpageClasses.Header)}>
          <span>
            <CTA
              className={subpageClasses.NavBack}
              title="Back to engagement"
              to={generatePath('/w/:workspaceId/engagements/:engagementId/:section', {
                ...params,
              })}
            >←
            </CTA>

            {' '}

            <h1 className={subpageClasses.Title}>Engagement Schedule</h1>
          </span>

          <Button.Group>
            <Button
              appearance={Button.APPEARANCES.WARNING}
              className={clsx(subpageClasses.HeaderButtons)}
              disabled={isEditingDisabled}
              form={formId}
              type="reset"
            >Reset
            </Button>

            <SubmitButton
              appearance={Button.APPEARANCES.PRIMARY}
              className={clsx(subpageClasses.HeaderButtons)}
              disabled={!isDirty || isSubmitting}
              form={formId}
              isSubmitting={isSubmitting}
              type="submit"
            >Save
            </SubmitButton>
          </Button.Group>
        </header>

        <ScheduleForm
          disabled={isEditingDisabled}
          engagement={engagement}
          isDirty={isDirty}
          name={formId}
          onChange={onChange}
          onConvoDateAdd={onConvoDateAdd}
          onConvoDateBlur={onConvoDateBlur}
          onConvoDateRemove={onConvoDateRemove}
          onDirty={setDirty}
          onOptInClosesChange={onOptInClosesChange}
          onPristine={setDirty}
          onReset={onReset}
          onSubmit={onSubmit}
          onTimezoneBlur={onTimezoneBlur}
          state={state}
        />
      </section>

      <section className={clsx(subpageClasses.Section, subpageClasses.Sidebar)} style={{ alignItems: 'initial' }}>
        <ScheduleTimeline {...state} />
      </section>
    </main>
  );
}
EngagementSchedulePage.displayName = 'EngagementSchedulePage';

const handle = {
  title: 'Engagement Setup Schedule',
};

export {
  EngagementSchedulePage as Component,
  handle,
};

const DATE_LIKE_FIELDS = new Set([
  'date',
  'datetime-local',
  'time',
]);
