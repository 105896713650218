import PropTypes from 'prop-types';

import { rpc } from '…/app/common/rpc/client.mts';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { copyToClipboard } from '…/app/common/copy-utils.mts';
import { IconButton } from '…/app/common/IconButton/IconButton.tsx';

import classes from './FeedbackExperiment.module.css';

const {
  arrayOf,
  number,
  shape,
  string,
} = PropTypes;


const formatMember = ({
  fields: {
    firstName,
    role,
    company,
  },
}) => {
  let output = firstName;
  if (role || company) output += ',';
  if (role && company) output += ` ${role} at ${company}`;
  else if (role) output += ` ${role}`;
  else if (company) output += ` works at ${company}`;
  return output;
};

export function FeedbackExperiment({
  data,
  engagementId,
  workspaceId,
}) {
  const onCopy = async ({ fields, id }) => {
    await copyToClipboard(fields.testimonial, 'Testimonial').catch(/* already handled */);

    rpc.post('actions/recent-feedback-form-telemetry', {
      json: {
        engagementId,
        feedbackFormIds: [id],
        workspaceId,
      },
    }).json()
      .then((response) => !!response?.message && !!response?.time);
  };

  return (
    <section className={classes.SectionContainer}>
      <header className={classes.SectionHeader}>
        <IIcon name="AI-powered" />

        <h4>Recent Feedback</h4>
      </header>

      <p>
        Attach your recent positive feedback into your invite emails to showcase

        {' '}

        your impact and increase opt-in rates.
      </p>

      <ul className={classes.CardsList}>
        {data.map(({
          id,
          fields,
          member,
        }) => {
          if (fields.testimonial?.length < 10) return null;
          return (
            <li className={classes.CardItem} key={`test-${id}`}>
              <header className={classes.CardHeader}>
                <div className={classes.CardHeaderLeft}>
                  <span style={{ color: 'gold' }}>
                    {Array.from({ length: fields.nps }).map((i) => (
                      <IIcon key={`${id}-star-${i}`} name="star filled" />
                    ))}
                  </span>

                  <h4>
                    {formatMember(member)}
                  </h4>
                </div>

                <IconButton
                  onClick={() => onCopy({
                    fields,
                    id,
                  })}
                >copy
                </IconButton>
              </header>

              <p>
                {fields.testimonial}
              </p>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

FeedbackExperiment.displayName = 'FeedbackExperiment';

FeedbackExperiment.propTypes = {
  data: arrayOf(shape({
    fields: shape({
      nps: number.isRequired,
      rating: number,
      testimonial: string,
    }),
    id: string.isRequired,
    member: shape({
      fields: shape({
        company: string,
        firstName: string.isRequired,
        lastName: string,
        role: string,
      }),
    }),
  })).isRequired,
  engagementId: string.isRequired,
  workspaceId: string.isRequired,
};
