.Details {
  background-color: var(--brand-light);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.Summary {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1ch;
}

.Details[open] .Summary {
  margin-bottom: var(--grid-gutter);
}

.Summary::before {
  color: var(--brand-accent-primary);
  content: '›';
  font-weight: 900;
  place-self: center;
  rotate: 90deg;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: rotate;
}

.Details:not([open]) .Summary::before {
  rotate: 0deg;
}
