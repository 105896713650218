import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';

import { useStore } from '…/app/state/useStore.mts';
import { login } from '…/app/state/user/login.mts';

import {
  OrbiitBackground,
  orbiitBackgroundContentClassName,
} from '…/app/common/OrbiitBackground/OrbiitBackground.tsx';

import { log } from './logger.mts';


export function LandingPage() {
  const user = useStore((data) => data.user);

  const [error, setError] = useState();
  const [formSuccess, setFormSuccess] = useState(false);

  if (user.isAuthenticated) {
    const redirectUri = (new URLSearchParams(location.search)).get('redirectUri');
    const to = redirectUri
      ? (new URL(decodeURIComponent(redirectUri))).pathname
      : '/w';

    return <Navigate to={to} />;
  }

  const onSubmit: OnSubmit = async ({ email }: { email: string }) => {
    const searchParams = new URLSearchParams(location.search);
    const redirectUri = new URL(
      searchParams.get('redirectUri') ?? '/w',
      location.origin,
    ).href;

    login({
      email,
      redirectUri,
    })
      .then(({ loginLink }) => {
        if (loginLink) {
          log.debug('login successful; hard redirecting to', loginLink);
          location.assign(loginLink);
        }
        setFormSuccess(true);
      })
      .catch(setError);
  };

  return (
    <OrbiitBackground>
      <div className={orbiitBackgroundContentClassName} data-testid="LandingPage">
        <img
          alt="Orbiit.ai"
          src="https://storage.googleapis.com/orbiit-assets/logos/logo_white_fullcolor.png"
          style={{
            marginBottom: '2rem',
            maxWidth: '120px',
          }}
        />


        {formSuccess
          ? (
            <p>
              An email with your login link is on its way!
              <br />

              <br />
              Click that link to proceed to the platform.
            </p>
          )
          : (
            <Form onSubmit={onSubmit}>
              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                autoComplete="email"
                autoFocus
                label="Continue using your email"
                name="email"
                placeholder="alex@example.com"
                required
                type="email"
              />

              <Button type="submit">Login with Email</Button>
            </Form>
          )
        }

        {!!error && (
          <>
            <p>Error:</p>

            <p>{error}</p>
          </>
        )}
      </div>
    </OrbiitBackground>
  );
}
LandingPage.displayName = 'LandingPage';

const handle = {
  showNav: false,
  title: 'Login',
};

export {
  LandingPage as Component,
  handle,
};
