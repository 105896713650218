import { rpc } from '…/app/common/rpc/client.mts';

import type { Match, OptIn } from './Match.d.ts';


export type DeletedMatches = Array<Match['id']>;
export interface UpsertedMatches {
  [key: Match['id']]: {
    name: string,
    optinIds: Array<OptIn['id']>,
  }
}

export function saveMatches(engagementId: ObjectId, matches: {
  deleteMatches?: DeletedMatches,
  upsertMatches?: UpsertedMatches,
}) {
  return rpc
    .post('actions/match-upsert-and-delete', { json: matches })
    .json<MatchesCRUDResponse>();
}

export interface UpsertedMatchResult { id: Match['id'], datetimeLive: ISO_8601 }
interface MatchesCRUDResponse {
  createdMatches: Array<UpsertedMatchResult>,
  deletedMatches: Array<Match['id']>,
  failedMatches: Array<{ [key: Match['id']]: string }>,
  updatedMatches: Array<UpsertedMatchResult>,
}
