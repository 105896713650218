import classes from './TimelineDot.module.css';

export const TimelineDot = ({
  label,
}: {
  label?: string,
}) => (
  <div className={classes.Container}>
    <div className={classes.Line}></div>

    <div className={classes.Dot}></div>

    {label && (<div className={classes.Label}>{label}</div>)}
  </div>
);

TimelineDot.displayName = 'TimelineDot';
