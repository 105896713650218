import MetricCardHeader from '../MetricCardHeader/MetricCardHeader.tsx';

import classes from './Statistics.module.css';

type Statistic = {
  label: string;
  value: number;
};

export const Statistics = ({
  metrics = []
}: {
  metrics: Statistic[]
}) => (
  <div className={classes.Content}>
    <MetricCardHeader description="Statistics are the general activity metrics of this engagement." title="Statistics" />

    {metrics?.map((data, idx) => {
      const key = `grid-${idx}`;
      return (
        <div className={classes.Row}  key={key}>
          <p>{data.label}</p>

          <p><strong>{data?.value || '-'}</strong></p>
        </div>
      );
    })}
  </div>
);

Statistics.displayName = 'Statistics';
