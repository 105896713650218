import { clsx } from 'clsx';
import {
  Fragment,
  useEffect,
  useState,
} from 'react';
import {
  Link,
  generatePath,
  useParams,
} from 'react-router-dom';

import { WalledRouteParams } from '…/app/route-params.mts';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { PRODUCT_TO_TYPE_DICT } from '…/app/deprecated/constants.mts';
import { formatDate } from '…/app/common/dateUtils.mts';

import { OrbiitLoader } from '…/app/common/OrbiitLoader/OrbiitLoader.jsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { SocialIconLink } from '…/app/w/workspace/common/Icon/Icon.tsx';

import type {
  MatchCard,
  Member,
  MemberFields,
  MemberHistory,
  OptInForm,
} from './Member.d.ts';
import { getMemberHistory } from './getMemberHistory.op.mts';
import classes from './MemberHistory.module.css';
import tabsClasses from './Tabs.module.css';


export function MemberHistory({ member }: { member: Member }) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<MemberHistory>();

  useEffect(() => {
    if (member.matchCards) return setLoading(false);

    if (loading) return;

    setLoading(true);

    getMemberHistory(workspaceId!, member.id)
      .then(setHistory)
      .finally(() => setLoading(false));
  }, [member]);

  return (
    <>
      <MemberCard fields={member.fields || {}} />

      {loading !== false
        ? <OrbiitLoader />
        : <HistoryTabs history={history} />
      }
    </>
  );
}
MemberHistory.displayName = 'MemberHistory';

export function MemberCard({
  fields: {
    company,
    fullName,
    role,
    url,
  } = {},
}: { fields: MemberFields }) {
  return (
    <header className={classes.Nameplate}>
      <h1>{fullName}</h1>

      <h3>{composeCompanyRole(role, company)}</h3>

      <p>
        {url && (<SocialIconLink url={url} />)}
      </p>
    </header>
  );
}
MemberCard.displayName = 'MemberCard';

const enum HistoryTab {
  Matches = 'matches',
  Optins = 'optins',
}
function HistoryTabs({ history }: { history?: MemberHistory }) {
  const [activeTab, setActiveTab] = useState<HistoryTab>(HistoryTab.Matches);

  let Card: typeof MatchCard | typeof OptinCard;
  let data: MemberHistory['matchCards'] | MemberHistory['optInForms'] | undefined;
  switch (activeTab) {
    case HistoryTab.Matches:
      Card = MatchCard;
      data = history?.matchCards;
      break;
    case HistoryTab.Optins:
      Card = OptinCard;
      data = history?.optInForms;
      break;
  }

  return (
    <>
      <nav className={tabsClasses.Tabs} role="tablist">
        <CTA
          active={activeTab === 'matches' ? '' : null}
          className={tabsClasses.Tab}
          guise={CTA.GUISES.LINK}
          role="tab"
          to={() => setActiveTab(HistoryTab.Matches)}
        >
          Matches
        </CTA>

        <CTA
          active={activeTab === 'optins' ? '' : null}
          className={tabsClasses.Tab}
          guise={CTA.GUISES.LINK}
          role="tab"
          to={() => setActiveTab(HistoryTab.Optins)}
        >
          Opt-ins
        </CTA>
      </nav>

      <Fragment key={activeTab}>
        {/* eslint-disable-next-line react/prop-types */}
        {data?.map((props) => (<Card key={cardKeyComposers[activeTab](props)} {...props} />))}
      </Fragment>

      {!data?.length && (
        <div className={classes.Nameplate}>
          <OrbiitIcon icon="EmptyEngagementIcon" size="xxlarge" />

          No records found
        </div>
      )}
    </>
  );
}
HistoryTabs.displayName = 'HistoryTabs';

function MatchCard({
  matchDate,
  matchRatingGiven,
  matchRatingReceived,
  members,
}: MemberHistory['matchCards'][number]) {
  return (
    <div className={clsx(classes.Card, classes.MatchCard)} role="tabpanel">
      <section>
        {members.map(({
          fields: {
            fullName,
            company,
            role,
          },
        }) => (
          <Fragment key={`${matchDate}.${fullName}`}>
            <p className={classes.CardSubject}>{fullName}</p>

            <p>{composeCompanyRole(role, company)}</p>
          </Fragment>
        ))}
      </section>

      <section>
        <p>
          <time dateTime={matchDate}>{formatDate(matchDate)}</time>
        </p>

        {matchRatingGiven && (
          <p>👏 Given: {matchRatingGiven}</p>
        )}

        {matchRatingReceived && (
          <p>👏 Received: {matchRatingReceived}</p>
        )}
      </section>
    </div>
  );
}
MatchCard.displayName = 'MatchCard';

function OptinCard({
  createdAt,
  engagement: {
    id,
    name,
    product,
  },
}: MemberHistory['optInForms'][number]) {
  const { workspaceId } = useParams() as WalledRouteParams;

  return (
    <div className={clsx(classes.Card, classes.OptinCard)} role="tabpanel">
      <time dateTime={createdAt}>{formatDate(createdAt)}</time>

      {' '}

      <Link
        className={classes.CardSubject}
        to={generatePath('/w/:workspaceId/engagements/:engagementId', {
          engagementId: id,
          workspaceId,
        })}
      >
        {name}
      </Link>

      {' '}

      <Chip variant={Chip.VARIANTS.NEUTRAL}>{PRODUCT_TO_TYPE_DICT[product]}</Chip>
    </div>
  );
}
OptinCard.displayName = 'OptinCard';

export function composeCompanyRole(
  role: NonNullable<Member['fields']>['role'],
  company: NonNullable<Member['fields']>['company'],
) {
  const output = [];

  if (role) output.push(role);
  if (company) output.push(`@ ${company}`);

  return output.join(' ');
}

const cardKeyComposers: Record<HistoryTab, (data: any) => string> = {
  [HistoryTab.Optins]: (data: OptInForm) => `optin.${data.createdAt}.${data.engagement.id}`,
  [HistoryTab.Matches]: (data: MatchCard) => `match.${data.matchDate}.${data.members.map((m) => m.email)}`,
};
