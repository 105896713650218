import type { OrbiitAPI } from '…/app/state/api.d.ts';

import type {
  Teammate,
  WorkspaceAdmin,
} from '…/app/state/states.d.ts';
import type { Role } from '…/app/state/Role.mts';

import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { workspacesStore } from './workspaces.store.mts';

import { UPSERT_TEAMMATE } from './upsertTeammate.gql.mjs';

import { gotTeammates } from './gotTeammates.mts';


interface TeammateInput {
  firstName: OrbiitAPI.Teammate['user']['profile']['firstName'],
  lastName: OrbiitAPI.Teammate['user']['profile']['lastName'],
  role: Role,
  userEmail: string,
}

export function upsertTeammate(workspaceId: ObjectId, teammate: Teammate) {
  const update: TeammateInput = {
    firstName: teammate.profile.firstName,
    lastName: teammate.profile.lastName,
    role: teammate.role,
    userEmail: teammate.emails[0],
  };

  return gqlOperation<{
    workspace: {
      teammates: OrbiitAPI.Teammate[],
    },
  }>(UPSERT_TEAMMATE, {
    ...update,
    workspaceId,
  })
    .then((updated): WorkspaceAdmin['teammates'] => gotTeammates(updated.workspace.teammates))
    .then((processedTeammates) => {
      const wTeammates = workspacesStore.get(workspaceId)!.teammates;

      wTeammates.clear();
      for (const [id, t] of processedTeammates.entries()) wTeammates.set(id, t);

      return processedTeammates;
    });
}
