import { PropsWithChildren } from 'react';
import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import _find from 'lodash-es/find.js';

import classes from './Banner.module.css';


export function Banner({
  children,
}: PropsWithChildren<object>) {
  return (
    <div className={clsx(classes.Banner, 'Well')}>
      {children}
    </div>
  );
}
Banner.displayName = 'Banner';

type PropertiesItemType = {
  type: 'emoji' | 'title' | 'urlHref' | 'urlCTA';
  value: string;
}

export function AcquisitionBanner({ properties }: { properties: PropertiesItemType[] }) {
  if (localStorage.getItem(STORAGE_KEY) === STORAGE_VAL) return null;

  const emoji: string | null = _find(properties, (p) => p.type === 'emoji')?.value;
  const title: string | null = _find(properties, (p) => p.type === 'title')?.value;
  const urlHref: string | null = _find(properties, (p) => p.type === 'urlHref')?.value;
  const urlCTA: string | null = _find(properties, (p) => p.type === 'urlCTA')?.value;

  function onClose() {
    localStorage.setItem(STORAGE_KEY, STORAGE_VAL);
    window.location.reload();
  }

  return (
    <section className={classes.GradientBackground}>
      {emoji && (<span role="img">{emoji}</span>)}

      <p>{title}</p>

      {(urlHref && urlCTA) && (
        <a
          className={classes.BannerCTA}
          href={urlHref}
          rel="noreferrer"
          target="_blank"
        >{urlCTA}
        </a>
      )}

      <Button
        appearance={Button.APPEARANCES.BASIC}
        className={classes.CloseButton}
        onClick={onClose}
        variant={Button.VARIANTS.GLYPH}
      >×
      </Button>
    </section>
  );
}

AcquisitionBanner.displayName = 'AcquisitionBanner';

const STORAGE_KEY = 'AcquisitionBanner';
const STORAGE_VAL = 'hidden';
