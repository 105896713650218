export const METRICS_EMPTY_TEXTS = {
  'split-out': {
    title: 'Opt-In Rate',
    text: 'Waiting for Opt-In Forms.\n\nData will begin to populate when your engagement is in the Opt-Ins Open stage.',
  },
  'split-out-not-available': {
    title: 'Opt-In Rate',
    text: 'We can not display Opt-In rates when there is no Audience data available.',
  },
  nps: {
    title: 'Net Promoter Score',
    text: 'Waiting for Feedback Form submissions.\n\nData will begin to populate when the first feedback form is submitted.',
  },
  rating: {
    title: 'Average Match Rating',
    text: 'Waiting for Feedback Form submissions.\n\nData will begin to populate when the first feedback form is submitted.',
  },
  optInQuestion: {
    title: 'Opt-In Questions',
    text: 'Waiting for Opt-In Form submissions.\n\nData will begin to populate when the first form-app is submitted.',
  },
  feedbackQuestion: {
    title: 'Feedback Questions',
    text: 'Waiting for the first Feedback Forms to be submitted.',
  },
};
