import { clsx } from 'clsx';
import {
  type Dispatch,
  type FocusEvent,
  PureComponent,
  type SetStateAction,
} from 'react';
import { toast } from 'sonner';

import type { EngagementSetupRouteParams } from '…/app/route-params.mts';

import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import type {
  Cursors,
  PaginatedRecords,
  Paging,
} from '…/app/common/pagination/pagination.d.ts';
import { PagingControls } from '…/app/common/pagination/PagingControls.tsx';
import { paginationFactory } from '…/app/common/pagination/factories.mts';

import { MemberHistory } from '…/app/w/workspace/audiences/audience/members/MemberHistory.tsx';

import type { Engagement } from '../../Engagement.d.ts';
import type { EngagementViewData } from '../EngagementView.d.ts';
import { STATUSES } from '../../constants.mts';

import { composeScheduleState } from '../setup/schedule/composeScheduleData.mts';

import type {
  Match,
  MatchMetaKey,
  MatchMetas,
  Matches,
  OptIn,
  OptIns,
} from './Match.d.ts';
import {
  addGroup,
} from './stateCRUD.mts';
import type { DragInfo } from './onDrag.mts';
import { fetchMatchGroups } from './fetchMatchGroups.op.mts';
import { fetchMatchMeta } from './fetchMatchMeta.op.mts';
import { onDrop } from './onDrop.mts';
import { onDissolveMatch } from './onDissolveMatch.mts';
import { onSave } from './onSave.mts';
import { onUngroupParticipant } from './onUngroupParticipant.mts';
import { AvailableMembers } from './AvailableMembers.tsx';
import { MatchesForm } from './MatchesForm.tsx';
import { MatchesGenerating } from './MatchesGenerating.tsx';
import { MatchesHeader } from './MatchesHeader.tsx';

import classes from './Matches.module.css';
import { composeGroupMetaKey, selectGroupOptins } from './composeGroupMetaKey.mts';


export class EditMatches extends PureComponent<MatchEditingProps, MatchEditingState> {
  static displayName = 'EditMatches';

  cursors: Cursors = {
    current: {
      end: '',
      start: '',
    },
  };

  dragging = {} as DragInfo;

  lonelyGroups = new Set<Match['id']>();

  meta: Record<MatchMetaKey, MatchMetas> = {};

  state: MatchEditingState = {
    available: new Map() as OptIns,
    deletedMatches: new Set<Match['id']>(),
    groupCount: 0,
    groups: new Map() as Matches,
    isDirty: false,
    isLoading: false,
    isSaving: false,
    matchesSearch: { query: '' },
    meta: {},
    page: {
      limit: LIMIT_PER_PAGE,
      number: 1,
      reset: false,
    },
  };

  constructor(props: MatchEditingProps) {
    super(props);

    this.cursors.current.end = props.engagement.matches.pageInfo.endCursor;
    this.cursors.current.start = props.engagement.matches.pageInfo.startCursor;

    this.state.available = props.engagement.unmatched;
    this.state.groupCount = props.engagement.matches.totalCount;
    this.state.groups = props.engagement.matches.nodes as Matches;
  }

  onAddGroup = () => addGroup(this.setState.bind(this), this.props.engagement.product);

  onFocusGroup = async ({ currentTarget: { id: groupId } }: FocusEvent<HTMLDetailsElement & { id: OptIn['id'] }>) => {
    const groupMembers = selectGroupOptins(this.state.groups, groupId);
    const metaKey = composeGroupMetaKey(groupMembers);

    if (metaKey in this.meta) return this.setState({ meta: this.meta[metaKey] });

    const meta = this.meta[metaKey] = await fetchMatchMeta(this.props.engagement.id, groupMembers);
    this.setState({ meta });
  };

  onConfirmed = (status: Engagement['status']) => {
    this.props.setPageProps((prev) => ({
      ...prev,
      engagement: {
        ...prev.engagement,
        status,
      },
    }));
  };

  /* [1] */ onDissolveMatch = onDissolveMatch.bind(this);

  /* [1] */ onUngroupParticipant = onUngroupParticipant.bind(this);

  /* [1] */ onDrop = onDrop.bind(this);

  /* [1] */ onSave = onSave.bind(this);

  onFetch = (
    page: MatchEditingState['page'],
    matchesSearch: MatchEditingState['matchesSearch'],
    reset = false,
  ) => {
    this.setState({ isLoading: true });

    const { engagementId, workspaceId } = this.props.params;
    const pagination = paginationFactory<Match, 'matches'>(
      'matches',
      {
        cursors: this.cursors,
        direction: page.direction,
        limit: page.limit,
        reset,
        search: matchesSearch.query,
      },
      {
        setCount: this.setGroupCount,
        setRecords: this.setGroups,
      },
    );

    return fetchMatchGroups(
      engagementId,
      workspaceId,
      pagination.props,
    )
      .then((matches) => ({ matches }))
      .then(pagination.then)
      .finally(() => this.setState({ isLoading: false }));
  };

  onPage: Dispatch<SetStateAction<Paging>> = (setPage) => {
    if (this.state.isDirty) {
      toast.error(UNSAVED_CHANGES_PAGING_ERR);
      return Promise.reject(UNSAVED_CHANGES_PAGING_ERR);
    }

    const page = typeof setPage === 'function' ? setPage(this.state.page) : setPage;

    return this.onFetch(page, this.state.matchesSearch)
      .then((data) => {
        this.setState({ page });

        return data;
      });
  };

  onSearch = (matchesSearch: MatchEditingState['matchesSearch']) => {
    if (this.state.isDirty) {
      toast.error(UNSAVED_CHANGES_SEARCH_ERR);
      return Promise.reject(UNSAVED_CHANGES_SEARCH_ERR);
    }

    this.onFetch(this.state.page, matchesSearch, true)
      .then((data) => { // Handle state update after in order to facilitate batching renders
        this.setState((prev) => ({
          matchesSearch,
          page: {
            direction: 'forward',
            limit: prev.page.limit,
            number: 1,
            reset: true,
          },
        }));

        return data;
      });
  };

  setDragging = (dragging: this['dragging']) => Object.assign(this.dragging, dragging);

  setGroupCount: Dispatch<SetStateAction<MatchEditingState['groupCount']>> = (setGroupCount) => {
    this.setState((prev) => ({
      groupCount: typeof setGroupCount === 'function' ? setGroupCount(prev.groupCount) : setGroupCount,
    }));
  };

  setGroups: Dispatch<SetStateAction<MatchEditingState['groups']>> = (setGroups) => {
    this.setState((prev) => ({
      groups: typeof setGroups === 'function' ? setGroups(prev.groups) : setGroups,
    }));
  };

  render() {
    const {
      engagement,
    } = this.props;
    const {
      available,
      groups,
      isDirty,
      isLoading,
      isSaving,
    } = this.state;

    const isMatching = engagement.status === STATUSES.MATCHING;
    const editable = engagement.isEditMatchesEnabled && isMatching;

    if (isMatching && !engagement.matchesCount) return <MatchesGenerating />;

    const editableOrAvailable = (editable || available?.size > 0);

    return (
      <div
        className="ContentContainer"
        data-testid="EditMatches"
      >
        <Bureau>
          <Shelf
            as="main"
            className={clsx('ListPage', classes.Page, {
              [classes.WithSidebar]: editableOrAvailable,
              [classes.WithoutSidebar]: !editableOrAvailable,
            })}
          >
            <MatchesHeader
              closesAt={composeScheduleState(engagement).matchEditingCloses}
              dirty={isDirty}
              editingEnabled={engagement.isEditMatchesEnabled}
              formId={FORM_ID}
              isSaving={isSaving}
              onAddGroup={this.onAddGroup}
              onConfirmed={this.onConfirmed}
              onSearch={this.onSearch}
              readOnly={!editable}
              setState={this.setState}
              size={groups?.size}
              status={engagement.status}
              total={this.state.groupCount}
            />

            <MatchesForm
              deletedMatches={this.state.deletedMatches}
              dragging={this.dragging}
              formId={FORM_ID}
              groups={groups}
              isDirty={isDirty}
              isLoading={isLoading}
              lonelyGroups={this.lonelyGroups}
              onDissolveMatch={this.onDissolveMatch}
              onDrop={this.onDrop}
              onFocusGroup={this.onFocusGroup}
              onSave={this.onSave}
              onUngroupParticipant={this.onUngroupParticipant}
              page={this.state.page}
              readOnly={!editable}
              setDragging={this.setDragging}
              setState={this.setState.bind(this)}
            >
              <PagingControls
                onPage={this.onPage}
                page={this.state.page}
                total={this.state.groupCount}
              />
            </MatchesForm>

            {editableOrAvailable && (
              <AvailableMembers
                available={available}
                dragging={this.dragging}
                editable={editable}
                meta={this.state.meta}
                onDrop={this.onDrop}
                setDragging={this.setDragging}
                setMemberToInspect={(memberToInspect) => this.setState({ memberToInspect })}
              />
            )}
          </Shelf>

          <Drawer
            className={classes.Drawer}
            modal
            onClose={() => this.setState({ memberToInspect: undefined })}
            open={!!this.state.memberToInspect}
          >
            {this.state.memberToInspect && <MemberHistory member={this.state.memberToInspect} />}
          </Drawer>
        </Bureau>
      </div>
    );
  }
}

const FORM_ID = 'edit-matches';
const UNSAVED_CHANGES_PAGING_ERR = 'Changes must be saved before changing pages.';
const UNSAVED_CHANGES_SEARCH_ERR = 'Changes must be saved before searching.';

export const LIMIT_PER_PAGE = 5;

export interface MatchEditingState {
  available: OptIns,
  deletedMatches: Set<Match['id']>,
  groupCount: Int,
  groups: Matches,
  isDirty: boolean,
  isLoading: boolean,
  isSaving: boolean,
  matchesSearch: { query?: string },
  memberToInspect?: OptIn['member'],
  meta?: MatchMetas,
  page: Paging,
}

export interface EngagementMatching {
  id: Engagement['id'],
  isEditMatchesEnabled: Engagement['isEditMatchesEnabled'],
  matches: PaginatedRecords<Match>,
  matchesCount: Int,
  unmatched: OptIns,
  product: Engagement['product'],
  status: Engagement['status'],
}

interface MatchEditingProps {
  engagement: EngagementMatching,
  params: EngagementSetupRouteParams,
  setPageProps: Dispatch<SetStateAction<EngagementViewData<EngagementMatching>>>,
}
