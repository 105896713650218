import Button from 'form5/react/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { EngagementRouteParams } from '…/app/route-params.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { cancelEngagement } from '…/app/w/workspace/engagements/engagement/gql/cancelEngagement.op.mjs';


export function CancelEngagement({
  onSuccess,
}: {
  onSuccess(result: Engagement): void,
}) {
  const { engagementId, workspaceId } = useParams() as EngagementRouteParams;
  const [isSubmitting, setSubmitting] = useState(false);

  async function onSubmit() {
    setSubmitting(true);

    return cancelEngagement({
      engagementId,
      workspaceId,
    })
      .then((result) => {
        // ORB-2007 - Fix for API returning 'old' `status` field due to query pooling on GraphQL field
        if (!result.isLive && result.cancelledAt) {
          result.status = 'DRAFT';
        }
        onSuccess(result);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <h1>Cancel Engagement</h1>

      <p>
        I acknowledge that this engagement will return to draft
        and all scheduled emails and communications will be cancelled.
      </p>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        onClick={onSubmit}
        type="submit"
      >Revert to Draft
      </SubmitButton>
    </>
  );
}
CancelEngagement.displayName = 'CancelEngagement';
