import propTypes from 'prop-types';
import { rawTimeZones } from '@vvo/tzdb';


const { string } = propTypes;

const options = rawTimeZones.map(({ name, rawFormat }) => (
  <option key={rawFormat} value={name}>{rawFormat.split(' - ')[0]}</option>
));

export function TimezoneList({
  id,
}) {
  return (
    <datalist id={id}>
      {options}
    </datalist>
  );
}
TimezoneList.displayName = 'TimezoneList';

TimezoneList.propTypes = {
  id: string.isRequired,
};
