._DotLoader_1tjkj_1 {
  --size: 33%;
  --speed: 1.3s;

  align-items: center;
  display: inline-flex;
  flex-direction: row;
  gap: calc(var(--size) * 1.4);
  height: 1lh;
  justify-content: center;
}

._Dot_1tjkj_1 {
  animation-delay: calc(var(--speed) * 0.125 * var(--i)); /* attr & counter don't work with calc */
  animation-duration: var(--speed);
  animation-iteration-count: infinite;
  animation-name: _pulse_1tjkj_1;
  animation-timing-function: ease-in-out;
  aspect-ratio: 1;
  background-color: currentcolor; /* inherit text color */
  border-radius: 50%;
  display: inline-block;
  height: var(--size);
  transform: scale(0);
}

._Dot_1tjkj_1:not(:first-child, :last-child) {
  animation-fill-mode: both;
}

@keyframes _pulse_1tjkj_1 {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }
}
