import audienceFrag from './audience.frag.gql';

export const CREATE_AUDIENCE = `
mutation CreateAudience($workspaceId: ID!, $name: String!) {
  audience: createAudience(workspaceId: $workspaceId, name: $name) {
    ...audienceFields
  }
}

${audienceFrag}
`;
