import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';
import {
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';

import type {
  AudienceDict,
  AudienceId,
} from '../Audience.d.ts';
import { AudienceUpdateWarning } from '../AudienceUpdateWarning.tsx';

import type {
  Member,
} from './Member.d.ts';
import type { SelectedAudiences } from './AssociatedAudiencesList.tsx';
import { AssociatedAudiencesList } from './AssociatedAudiencesList.tsx';
import classes from './MemberForm.module.css';


export function UpsertMemberForm({
  member: {
    id,
    alternativeEmails,
    email,
    fields: {
      company,
      firstName,
      lastName,
      linkedin,
      role,
      url,
    } = {},
    ...member
  } = {},
  onSubmit,
}: {
  member?: Partial<Member>,
  onSubmit(member: Partial<Member>, audienceIds: AudienceId[]): Promise<void>,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const isNew = !id;
  const { audienceId, workspaceId } = useParams() as WalledRouteParams;
  const customAudiences = useStore((data) => data.workspaces[workspaceId].customAudiences) as AudienceDict;
  const [altEmailsState, setAltEmails] = useState(new Set(alternativeEmails ??= new Array()));
  const [selectedAudiences, setSelectedAudiences] = useState<SelectedAudiences>(() => {
    const preset: SelectedAudiences = new Map();
    if (member.audienceIds?.size) member.audienceIds.forEach((aId) => preset.set(aId, customAudiences.get(aId).name));
    return preset;
  });
  const isEditor = !!useEditorRole();

  useEffect(() => {
    // This must be set AFTER the initial render to avoid confusing form5's diffing algorithm
    // (which will otherwise think the value was pre-existing, eg already saved).
    if (isNew && audienceId && customAudiences.has(audienceId)) {
      setSelectedAudiences((prev) => {
        const updated = new Map(prev);
        updated.set(audienceId, customAudiences.get(audienceId).name);
        return updated;
      });
    }
  }, []);

  // D = <Partial<Member> & { audienceIds: AudienceId[] }>
  const onUpsert: OnSubmit<{ audienceIds: ObjectId[], email: string }> = (
    { audienceIds, ...delta },
    all, // eslint-disable-line @typescript-eslint/no-shadow
  ) => {
    setIsSaving(true);

    const result = onSubmit(
      {
        id,
        ...delta,
        email: all.email,
      },
      audienceIds,
    )
      .finally(() => setIsSaving(false));

    toast.promise(result, {
      error: 'Saving member failed',
      loading: 'Saving member…',
      success: `Member ${id ? 'updated' : 'created'}`,
    });

    return result;
  };

  return (
    <Form
      name="edit-member"
      onSubmit={onUpsert}
    >
      <h1>{isNew ? 'Add' : 'Edit' } member</h1>

      <AudienceUpdateWarning />

      <fieldset disabled={!isEditor}>
        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          defaultValue={email}
          fluid
          label="Email"
          name="email"
          placeholder="alex@good.inc"
          readOnly={!isNew}
          required
          type="email"
        />

        {!isNew && ( /* readOnly fields are excluded from form submission */
          <input
            name="email"
            type="hidden"
            value={email}
          />
        )}

        <FeatureFlag flags={[FeatureFlag.FLAGS.addAlternativeMemberEmails]}>
          <details>
            <summary className={classes.CollapsingHeader}>Alternative emails</summary>

            <fieldset>
              {Array.from(altEmailsState).map((alt, i) => (
                <div className={classes.ListInput} key={alt}>
                  <Field
                    defaultValue={alt}
                    id={`alternativeEmails[${i}]`}
                    label="Email"
                    name="alternativeEmails[]"
                    required
                    type="email"
                  />

                  <Button
                    onClick={() => setAltEmails((prev) => {
                      const updated = new Set(prev);
                      updated.delete(alt);
                      return updated;
                    })}
                    title="remove email"
                    variant={Button.VARIANTS.GLYPH}
                  >
                    <OrbiitIcon icon="TrashIcon" />
                  </Button>
                </div>
              ))}

              <Button
                className={classes.AddFieldCTA}
                onClick={() => setAltEmails((prev) => (new Set(prev).add('')))}
              >
                Add email
              </Button>
            </fieldset>
          </details>
        </FeatureFlag>

        <fieldset className={classes.Fieldset} name="fields">
          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={firstName}
            fluid
            label="First name"
            name="firstName"
            placeholder="Alex"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={lastName}
            fluid
            label="Last name"
            name="lastName"
            placeholder="Smith"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={company}
            fluid
            label="Company"
            name="company"
            placeholder="Good inc."
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={role}
            fluid
            label="Role"
            name="role"
            placeholder="CEO of in-house marketing"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={linkedin}
            fluid
            label="LinkedIn handle"
            name="linkedin"
            // pattern="[\w\-]+"
            placeholder="alex-smith"
            // title="do not include 'linkedin.com/in/'"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={url}
            fluid
            label="Website"
            name="url"
            placeholder="https://www.good.inc"
            type="url"
          />
        </fieldset>

        <AssociatedAudiencesList
          selected={selectedAudiences}
          setSelected={setSelectedAudiences}
        />

        <SubmitButton
          appearance={Button.APPEARANCES.AFFIRMING}
          disabled={isSaving}
          isSubmitting={isSaving}
          type="submit"
        >Save
        </SubmitButton>
      </fieldset>
    </Form>
  );
}
UpsertMemberForm.displayName = 'UpsertMemberForm';
