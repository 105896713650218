import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import {
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { uid } from 'uid';

import type { WalledRouteParams } from '…/app/route-params.mts';
import type { Teammate, WorkspaceAdmin } from '…/app/state/states.d.ts';
import { useStore } from '…/app/state/useStore.mts';
import { removeTeammates } from '…/app/state/workspaces/removeTeammates.op.mts';
import { upsertTeammate } from '…/app/state/workspaces/upsertTeammate.op.mts';

import type { TableHeading } from '…/app/common/Table/Table.tsx';
import { Table } from '…/app/common/Table/Table.tsx';

import type { DeSelectAllState } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import {
  DeSelectAll,
  areAnySelected,
  onUpdateSelection,
} from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import { getSelectedItems } from '…/app/w/workspace/common/DeSelectAll/getSelectedItems.mts';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import type { FormConfig } from '../crudForms.mts';
import { forms } from '../crudForms.mts';
import panelClasses from '../SettingsPanel.module.css';

import { transformTeammatesToTabularData } from './transformTeammatesToTabularData.tsx';

import classes from './WorkspaceTeam.module.css';


export function WorkspaceTeammates({
  className,
}: {
  className: string,
}) {
  const { workspaceId } = useParams() as WalledRouteParams;
  useStore((data) => data.workspaces[workspaceId]);
  const teammates = useStore((data) => data.workspaces[workspaceId].teammates); // FIXME: this should not be necessary

  const [form, setForm] = useState<FormConfig>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [selected, setSelected] = useState<DeSelectAllState>(() => {
    const initial = new Map();
    for (const id of teammates.keys()) initial.set(id, initial.get(id) ?? false);
    return initial;
  });

  function setDialog(context?: FormConfig | false) {
    setDialogOpen(!!context);
    if (context) return setForm(context);
  }

  function onDeleteTeammates(primaryEmails: string[]) {
    return removeTeammates(workspaceId, primaryEmails)
      .then((update) => {
        setSelected(() => {
          const updated = new Map();
          for (const id of update.keys()) updated.set(id, false);
          return updated;
        });

        setDialogOpen(false);
      });
  }

  function onUpsertTeammate(teammate: Teammate) {
    return upsertTeammate(workspaceId, teammate)
      .then(() => {
        setDialogOpen(false);

        onUpdateSelection(selected, setSelected, false);
      });
  }

  headings.set('checkbox', {
    label: (
      <DeSelectAll
        selected={selected}
        setSelected={setSelected}
      />
    ),
  });

  const CRUDForm = forms[form?.name ?? ''];

  const mainAction = areAnySelected(selected)
    ? {
      label: 'Delete selected',
      props: {
        appearance: Button.APPEARANCES.DANGER,
        onClick: () => setDialog({
          data: {
            onSubmit: onDeleteTeammates,
            teammates: getSelectedItems<WorkspaceAdmin['teammates']>(selected, teammates),
          },
          key: 'delete',
          name: 'delete',
        }),
      },
    }
    : {
      label: 'Add new user',
      props: {
        appearance: Button.APPEARANCES.PRIMARY,
        onClick: () => setDialog({
          data: {
            onSubmit: onUpsertTeammate,
          },
          key: `add.${uid(4)}`,
          name: 'add',
        }),
      },
    };

  return (
    <main
      className={panelClasses.SettingsPanel}
      data-testid="Settings:Team"
    >
      <div className="SplitHeader">
        <h1>Manage Team</h1>

        <div>
          <Button {...mainAction.props}>{mainAction.label}</Button>
        </div>
      </div>

      <section className={clsx(className, 'Card', panelClasses.Section)}>
        <Table
          className={classes.TeamTable}
          headings={headings}
          records={transformTeammatesToTabularData(
            teammates ?? new Map(),
            selected,
            {
              onSelect: ({ id, value }: { id: string, value: boolean }) => setSelected((prev) => {
                const updated = new Map(prev);
                updated.set(id, value);
                return updated;
              }),
              onUpsertTeammate,
              setDialog,
            },
          )}
        />
      </section>

      <Dialog
        backdropped
        open={!!isDialogOpen}
        setOpen={setDialogOpen}
      >
        <CRUDForm {...form?.data} key={form?.key} />
      </Dialog>
    </main>
  );
}
WorkspaceTeammates.displayName = 'WorkspaceTeammates';

const handle = {
  title: 'Team',
};

export {
  WorkspaceTeammates as Component,
  handle,
};

const headings = new Map<string, TableHeading>([
  ['checkbox', {}],
  ['firstName', {
    label: 'First Name',
  }],
  ['lastName', {
    label: 'Last Name',
  }],
  ['email', {
    label: 'Email',
  }],
  ['role', {
    label: 'Role',
  }],
  ['controls', {}],
]);
