import type { ButtonHTMLAttributes, ReactNode } from 'react';
import { clsx } from 'clsx';

import classes from './IconButton.module.css';

export function IconButton({
  children,
  className,
  onClick,
  ...props
}: {
  children: ReactNode,
  className?: string;
  onClick: () => void
} & ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className={clsx(classes.IconButton, className)}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

IconButton.displayName = 'IconButton';
