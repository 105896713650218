fragment teammatesFields on Workspace {
  teammates {
    role
    userId
    user {
      emails
      profile {
        firstName
        imageUrl
        lastName
      }
    }
  }
}
