/* eslint-disable react/jsx-max-depth */

import { clsx } from 'clsx';
import { toast } from 'sonner';

import { StickyHeader } from '…/app/w/workspace/common/StickyHeader/StickyHeader.tsx';
import { STATUSES, STATUSES_ORDERED } from '…/app/w/workspace/engagements/constants.mts';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';
import type { Engagement } from '@/app/w/workspace/engagements/Engagement.d.ts';

import { updateEngagement } from '../gql/updateEngagement.op.mts';
import subpageClasses from '../setup/EngagementSubPage.module.css';

import { MatchingNotificationsForm } from './FormMatchingNotifications.tsx';
import { MatchingConfigurationForm } from './FormMatchingConfiguration.tsx';
import { ConversationSettingsForm } from './FormConversationSettings.tsx';

import classes from './EngagementSettingsPage.module.css';

export function EngagementSettingsPage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState();
  const {
    engagementId,
    workspaceId,
  } = params;

  const title = 'Advanced Settings';

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  async function onEngagementSubmit(delta: Record<string, any>) {
    return updateEngagement({
      engagement: delta,
      engagementId,
      workspaceId,
    })
      .then((updatedData: Partial<Engagement>) => {
        setPageProps((prev) => ({
          ...prev,
          engagement: updatedData,
        }));
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      })
      .finally(() => toast.success('Settings saved.'));
  }

  const statusIx = STATUSES_ORDERED.indexOf(engagement.status);
  // const canEditInviteSettings = statusIx < STATUSES_ORDERED.indexOf(STATUSES.OPTINSOPEN);
  const canEditMatchingSettings = statusIx < STATUSES_ORDERED.indexOf(STATUSES.MATCHING);
  const canEditMatchExecutionSettings = canEditMatchingSettings;

  return (
    <main
      className={clsx(className, classes.Page)}
      data-testid="EngagementSettingsPage"
    >
      <StickyHeader className={classes.Header}>
        <h1 className={subpageClasses.Title}>Experimental: {title}</h1>
      </StickyHeader>

      <MatchingConfigurationForm
        disabled={!canEditMatchingSettings}
        engagement={engagement}
        onSubmit={onEngagementSubmit}
      />

      <MatchingNotificationsForm
        disabled={!canEditMatchExecutionSettings}
        engagement={engagement}
        onSubmit={onEngagementSubmit}
      />

      <ConversationSettingsForm
        disabled={!canEditMatchingSettings}
        engagement={engagement}
        onSubmit={onEngagementSubmit}
      />
    </main>
  );
}
EngagementSettingsPage.displayName = 'EngagementSettingsPage';

const handle = {
  sidebar: true,
  title: 'Engagement Setup Settings',
};

export {
  EngagementSettingsPage as Component,
  handle,
};
