.Dialog {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 50ch;
  min-width: 33vw;
  opacity: 0;
  padding: 2em;
  pointer-events: none;
  position: fixed;
  transition-duration: var(--transition-duration-controls);
  transition-property: opacity;
}

@media (width <= 960px) {
  .Dialog {
    width: calc(100% - 2 * var(--grid-gutter));
  }
}

.Dialog[open] {
  opacity: 1;
  pointer-events: all;
}

.Dialog:not(.Backdropped)::backdrop {
  display: none;
}

.Backdropped::backdrop {
  background: black; /* css variables are unavailable here for some reason 😵‍💫 */
  opacity: 0.3;
}

.CloseButton {
  position: absolute;
  right: 0;
  top: 0;
}

/* .Dialog button,
.Dialog [role="group"] {
  place-self: end;
} */
