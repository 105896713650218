import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';

import { DELETE_OPTIN_FORMS } from './deleteOptIns.gql.mjs';

export function deleteOptIns(
  workspaceId: ObjectId,
  optInFormIds: ObjectId[],
) {
  return gqlOperation<{ completed: boolean }>(DELETE_OPTIN_FORMS, {
    optInFormIds,
    workspaceId,
  })
    .then((data) => !!data?.completed);
}
