import { Link } from 'react-router-dom';

import styles from './BlankNav.module.css';


export function BlankNav() {
  return (
    <header className={styles.BlankNav}>
      <Link to="/w">
        <img
          alt="Orbiit"
          height="32px"
          src="https://storage.googleapis.com/orbiit-assets/logos/Orbiit-LogoMark-platform.png"
        />
      </Link>
    </header>
  );
}
BlankNav.displayName = 'BlankNav';
