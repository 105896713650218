import { clsx } from 'clsx';

import InfoIcon from '…/app/deprecated/components/icons/InfoIcon.jsx';

import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import classes from './MetricCardHeader.module.css';

const MetricCardHeader = ({
  title,
  description
}: {
  title: string,
  description?: string,
}) => {
  return (
    <div className={classes.Header}>
      <h3>{title}</h3>

      {description && (
        <Popover
          anchor={({ className, ...props }) => (
            <InfoIcon {...props} className={clsx(className, classes.MetricInfoContainer)} />
          )}
          containerClassName={classes.Header}
          position={Popover.POSITIONS.TOP_RIGHT}
          type={Popover.TYPES.TOOLTIP}
        >
          {description}
        </Popover>
      )}
    </div>
  );
};

export default MetricCardHeader;
