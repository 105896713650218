import imageSrc from './generating.gif';


export function MatchesGenerating() {
  return (
    <div
      className="ContentContainer ListPage"
      style={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={imageSrc} style={{ width: '6em' }} />

      Matches are generating. Check back later.
    </div>
  );
}
MatchesGenerating.displayName = 'MatchesGenerating';
