.OptionTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
}

.OptionText,
.OptionTextLight {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
}

.OptionText {
  color: var(--brand-subtle-dark);
}

.OptionTextLight {
  color: var(--neutral-dark);
}
