import Button from 'form5/react/Button';
import { useState } from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { getSelectedMemberData } from '../getSelectedMemberData.mts';
import { AudienceUpdateWarning } from '../AudienceUpdateWarning.tsx';

import type { Member } from './Member.d.ts';

import classes from './MemberForm.module.css';


type MemberIds = Member['id'][];

export function DeleteMembersForm({
  members,
  onSubmit,
}: {
  members: ReturnType<typeof getSelectedMemberData>,
  onSubmit(memberIds: MemberIds): Promise<MemberIds>,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const memberIds: MemberIds = [];
  const memberNamesItems = [];

  for (const [id, { fields }] of members) {
    memberIds.push(id);
    memberNamesItems.push(<div className={classes.Name} key={id}>{fields?.fullName}</div>);
  }

  const membersLabel = memberIds.length > 1 ? 'members' : 'member';

  function deleteMembers() {
    setIsSaving(true);

    const promise = onSubmit(memberIds).finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: `Deleting ${membersLabel} failed`,
      loading: `Deleting ${membersLabel}…`,
      success: `Deleted ${membersLabel}`,
    });

    return promise;
  }

  return (
    <>
      <h1>Delete member(s)</h1>

      <AudienceUpdateWarning />

      <p>The following members will be permanently deleted:</p>

      <div className={classes.EnumeratedUpdateTargets}>{memberNamesItems}</div>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving}
        isSubmitting={isSaving}
        onClick={deleteMembers}
      >Delete
      </SubmitButton>
    </>
  );
}
DeleteMembersForm.displayName = 'DeleteMembersForm';
