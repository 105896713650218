import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import {
  useState,
} from 'react';
import {
  useParams,
} from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import AudienceIcon from '…/app/deprecated/components/icons/AudienceIcon.jsx';

import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { Table } from '…/app/common/Table/Table.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import type { Audience, AudienceDict } from './audience/Audience.d.ts';
import { createAudience } from './audience/createAudience.op.mts';

import type { FormConfig } from './crudForms.mts';
import { forms } from './crudForms.mts';
import { transformAudiencesToTabularData } from './transformAudiencesToTabularData.tsx';
import classes from './AudiencesList.module.css';


// TODO: Add the option to request an audience sync.
// rpc.post('actions/integration-sync-list-id', {
//   json: {
//     integrationId, (from audience.integration.id)
//     listIds: [remoteId], (from audience.remoteId)
//   },
// });

export function AudiencesListPage() {
  const { workspaceId } = useParams() as WalledRouteParams;
  const workspace = useStore((data) => data.workspaces[workspaceId]);
  const [form, setForm] = useState<FormConfig>();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const isEditor = !!useEditorRole();

  function setDialog(context?: FormConfig | false) {
    setDialogOpen(!!context);
    if (context) return setForm(context);
  }

  function upsertAudienceInStore(audience: Audience, isDeleted?: boolean) {
    const method = isDeleted ? 'delete' : 'set';

    // ORB-1760 - 'Fix' for an error when redirect after deleting an audience
    // from the audience page. For some reason adding the console.log removes the
    // error, possibly because of some kind of race-condition of some sort.
    // Slack thread: https://orbiit.slack.com/archives/C036G7ELFFC/p1701776390935359
    /* eslint-disable-next-line no-console */
    console.log('debug', {
      isDeleted,
      method,
    });

    (workspace.audiences as AudienceDict)[method](audience.id, audience);
    (workspace.customAudiences as AudienceDict)[method](audience.id, audience);

    setDialog(false);
  }

  const CRUDForm = forms[form?.name ?? ''];
  const builtinAudiences = (() => {
    const output: AudienceDict = new Map(workspace.builtinAudiences);
    output.delete(workspace.primaryAudience);
    return output;
  })();
  const primaryAudience = (workspace.audiences as AudienceDict).get(workspace.primaryAudience)!;

  return (
    <>
      <main className={clsx('ContentContainer', 'ListPage')} data-testid="AudiencesListPage">
        <header className="SplitHeader">
          <h1>
            <AudienceIcon />

            {' '}

            Audiences
          </h1>

          <Button
            disabled={!isEditor}
            onClick={() => setDialog({
              data: {
                action: 'create',
                onSubmit(audienceName: Audience['name']) {
                  return createAudience(workspaceId, audienceName)
                    .then(upsertAudienceInStore);
                },
              },
              key: 'create',
              name: 'create',
            })}
          >Create audience
          </Button>
        </header>

        <Table
          className={classes.Table}
          headings={headings}
          records={transformAudiencesToTabularData(
            {
              builtins: builtinAudiences,
              customs: workspace.customAudiences,
              primary: primaryAudience,
            },
            {
              isEditor,
              setDialog,
              upsertAudienceInStore,
              workspaceId,
            },
          )}
        />
      </main>

      <Dialog
        backdropped
        open={!!isDialogOpen}
        setOpen={setDialogOpen}
      >
        <CRUDForm {...form?.data} key={form?.key} />
      </Dialog>
    </>
  );
}
AudiencesListPage.displayName = 'AudiencesListPage';

const handle = {
  title: 'Audiences',
};

export {
  AudiencesListPage as Component,
  handle,
};

const headings = new Map([
  ['indicator', { wrap: false }],
  ['link', { label: 'Audience' }],
  ['memberCount', {
    label: 'Member Count',
    numeric: true,
  }],
  ['actions', {}],
]);
