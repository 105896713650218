import { toast } from 'sonner';

import type { EditMatches } from './EditMatches2.tsx';
import {
  type DeletedMatches,
  type UpsertedMatchResult,
  type UpsertedMatches,
  saveMatches,
} from './saveMatches.op.mts';

export function onSave(
  this: EditMatches,
  {
    deleted,
    ...upserted
  }: {
    deleted: DeletedMatches,
  } & UpsertedMatches,
) {
  const { engagementId } = this.props.params;
  this.setState({ isSaving: true });

  if (this.lonelyGroups.size) {
    toast.error([
      'Matches must have more than one participant.',
      this.lonelyGroups.size,
      'matches have too few participants.',
    ].join(' '), {
      description: [
        'To avoid breaking the Engagement, matches without enough participants have been removed',
        'and their participants moved to the available pool.',
      ].join(' '),
    });

    deleted ??= []; // There may not be any items here, so the list was never generated.

    for (const matchId of this.lonelyGroups) {
      this.onDissolveMatch({ currentTarget: { id: matchId } });
      // Deleted and Upserted have already been collected from the DOM,
      // so after the groups are dissolved, manually update the post data.
      deleted.push(matchId);
      delete upserted[matchId];
    }
  }

  // When a group is removed, it's added to deleted and is no-longer present in the rest of the
  // form. form5's diff sees that it's gone, and marks it as such (setting `[id]: null`). The API
  // doesn't like that (it needs them separated), so remove the bit(s) from `upserted`.
  for (const upsertedId of Object.keys(upserted)) if (!upserted[upsertedId]) delete upserted[upsertedId];

  return saveMatches(engagementId, {
    deleteMatches: deleted,
    upsertMatches: upserted,
  })
    .catch((failedMatches) => {
      toast.error(`${failedMatches.length} match updates failed`);

      throw failedMatches;
    })
    .then((r) => {
      // Due to a technical limitation, the MS cannot return an empty list
      // Instead, when empty, it returns [{}]
      const updatedTimes: UpsertedMatchResult[] = [];

      if (r.createdMatches[0]?.id) {
        toast.success(`${r.createdMatches.length} matches created`);
        updatedTimes.push(...r.createdMatches);
      }
      if (r.deletedMatches.length) {
        toast.success(`${r.deletedMatches.length} matches deleted`);
        // Reset the list to avoid subsequent submissions containing stale values (already deleted)
        this.setState({ deletedMatches: new Set() });
      }
      if (r.updatedMatches[0]?.id) {
        toast.success(`${r.updatedMatches.length} matches updated`);
        updatedTimes.push(...r.updatedMatches);
      }
      if (r.failedMatches.length) {
        throw r.failedMatches;
      }

      this.setState(({ groups }) => {
        const updated = new Map(groups);
        for (const { id, datetimeLive } of updatedTimes) {
          const group = updated.get(id)!;
          updated.set(id, {
            ...group,
            scheduling: {
              ...group.scheduling,
              datetimeLive,
            },
          });
        }
        return { groups: updated };
      });
    })
    .finally(() => this.setState({ isSaving: false }));
}
