._Callout_l78ft_1 {
  align-items: start;
  color: var(--colour);
  display: flex;
  flex: 1;
  flex-flow: row nowrap;
  gap: var(--default-padding);
}

._Callout_l78ft_1::before {
  flex: none;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  margin-top: 0.2em; /** fixes alignment */
}

._Callout_l78ft_1[type="affirm"] { --colour: var(--status-success); }

._Callout_l78ft_1[type="affirm"]::before {
  content: '✓';
}

._Callout_l78ft_1[type="error"] { --colour: var(--status-danger); }

._Callout_l78ft_1[type="error"]::before {
  content: '!!';
}

._Callout_l78ft_1[type="info"] { --colour: var(--status-info); }

._Callout_l78ft_1[type="info"]::before {
  content: 'i';
  font-family: monospace;
}

._Callout_l78ft_1[type="warn"] { --colour: var(--status-warning); }

._Callout_l78ft_1[type="warn"]::before {
  content: '⚠︎';
}
