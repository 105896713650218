import type { Audience } from './Audience.d.ts';


// [1] `as const` so the return type has exact values for `name`: `'hubspot'|…` instead of `string`.

export function determineAudienceIndicator({
  integration,
  isBuiltin,
  isPrimary,
}: Audience) {
  if (isBuiltin || isPrimary) {
    if (integration) {
      switch (integration.accountType) {
        case 'HUBSPOT': return {
          label: 'Generated from Hubspot',
          name: 'hubspot' as const, // [1]
        };
        case 'SALESFORCE': return {
          label: 'Generated from Salesforce',
          name: 'salesforce' as const, // [1]
        };
      }
    }
    return {
      label: 'Built-in Audience',
      name: 'group' as const, // [1]
    };
  }
  // Smart audiences aren't available for user setup (so the only ones that exist are also builtin)
  // if (name.includes('Smart')) {
  //   return {
  //     label: 'Smart Audience',
  //     name: 'funnel' as const,
  //   };
  // }
  return {
    label: 'Custom Audience',
    name: 'custom audience' as const, // [1]
  };
}
