import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import {
  type ElementType,
  type MouseEvent,
  type PropsWithChildren,
  PureComponent,
  createRef,
} from 'react';

import classes from './Drawer.module.css';


export const Bureau = ({
  as: Tag = 'div',
  className,
  ...props
}: PropsWithChildren<{
  as?: ElementType,
  className?: HTMLElement['className'],
}>) => (
  <Tag className={clsx(className, classes.Bureau)} {...props} />
);
Bureau.displayName = 'Bureau';

function CloseButton({
  onClick,
  ...props
}: {
  onClick(event: MouseEvent<HTMLButtonElement>): void,
}) {
  return (
    <Button
      {...props}
      appearance="basic"
      autoFocus
      className={classes.CloseButton}
      onClick={onClick}
      title="close"
      variant={Button.VARIANTS.GLYPH}
    >×
    </Button>
  );
}
CloseButton.displayName = 'CloseButton';

type CommonDrawerProps = PropsWithChildren<{
  className?: HTMLElement['className'],
  open?: boolean,
  onClose?: () => void,
}>;

export const Drawer = ({ modal, ...props }: CommonDrawerProps & { modal?: true | 'backdropped' }) => modal
  ? <PopoverDrawer {...props} backdropped={modal === 'backdropped'} />
  : <InlineDrawer {...props} />;
Drawer.displayName = 'Drawer';

interface InlineDrawerProps extends CommonDrawerProps {
  as?: ElementType,
}
export class InlineDrawer extends PureComponent<InlineDrawerProps> {
  state = {
    open: false,
  };

  static displayName = 'InlineDrawer';

  UNSAFE_componentWillReceiveProps({ open }: InlineDrawerProps) {
    return this.setState({ open });
  }

  onClose() {
    this.setState({ open: false });
    this.props.onClose?.();
  }

  render() {
    const {
      as: Tag = 'aside',
      children,
      className,
      ...props
    } = this.props;
    const { open } = this.state;

    return (
      <Tag
        {...props}
        className={clsx(classes.Drawer, classes.InlineDrawer)}
        open={open}
      >
        <div className={clsx(classes.DrawerLiner, className)}>
          <CloseButton onClick={this.onClose} />

          {children}
        </div>
      </Tag>
    );
  }
}

interface PopoverDrawerProps extends CommonDrawerProps {
  backdropped?: boolean,
}

export class PopoverDrawer extends PureComponent<PopoverDrawerProps> {
  static displayName = 'PopoverDrawer';

  ref = createRef<HTMLDialogElement>();

  state = {
    open: false,
  };

  // [1] This must be declared as a fat-arrow prop (not a method) in order to maintain its context when
  // called by the event listener.

  /* [1] */ onClose = () => {
    this.setState({ open: false });
    this.toggle(false); // Ensure focus trap is released
    this.props.onClose?.();
  };

  /* [1] */ onKeyPress = ({ key }: KeyboardEvent) => {
    if (key === 'Escape') this.onClose();
  };

  toggle(open?: boolean) {
    for (const child of this.ref.current?.parentElement?.children || new Array()) {
      if (child?.dataset.is === 'shelf') {
        child.inert = open;
        break;
      }
    }
  }

  UNSAFE_componentWillReceiveProps({ open }: PopoverDrawerProps) {
    if (this.props.backdropped) {
      if (open !== this.state.open) this.toggle(open);
    }
    this.setState({ open });
  }

  componentDidMount(): void {
    if (this.props.backdropped) {
      window.addEventListener('keydown', this.onKeyPress, true);
    }
  }

  componentWillUnmount(): void {
    if (this.props.backdropped) {
      window.removeEventListener('keydown', this.onKeyPress, true);
      this.toggle(false); // Ensure focus trap is released
    }
  }

  render() {
    const {
      backdropped,
      className,
      children,
      ...props
    } = this.props;
    const { open } = this.state;

    return (
      <>
        {backdropped && <div
          className={classes.Backdrop}
          onClick={this.onClose}
          open={open}
        />}

        <dialog
          {...props}
          className={clsx(classes.Drawer, classes.PopoverDrawer, className)}
          open={open}
          ref={this.ref}
          role={backdropped ? 'dialog' : undefined}
        >
          <CloseButton onClick={this.onClose} />

          {children}
        </dialog>
      </>
    );
  }
}

export const Shelf = ({
  as: Tag = 'div',
  className,
  ...props
}: PropsWithChildren<{
  as?: ElementType,
  className?: HTMLElement['className'],
}>) => (
  <Tag
    {...props}
    className={clsx(className, classes.Shelf)}
    data-is="shelf"
  />
);
Shelf.displayName = 'Shelf';

