import Button from 'form5/react/Button';
import { useState } from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { getSelectedMemberData } from '../getSelectedMemberData.mts';
import { AudienceUpdateWarning } from '../AudienceUpdateWarning.tsx';

import type { Member } from './Member.d.ts';

import classes from './MemberForm.module.css';


type MemberIds = Member['id'][];

export function RemoveMembersFromAudienceForm({
  members,
  onSubmit,
}: {
  members: ReturnType<typeof getSelectedMemberData>,
  onSubmit(memberIds: MemberIds): Promise<MemberIds>,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const memberIds: MemberIds = [];
  const memberNamesItems = [];

  for (const [id, { fields }] of members) {
    memberIds.push(id);
    memberNamesItems.push(<div className={classes.Name} key={id}>{fields?.fullName}</div>);
  }

  function removeMembers() {
    setIsSaving(true);
    const promise = onSubmit(memberIds).finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: 'Removal failed',
      loading: 'Removing…',
      success: `${memberIds.length} member${memberIds.length > 1 ? 's' : ''} removed`,
    });

    return promise;
  }

  return (
    <>
      <h1>Remove member(s)</h1>

      <AudienceUpdateWarning />

      <p>The following members will be removed from the audience:</p>

      <div className={classes.EnumeratedUpdateTargets}>{memberNamesItems}</div>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving}
        isSubmitting={isSaving}
        onClick={removeMembers}
        type="submit"
      >Remove
      </SubmitButton>
    </>
  );
}
RemoveMembersFromAudienceForm.displayName = 'RemoveMembersFromAudienceForm';
