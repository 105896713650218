import audienceFrag from './audience.frag.gql';

export const RENAME_AUDIENCE = `
mutation UpdateAudienceName($audienceId: ID!, $name: String!) {
  audience: updateAudienceName(audienceId: $audienceId, name: $name) {
    ...audienceFields
  }
}

${audienceFrag}
`;
