import Button from 'form5/react/Button';
import { useState } from 'react';
import {
  Link,
  generatePath,
  useParams,
} from 'react-router-dom';

import {
  AUDIENCE_ROUTE_TEMPLATE,
  ENGAGEMENT_SETUP_ROUTE_TEMPLATE,
  EngagementSectionRouteParams,
} from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { ago, formatDate } from '…/app/common/dateUtils.mts';

import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { EditorRole } from '…/app/common/permissions/RequiredRole.jsx';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import type { Audience } from '…/app/w/workspace/audiences/audience/Audience.d.ts';
import { determineAudienceIndicator } from '…/app/w/workspace/audiences/audience/determineAudienceIndicator.mts';

import type { EngagementFull } from '…/app/w/workspace/engagements/Engagement.d.ts';
import { STATUSES } from '…/app/w/workspace/engagements/constants.mts';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import AudienceFigure from './figures/audience.svg';
import ExperienceFigure from './figures/experience.svg';
import ConvoGuideFigure from './figures/convo-guide.svg';
import FeedbackFigure from './figures/feedback.svg';
import InviteFigure from './figures/invite.svg';
import OptinFigure from './figures/optin.svg';
import ReminderFigure from './figures/reminder.svg';
import ScheduleFigure from './figures/schedule.svg';
import TemplateFigure from './figures/template.svg';

import {
  SetupCard,
  SetupCardHeader,
  SetupCardItem,
  SetupCardItems,
} from './SetupCard/SetupCard.tsx';
import { ItemStatus } from './SetupCard/ItemStatus.tsx';
import { SetupGuide } from './SetupGuide/SetupGuide.tsx';
import { forms } from './forms/index.mts';
import * as validators from './validators.mts';
import { useInternalAdminRole } from '…/app/common/permissions/useRoles.mjs';


export function EngagementSetupOverviewPage() {
  const { workspaceId } = useParams() as EngagementSectionRouteParams;
  const [
    {
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState<EngagementFull>();
  const audience = useStore((data) => data.workspaces[workspaceId]).audiences?.get(engagement.audienceId);
  const [dialog, setDialog] = useState<keyof typeof forms>();

  const validations = validators.all({
    ...engagement,
    audience,
  });
  const canSubmitEngagement = validators.isEngagementReady(validations);
  const pathToSchedule = generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
    ...params,
    subSection: 'schedule',
  });
  const isDraft = engagement.status === STATUSES.DRAFT;
  const hasSelfScheduling = useFeatureFlag([FLAGS.engagementSelfScheduling]);
  const isInternalAdmin = useInternalAdminRole();
  const DialogContents = forms[dialog];

  function onSuccess(result: EngagementFull) {
    setDialog(undefined);
    setPageProps((prev) => ({
      ...prev,
      engagement: result,
    }));
  }

  const ScheduleButton = () => (
    <Button
      appearance={Button.APPEARANCES.AFFIRMING}
      disabled={!canSubmitEngagement}
      onClick={() => setDialog('schedule-engagement')}
    >Schedule
    </Button>
  );
  ScheduleButton.displayName = 'ScheduleButton';

  const audienceLastUpdated = getAudienceLastUpdated(audience);

  return (
    <main
      className="ContentContainer ListPage"
      data-testid="EngagementSetupOverviewPage"
    >
      <header className="SplitHeader">
        <h1>Set up your Engagement</h1>

        <EditorRole>
          {isDraft && (
            hasSelfScheduling && (
              <ScheduleButton />
            )
            || (
              <Button
                appearance={Button.APPEARANCES.PRIMARY}
                disabled={!canSubmitEngagement}
                onClick={() => setDialog('submit-engagement')}
              >Submit for Review
              </Button>
            )
          )}

          {engagement.status === STATUSES.REVIEW && isInternalAdmin && (
            <ScheduleButton />
          )}

          {engagement.status === STATUSES.SCHEDULED && (
            <Button
              appearance={Button.APPEARANCES.DANGER}
              onClick={() => setDialog('cancel-engagement')}
            >Revert to draft
            </Button>
          )}
        </EditorRole>
      </header>

      <SetupGuide />

      <SetupCard status={validations.experience._section.status}>
        <SetupCardHeader
          Figure={ExperienceFigure}
          label={validations.experience._section.label}
          status={validations.experience._section.status}
          to="template"
        >
          <h2>Experience</h2>

          <h3>How are you communicating & matching the participants?</h3>
        </SetupCardHeader>

        {validations.experience._section.status !== validators.SECTION_STATUSES.NEW && (
          <SetupCardItems withIcon>
            <SetupCardItem>
              <TemplateFigure />

              <span>
                <strong>Template: </strong>

                <span>{engagement.title}</span>

                {' '}

                <EditorRole>
                  {isDraft && (
                    <>
                      (
                      <CTA
                        appearance="danger"
                        guise={CTA.GUISES.LINK}
                        to={() => setDialog('remove-template')}
                      >remove
                      </CTA>
                      )
                    </>
                  )}
                </EditorRole>
              </span>

              <ItemStatus {...validations.experience.description} />
            </SetupCardItem>

            <SetupCardItem>
              <InviteFigure />

              <Link to={generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
                ...params,
                subSection: 'invite',
              })}
              >Invite email
              </Link>

              <ItemStatus {...validations.experience.invite} />
            </SetupCardItem>

            <SetupCardItem>
              <ReminderFigure />

              <Link to={generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
                ...params,
                subSection: 'chaser',
              })}
              >Reminder email
              </Link>

              <ItemStatus {...validations.experience.chaser} />
            </SetupCardItem>

            <SetupCardItem>
              <OptinFigure />

              <Link to={generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
                ...params,
                subSection: 'signup',
              })}
              >Opt-in Form
              </Link>

              <ItemStatus {...validations.experience.signup} />
            </SetupCardItem>

            <SetupCardItem>
              <ConvoGuideFigure />

              <Link to={generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
                ...params,
                subSection: 'conversation-guide',
              })}
              >Conversation Guide
              </Link>

              <ItemStatus {...validations.experience.convoGuide} />
            </SetupCardItem>

            <SetupCardItem>
              <FeedbackFigure />

              <Link to={generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
                ...params,
                subSection: 'feedback',
              })}
              >Feedback Form
              </Link>

              <ItemStatus {...validations.experience.feedback} />
            </SetupCardItem>
          </SetupCardItems>
        )}
      </SetupCard>

      <SetupCard status={validations.schedule._section.status}>
        <SetupCardHeader
          Figure={ScheduleFigure}
          label={validations.schedule._section.label}
          status={validations.schedule._section.status}
          to="schedule"
        >
          <h2>Schedule</h2>

          <h3>When is this engagement happening?</h3>
        </SetupCardHeader>

        {validations.schedule._section.status !== validators.SECTION_STATUSES.NEW && (
          <SetupCardItems>
            <SetupCardItem>
              <span>
                <strong>Registration: </strong>

                <Link to={`${pathToSchedule}#registration`}>
                  {formatDate(engagement.optInOpensAt, engagement.timezoneIso)}

                  {' – '}

                  {formatDate(engagement.optInClosesAt, engagement.timezoneIso)}
                </Link>
              </span>

              <ItemStatus
                {...(validations.schedule.optInOpensAt.status === validators.ITEM_STATUSES.VALID
                  ? validations.schedule.optInClosesAt
                  : validations.schedule.optInOpensAt
                )}
              />
            </SetupCardItem>

            <SetupCardItem>
              <span>
                <strong>Invite: </strong>

                <Link to={`${pathToSchedule}#emails`}>
                  {formatDate(engagement.oneToOneEmails?.invite?.sendAt, engagement.timezoneIso, true)}
                </Link>
              </span>

              <ItemStatus {...validations.schedule.invite} />
            </SetupCardItem>

            <SetupCardItem>
              <span>
                <strong>Reminder: </strong>

                <Link to={`${pathToSchedule}#emails`}>
                  {formatDate(engagement.oneToOneEmails?.chaser?.sendAt, engagement.timezoneIso, true)}
                </Link>
              </span>

              <ItemStatus {...validations.schedule.chaser} />
            </SetupCardItem>

            {engagement.isEditMatchesEnabled && (
              <SetupCardItem>
                <span>
                  <strong>Introductions: </strong>

                  <Link to={`${pathToSchedule}#intros`}>
                    {formatDate(engagement.matchIntroAt, engagement.timezoneIso)}
                  </Link>
                </span>

                <ItemStatus {...validations.schedule.matchIntroAt} />
              </SetupCardItem>
            )}

            <SetupCardItem>
              <span>
                <strong>Conversations: </strong>

                <Link to={`${pathToSchedule}#conversations`}>
                  {formatDate(engagement.conversationDates?.at(0), engagement.timezoneIso)}

                  {' – '}

                  {formatDate(engagement.conversationDates?.at(-1), engagement.timezoneIso)}
                </Link>
              </span>

              <ItemStatus {...validations.schedule.convoDates} />
            </SetupCardItem>
          </SetupCardItems>
        )}
      </SetupCard>

      <SetupCard status={validations.audience._section.status}>
        <SetupCardHeader
          Figure={AudienceFigure}
          label={validations.audience._section.label}
          status={validations.audience._section.status}
          to={() => setDialog('select-audience')}
        >
          <h2>Audience</h2>

          <h3>Who are you matching in this engagement?</h3>
        </SetupCardHeader>

        {validations.audience._section.status !== validators.SECTION_STATUSES.NEW && (
          <SetupCardItems>
            <SetupCardItem>
              <span>
                <Link to={generatePath(AUDIENCE_ROUTE_TEMPLATE, {
                  ...params,
                  audienceId: audience.id,
                })}
                >
                  {audience.name}
                </Link>

                {' '}

                <IIcon {...determineAudienceIndicator(audience)} />

                {' '}

                <EditorRole>
                  {isDraft && (
                    <>
                      (
                      <CTA
                        guise={CTA.GUISES.LINK}
                        to={() => setDialog('select-audience')}
                      >change
                      </CTA>
                      )

                      {' '}
                    </>
                  )}
                </EditorRole>

                {audience.memberCount} members

                {!!audienceLastUpdated && (<>, last updated {ago(audienceLastUpdated)}</>)}
              </span>
            </SetupCardItem>
          </SetupCardItems>
        )}
      </SetupCard>

      <Dialog
        backdropped
        open={!!dialog}
        setOpen={setDialog}
      >
        {DialogContents && <DialogContents onSuccess={onSuccess} />}
      </Dialog>
    </main>
  );
}
EngagementSetupOverviewPage.displayName = 'EngagementSetupOverviewPage';

const handle = {
  sidebar: true,
  subheader: true,
  title: 'Engagement Setup Overview',
};

export {
  EngagementSetupOverviewPage as Component,
  handle,
};

function getAudienceLastUpdated({
  integration,
  isBuiltin,
  lastSyncedAt,
  updatedAt,
}: Audience = {} as Audience) {
  if (integration) return lastSyncedAt;
  if (isBuiltin) return '';
  return updatedAt;
}
