import type {
  Dispatch,
  SetStateAction,
} from 'react';
import _memoize from 'lodash-es/memoize.js';
import _kebabCase from 'lodash-es/kebabCase.js';
import { memo } from 'react';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import type { PRODUCT_TYPE } from '…/app/deprecated/constants.mts';
import {
  PRODUCT_TO_TYPE_DICT,
  PRODUCT_TYPES,
  PRODUCT_TYPES_DB_DICT,
} from '…/app/deprecated/constants.mts';
import { getNow } from '…/app/common/dateUtils.mts';

import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';
import { FiltersContent, FiltersHeader } from '…/app/common/filtering/FiltersDrawer.tsx';

import statusClasses from './engagement/EngagementStatusIndicator.module.css';

import type { EngagementFilters } from './Engagement.d.ts';
import { STATUSES_LABEL_DICT } from './constants.mts';

import classes from './FiltersForm.module.css';


const getClosingDateOptions = _memoize((now: ReturnType<typeof getNow>) => [
  ['Last 7 days', now.minus({ days: 7 }).toISODate()],
  ['Last 30 days', now.minus({ days: 30 }).toISODate()],
  ['Last 90 days', now.minus({ days: 90 }).toISODate()],
  ['This Week', now.startOf('week').toISODate()],
  ['This Month', now.startOf('month').toISODate()],
  ['This Year', now.startOf('year').toISODate()],
]);

export function EngagementFiltersForm({
  filters: {
    CLOSING_DATE,
    PRODUCT_TYPE,
    STATUS,
  },
  setFilters,
}: {
  filters: EngagementFilters,
  setFilters: Dispatch<SetStateAction<EngagementFilters>>,
}) {
  return (
    <>
      <FiltersHeader />

      <FiltersContent>
        <Form
          name="engagement-filters"
          onReset={() => setFilters({
            AUDIENCE: new Array(),
            CLOSING_DATE: new Array(),
            PRODUCT_TYPE: new Array(),
            STATUS: new Array(),
          })}
          onSubmit={(_, all) => setFilters(all)}
        >
          <Details open>
            <Summary className={classes.Summary}>Product type</Summary>

            <div className={classes.FilterOptions}>
              <Field
                defaultChecked={PRODUCT_TYPE?.includes(PRODUCT_TYPES_DB_DICT.ONE_TO_ONE)}
                id={`PRODUCT_TYPE.${PRODUCT_TYPES.ONE_TO_ONE}`}
                label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.ONE_TO_ONE]}
                name="PRODUCT_TYPE[]"
                type="checkbox"
                value={transformProductTypeToFilterValue(PRODUCT_TYPES.ONE_TO_ONE)}
              />

              <FeatureFlag flags={[FeatureFlag.FLAGS.createEngagementGroup]}>
                <Field
                  defaultChecked={PRODUCT_TYPE?.includes(PRODUCT_TYPES_DB_DICT.GROUP)}
                  id={`PRODUCT_TYPE.${PRODUCT_TYPES.GROUP}`}
                  label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.GROUP]}
                  name="PRODUCT_TYPE[]"
                  type="checkbox"
                  value={transformProductTypeToFilterValue(PRODUCT_TYPES.GROUP)}
                />
              </FeatureFlag>

              <FeatureFlag flags={[FeatureFlag.FLAGS.createEngagementTopN]}>
                <Field
                  defaultChecked={PRODUCT_TYPE?.includes(PRODUCT_TYPES_DB_DICT.TOP_N)}
                  id={`PRODUCT_TYPE.${PRODUCT_TYPES.TOP_N}`}
                  label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.TOP_N]}
                  name="PRODUCT_TYPE[]"
                  type="checkbox"
                  value={transformProductTypeToFilterValue(PRODUCT_TYPES.TOP_N)}
                />
              </FeatureFlag>
            </div>
          </Details>

          <Details open>
            <Summary className={classes.Summary}>Status</Summary>

            <div className={classes.FilterOptions}>
              {(() => {
                const output = [];

                for (const { 0: key, 1: label } of Object.entries(STATUSES_LABEL_DICT)) {
                  output.push(
                    <Field
                      defaultChecked={STATUS?.includes(key)}
                      id={`STATUS.${key}`}
                      key={key}
                      label={
                        <>
                          <span aria-label={key} className={statusClasses.StatusIndicator} />

                          {' '}

                          {label}
                        </>
                      }
                      name="STATUS[]"
                      type="checkbox"
                      value={key}
                    />,
                  );
                }

                return output;
              })()}
            </div>
          </Details>

          <Details open>
            <Summary className={classes.Summary}>Closing date</Summary>

            <div className={classes.FilterOptions}>
              {getClosingDateOptions(getNow()).map(({ 0: label, 1: value }) => (
                <Field
                  defaultChecked={CLOSING_DATE?.includes(value!)}
                  id={`CLOSING_DATE.${label}`}
                  key={label}
                  label={label}
                  name="CLOSING_DATE[]"
                  type="radio"
                  value={value}
                />
              ))}
            </div>
          </Details>

          <Button.Group>
            <Button appearance={Button.APPEARANCES.BASIC} type="reset">Clear</Button>

            <Button appearance={Button.APPEARANCES.PRIMARY} type="submit">Apply</Button>
          </Button.Group>
        </Form>
      </FiltersContent>
    </>
  );
}
EngagementFiltersForm.displayName = 'EngagementFiltersForm';

const transformProductTypeToFilterValue = (productType: PRODUCT_TYPE) => _kebabCase(productType.toLowerCase());

export const MemoizedEngagementFiltersForm = memo(EngagementFiltersForm);
MemoizedEngagementFiltersForm.displayName = 'MemoizedEngagementFiltersForm';
