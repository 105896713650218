import { SETUP_SECTIONS } from '../../../constants.mts';

import { setupPageConfig } from '../setupPageConfig.mts';


export const tabs = new Map([
  [{ section: 'setup' }, {
    end: true,
    routeTemplate: '/w/:workspaceId/engagements/:engagementId/:section',
    title: '←\u00A0Overview', // non-breaking space
  }],
  [{ subSection: SETUP_SECTIONS.INVITATION }, setupPageConfig[SETUP_SECTIONS.INVITATION]],
  [{ subSection: SETUP_SECTIONS.REMINDER }, setupPageConfig[SETUP_SECTIONS.REMINDER]],
  [{ subSection: SETUP_SECTIONS.CONVO_GUIDE }, setupPageConfig[SETUP_SECTIONS.CONVO_GUIDE]],
  [{ subSection: SETUP_SECTIONS.SIGNUP }, { title: 'Forms\u00A0→' }],
]);
