import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';


export function useRefinementParams<Refinements extends AbstractRefinements>(
  init: Refinements,
  exisiting: Location['search'] = location.search,
) {
  const groupNames = getKeys(init);

  const [all, setAll] = useState(() => {
    const params = new URLSearchParams(exisiting);
    const states = {} as Refinements;
    for (const groupName of groupNames) {
      states[groupName] = mergeInitialParams(init[groupName], params);
    }

    return states;
  });

  const groups = {} as RefinementsStates<Refinements>;

  for (const groupName of groupNames) {
    const setGroupState = (update: typeof groups[typeof groupName][1]) => setAll((prev) => {
      const updatedGroup = typeof update === 'function'
        ? update(prev[groupName])
        : update;

      return {
        ...prev,
        [groupName]: updatedGroup,
      };
    });

    groups[groupName] = [ // TypeScript doesn’t understand `new Array(i1, i2, …)`
      all[groupName],
      setGroupState,
    ];
  }

  useEffect(() => {
    updateAddress(all);
  }, [all]);

  return groups;
}

function mergeInitialParams<Data extends SerialParams>(
  data: Data,
  existing: URLSearchParams,
) {
  const result: Data = { ...data };

  for (const param in result) {
    /**
     * `URLSearchParams::get()` returns only the first element of a list when calling `get` instead
     * of all of them, so we need to determine whether there could be multiple (when the param is a
     * list), where we need to call `getAll()`).
     */
    const isArray = Array.isArray(result[param]);
    const isSSet = result[param] instanceof SerialSet;
    const getMethod = (isArray || isSSet)
      ? 'getAll'
      : 'get';
    const searchParamVal = (() => {
      let output = existing?.[getMethod]?.(param);
      if (isArray) return output;
      if (isSSet) return new SerialSet(output);
      try { output = JSON.parse(output) } catch { /* eslint-disable-line no-empty */ }

      return output as SerialListItem | null;
    })();

    if (
      searchParamVal != null
      && (!(searchParamVal instanceof SerialSet) || searchParamVal.size)
    ) {
      result[param] = searchParamVal;
    }
  }

  return result;
}

const getKeys = Object.keys as <T extends Record<string, unknown>>(obj: T) => Array<keyof T>;

function updateAddress<Groups extends AbstractRefinements>(all: Groups) {
  const url = new URL(location.href);
  const mergedParams = url.searchParams;

  const updates: SerialParams = {};
  for (const groupName of Object.keys(all)) Object.assign(updates, all[groupName]);

  for (const fieldName of Object.keys(updates)) {
    mergedParams.delete(fieldName);

    const vals = updates[fieldName];
    if (typeof vals === 'object') for (const val of vals) mergedParams.append(fieldName, `${val}`);
    else mergedParams.set(fieldName, `${vals}`);
  }

  history.pushState({}, '', url);
}

export class SerialSet<V extends SerialListItem = SerialListItem> extends Set<V> {
  toJSON(): SerialListItem[] {
    return [...this];
  }
}

export type SerialPrimitive = boolean | number | string;
export type SerialListItem = number | string;
export type SerialValue = SerialPrimitive | SerialListItem[] | SerialSet;
export type SerialParams<V = SerialValue> = Record<string, V>;

type AbstractRefinements = Record<string, SerialParams>;
type RefinementsStates<Refinements extends AbstractRefinements> = {
  [K in keyof Refinements]: [
    Refinements[K],
    Dispatch<SetStateAction<Refinements[K]>>
  ]
}
