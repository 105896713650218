import type { DateTime } from 'luxon';

import {
  deriveDateFromDateTime,
  deriveDateTimeFromDate,
  getNow,
} from '…/app/common/dateUtils.mts';

import type { Engagement } from '../../../Engagement.d.ts';

import type { ScheduleState, composeConvoDateProps } from './composeScheduleData.mts';
import { disableFieldApproachingTime } from './utils.mts';


// ! Note that date and datetime outputs here must NOT contain time-zone info
// ! The HTML elements into which they feed are "local", meaning time-zone agnostic.
// ! Including a time-zone will cause the constraint mechanism to silently fail.

export const timezoneIso = {
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive;
  },
};
export const optInOpensAt = {
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive;
  },
};

export const optInClosesAt = {
  max(firstConvoDate: DateTime | undefined, isLive: Engagement['isLive']) {
    return isLive
      ? firstConvoDate
        ?.minus({ hours: 48 })
        .toISODate()
      : null;
  },
  min(optInOpens: DateTime | null, isLive: Engagement['isLive']) {
    return isLive
      ? (new Array(optInOpens?.toISODate(), getNow().toISODate())).sort()[1]
      : optInOpens?.toISODate();
  },
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive && disableFieldApproachingTime(state, 'optInClosesAt');
  },
};

function emailMaxFromRegistration(relativeTo: DateTime | null) {
  return relativeTo
    ? deriveDateTimeFromDate(relativeTo.set({
      hour: 23,
      minute: 59,
    }))
    : null;
}
function emailMinFromRegistration(relativeTo: DateTime | null) {
  return relativeTo
    ? deriveDateTimeFromDate(relativeTo.set({
      hour: 0,
      minute: 0,
    }))
    : null;
}

const EMAIL_BUFFER_TIME = { minutes: 15 };

type OneToOneEmail = { isDisabled?: boolean | null, sendAt: DateTime | null };

export const oneToOneEmails = {
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive && disableFieldApproachingTime(state, 'conversationDates[0]');
  },
};

export const invite = {
  max(chaserAt: OneToOneEmail, closesAt: DateTime | null) {
    if (!chaserAt?.isDisabled && chaserAt?.sendAt) {
      return deriveDateTimeFromDate(chaserAt.sendAt.minus(EMAIL_BUFFER_TIME));
    }
    if (closesAt) return emailMaxFromRegistration(closesAt);
    return null;
  },
  min: emailMinFromRegistration,
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive && disableFieldApproachingTime(state, 'oneToOneEmails.invite.sendAt');
  },
};

export const chaser = {
  max: emailMaxFromRegistration,
  min(inviteAt: OneToOneEmail, opensAt: DateTime | null) {
    if (!inviteAt?.isDisabled && inviteAt?.sendAt) {
      return deriveDateTimeFromDate(inviteAt.sendAt.plus(EMAIL_BUFFER_TIME));
    }
    if (opensAt) return emailMinFromRegistration(opensAt);
    return null;
  },
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive && disableFieldApproachingTime(state, 'oneToOneEmails.chaser.sendAt');
  },
};

export const matchEditing = {
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive;
  },
};

// NOTE: Lock this down to Tue/Fri. How can we do this currently?
export const matchIntroAt = {
  max(firstConvoDate?: DateTime) {
    return firstConvoDate
      ? deriveDateFromDateTime(firstConvoDate?.minus({ hours: 24 }))
      : null;
  },
  min(matchesGenerated?: DateTime | null) {
    return matchesGenerated
      ? deriveDateFromDateTime(matchesGenerated.plus({ hours: 48 })) // 24h buffer + 24h for editing
      : null;
  },
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive;
  },
};

export const conversationDates = {
  min(
    introAt: DateTime | null,
    closesAt: DateTime | null,
    hasMatchEditing: boolean,
    deriver: ReturnType<typeof composeConvoDateProps>['deriver'],
  ) {
    if (hasMatchEditing) {
      // 25h: 1h for the notification service needs time to process; convos can happen the next day
      if (introAt) return deriver(introAt.plus({ hours: 25 }));
    }
    // 2d: matches go out the following day; convos can happen the next day
    if (closesAt) return deriver(closesAt.plus({ hour: 48 }));

    return null;
  },
  readonly(state: ScheduleState, isLive: Engagement['isLive']) {
    return isLive;
  },
};
