.Header {
  background: white;
}

.HeaderContent {
  padding: 2rem;
}

.Tabs {
  align-items: center;
  box-shadow: 0 2px 4px -2px rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: row;
}

.Tab {
  border-bottom: 3px solid white;
  color: #BBB; /** FIXME: color */
  cursor: pointer;
  flex: 1 1 auto;
  padding: 1rem;
  text-align: center;
}

.Tab:hover {
  border-bottom: 3px solid #357FDA;
}

.TabSelected {
  border-bottom: 3px solid #357FDA;
  color: black;
  font-weight: 700;
}

.Content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  padding-top: 0;
}

.Title {
  margin: 0.75rem 0 0.5rem;
  padding: 0;
}

.Lists {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  justify-content: flex-start;
}

.ListsScrollable {
  max-height: 70vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}

.ListsScrollable::after {
  background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
  bottom: 0;
  content: "";
  height: 2rem;
  left: 0;
  position: absolute;
  right: 0;
}

.ListItem {
  align-items: center;
  background: white;
  border: 1px solid var(--neutral-light);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  padding: 0.675rem 1rem;
}

.SearchContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.Highlight {
  background: var(--brand-subtle-light);
  color: var(--brand-accent-primary);
  font-weight: 500;
  padding: 2px 6px;
}
