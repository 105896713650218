import {
  type ParticipantForm,
  SETUP_SECTIONS,
} from '…/app/w/workspace/engagements/constants.mts';

import type { MultipleChoiceQuestionData } from '../ParticipantQuestions.d.ts';


export const isDataPrivate = {
  readOnly(disabled: boolean, questionType: string) {
    if (questionType === 'GenericTextStep') return true;
    return disabled;
  },
  visible(form: ParticipantForm) {
    return form === SETUP_SECTIONS.SIGNUP;
  },
};

export const isMatchingDisabled = {
  readOnly(disabled: boolean, questionType: string) {
    if (questionType === 'GenericTextStep') return true;
    return disabled;
  },
  visible(form: ParticipantForm) {
    return form === SETUP_SECTIONS.SIGNUP;
  },
};

export const isMatchingOpposites = {
  readOnly(disabled: boolean, data: MultipleChoiceQuestionData) {
    if (disabled || data.isMatchingDisabled) return true;
    if (
      data.question === 'MultiSelectStep'
      || data.question === 'GenericTextStep'
    ) return true;
    return false;
  },
  visible(form: ParticipantForm, isCustom: boolean) {
    return form === SETUP_SECTIONS.SIGNUP && isCustom;
  },
};

export const isOrdered = {
  readOnly(disabled: boolean, data: MultipleChoiceQuestionData) {
    if (disabled || data.isMatchingDisabled) return true;
    if (
      data.question === 'MultiSelectStep'
      || data.question === 'GenericTextStep'
    ) return true;
    return false;
  },
  visible(form: ParticipantForm, isCustom: boolean) {
    return form === SETUP_SECTIONS.SIGNUP && isCustom;
  },
};

export const questionType = {
  visible(form: ParticipantForm, isCustom: boolean) {
    return isCustom;
  },
};
