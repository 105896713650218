.Content {
  padding: var(--grid-gutter);
  text-align: center;
}

.Row {
  align-items: center;
  border-bottom: 1px solid var(--border-colour-subtle);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;
}

.Row p {
  font-size: 1em;
  line-height: 1em;
  margin: 0;
  padding: 0;
}
