import { clsx } from 'clsx';
import type { LoaderFunctionArgs } from 'react-router-dom';
import {
  Navigate,
  Outlet,
  generatePath,
} from 'react-router-dom';

import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';

import { getEngagementStep } from '../constants.mts';
import { engagementParams } from '../engagementParams.mts';
import {
  useContainerLayout,
  useContainerState,
} from './state.mts';

import { getEngagement } from './gql/getEngagement.op.mts';
import { EngagementHeader } from './EngagementHeader.tsx';
import { EngagementSidebar } from './EngagementSidebar.tsx';

import classes from './EngagementOverviewContainer.module.css';


export function EngagementContainer() {
  const params = engagementParams();
  const {
    subheader,
    sidebar,
  } = useContainerLayout();
  const [pageProps, setPageProps] = useContainerState();

  handlePageErrors('Engagement Matches', pageProps.errors);

  if (!params.section) {
    return (
      <Navigate
        replace
        to={generatePath('/w/:workspaceId/engagements/:engagementId/:section', {
          ...params,
          section: getEngagementStep(pageProps.engagement.status),
        })}
      />
    );
  }

  return (
    <div className={clsx('ContentContainer', classes.Container)}>
      {subheader && (
        <EngagementHeader {...pageProps} setPageProps={setPageProps} />
      )}

      <div className={classes.ContentWrapper}>
        {sidebar && (
          <EngagementSidebar {...pageProps} />
        )}

        <Outlet
          context={{
            className: classes.Content,
            pageProps,
            setPageProps,
          }}
        />
      </div>
    </div>
  );
}
EngagementContainer.displayName = 'EngagementContainer';

async function containerFetch({ request }: LoaderFunctionArgs) {
  const params = engagementParams((new URL(request.url)).pathname);

  const engagement = await getEngagement(params)
    .then((e) => {
      if (!e) throw new Error('Engagement not found');
      return e;
    });

  return { engagement };
}

export {
  EngagementContainer as Component,
  containerFetch as loader,
};
