import type {
  Workspace,
  WorkspaceId,
} from '../states.d.ts';
import { storeProxyFactory } from '../storeProxyFactory.mts';

import { workspacesState } from './workspaces.state.mts';

import { getWorkspace } from './getWorkspace.op.mts';
import { getWorkspaces } from './getWorkspaces.op.mts';
import { getWorkspaceSettings } from './getWorkspaceSettings.op.mts';


export const workspacesStore = storeProxyFactory<Map<WorkspaceId, Workspace>, Record<WorkspaceId, Workspace>>(
  'Workspaces',
  workspacesState,
  {
    default: getWorkspaces,
    item: {
      default: getWorkspace,
      settings: getWorkspaceSettings,
      teammates: getWorkspaceSettings,
    },
  },
);
