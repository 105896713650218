import {
  Link, generatePath, useParams,
} from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { AUDIENCE_ROUTE_TEMPLATE } from '…/app/route-params.mts';

import { determineAudienceIndicator } from '…/app/w/workspace/audiences/audience/determineAudienceIndicator.mts';

import type { Audience } from '../../audiences/audience/Audience.d.ts';

import { IIcon } from '../Icon/Icon.tsx';


export function AudienceListItem(props: Audience & { linked?: boolean }) {
  const params = useParams() as WalledRouteParams;
  const {
    id,
    integration,
    linked,
    memberCount,
    name,
  } = props;
  const typeIndicator = determineAudienceIndicator(props);

  const Name = linked
    ? (
      <Link to={generatePath(AUDIENCE_ROUTE_TEMPLATE, {
        ...params,
        audienceId: id,
      })}
      >
        {name}
      </Link>
    )
    : name;

  return (
    <>
      {Name}

      {' '}

      <span className="muted">
        ({`${memberCount} member${memberCount > 1 ? 's' : ''}`})
      </span>

      {integration && (
        <>
          {' '}

          <IIcon {...typeIndicator} />
        </>
      )}
    </>
  );
}
AudienceListItem.displayName = 'AudienceListItem';
