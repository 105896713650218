import { Meter } from '…/app/common/Meter/Meter.tsx';

import MetricCardHeader from '…/app/deprecated/components/engagement/Dashboard/metrics/MetricCardHeader/MetricCardHeader.tsx';

import classes from './AverageRating.module.css';

type RatingInfo = {
  percentage: number;
  votes: number;
}

type MetricsProps = {
  title: string;
  subtitle: string;
  average: number;
  values: number[];
  globalAverage: number;
}

const RatingProgress = (text: string, ratingInfo: RatingInfo) => {
  const roundedValue = Math.round((ratingInfo.percentage + Number.EPSILON) * 10) / 10;
  return (
    <div className={classes.GridRow}>
      <h4>{text}</h4>

      <Meter value={ratingInfo.percentage} />

      <h4>{roundedValue}%</h4>
    </div>
  );
};

RatingProgress.displayName = 'RatingProgress';

export const AverageRating = ({
  metrics,
}: {
  metrics: MetricsProps
}) => {
  const {
    title, subtitle, average, values, globalAverage,
  } = metrics;
  const totalVotes = values.reduce((a, b) => a + b, 0);
  const multiplier = 100 / totalVotes;

  const oneStar: RatingInfo = {
    percentage: values[0] * multiplier,
    votes: values[0],
  };
  const twoStar: RatingInfo = {
    percentage: values[1] * multiplier,
    votes: values[1],
  };
  const threeStar: RatingInfo = {
    percentage: values[2] * multiplier,
    votes: values[2],
  };
  const fourStar: RatingInfo = {
    percentage: values[3] * multiplier,
    votes: values[3],
  };
  const fiveStar: RatingInfo = {
    percentage: values[4] * multiplier,
    votes: values[4],
  };

  const votesAverage = Math.round((average + Number.EPSILON) * 10) / 10;

  return (
    <div className={classes.Content}>
      <MetricCardHeader
        description="Members are asked to provide a 1-5 rating to the statement: “My match was a good fit for me”."
        title={title}
      />

      <div className={classes.Header}>
        <h1>{votesAverage}&nbsp;</h1>

        <p>Out of 5</p>
      </div>

      <div>
        {RatingProgress('5 stars', fiveStar)}

        {RatingProgress('4 stars', fourStar)}

        {RatingProgress('3 stars', threeStar)}

        {RatingProgress('2 stars', twoStar)}

        {RatingProgress('1 star', oneStar)}
      </div>

      <div className={classes.Bottom}>
        <p>{subtitle}&nbsp;</p>

        <p>
          {globalAverage}/{values.length} {/* eslint-disable-line react/jsx-newline */}
        </p>
      </div>
    </div>
  );
};

AverageRating.displayName = 'AverageRating';
