import Field from 'form5/react/Field';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import type { Match } from './Match.d.ts';


export function GroupName({
  name,
  optins,
  product,
  readOnly,
}: Match & {
  readOnly: boolean
}) {
  const defaultName = name ?? (() => {
    const names = [];
    let i = 0;

    for (const optin of optins.values()) if (optin) names[i++] = optin.member.fields!.firstName;

    if (names.length) return names.join(' & ');

    return 'New empty group';
  })();

  if (product === PRODUCT_TYPES.GROUP && !readOnly) {
    return (
      <Field
        aria-label="group name"
        arrangement={Field.ARRANGEMENTS.COMPACT}
        defaultValue={name}
        fluid
        label={null}
        name="name"
        placeholder={defaultName}
      />
    );
  }

  return (<h3>{defaultName}</h3>);
}
GroupName.displayName = 'GroupName';
