import type { LoaderFunction } from 'react-router-dom';

import { useViewState } from '../state.mts';

import {
  EditMatches,
  type EngagementMatching,
  LIMIT_PER_PAGE,
} from './EditMatches2.tsx';
import { fetchMatches } from './fetchMatchData.op.mts';

/**
 * React does not allow using hooks in class components, so this wrapper is needed to pass the
 * hooks’ output as props.
 */
export function EditMatchesSFC() {
  const [
    {
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState<EngagementMatching>();

  return (
    <EditMatches
      engagement={engagement}
      params={params}
      setPageProps={setPageProps}
    />
  );
}
EditMatchesSFC.displayName = 'EditMatchesSFC';

const handle = {
  sidebar: true,
  subheader: true,
  title: 'Engagement Edit Matches',
};

const loader: LoaderFunction = ({ params: { engagementId, workspaceId } }) => fetchMatches(
  engagementId,
  workspaceId,
  { first: LIMIT_PER_PAGE },
);

export {
  EditMatchesSFC as Component,
  loader,
  handle,
};
