import type { HTTPError } from 'ky';

import { storeProxyFactory } from '../storeProxyFactory.mts';


export const errorsState = new Set<AggregateError | HTTPError>();
export const errorsStore = storeProxyFactory('Errors', errorsState, { async getter() { return new Set() } });

export function onError(err: AggregateError | HTTPError) {
  // log.warn(err);
  errorsState.add(err);
  return Promise.reject(err);
}
