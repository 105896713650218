._Header_b8ghf_1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 1rem;
}

._MetricInfoContainer_b8ghf_9 {
  margin: 0.5em;
  opacity: 0.4;
}

._MetricInfoContainer_b8ghf_9:hover {
  opacity: 0.85;
}
