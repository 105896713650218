.AppHeader {
  align-items: center;
  background-color: var(--brand-dark);
  box-shadow: var(--box-shadow);
  color: var(--brand-light);
  gap: var(--grid-gutter);
  padding:
    calc(0.75 * var(--grid-gutter))
    calc(2 * var(--grid-gutter));
  z-index: 10;
}

@media (width <= 960px) {
  .AppHeader {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (width > 960px) {
  .AppHeader {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    justify-content: space-between;
  }
}

.Masthead {
  max-height: 1.5em;
}

.NavLinks {
  align-items: center;
  background-color: rgb(255 255 255 / 31%);
  border-radius: 2em;
  display: flex;
  flex-direction: row;
  padding: 0.2em;
}

@media (width <= 960px) {
  .NavLinks {
    order: 1;
    overflow-x: auto;
  }
}

.NavLink {
  border-radius: 2em;
  color: var(--brand-light);
  font-size: 0.875em;
  padding: 0.375em 1em;
}

.NavLink:hover {
  background-color: rgb(255 255 255 / 10%);
}

.NavLink:global(.active) { /* stylelint-disable-line selector-class-pattern */
  background-color: var(--brand-dark);
  font-weight: bold;
}

.UserWidget {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  gap: 1ch;
  justify-content: end;
}
