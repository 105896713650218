import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';

import type { Engagement } from '../Engagement.d.ts';
import { UPDATE_ENGAGEMENT_RECURRANCE } from './updateEngagementRecurrance.gql.mjs';

type UpdateEngagementRecurrance = {
  interval?: Int,
  isAutoscheduling?: boolean,
  isRecurringEnabled?: boolean,
  repeat?: string,
  seriesEndDate?: string,
}

export function updateEngagementRecurrance({
  engagementId,
  engagementRecurrance,
}: {
  engagementId: ObjectId,
  engagementRecurrance: UpdateEngagementRecurrance
}) {
  return gqlOperation<{ engagement: Partial<Engagement>}>(UPDATE_ENGAGEMENT_RECURRANCE, {
    engagementId,
    engagementRecurrance,
  })
    .then((data) => data.engagement);
}
