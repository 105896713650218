import classes from './StackTrace.module.css';


export function StackTrace({ errors, stack }: { errors: Error[], stack: string }) {
  if (!errors?.length && !stack) return (<div className={classes.ErrorDetails} />);

  return (
    <details className={classes.ErrorDetails}>
      <summary className={classes.ErrorDetailsToggle}>Error details</summary>

      <section
        className={classes.ErrorOutput}
        data-testid="debug-output"
        id="debug-output"
      >
        {!!stack && (
          <pre className={classes.Error}>{stack}</pre>
        )}

        {errors?.length && (
          <>
            <h3>The above error was caused by</h3>

            {errors.map((err) => {
              const msg = err?.message || `${err}`;
              const subStack = err?.stack || null;

              return (
                <pre
                  className={classes.Error}
                  key={msg.toString()}
                >
                  {subStack || msg}
                </pre>
              );
            })}
          </>
        )}
      </section>
    </details>
  );
}
StackTrace.displayName = 'StackTrace';
