import { clsx } from 'clsx';
import type {
  ButtonHTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from 'react';
import {
  Link,
  type LinkProps,
  type To,
} from 'react-router-dom';
import Button, { type ButtonProps, buttonClasses } from 'form5/react/Button';

import ctaClasses from './Link.module.css';


const GUISES = {
  BUTTON: 'Button',
  LINK: 'Link',
};
const GUISE_VALUES = Object.values(GUISES);

const classes: { [key: string]: Record<string, object>} = {
  [GUISES.BUTTON]: buttonClasses,
  [GUISES.LINK]: ctaClasses,
};

export function CTA(props: CTAButtonProps | CTALinkProps,
) {
  // FIXME: type should be something like
  // typeof to extends CTAButtonProps['to] ? CTAButtonProps : CTALinkProps;
  const p: any = {...props};

  let Component;

  switch (typeof p.to) {
    default: return null;

    case 'object':
    case 'string':
      p.guise ??= GUISES.LINK;
      Component = CTALink;
      break;

    case 'function':
      p.guise ??= GUISES.BUTTON;
      Component = CTAButton;
      break;
  }

  switch (p.guise) {
    case GUISES.BUTTON:
      p.appearance ??= Button.APPEARANCES.PRIMARY;
      p.variant ??= Button.VARIANTS.CTA;
      break;

    case GUISES.LINK:
      p.variant = null;
      break;
  }

  return (
    <Component {...p} />
  );
}
CTA.displayName = 'CTA';
CTA.GUISES = GUISES;
CTA.VARIANTS = Button.VARIANTS;

type CTAOwnProps = {
  children?: ReactNode,
  className?: string,
  disabled?: boolean,
  fluid?: boolean,
  guise?: typeof GUISE_VALUES[number],
  to: To | MouseEventHandler<HTMLButtonElement>,
}

type CTAButtonProps = (
  Omit<CTAOwnProps, 'to'>
  & { to: MouseEventHandler<HTMLButtonElement> }
  & ButtonProps
  & ButtonHTMLAttributes<HTMLButtonElement>
);

function CTAButton({
  className,
  fluid,
  to,
  ...rest
}: CTAButtonProps) {
  const props: Omit<CTAButtonProps, 'to'> = {
    ...rest,
    onClick: to,
  };

  const guise = props.guise ??= GUISES.BUTTON;

  props.className = clsx(ctaClasses.CTA, className, classes[guise][guise], {
    [buttonClasses.fluid]: fluid,
  });

  return (<Button {...props} />);
}
CTAButton.displayName = 'CTAButton';

type CTALinkProps = (
  Omit<CTAOwnProps, 'to'>
  & LinkProps
);
function CTALink({
  className,
  fluid,
  ...rest
}: CTALinkProps) {
  const props: CTALinkProps = { ...rest };
  const guise = props.guise ??= GUISES.LINK;

  if (rest.disabled) {
    props.onClick = (event) => {
      event.preventDefault();
      rest?.onClick(event);
    };
  }
  props.tabIndex = 0;

  props.className = clsx(ctaClasses.CTA, className, classes[guise][guise], {
    [buttonClasses.fluid]: fluid,
  });

  return (<Link {...props} />);
}
CTALink.displayName = 'CTALink';

// const typeofCTA = (
//   to: CTAButtonProps['to'] | CTALinkProps['to'],
// ): to is CTAButtonProps['to'] => typeof to === 'function';
