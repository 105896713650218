import type { WorkspaceAdmin } from '…/app/state/states.d.ts';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import type { TableDatum } from '…/app/common/Table/Table.tsx';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import type { DeSelectAllState, OnSelectChange } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import { SelectCheckbox } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';

import type { upsertTeammate } from '…/app/state/workspaces/upsertTeammate.op.mts';

import type { FormConfig } from '../crudForms.mts';
import classes from './WorkspaceTeam.module.css';


export function transformTeammatesToTabularData(
  teammates: WorkspaceAdmin['teammates'],
  selected: DeSelectAllState,
  {
    onSelect,
    onUpsertTeammate,
    setDialog,
  }: {
    onSelect: OnSelectChange,
    onUpsertTeammate<A extends Parameters<typeof upsertTeammate>>(
      update: A[1],
      audienceIds: A[2],
    ): Promise<void>,
    setDialog(context?: FormConfig | false): void,
  },
) {
  return Array.from(teammates.values()).map((teammate): TableDatum => {
    const {
      id,
      emails: { 0: email },
      profile: {
        firstName,
        lastName,
      },
      role,
    } = teammate;

    return [
      {
        display: (
          <SelectCheckbox
            ariaLabel={`select ${firstName} ${lastName}`}
            checked={selected.get(id)}
            id={id}
            name="teammate"
            onChange={onSelect}
          />
        ),
        fieldName: 'checkbox',
        raw: id,
      },
      {
        fieldName: 'firstName',
        raw: firstName,
      },
      {
        fieldName: 'lastName',
        raw: lastName,
      },
      {
        fieldName: 'email',
        raw: email,
      },
      {
        display: (
          <Chip className={classes.RoleChip} role={role}>{role.toLowerCase()}</Chip>
        ),
        fieldName: 'role',
        raw: role,
      },
      {
        display: (

          <CTA
            guise={CTA.GUISES.LINK}
            to={() => setDialog({
              data: {
                onSubmit: onUpsertTeammate,
                teammate,
              },
              key: `edit.${id}`,
              name: 'edit',
            })}
          >
            <IIcon name="settings" />
          </CTA>
        ),
        fieldName: 'controls',
      },
    ];
  });
}
