import {
  Link,
  NavLink,
  Navigate,
  Outlet,
} from 'react-router-dom';

Link.displayName = 'ReactRouter/Link';
NavLink.displayName = 'ReactRouter/NavLink';
Navigate.displayName = 'ReactRouter/Navigate';
Outlet.displayName = 'ReactRouter/Outlet';
