import type { DateTime } from 'luxon';
import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';
import { isSaturday } from '…/app/common/dateUtils.mts';
import { timezones } from '…/app/common/timezones.mts';

import type { Engagement } from '../../../Engagement.d.ts';


export class ConstraintError extends Error {
  constructor(...args: Parameters<typeof Error>) {
    super(...args);

    this.name = this.constructor.name;
  }
}

export function validateTimezone(timezoneIso: TimezoneISO) {
  if (!(timezoneIso in timezones)) throw new ConstraintError('Timezone is not recognised');

  return true;
}

export function validateBufferTime(convoDates: DateTime[]) {
  for (let i = 1; i < convoDates.length; i++) {
    const previous = convoDates[i - 1];
    const current = convoDates[i];

    if (Math.abs(+previous - +current) < 3_600_000 /* 60 minutes */) {
      throw new ConstraintError('Conversations must be at least 60 minutes apart');
    }
  }

  return true;
}

export const LIMIT_BY_PRODUCT = {
  [PRODUCT_TYPES.GROUP]: 5,
  [PRODUCT_TYPES.ONE_TO_ONE]: 3,
  [PRODUCT_TYPES.TOP_N]: 1,
  [PRODUCT_TYPES.MENTORSHIP]: 3, // This should never happen though
};
export function allowMoreConvoDates(
  convoDates: Array<DateTime | undefined>,
  product: Engagement['product'],
  isLive: Engagement['isLive'] = false,
) {
  if (isLive) return false;

  if (convoDates.length && convoDates.at(-1) == null) return false;

  const LIMIT = LIMIT_BY_PRODUCT[product];

  return convoDates.length < LIMIT;
}

export function validateDayOfWeek(
  date: DateTime | ISO_8601,
  product: keyof typeof LIMIT_BY_PRODUCT,
) {
  if (!date || product === PRODUCT_TYPES.ONE_TO_ONE) return true;

  if (isSaturday(date)) throw new ConstraintError('Cannot occur on a Saturday');
}

export const validations = {
  conversationDates: new Array(
    validateBufferTime,
  ),
  optInCloses: new Array(
    validateDayOfWeek,
  ),
  timezone: new Array(
    validateTimezone,
  ),
};
