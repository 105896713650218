export default {
  "FlexRow": "_FlexRow_1lmhy_1",
  "FlexCol": "_FlexCol_1lmhy_7",
  "Container": "_Container_1lmhy_12",
  "EmailStatsRow": "_EmailStatsRow_1lmhy_16",
  "CardTitle": "_CardTitle_1lmhy_26",
  "CardDate": "_CardDate_1lmhy_31",
  "Stat": "_Stat_1lmhy_38",
  "SkeletonLoader": "_SkeletonLoader_1lmhy_54",
  "loading": "_loading_1lmhy_1"
};import "ni:sha-256;PUafstuB49v-YoxrUYejuMicJdsSkXnJ0Silv2lnnwM";