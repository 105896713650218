._DashboardFiltersContainer_1j9nx_1 {
  background-color: var(--brand-light);
  border: 1px solid var(--brand-subtle-light);
  border-radius: var(--border-radius);
  padding: 1rem;
}

._DashboardFiltersForm_1j9nx_8 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

._ButtonGroupButton_1j9nx_15 {
  padding: 0.5rem 1rem;
}

._FilterTextContainer_1j9nx_19 {
  align-items: center;
  display: flex;
  gap: 1rem;
}

._FilterPanelButton_1j9nx_25 {
  appearance: none;
  background-color: var(--brand-light);
  border: 1px solid var(--brand-subtle-light);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
}

._FilterSpan_1j9nx_36 {
  background: var(--brand-subtle-light);
  border-radius: var(--border-radius);
  font-weight: 500;
  margin: 0 0.375rem;
  padding: 0.5rem 0.75rem;
}
