import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import _random from 'lodash-es/random.js';
import { useState } from 'react';

import matchingFigure from './guide-figure_matching.png';
import messagingFigure from './guide-figure_messaging.png';
import topicFigure from './guide-figure_topic.png';
import classes from './SetupGuide.module.css';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';


export function SetupGuide() {
  const [topic, setTopic] = useState<Topic>(SetupGuide.TOPICS[_random(0, 2)]);

  if (localStorage.getItem(STORAGE_KEY) === STORAGE_VAL) return null;

  const {
    blurb,
    href,
    i,
    label,
    src,
  } = contents[topic];

  function onClose() {
    localStorage.setItem(STORAGE_KEY, STORAGE_VAL);
    setTopic();
  }

  return (
    <figure className={clsx('Well', classes.SetupGuide)}>
      <Button
        appearance={Button.APPEARANCES.BASIC}
        className={classes.CloseButton}
        onClick={onClose}
        variant={Button.VARIANTS.GLYPH}
      >×
      </Button>

      <figcaption className={classes.Steps}>
        <h2 className={classes.Title}>Three keys to a great experience ✨</h2>

        <ol className="bulleted">
          <li aria-current={i === 0}>
            <CTA
              appearance={Button.APPEARANCES.BASIC}
              guise={CTA.GUISES.LINK}
              to={() => setTopic('template')}
            >Select an engaging conversation topic
            </CTA>
          </li>

          <li aria-current={i === 1}>
            <CTA
              appearance={Button.APPEARANCES.BASIC}
              guise={CTA.GUISES.LINK}
              to={() => setTopic('matching')}
            >Customize the matching criteria
            </CTA>
          </li>

          <li aria-current={i === 2}>
            <CTA
              appearance={Button.APPEARANCES.BASIC}
              guise={CTA.GUISES.LINK}
              to={() => setTopic('messaging')}
            >Tweak the messaging to match your brand & feel
            </CTA>
          </li>
        </ol>
      </figcaption>

      <img className={classes.Img} src={src} />

      <figcaption className={classes.Guide}>
        <a
          className={classes.GuideLink}
          href={href}
          rel="noreferrer"
          target="_blank"
        >{label}
        </a>

        <p>{blurb}</p>
      </figcaption>
    </figure>
  );
}
SetupGuide.displayName = 'SetupGuide';

const contents = {
  matching: {
    blurb: 'Considerate matches spark great conversations and create loyal fans',
    href: 'https://intercom.help/orbiit/en/articles/6466268-client-workflow-for-1-1-matching',
    i: 1,
    label: 'Become a matching pro',
    src: matchingFigure,
  },
  messaging: {
    blurb: 'Create excitement around matching rounds for community success',
    href: 'https://intercom.help/orbiit/en/articles/6778433-optimizing-opt-ins-for-1-1-engagements',
    i: 2,
    label: 'Raise those opt-in rates',
    src: messagingFigure,
  },
  template: {
    blurb: 'Find inspiration in our library of proven ideas for conversation topics',
    href: 'https://intercom.help/orbiit/en/articles/6518005-discussion-topic-library',
    i: 0,
    label: 'Get ideas for topics',
    src: topicFigure,
  },
};

type Topic = keyof typeof contents
SetupGuide.TOPICS = Object.keys(contents) as Topic[];

const STORAGE_KEY = 'SetupGuide';
const STORAGE_VAL = 'hidden';
