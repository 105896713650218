export const TINY_MCE_CONFIG = {
  branding: false,
  convert_unsafe_embeds: true, // disable javascript for embed tags
  height: 400,
  menubar: false,
  paste_as_text: true,
  plugins: [
    'autolink', // The Autolink plugin automatically creates hyperlinks when a user types a valid, complete URL
    'link', // lets the user add a hyperlink to text
    'lists', // provides bullet and numbered list
    // 'paste', // pastes as plain text
  ],
  sandbox_iframes: true, // disable javascript within iframes
  toolbar: 'undo redo | bold italic link backcolor | bullist numlist | alignleft aligncenter',
};
export const TINY_MCE_KEY = process.env.TINY_CLOUD_WYSIWYG_KEY; // eslint-disable-line no-undef
