import { ClearCacheKeys } from './ClearCacheKeys.tsx';
import { StackTrace } from './StackTrace.tsx';

import type { ErrorState } from './ErrorState.d.ts';
import classes from './ErrorScreen.module.css';
import warningSrc from './warning.gif';


/**
 * A unified UI for errors processed by `<ReactErrorBoundary>` and `<RouterErrorBoundary>`.
 */
export function ErrorScreen({
  // @ts-ignore when `code` is supplied, it should be an Int; we don't want to suggest supplying a
  // string is okay by expand the type
  code = 'Oh Snap!',
  errors,
  message,
  stack,
}: ErrorState) {
  return (
    <main className={classes.ErrorScreen}>
      <img className={classes.ErrorImage} src={warningSrc} />

      <h2
        className={classes.ErrorCode}
        data-code={code}
        data-testid="error-code"
      >
        {code}
      </h2>

      <h1 className={classes.ErrorMessage}>{message}</h1>

      <p>
        You may be running an outdated version of the app. Try hard-refreshing:

        {' '}

        <ClearCacheKeys />
      </p>

      <p>Contact us if the issue persists.</p>

      {!!stack && <StackTrace errors={errors} stack={stack} />}
    </main>
  );
}
ErrorScreen.displayName = 'ErrorScreen';
