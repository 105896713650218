import Button from 'form5/react/Button';
import { useState } from 'react';
import { useReward } from 'react-rewards';
import { useParams } from 'react-router-dom';

import { EngagementRouteParams } from '…/app/route-params.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { scheduleEngagement } from '…/app/w/workspace/engagements/engagement/gql/scheduleEngagement.op.mjs';


export function ScheduleEngagement({
  onSuccess,
}: {
  onSuccess(result: Engagement): void,
}) {
  const { engagementId, workspaceId } = useParams() as EngagementRouteParams;
  const [isSubmitting, setSubmitting] = useState(false);
  const { reward: confettiReward } = useReward('schedule-engagement', 'confetti');

  async function onSubmit() {
    setSubmitting(true);

    return scheduleEngagement({
      engagementId,
      workspaceId,
    })
      .then(async (d) => {
        confettiReward();
        await new Promise((r) => setTimeout(r, 1_000)); // Artificially delay the dialogue closing so confetti has time
        return d;
      })
      .then((result) => {
        // ORB-2007 - Fix for API returning 'old' `status` field due to query pooling on GraphQL field
        if (result.isLive && result.scheduledAt) {
          result.status = 'SCHEDULED';
        }
        onSuccess(result);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <h1>Schedule Engagement</h1>

      <p>By clicking Confirm below, I acknowledge:</p>

      <p>
        All email, signup & feedback form copy, dates, audience list, and engagement title is final and approved;
        these <strong>cannot be changed for this round</strong>.
      </p>

      <p>
        The <strong>member data I have provided includes an email address and first name</strong>,

        and that if it does not, <em>Orbiit cannot move forward</em> with the creation of this engagement.
      </p>

      <SubmitButton
        appearance={Button.APPEARANCES.AFFIRMING}
        disabled={isSubmitting}
        id="schedule-engagement"
        isSubmitting={isSubmitting}
        onClick={onSubmit}
        type="submit"
      >Schedule
      </SubmitButton>
    </>
  );
}
ScheduleEngagement.displayName = 'ScheduleEngagement';
