import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import {
  Link,
  generatePath,
} from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';

import { ago } from '…/app/common/dateUtils.mts';
import { downloadAudience } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import type { Audience, AudienceDict } from './audience/Audience.d.ts';
import { determineAudienceIndicator } from './audience/determineAudienceIndicator.mts';
import { copyAudience } from './audience/copyAudience.op.mts';
import { renameAudience } from './audience/renameAudience.op.mts';

import type { FormConfig } from './crudForms.mts';


export function transformAudiencesToTabularData(
  audiences: {
    primary: Audience,
    builtins: AudienceDict,
    customs: AudienceDict,
  },
  {
    isEditor,
    setDialog,
    upsertAudienceInStore,
    workspaceId,
  }: {
    isEditor: boolean,
    setDialog(context?: FormConfig | false): void,
    upsertAudienceInStore(audience: Audience, isDeleted?: true): void,
    workspaceId: WalledRouteParams['workspaceId'],
  },
) {
  const builtins = Array.from(audiences.builtins.values())
    .sort((A, B) => A.name > B.name ? 1 : -1);
  const customs = Array.from(audiences.customs.values())
    .sort((A, B) => A.name > B.name ? 1 : -1);

  return [
    audiences.primary,
    ...builtins,
    ...customs,
  ].map((audience) => {
    const {
      id,
      isBuiltin,
      isPrimary,
      lastSyncedAt,
      memberCount,
      name,
    } = audience;
    const typeIndicator = determineAudienceIndicator(audience);
    return [
      {
        display: (
          <Popover
            anchor={(props) => (
              <IIcon
                name={typeIndicator.name}
                {...props}
              />
            )}
            minimal
            position={Popover.POSITIONS.TOP_LEFT}
            type={Popover.TYPES.TOOLTIP}
          >
            {typeIndicator.label}
          </Popover>
        ),
        fieldName: 'indicator',
        raw: id,
      },
      {
        display: (
          <Popover
            anchor={(...props) => (
              <Link
                {...props}
                tabIndex={0} // FIXME: ORB-1827 why is this necessary?
                to={generatePath('/w/:workspaceId/audiences/:audienceId', {
                  audienceId: id,
                  workspaceId,
                })}

              >
                {name}
              </Link>
            )}
            minimal
            type="tooltip"
          >
            {!!lastSyncedAt && `last synced ${ago(lastSyncedAt)}`}
          </Popover>
        ),
        fieldName: 'link',
        raw: name,
      },
      {
        fieldName: 'memberCount',
        raw: memberCount,
      },
      {
        display: (
          <Popover
            anchor={({ className, ...props }) => (
              <Button
                {...props}
                appearance={Button.APPEARANCES.BASIC}
                className={clsx(className, 'ContextMenuIcon')}
                disabled={!isEditor}
                id={`more-actions_${id}`}
                title="more actions"
                variant={Button.VARIANTS.GLYPH}
              />
            )}
            position={Popover.POSITIONS.TOP_RIGHT}
            type={Popover.TYPES.MENU}
          >
            <CTA
              guise={CTA.GUISES.LINK}
              id={`clone_${id}`}
              menuitem=""
              to={() => setDialog({
                data: {
                  action: 'clone',
                  audience: {
                    id,
                    name: `Copy of ${audience.name}`,
                  },
                  onSubmit(cloneName: Audience['name']) {
                    return copyAudience(workspaceId, id, cloneName)
                      .then(upsertAudienceInStore);
                  },
                },
                key: `clone.${id}`,
                name: 'clone',
              })}
            >Make a copy
            </CTA>

            <CTA
              guise={CTA.GUISES.LINK}
              id={`download-csv_${id}`}
              menuitem=""
              to={() => downloadAudience(workspaceId, audience)}
            >Download CSV
            </CTA>

            {!isBuiltin && !isPrimary && (
              <>
                <CTA
                  guise={CTA.GUISES.LINK}
                  id={`rename_${id}`}
                  menuitem=""
                  to={() => setDialog({
                    data: {
                      action: 'rename',
                      audience,
                      onSubmit(newName: Audience['name']) {
                        return renameAudience(workspaceId, id, newName)
                          .then(upsertAudienceInStore);
                      },
                    },
                    key: `upsert.${id}`,
                    name: 'upsert',
                  })}
                >Rename
                </CTA>

                <CTA
                  guise={CTA.GUISES.LINK}
                  id={`delete_${id}`}
                  menuitem=""
                  to={() => setDialog({
                    data: {
                      afterSubmit() {
                        upsertAudienceInStore(audience, true);
                      },
                      audience,
                    },
                    key: `delete.${id}`,
                    name: 'delete',
                  })}
                >Delete
                </CTA>
              </>
            )}
          </Popover>
        ),
        fieldName: 'actions',
      },
    ];
  });
}
