import engagementFrag from './engagement.frag.gql.mjs';

export const UPDATE_ENGAGEMENT = `
mutation UpdateEngagement(
  $workspaceId: ID!
  $engagementId: ID!
  $engagement: EngagementInput!
) {
  engagement: updateEngagement(
    workspaceId: $workspaceId
    engagementId: $engagementId
    engagement: $engagement
  ) {
    ...engagementFields
  }
}

${engagementFrag}
`;
