.ProfileAvatar[role] {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--brand-subtle-light);
  border: 0.1em solid transparent;
  border-radius: 100%;
  box-shadow: var(--box-shadow);
  color: var(--brand-dark);
  display: inline-flex;
  font-size: var(--size);
  height: 1.5em;
  justify-content: center;
  margin: 0.2em;
  outline: 0.2em double;
  overflow: hidden;
  text-align: center;
  width: 1.5em;
}

.ProfileAvatar[role="ADMIN"] {
  color: var(--brand-accent-secondary);
}

.ProfileAvatar[role="EDITOR"] {
  color: var(--brand-accent-ancillary);
}

.ProfileAvatar[role="INTERNALADMIN"] {
  color: var(--brand-accent-teriary);
}

.ProfileAvatar[role="OBSERVER"] {
  color: var(--neutral-medium);
}

.FallbackInitial {
  height: 1.5ex;
  line-height: 1;
}

.ProfileImage {
  max-width: 100%;
}
