import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { OrbiitAPI } from '../api.d.ts';
import type { User } from '../states.d.ts';
import { onError } from '../errors/errors.state.mts';

import { userStore } from './user.store.mts';

import { UPDATE_MY_PROFILE } from './updateMyProfile.gql.mjs';
import { gotMe } from './gotMe.mts';


export function updateMe(profileFields: User['profile']) {
  return gqlOperation<{ me: OrbiitAPI.User }>(UPDATE_MY_PROFILE, profileFields)
    .then(gotMe)
    .then((update) => Object.assign(userStore, update))
    .catch(onError);
}
