import { rpc } from '…/app/common/rpc/client.mts';

import type { Audience } from '../Audience.d.ts';
import type { Member } from './Member.d.ts';


export function getAudiencesForMembers(workspaceId: ObjectId, memberIds: Member['id'][]) {
  return rpc.post('actions/get-audiences-for-members', {
    json: {
      memberIds,
      workspaceId,
    },
  })
    .json<Record<Member['id'], Array<{ [k: Audience['id']]: Audience['name'] }>>>();
}
