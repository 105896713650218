import type { ElementType, PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import type { SECTION_STATUSES } from '../validators.mts';

import classes from './SetupCard.module.css';


export function SetupCard({
  children,
  status,
}: PropsWithChildren<{ status: SECTION_STATUSES }>) {
  return (<section className={clsx(classes.SetupCard, 'Card')} data-status={status}>{children}</section>);
}
SetupCard.displayName = 'SetupCard';

interface SetupCardHeaderProps extends PropsWithChildren<object> {
  Figure: ElementType,
  label: string,
  status: SECTION_STATUSES,
  to: URL['pathname'] | ((value: string) => void),
}

export function SetupCardHeader({
  children,
  Figure,
  label,
  status,
  to,
}: SetupCardHeaderProps) {
  return (
    <header className={classes.SetupCardHeader}>
      <Figure />

      <div>{children}</div>

      {status === 'new'
        ? <CTA guise={CTA.GUISES.BUTTON} to={to}>{label}</CTA>
        : <span className={classes.Status} data-status={status}>{label ?? STATUS_DICT[status]}</span>
      }
    </header>
  );
}
SetupCardHeader.displayName = 'SetupCardHeader';

const STATUS_DICT: { [k in SetupCardHeaderProps['status']]: string } = {
  complete: 'Looks great',
  incomplete: 'Changes needed',
  new: '',
};

export function SetupCardItems({ children, withIcon }: PropsWithChildren<{ withIcon?: boolean }>) {
  return (<ul className={clsx(classes.SetupCardItems, { [classes.WithIcon]: withIcon })}>{children}</ul>);
}
SetupCardItems.displayName = 'SetupCardItems';

export function SetupCardItem({ children }: PropsWithChildren<object>) {
  return (<li className={classes.SetupCardItem}>{children}</li>);
}
SetupCardItem.displayName = 'SetupCardItem';
