import { ENGAGEMENT_STATUS_STEP } from '…/app/deprecated/constants.mts';
import { METRICS_EMPTY_TEXTS } from './constants.mjs';

import { EmptyCard } from '…/app/w/workspace/common/Cards/Cards.tsx';
import { SplitOut } from '…/app/common/SplitOut/SplitOut.tsx';
import { AverageRating } from '…/app/common/AverageRating/AverageRating.tsx';
import { NetPromoterScore } from './NPS/NetPromoterScore.tsx';
import { Statistics } from './Statistics/Statistics.tsx';

const selectedMetric = ({
  metrics, type, status,
}) => {
  switch (type) {
  case 'statistics':
    return <Statistics metrics={metrics} />;
  case 'split-out':
    if (!metrics && ENGAGEMENT_STATUS_STEP[status] >= 1) {
      return <EmptyCard {...METRICS_EMPTY_TEXTS[`${type}-not-available`]} />;
    }
    if ((!metrics && ENGAGEMENT_STATUS_STEP[status] < 1) || !status) {
      return <EmptyCard {...METRICS_EMPTY_TEXTS[type]} />;
    }
    return <SplitOut metrics={metrics} />;

  case 'nps':
    if (!metrics) return <EmptyCard {...METRICS_EMPTY_TEXTS[type]} />;
    return <NetPromoterScore {...metrics} />;

  case 'rating':
    if (!metrics) return <EmptyCard {...METRICS_EMPTY_TEXTS[type]} />;
    return <AverageRating metrics={metrics} />;

  default:
    return null;
  }
};
export const Metrics = ({ data }) => selectedMetric({
  metrics: data.metrics,
  status: data.status,
  type: data.type,
});
