import { Callout } from '…/app/common/Callout/Callout.tsx';


export function AudienceUpdateWarning() {
  return (
    <Callout type={Callout.TYPES.WARN}>
      Members changed within 5 minutes of scheduled email(s) are not reflected in distribution lists.
    </Callout>
  );
}
AudienceUpdateWarning.displayName = 'AudienceUpdateWarning';
