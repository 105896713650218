@media (width <= 960px) {
  .TeamTable td:nth-child(4),
  .TeamTable th:nth-child(4) {
    display: none;
  }
}

.RoleChip {
  color: var(--brand-light);
  text-transform: capitalize;
}

.RoleChip[role="ADMIN"] {
  background-color: var(--brand-dark);
}

.RoleChip[role="EDITOR"] {
  background-color: var(--brand-accent-ancillary);
}

.RoleChip[role="OBSERVER"] {
  background-color: var(--neutral-medium);
}
