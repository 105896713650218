query GetPaginatedMatches(
  $workspaceId: ID!,
  $engagementId: ID!,
  $after: String,
  $first: Int,
  $before: String,
  $last: Int,
  $search: String,
) {
  engagement(
    workspaceId:
    $workspaceId,
    engagementId:
    $engagementId,
  ) {
    isEditMatchesEnabled
    matches: matchesPager(
      first: $first
      after: $after
      last: $last
      before: $before
      search: $search
    ) {
      nodes {
        id
        moderator {
          member {
            fields {
              fullName
            }
            id
          }
        }
        name
        optInForms {
          fields {
            timeslotsUtc
            timezoneIso
          }
          id
          member {
            fields {
              company
              firstName
              fullName
              role
            }
            id
          }
        }
        product
        scheduling {
          datetimeLive
          timezone
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
    matchesCount
  }
}
