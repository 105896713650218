.EnumeratedUpdateTargets {
  display: grid;
  gap: var(--grid-gutter-half);
  grid-template-columns: repeat(3, 1fr);
}

.Fieldset {
  grid-template-columns: 1fr 1fr;
}

.Name {
  background: var(--neutral-lightest);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  display: block;
  margin-bottom: var(--grid-gutter-half);
  padding: var(--grid-gutter-half);
}

.FullField {
  grid-column: 1 / -1;
}

.CollapsingHeader {
  cursor: pointer;
  margin: var(--grid-gutter) 0;
}

.ListInput {
  display: grid;
  gap: inherit;
  grid-template-columns: auto min-content;
}

.AddFieldCTA {
  place-self: start;
}
