import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form, { type OnSubmit } from 'form5/react/Form';
import FileInput from 'form5/react/FileInput';
import Field from 'form5/react/Field';
import {
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';
import { uploadWorkspaceLogo } from '…/app/state/workspaces/uploadWorkspaceLogo.op.mts';
import { updateWorkspaceSettings } from '…/app/state/workspaces/updateWorkspaceSettings.op.mts';

import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import panelClasses from '../SettingsPanel.module.css';

import classes from './WorkspaceBranding.module.css';


export function WorkspaceBranding({
  className,
}: {
  className: string,
}) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const workspace = useStore((data) => data.workspaces[workspaceId]);
  const { settings } = workspace;

  const canSetLogo = useFeatureFlag([FLAGS.uploadWorkspaceLogo]);
  const isEditor = useEditorRole();

  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [{
    backgroundColor,
    foregroundColor,
    logoUrl,
  }, setFieldState] = useState<{
    backgroundColor: string,
    foregroundColor: string,
    logoUrl: string | File,
  }>(() => settings ?? {
    backgroundColor: '',
    foregroundColor: '',
    logoUrl: '',
  });

  function setFieldValue({ name, value }: { name: 'backgroundColor' | 'foregroundColor' | 'logoUrl', value: unknown }) {
    setFieldState((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const onSubmit: OnSubmit = (delta: {
    backgroundColor: string,
    foregroundColor: string,
    logoUrl: File,
  }) => {
    setIsSaving(true);
    if ('logoUrl' in delta) {
      uploadWorkspaceLogo(workspaceId, logoUrl as File)
        .then(() => {
          toast.success('Logo updated');
          setIsSaving(false);
        });
    }

    if (
      'backgroundColor' in delta
      || 'foregroundColor' in delta
    ) {
      updateWorkspaceSettings(workspaceId, {
        backgroundColor,
        foregroundColor,
      })
        .then(() => {
          toast.success('Color(s) saved');
          setIsSaving(false);
        });
    }
  };

  return (
    <main
      className={panelClasses.SettingsPanel}
      data-testid="Settings:Branding"
    >
      <Form
        className={className}
        name="workspace-branding"
        onSubmit={onSubmit}
      >
        <header className="SplitHeader">
          <h1>Branding</h1>

          <SubmitButton
            appearance={Button.APPEARANCES.PRIMARY}
            disabled={isSaving || !isDirty}
            isSubmitting={isSaving}
            type="submit"
          >Save changes
          </SubmitButton>
        </header>

        <div className={clsx('Card', className, panelClasses.Section)}>
          <h2>Workspace Name</h2>

          <fieldset className={clsx('SplitContent StackableSplit', classes.FullStack)}>
            <Field
              arrangement={Field.ARRANGEMENTS.STACKED}
              defaultValue={workspace.name}
              fluid
              label={null}
              name="name"
              readOnly
              required
              type="text"
            />
          </fieldset>
        </div>

        <div className={clsx('Card', className, panelClasses.Section)}>
          <h2>Workspace Logo</h2>

          <fieldset className={clsx('SplitContent StackableSplit', classes.FullStack)}>
            <FileInput
              accept="image/*"
              className={classes.LogoPreview}
              name="logoUrl"
              onChange={(e, files) => {
                setFieldValue({
                  name: 'logoUrl',
                  value: files[0],
                });
                setIsDirty(true);
              }}
              readOnly={!canSetLogo}
              value={logoUrl}
            />
          </fieldset>
        </div>

        <div className={clsx('Card', className, panelClasses.Section)}>
          <h2>Brand Colors</h2>

          <div className="SplitContent">
            <fieldset
              disabled={!isEditor}
              readOnly={!isEditor}
              style={{ flexWrap: 'wrap' }}
            >
              <Field
                className="ColorPicker"
                label="Background Color"
                name="backgroundColor"
                onChange={(d) => {
                  setFieldValue({
                    name: d.name as 'backgroundColor',
                    value: d.value,
                  });
                  setIsDirty(true);
                }}
                type="color"
                value={backgroundColor}
              />

              <Field
                className="ColorPicker"
                label="Foreground Color"
                name="foregroundColor"
                onChange={(d) => {
                  setFieldValue({
                    name: d.name as 'foregroundColor',
                    value: d.value,
                  });
                  setIsDirty(true);
                }}
                type="color"
                value={foregroundColor}
              />
            </fieldset>

            <div>
              <Button
                onClick={() => null}
                style={{
                  backgroundColor: backgroundColor,
                  color: foregroundColor,
                  flex: 1,
                }}
                type="button"
              >Example Button
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}
WorkspaceBranding.displayName = 'WorkspaceBranding';

const handle = {
  title: 'Branding',
};

export {
  WorkspaceBranding as Component,
  handle,
};
