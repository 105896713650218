import { CancelEngagement } from './CancelEngagement.tsx';
import { RemoveTemplate } from './RemoveTemplate.tsx';
import { ScheduleEngagement } from './ScheduleEngagement.tsx';
import { SelectAudience } from './SelectAudience.tsx';
import { SubmitEngagement } from './SubmitEngagement.tsx';

export const forms = {
  'cancel-engagement': CancelEngagement,
  'remove-template': RemoveTemplate,
  'schedule-engagement': ScheduleEngagement,
  'select-audience': SelectAudience,
  'submit-engagement': SubmitEngagement,
};
