import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { CANCEL_ENGAGEMENT } from './cancelEngagement.gql.mjs';

export function cancelEngagement({ engagementId, workspaceId }) {
  return gqlOperation(CANCEL_ENGAGEMENT, {
    engagementId,
    workspaceId,
  })
    .then((data) => data.engagement);
}
