import {
  Navigate,
  Outlet,
  useParams,
} from 'react-router-dom';

import { useStore } from '…/app/state/useStore.mts';


export function AuthWall() {
  const isAuthenticated = useStore((data) => data.user.isAuthenticated);
  const { workspaceId } = useParams();

  localStorage.setItem('workspaceId', workspaceId ?? '');

  if (!isAuthenticated) {
    return <Navigate to={`/?redirectUri=${encodeURIComponent(location.href)}`} />;
  }

  return <Outlet />;
}
AuthWall.displayName = 'AuthWall';

export { AuthWall as Component };
