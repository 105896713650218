import { clsx } from 'clsx';
import { html2json, json2html } from 'html2json_2';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';
import _get from 'lodash-es/get.js';
import _set from 'lodash-es/set.js';
import {
  Link,
  generatePath,
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import {
  useEffect,
  useState,
} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'sonner';

import type { SETUP_SECTIONS } from '…/app/w/workspace/engagements/constants.mts';
import { rpc } from '…/app/common/rpc/client.mts';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Tabs } from '…/app/common/Tabs/Tabs.tsx';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { UnsavedChangesWarning } from '…/app/w/workspace/common/UnsavedChangesWarning/UnsavedChangesWarning.tsx';
import { StickyHeader } from '…/app/w/workspace/common/StickyHeader/StickyHeader.tsx';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import type {
  Engagement,
  EngagementEmail,
  ScheduledEngagement,
} from '../../../Engagement.d.ts';
import { updateEngagement } from '../../gql/updateEngagement.op.mts';

import { setupPageConfig } from '../setupPageConfig.mts';
import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';
import subpageClasses from '../EngagementSubPage.module.css';
import pageClasses from './EngagementEmailSetupPage.module.css';
import { Advice } from '../Advice.tsx';

import {
  TINY_MCE_CONFIG,
  TINY_MCE_KEY,
} from './editor.config.mts';
import { sendEmailPreview } from './sendEmailPreview.op.mts';
import { tabs } from './tabs.mts';
import { EmailTemplateVariables } from './EmailTemplateVariables.jsx';
import { FeedbackExperiment } from './FeedbackExperiment.jsx';


export function EngagementEmailSetupPage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState<ScheduledEngagement>();
  const {
    engagementId,
    workspaceId,
  } = params;
  const subSection = params.subSection as typeof SETUP_SECTIONS['INVITATION'] | typeof SETUP_SECTIONS['REMINDER'];

  const {
    advice,
    dataPath,
  } = setupPageConfig[subSection];

  const data: EngagementEmail = _get(engagement, dataPath) ?? {} as EngagementEmail;

  const [recentFeedbackForms, setRecentFeedbackForms] = useState(null);
  const [isDirty, setDirty] = useState(false);
  const [isDisabled, setDisabled] = useState(data.isDisabled);
  const [isSubmitting, setSubmitting] = useState(false);
  const isEditor = useEditorRole();
  const blocker = useBlocker(isDirty);

  const disabled = engagement.isLive || !isEditor;
  const {
    emailFieldName,
  } = setupPageConfig[subSection];
  const emailBody = json2html(
    data[emailFieldName]
    || 'bodyHtml' in data && html2json(data.bodyHtml)
    || '',
  );

  const scheduleLink = `${generatePath(
    '/w/:workspaceId/engagements/:engagementId/:section/:subSection',
    {
      ...params,
      subSection: 'schedule',
    },
  )}#${subSection}`;

  const isRecentFeedbackEnabled = useFeatureFlag([FLAGS.emailRecentFeedback]);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  useEffect(() => {
    if (!isRecentFeedbackEnabled) return;
    if (engagement.status !== 'DRAFT') return;
    if (recentFeedbackForms) return;
    rpc.post('actions/get-recent-feedback-forms', {
      json: { workspaceId },
    }).json()
      .then(({ feedbackForms }) => setRecentFeedbackForms(feedbackForms));
  }, [isRecentFeedbackEnabled, recentFeedbackForms]);

  async function onPreview() {
    return sendEmailPreview({
      engagementId,
      type: subSection,
      workspaceId,
    })
      .then((sent: boolean) => {
        if (sent === true) {
          toast.success('Preview email sent!');
        }
        if (sent === false) {
          toast.error('Preview not sent');
        }
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      });
  }

  const onSubmit: OnSubmit = async (delta) => {
    setSubmitting(true);
    const promise = updateEngagement({
      engagement: _set({}, dataPath, {
        ...delta,
        // TinyMCE stores its value as HTML in its textarea (but consumes JSON 😑).
        ...(delta?.bodyJson && { bodyJson: html2json(delta.bodyJson) }), // [1]
      }),
      engagementId,
      workspaceId,
    })
      .then((result: Engagement) => {
        setPageProps((prev) => ({
          ...prev,
          engagement: result,
        }));
      })
      .finally(() => {
        setDirty(false);
        setSubmitting(false);
      });

    toast.promise(promise, {
      error: (err: AggregateError) => err.errors?.[0].message,
      loading: 'Saving email…',
      success: 'Email changes saved!',
    });
  };

  return (
    <main
      className={clsx(className, subpageClasses.SubPage)}
      data-testid="EngagementEmailSetupPage"
    >
      <section className={clsx(subpageClasses.Section, subpageClasses.EditableSection)}>
        <Tabs
          params={params}
          routeTemplate="/w/:workspaceId/engagements/:engagementId/:section/:subSection"
          tabs={tabs}
        />

        <Form
          className={editableSectionClasses.Form}
          name={subSection}
          onDirty={setDirty}
          onPristine={setDirty}
          onSubmit={onSubmit}
        >
          {blocker && <UnsavedChangesWarning blocker={blocker} />}

          <StickyHeader className={clsx(pageClasses.FlexEnd)}>
            {isDirty && (
              <h4 className={pageClasses.Warning}>You have unsaved changes</h4>
            )}

            <Button.Group className={editableSectionClasses.HeaderButtons}>
              <Button
                appearance={Button.APPEARANCES.BASIC}
                onClick={onPreview}
              >Preview
              </Button>

              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                disabled={disabled || !isDirty || isSubmitting}
                isSubmitting={isSubmitting}
                type="submit"
              >Save changes
              </SubmitButton>
            </Button.Group>
          </StickyHeader>

          <blockquote>
            If you are not going to send emails from the Orbiit platform, you do not need to
            complete this step. Go to <Link to={scheduleLink}>schedule setup</Link> to configure the send date/time.
          </blockquote>

          {/* @ts-ignore */}

          <fieldset disabled={disabled || isDisabled} readOnly={isDisabled}>
            <Field
              arrangement={Field.ARRANGEMENTS.INLINE}
              defaultValue={data.subject}
              fluid
              label="Subject line"
              name="subject"
            />

            <Field
              arrangement={Field.ARRANGEMENTS.INLINE}
              defaultValue={data.preheader}
              fluid
              label="Preview text"
              name="preheader"
            />

            <Field
              arrangement={Field.ARRANGEMENTS.INLINE}
              defaultValue={data.callToAction}
              label="Button text"
              name="callToAction"
              required
            />

            <blockquote>
              This text will appear in the inbox after the subject line. It is also known as the
              email preheader.
            </blockquote>

            <Editor
              apiKey={TINY_MCE_KEY}
              disabled={disabled || isDisabled}
              id={emailFieldName}
              init={TINY_MCE_CONFIG}
              initialValue={emailBody}
              onDirty={() => setDirty(true)}
            />

            <EmailTemplateVariables emailBody={emailBody} />
          </fieldset>

          {(isRecentFeedbackEnabled && recentFeedbackForms?.length) && (
            <FeedbackExperiment
              data={recentFeedbackForms}
              engagementId={engagement.id}
              workspaceId={workspaceId}
            />
          )}

          <fieldset className={pageClasses.DisableEmailContainer}>
            <div className={subpageClasses.SplitContent}>
              <h4>Disable Email</h4>

              <Field
                checked={isDisabled || false}
                className={editableSectionClasses.InvertedToggle}
                disabled={disabled}
                label={null}
                name="isDisabled"
                onChange={({ value }) => setDisabled(value as boolean)}
                type="checkbox"
                variant="toggle"
              />
            </div>

            <p>
              If you’re not going to send emails from the Orbiit platform, you do not need to
              complete this step. Disable the emails and go to schedule setup to configure
              the send date/time.
            </p>
          </fieldset>
        </Form>
      </section>

      <Advice {...advice} className={clsx(subpageClasses.Section, subpageClasses.Sidebar)} />
    </main>
  );
}
EngagementEmailSetupPage.displayName = 'EngagementEmailSetupPage';

export {
  EngagementEmailSetupPage as Component,
};
