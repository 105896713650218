import { useState } from 'react';
import propTypes from 'prop-types';

import { MeterWithLabels } from '…/app/common/Meter/MeterWithLabels.tsx';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { BaseCard, FeatureFlagDisabledCard } from '…/app/w/workspace/common/Cards/Cards.tsx';

import styles from './OptionsMetricCard.module.css';

const MAX_OPTIONS = 12;
const LABELS = {
  MultiSelectStep: 'Multi-Select',
  SingleSelectStep: 'Single-Select',
};

const {
  arrayOf, shape, string, number,
} = propTypes;

const optionPropType= shape({
  count: number,
  percentage: number,
  text: string,
});

function OptionsMetricCard({
  questionType,
  title,
  values = new Array(),
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const showButton = values.length > MAX_OPTIONS;

  const isCustom = questionType === 'Custom';

  const toggleView = () => setIsExpanded(!isExpanded);

  const visibleItems = new Array(
    isExpanded
      ? values.length
      : Math.min(MAX_OPTIONS, values.length),
  );
  for (let i = 0; i < visibleItems.length; i++) {
    const option = values[i];
    visibleItems[i] = (
      <MeterWithLabels
        className={styles.OptionWrapper}
        disablePercentage={isCustom}
        key={option.id}
        {...option}
      >
        {option.text}
      </MeterWithLabels>
    );
  }

  return (
    <>
      <div className={styles.OptionsCardHeaderContainer}>
        <div>
          <h4>{title}</h4>
        </div>

        {!isCustom && (
          <div>
            <Chip>{LABELS[questionType]}</Chip>
          </div>
        )}
      </div>

      <div className={styles.OptionsItemsWrapper}>
        {visibleItems}
      </div>

      {showButton && (
        <div className={styles.MoreButtonContainer}>
          <button
            onClick={toggleView}
            type="button"
          >
            {isExpanded ? 'View less' : 'View more'}
          </button>
        </div>
      )}
    </>
  );
}

OptionsMetricCard.displayName = 'OptionsMetricCard';

OptionsMetricCard.propTypes = {
  questionType: string,
  title: string,
  values: arrayOf(optionPropType),
};


export function OptionsCard({
  className,
  flag,
  questionType,
  title,
  values,
}) {
  const isFeatureEnabled = useFeatureFlag([flag]);
  if (isFeatureEnabled && !values?.length) return null;
  return (
    <BaseCard className={className} disabled={!isFeatureEnabled}>
      {isFeatureEnabled
        ? <OptionsMetricCard
          questionType={questionType}
          title={title}
          values={values}
        />
        : <FeatureFlagDisabledCard title={title} />}
    </BaseCard>
  );
}
OptionsCard.displayName = 'OptionsCard';

OptionsCard.propTypes = {
  className: string,
  flag: string,
  questionType: string,
  title: string,
  values: arrayOf(optionPropType),
};
