._Content_22m1e_1 {
  padding: 0.75rem 1.5rem;
}

._Header_22m1e_5 {
  background: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  padding: 1.5em;
}
