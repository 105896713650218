import type {
  Dispatch,
  MouseEvent,
  SetStateAction,
} from 'react';
import Button from 'form5/react/Button';
import Field, { type FieldProps } from 'form5/react/Field';
import { useParams } from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import type {
  Audience,
  AudienceDict,
  AudienceId,
} from '../Audience.d.ts';

import classes from './AssociatedAudiencesList.module.css';


export type SelectedAudiences = Map<AudienceId, Audience['name']>;

export function AssociatedAudiencesList(
  {
    label = 'Audience(s)',
    name = 'audienceIds',
    selected,
    setSelected,
  }: {
    label?: string,
    name?: string,
    selected: SelectedAudiences,
    setSelected: Dispatch<SetStateAction<SelectedAudiences>>,
  },
) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const audiences = useStore((data) => data.workspaces[workspaceId].customAudiences) as AudienceDict;
  const list = [];

  const remove = ({ currentTarget: { id } }: MouseEvent<HTMLButtonElement>) => setSelected((prev) => {
    const updated = new Map(prev);
    updated.delete(id);
    return updated;
  });

  const onAudienceSelect: FieldProps['onChange'] = ({ value }, { target }) => {
    if (audiences.has(value)) {
      setSelected((prev) => {
        const updated = new Map(prev);
        updated.set(value, audiences.get(value)!.name);
        return updated;
      });
      target.value = '';
    }
  };

  for (const [id, audienceName] of selected.entries()) {
    list.push(
      <li key={id}>
        <input
          id={`audiences[${id}]`}
          name={`${name}[]`}
          type="hidden"
          value={id}
        />

        <Button
          appearance={Button.APPEARANCES.BASIC}
          className={classes.RemovableButton}
          id={id}
          onClick={remove}
          title="click to remove"
        >
          {audienceName}
        </Button>
      </li>,
    );
  }

  return (
    <>
      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        fluid
        label={label}
        list="audiences-list"
        name="" // Set no name to exclude it from onSubmit's `delta`
        onChange={onAudienceSelect}
      />

      <ul className={classes.SelectedAudiences}>{list}</ul>
    </>
  );
}
AssociatedAudiencesList.displayName = 'AssociatedAudiencesList';
