import propTypes from 'prop-types';
import { clsx } from 'clsx';

import InfoIcon from '…/app/deprecated/components/icons/InfoIcon.jsx';

import { appendPercent } from '…/app/common/appendPercent.mts';
import { numberPrecision } from '…/app/common/mathUtils.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FeatureFlagDisabledCard } from '…/app/w/workspace/common/Cards/Cards.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import { MeterWithPopover } from '…/app/common/Meter/MeterWithPopover.jsx';

import classes from './MetricsCard.module.css';

export function MetricsCard({
  decimals,
  flag,
  infoBlurb,
  isPercent,
  meter,
  orbiit,
  prominence,
  title,
  value,
}) {
  const isFeatureEnabled = useFeatureFlag([flag]);
  const multiplier = isPercent ? 100 : 1;
  const scaledValue = multiplier * value;

  return (
    <figure
      className={clsx(classes.MetricsCard, isFeatureEnabled ? null : classes.MetricsCardDisabled)}
      prominence={prominence}
    >
      {isFeatureEnabled
        ? (
          <>
            {infoBlurb && (
              <Popover
                anchor={({ className, ...props }) => (
                  <InfoIcon {...props} className={clsx(className, classes.MetricInfoContainer)} />
                )}
                containerClassName={classes.MetricInfo}
                position={Popover.POSITIONS.TOP_RIGHT}
                type={Popover.TYPES.TOOLTIP}
              >
                {infoBlurb}
              </Popover>
            )}

            <p className={classes.MetricValue}>
              {(Number.isNaN(value) || value == null ) ? '∅' : appendPercent(numberPrecision(scaledValue, decimals || 0).toString(), isPercent) }
            </p>

            {!!meter && (
              <MeterWithPopover
                high={meter.high}
                low={meter.low}
                max={meter.max}
                min={meter.min}
                optimum={orbiit}
                value={scaledValue}
              />
            )}

            <figcaption className={classes.MetricTitle}>
              {title}
            </figcaption>

            {orbiit != null && (
              <p className={classes.MetricBenchmark}>
                The Orbiit-wide average is {appendPercent(numberPrecision(orbiit, decimals || 0).toString(), isPercent)}
              </p>
            )}
          </>
        )
        : (<FeatureFlagDisabledCard minimalPadding title={title} />)}
    </figure>
  );
}
MetricsCard.PROMINENCES = {
  /* eslint-disable sort-keys */
  SIMPLE: 'simple',
  CATCHING: 'catching',
  FRONT_AND_CENTRE: 'front-end-centre',
  /* eslint-enable sort-keys */
};

MetricsCard.displayName = 'MetricsCard';

const {
  bool,
  node,
  number,
  oneOf,
  shape,
  string,
} = propTypes;
MetricsCard.propTypes = {
  decimals: number,
  flag: string,
  infoBlurb: node,
  isPercent: bool,
  meter: shape({
    high: number,
    low: number,
    max: number.isRequired,
    min: number.isRequired,
  }),
  orbiit: number,
  prominence: oneOf(Object.values(MetricsCard.PROMINENCES)),
  title: node.isRequired,
  value: number.isRequired,
};
