._SelectedAudiences_1cxnz_1 {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
}

._RemovableButton_1cxnz_7::after {
  content: '×';
  margin-left: 0.4em;
}
