import type { ElementType } from 'react';

import type { SetupAdvice } from './SetupAdvice.d.ts';

import classes from './Advice.module.css';


export function Advice({
  Figure,
  as: Tag = 'section',
  article,
  className,
  heading,
  tips,
}: SetupAdvice & {
  as?: ElementType,
  className?: string,
}) {
  return (
    <Tag className={className}>
      {Figure && <Figure className={classes.AdviceFigure} />}

      <h1>{heading}</h1>

      {tips?.length && (
        <ul className={classes.Tips}>
          {tips.map((tip) => (<li key={tip}>{tip}</li>))}
        </ul>
      )}

      {article && (
        <a
          href={article.href}
          rel="noopener noreferrer"
          target="_blank"
        >{article.label}
        </a>
      )}
    </Tag>
  );
}
Advice.displayName = 'Advice';
