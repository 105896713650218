import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import { useState } from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type {
  Audience,
} from './Audience.d.ts';

type FormData = {
  name: string,
}

export function UpsertAudienceForm({
  action,
  audience: {
    id,
    name,
  } = {
    id: '',
    name: '',
  },
  onSubmit,
}: {
  action: Action,
  audience?: Pick<Audience, 'id' | 'name'>,
  onSubmit(name: Audience['name']): Promise<void>,
}) {
  const [isDirty, setDirty] = useState(!id && name || false);
  const [isSaving, setIsSaving] = useState(false);
  const isNew = !id;

  function handleSubmit(delta: FormData, all: FormData) {
    setIsSaving(true);

    const promise = onSubmit(all.name).finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: 'Saving audience failed',
      loading: 'Saving audience…',
      success: `“${delta.name}” saved`,
    });

    return promise;
  }

  return (
    <Form
      name="upsert-audience"
      onDirty={setDirty}
      onPristine={setDirty}
      onSubmit={handleSubmit}
    >
      <h1>{ACTION_HEADINGS[action]} Audience</h1>

      <Field
        arrangement={Field.ARRANGEMENTS.INLINE}
        defaultValue={name}
        fluid
        label="Name"
        name="name"
        placeholder="Guest list"
        required
        type="text"
      />

      <SubmitButton
        appearance={isNew ? Button.APPEARANCES.AFFIRMING : Button.APPEARANCES.PRIMARY}
        disabled={isSaving || !isDirty}
        isSubmitting={isSaving}
        type="submit"
      >{isNew ? 'Create' : 'Save changes'}
      </SubmitButton>
    </Form>
  );
}
UpsertAudienceForm.displayName = 'UpsertAudienceForm';

const ACTION_HEADINGS = {
  clone: 'Copy',
  create: 'Create',
  rename: 'Rename',
};
type Action = keyof typeof ACTION_HEADINGS;
