mutation DeleteSignupCustomQuestion(
  $workspaceId: ID!,
  $engagementId: ID!,
  $questionId: ID!,
) {
  question: deleteEngagementOptInCustomQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    optInQuestionId: $questionId
  ) {
    id
  }
}
