._ErrorScreen_1wvpj_1 {
  align-items: center;
  background-color: var(--brand-light);
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: "AT Surt", monospace;
  gap: 1rem;
  justify-content: center;
  padding: 2em;
}

._ErrorImage_1wvpj_13 {
  width: 6ch;
}

._ErrorCode_1wvpj_17 {
  font-size: 3em;
  line-height: 1;
  margin: 0;
}

._ErrorMessage_1wvpj_23 {
  color: var(--text-neutral);
  font-size: 1.5em;
  font-weight: 600;
  margin: 0;
}
