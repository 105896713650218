._FlexRow_1lmhy_1 {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

._FlexCol_1lmhy_7 {
  display: flex;
  flex-direction: column;
}

._Container_1lmhy_12 {
  padding: 1rem;
}

._EmailStatsRow_1lmhy_16 {
  border-top: 1px solid var(--neutral-light);
  justify-content: space-between;
  padding: 0.375rem 0;
}

._EmailStatsRow_1lmhy_16:first-child {
  border-top: none;
}

._CardTitle_1lmhy_26 {
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

._CardDate_1lmhy_31 {
  color: #AAA;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

._Stat_1lmhy_38 {
  color: var(--neutral-medium);
  display: block;
  font-size: 0.875rem;
}

@keyframes _loading_1lmhy_1 {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

._SkeletonLoader_1lmhy_54 {
  animation: _loading_1lmhy_1 1s infinite;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  height: 20px;
  width: 100%;
}
