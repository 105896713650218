._EmailEventRow_rfx83_1 {
  border-top: 1px solid #EEE;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 0.5rem 0;
}

._EmailEventRow_rfx83_1:first-child {
  border-top: none;
}

._EmailEventRow_rfx83_1 > :first-child {
  flex: 1;
}

._EmailEventRow_rfx83_1 > :nth-child(2) {
  flex: 4;
}

._EmailEventRow_rfx83_1 > :nth-child(3) {
  flex: 3;
  text-align: right;
}
