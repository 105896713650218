import { useRef, useState } from 'react';

import type {
  Cursors,
  Paging,
} from './pagination.d.ts';
import { PAGE_ITEM_LIMIT } from './factories.mts';


export function usePagination<R extends BasicRecord>(
  limit = PAGE_ITEM_LIMIT,
  initialRecords: RecordsDict<R> = undefined,
) {
  const count = useState<Int>(0);
  const cursors: Cursors = useRef({
    end: '',
    start: '',
  });
  const page = useState<Paging>({
    limit,
    number: 1,
    reset: false,
  });
  const records = useState<RecordsDict<R>>(initialRecords ?? new Map());

  return {
    count,
    cursors,
    page,
    records,
  };
}

type BasicRecord = { id: ObjectId };
type RecordsDict<R extends BasicRecord> = Map<R['id'], R>
