import { useState } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import Field from 'form5/react/Field';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { formatDate } from '…/app/common/dateUtils.mts';

import pageClasses from './IntegrationsSettingsDrawer.module.css';
import parentClasses from './WorkspaceIntegrationsPage.module.css';


const {
  arrayOf, func, shape, string,
} = PropTypes;

export function IntegrationsSettingsDrawer({
  onAdd,
  onUnlink,
  lists,
  settings,
}) {
  const [searchLists, setSearchLists] = useState(null);
  const [showLists, setShowLists] = useState(lists);

  const integrationId = settings.id;
  const currentListIds = settings?.syncLists?.map(item => item.remoteId);

  return (
    <>
      <header className={pageClasses.Header}>
        <div className={pageClasses.HeaderContent}>
          <div className={parentClasses.IntegrationName}>
            <IIcon name={settings?.accountType?.toLowerCase()} />

            <h2>{settings.accountType}</h2>
          </div>

          <span className={parentClasses.SubText}>
            Connected: {formatDate(settings.createdAt, null, true)}
          </span>
        </div>

        <div className={pageClasses.Tabs}>
          <div className={clsx(pageClasses.Tab, pageClasses.TabSelected)}>Lists</div>

          <div className={pageClasses.Tab}>Permissions (coming soon)</div>

          {/* <div className={pageClasses.Tab}>Logs <Chip>Coming Soon</Chip></div> */}
        </div>
      </header>


      <div className={clsx(pageClasses.Content, pageClasses.ListsScrollable)}>
        <div>
          <h4 className={pageClasses.Title}>Syncing Lists</h4>

          <div className={pageClasses.Lists}>
            {settings?.syncLists?.map((item) => (
              <div className={pageClasses.ListItem} key={`integration-settings-${item.remoteId}`}>
                <div>
                  <h4>{item.displayName}</h4>

                  <div>
                    <span className={parentClasses.SubText}>
                    ID: {item.remoteId}
                    </span>
                  </div>
                </div>

                {/** TODO: Loading button when submitting? */}
                <button
                  className={parentClasses.IconButton}
                  onClick={() => onUnlink({
                    integrationId,
                    remoteId: item.remoteId,
                  })}
                >
                  <IIcon name="unlink" />

                  {' '}

                Unlink
                </button>
              </div>
            ))}
          </div>
        </div>

        <div>
          <h4 className={pageClasses.Title}>Add Lists to Sync</h4>

          <div className={pageClasses.SearchContainer}>
            <Field
              arrangement={Field.ARRANGEMENTS.STAND_ALONE}
              name="query"
              onChange={({ value }) => {
                setSearchLists(value);
                if (value?.length > 0 && lists?.length) {
                  setShowLists(
                    lists.filter(
                      (item) => item.name.toLowerCase().includes(value.toLowerCase()),
                    ),
                  );
                } else {
                  setShowLists(lists);
                }
              }}
              placeholder="Search"
              type="search"
              value={searchLists}
            />

            <Chip>
              {showLists?.length && searchLists?.length && (
                `${showLists.length} results`
              )}

              {showLists?.length && !searchLists?.length && (
                `${showLists.length} lists`
              )}
            </Chip>
          </div>

          <div className={clsx(pageClasses.Lists)}>
            {showLists?.length > 0 && showLists.map(item => {
              if (currentListIds?.includes(item.id)) return null;
              return (
                <div className={pageClasses.ListItem} key={`list-${item.id}`}>
                  <div>
                    <h4>{item.name}</h4>

                    <div>
                      <span className={parentClasses.SubText}>
                      ID: {item.id}
                      </span>
                    </div>
                  </div>

                  {/** TODO: Loading button when submitting? */}
                  <button
                    className={parentClasses.IconButton}
                    onClick={() => onAdd({
                      displayName: item.name,
                      integrationId,
                      remoteId: item.id,
                    })}
                  >+ Add
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

IntegrationsSettingsDrawer.propTypes = {
  lists: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  onAdd: func.isRequired,
  onUnlink: func.isRequired,
  settings: shape({
    accountType: string.isRequired,
    createdAt: string.isRequired,
    id: string.isRequired,
    syncLists: arrayOf(shape({
      displayName: string.isRequired,
      remoteId: string.isRequired,
    })),
  }).isRequired,
};

IntegrationsSettingsDrawer.displayName = 'IntegrationsSettingsDrawer';

const SearchHighlight = ({ value }) => {
  if (!value) return null;
  return (<span className={pageClasses.Highlight}>{value.toLowerCase()}</span>);
};
SearchHighlight.displayName = 'SearchHighlight';
SearchHighlight.propTypes = { value: string.isRequired };
