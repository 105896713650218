import { clsx } from 'clsx';
import _set from 'lodash-es/set.js';
import type {
  SyntheticEvent,
} from 'react';
import {
  useEffect,
  useState,
} from 'react';
import {
  Link,
  generatePath,
  useBlocker,
} from 'react-router-dom';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import { questions as platformUtilsQuestions } from '@hivebrite/orbiit-platform-utils';

import InfoIcon from '…/app/deprecated/components/icons/InfoIcon.jsx';

import { StickyHeader } from '…/app/w/workspace/common/StickyHeader/StickyHeader.tsx';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { Callout } from '…/app/common/Callout/Callout.tsx';

import { UnsavedChangesWarning } from '…/app/w/workspace/common/UnsavedChangesWarning/UnsavedChangesWarning.tsx';

import { engagementParams } from '…/app/w/workspace/engagements/engagementParams.mts';
import {
  type ParticipantForm,
  SETUP_SECTIONS,
} from '…/app/w/workspace/engagements/constants.mts';

import { setupPageConfig } from '../setupPageConfig.mts';
import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';

import type {
  FormData,
  Question,
} from './ParticipantQuestions.d.ts';
import type { questionConfigGetters } from './questions.config.mts';
import { ParticipantQuestionType } from './questions.config.mts';
import { getQuestionData } from './getQuestionData.mts';
import { FreeResponseQuestion } from './FreeResponseQuestion.tsx';
import { AdvancedQuestion } from './AdvancedQuestion/AdvancedQuestion.tsx';
import { PairedResponseQuestion } from './PairedResponseQuestion.tsx';

import classes from './ParticipantSetupForm.module.css';


const { initOptions } = platformUtilsQuestions;

export function ParticipantSetupForm({
  config,
  data,
  disabled,
  name,
  onSubmit,
  previewUrl,
  type,
}: ParticipantSetupFormProps) {
  const [state, setState] = useState<FormData>(data);
  const [isDirty, setDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { subSection, ...params } = engagementParams();
  const blocker = useBlocker(isDirty);

  const {
    description,
    questions,
    title,
  } = state;
  const { hasWelcome } = setupPageConfig[subSection];

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  function onChange({ id, value }: { id: string, value: unknown }) {
    setState((prev: FormData) => _set({ ...prev }, id, value));
  }

  const addCustomQuestion = () => setState((prev: FormData) => {
    const newState = { ...prev };
    const newCustomQuestion = {
      isDataPrivate: false,
      isDisabled: false,
      isMatchingDisabled: false,
      isMatchingOpposites: false,
      isOrdered: false,
      props: {
        // minSelect,
        // maxSelect,
        ledeIn: '',
        lockedProps: new Array(),
        options: [initOptions('')],
        title: '',
      },
      question: 'SingleSelectStep',
      stepName: 'custom_',
      target: '',
    };

    const idx = newState.questions.custom.push(newCustomQuestion) - 1;
    newCustomQuestion.stepName += idx + 1;
    newCustomQuestion.target = `questions.custom[${idx}]`;

    // NOTE: Something happens with the indexing when doing add-delete before saving state in-between.
    // console.warn('🚨 newCustomQuestion', newCustomQuestion.target, newCustomQuestion);

    return newState;
  });

  const previewCTA = (
    <CTA
      appearance={Button.APPEARANCES.AFFIRMING}
      disabled={!previewUrl || isDirty}
      guise={CTA.GUISES.BUTTON}
      rel="noreferrer"
      style={{
        borderBottomRightRadius: 'unset',
        borderTopRightRadius: 'unset',
      }}
      target="_blank"
      to={previewUrl}
    >Preview
    </CTA>
  );

  return (
    <Form
      name={name}
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={async () => {
        setIsSubmitting(true);
        await onSubmit(state);
        setIsSubmitting(false);
      }}
    >
      {blocker && <UnsavedChangesWarning blocker={blocker} />}

      <StickyHeader className={classes.FlexEnd}>
        {isDirty && (
          <p className={clsx(classes.Warning, 'warning')}>You have unsaved changes</p>
        )}

        <Button.Group className={editableSectionClasses.RightButton}>
          {(previewUrl && !isDirty) && (previewCTA)}

          {(previewUrl && isDirty) && (
            <Popover
              anchor={() => previewCTA}
              type={Popover.TYPES.TOOLTIP}
            >You cannot preview the Opt-In Form without saving changes.
            </Popover>
          )}

          {!previewUrl && (
            <Popover
              anchor={() => previewCTA}
              type={Popover.TYPES.TOOLTIP}
            >You cannot preview the Opt-In Form without Engagement

              {' '}

              <Link to={generatePath('/w/:workspaceId/engagements/:engagementId/:section/:subSection', {
                ...params,
                subSection: SETUP_SECTIONS.SCHEDULE,
              })}
              >dates
              </Link>.
            </Popover>
          )}

          <SubmitButton
            appearance={Button.APPEARANCES.PRIMARY}
            disabled={!isDirty || isSubmitting || disabled}
            isSubmitting={isSubmitting}
            type="submit"
          >Save
          </SubmitButton>
        </Button.Group>
      </StickyHeader>

      <fieldset disabled={disabled}>
        {hasWelcome && (
          <Details open>
            <Summary>
              <h2>Welcome Message</h2>

              <Popover
                anchor={InfoIcon}
                type={Popover.TYPES.TOOLTIP}
              >
                Craft a warm welcome note to reflect the excitement of users that chose to opt-in and set expectations
                for what’s to come. A thoughtful welcome goes a long way in building engagement.
              </Popover>
            </Summary>

            <div className={editableSectionClasses.Panel}>
              <Field
                arrangement={Field.ARRANGEMENTS.INLINE}
                defaultValue={title}
                fluid
                label="Title"
                name="title"
                onChange={onChange}
                required
              />

              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                as="textarea"
                fluid
                label="Description"
                name="description"
                onChange={onChange}
                required
                value={description}
              />
            </div>
          </Details>
        )}

        {config.map(([sectionTitle, section]) => (
          <Details key={sectionTitle} open>
            <Summary>
              <h2 id={sectionTitle}>{sectionTitle}</h2>

              {section.info && (<Popover anchor={InfoIcon} type={Popover.TYPES.TOOLTIP}>{section.info}</Popover>)}
            </Summary>

            <div className={editableSectionClasses.Panel}>
              {section.questions.map(({
                heading,
                name: qName,
                required,
                type: qType,
                ...rest
              }) => {
                const QuestionComponent = questionTypeMap[qType];
                const { target, ...props } = getQuestionData(questions, qName, qType);

                if (!target) return null; // Something went wrong, but avoid blowing up.

                // We will not show the Timezone question step when the variableConfig is configured to
                // skip it. It allows us to test skipping it selectively and at some point skip it globally.
                // For now, despite skipping, the step NEEDS to be there for the form to work.
                if (target === 'questions.timezone' && props?.variableConfig?.includes('override::skip')) {
                  return (
                    <div className={classes.TimezoneNotification} key={target}>
                      <Callout type="info">
                        <div>
                          <h4>Timezone Step</h4>

                          <p>
                            From now on the timezone step is no longer needed for Opt-In Forms. It will
                            be obtained directly from the Member.
                          </p>
                        </div>
                      </Callout>
                    </div>
                  );
                }

                return (
                  <QuestionComponent
                    {...rest}
                    data={props}
                    disabled={questions[qName].isDisabled}
                    heading={heading}
                    id={target}
                    key={target}
                    name={name}
                    onChange={onChange}
                    required={required}
                    setDirty={setDirty}
                    type={subSection}
                  />
                );
              })}
            </div>
          </Details>
        ))}

        <Details open>
          <Summary>
            <h2>Custom Questions</h2>

            <Popover
              anchor={InfoIcon}
              type={Popover.TYPES.TOOLTIP}
            >
              Custom Questions are fully editable. For more details on functionality, refer

              {' '}

              <a
                href="https://intercom.help/orbiit/en/articles/6526365-creating-a-custom-question"
                rel="noreferrer"
                target="_blank"
              >here
              </a>.
            </Popover>
          </Summary>

          <div className={editableSectionClasses.Panel}>
            {questions.custom.map((q: Question, idx) => (
              <AdvancedQuestion
                custom
                data={getQuestionData(questions.custom, idx, ParticipantQuestionType.MultipleChoice)}
                disabled={questions.custom[idx].isDisabled}
                heading="Custom Question"
                id={q.target}
                key={q.target}
                onChange={onChange}
                setDirty={setDirty}
                type={subSection}
              />
            ))}
          </div>

          <footer className={classes.Footer}>
            <AddCustomQuestion
              currentCount={questions.custom.length}
              onClick={addCustomQuestion}
              type={type}
            />
          </footer>
        </Details>
      </fieldset>
    </Form>
  );
}
ParticipantSetupForm.displayName = 'ParticipantFormSetupForm';

interface ParticipantSetupFormProps {
  config: ReturnType<typeof questionConfigGetters['feedback']> | ReturnType<typeof questionConfigGetters['signup']>,
  data: FormData,
  disabled?: boolean,
  name: string,
  onSubmit: (...rest: unknown[]) => void,
  previewUrl: URL['href'],
  type: ParticipantForm,
}

const questionTypeMap = {
  [ParticipantQuestionType.FreeResponse]: FreeResponseQuestion,
  [ParticipantQuestionType.MultipleChoice]: AdvancedQuestion,
  [ParticipantQuestionType.PairedResponse]: PairedResponseQuestion,
};

function AddCustomQuestion({
  currentCount,
  onClick,
  type,
}: {
  currentCount: number,
  onClick(event: SyntheticEvent<HTMLButtonElement, MouseEvent>): void,
  type: ParticipantSetupFormProps['type'],
}) {
  const {
    x1, x4, x8, x12,
  } = customQuestionFlags[type];
  let limit = 0;
  if (useFeatureFlag([x1])) limit = 1;
  if (useFeatureFlag([x4])) limit = 4;
  if (useFeatureFlag([x8])) limit = 8;
  if (useFeatureFlag([x12])) limit = 12;

  return (
    <Button
      appearance={Button.APPEARANCES.GLYPH}
      disabled={currentCount >= limit}
      onClick={onClick}
    >Add Custom Question
    </Button>
  );
}
AddCustomQuestion.displayName = 'AddCustomQuestion';

// NOTE: Do we extend here?
const customQuestionFlags = {
  [SETUP_SECTIONS.FEEDBACK]: {
    x1: FLAGS.customFeedbackQuestion,
    x4: FLAGS.customFeedbackQuestionX4,
    x8: FLAGS.customFeedbackQuestionX8,
    x12: FLAGS.customFeedbackQuestionX12,
  },
  [SETUP_SECTIONS.SIGNUP]: {
    x1: FLAGS.customQuestion,
    x4: FLAGS.customQuestionX4,
    x8: FLAGS.customQuestionX8,
    x12: FLAGS.customQuestionX12,
  },
};
