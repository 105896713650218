import type {
  Dispatch,
  SetStateAction,
} from 'react';
import Button from 'form5/react/Button';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import type { Paging } from './pagination.d.ts';
import classes from './PagingControls.module.css';


export function PagingControls({
  page,
  onPage,
  total,
}: PagingControlsProps) {
  const pageCount = Math.ceil(total / page.limit);

  if (!pageCount) return null;

  const onNext = () => onPage?.((prev) => ({
    ...prev,
    direction: 'forward',
    number: page.number + 1,
    reset: false,
  }));
  const onPrevious = () => onPage?.((prev) => ({
    ...prev,
    direction: 'backward',
    number: page.number - 1,
    reset: false,
  }));

  return (
    <footer className={classes.Pagination}>
      <Button.Group>
        <CTA
          disabled={page.number === 1}
          title="go to previous page of data"
          to={onPrevious}
          variant={Button.VARIANTS.GLYPH}
        >
          ‹
        </CTA>

        <Button
          appearance={Button.APPEARANCES.BASIC}
          disabled
          variant={Button.VARIANTS.GLYPH}
        >
          {page.number} of {pageCount} {/* eslint-disable-line react/jsx-newline */}
        </Button>

        <CTA
          disabled={page.number === pageCount}
          title="go to next page of data"
          to={onNext}
          variant={Button.VARIANTS.GLYPH}
        >
          ›
        </CTA>
      </Button.Group>
    </footer>
  );
}
PagingControls.displayName = 'PagingControls';

export interface PagingControlsProps {
  page: Paging,
  onPage: Dispatch<SetStateAction<Paging>>,
  total: Int,
}
