import type {
  SerialListItem,
  SerialParams,
  SerialSet,
} from '…/app/common/refinement/useRefinementParams.mts';

import type { GraphQLFilter } from './filters.d.ts';


export function transformFilterMapToGQL<Filters extends SerialParams<SerialSet | SerialListItem[]>>(
  filters: Filters = {} as Filters,
) {
  const output: GraphQLFilter[] = [];

  for (const field in filters) {
    const values = filters[field];
    // @ts-ignore Array vs SerialSet doesn’t matter.
    if (values.length ?? values.size) {
      output.push({
        field,
        // @ts-ignore TypeScript doesn’t understand optional invocation.
        values: values.toJSON?.() ?? values,
      });
    }
  }

  return output;
}
