import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Audience } from './Audience.d.ts';
import { COPY_AUDIENCE } from './copyAudience.gql.mjs';


export async function copyAudience(
  workspaceId: ObjectId,
  audienceId: Audience['id'],
  cloneName: Audience['name'],
) {
  return gqlOperation<{clone: Audience}>(COPY_AUDIENCE, {
    audienceId,
    name: cloneName,
    workspaceId,
  }).then(({ clone }) => clone);
}
