import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import { DateTime } from 'luxon';

import { formatRelativeDate } from '…/app/common/dateUtils.mts';

import type { TableDatum, TableHeading } from '…/app/common/Table/Table.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import type {
  OptInForm,
  OptInFormsDict,
  WidgetConfig,
} from './optin-forms.d.ts';
import { StatusIcon } from './StatusIcon.tsx';

import classes from './OptInFormsPage.module.css';

export const tableHeadings = new Map<string, TableHeading>([
  ['status', {}],
  ['name', {
    label: 'Name',
    sortable: true,
    wrap: true,
  }],
  ['role', {
    label: 'Role',
    sortable: true,
    wrap: true,
  }],
  ['company', {
    label: 'Company',
    sortable: true,
    wrap: true,
  }],
  ['optInDate', {
    label: 'Date',
    numeric: true,
    sortable: true,
    wrap: true,
  }],
  ['actions', {}],
]);

export function transformOptInsToTabularData(
  optInForms: OptInFormsDict,
  {
    page,
    limit,
  }: {
    page: Int,
    limit: Int,
  },
  {
    isDeleteOptInFormDisabled,
    setWidgetConfig,
  }: {
    isDeleteOptInFormDisabled: boolean,
    setWidgetConfig: (config: WidgetConfig) => void,
  },
) {
  const start = (page - 1) * limit;
  const end = page * limit;

  return Array.from(optInForms.values())
    .slice(start, end)
    .map((optInForm: OptInForm) => {
      const {
        id,
        createdAt,
        fields,
      } = optInForm;
      const {
        email,
        company,
        fullName,
        role,
      } = fields || {};

      const onViewForm = () => setWidgetConfig({
        data: { optInForm },
        key: `optin.${id}`,
        name: 'form',
        type: 'drawer',
      });

      const standardFields: TableDatum[] = [
        {
          display: false,
          fieldName: 'key',
          raw: id,
        },
        {
          display: (<StatusIcon optInForm={optInForm} />),
          fieldName: 'status',
        },
        {
          display: (
            <h4 className={classes.NameCell}>
              {fullName || email}
            </h4>
          ),
          fieldName: 'name',
          onClick: onViewForm,
          raw: fullName,
        },
        {
          fieldName: 'role',
          onClick: onViewForm,
          raw: role,
        },
        {
          fieldName: 'company',
          onClick: onViewForm,
          raw: company,
        },
        {
          display: formatRelativeDate(DateTime.fromISO(createdAt)),
          fieldName: 'optInDate',
          raw: createdAt,
        },
        {
          display: (
            <Popover
              anchor={({ className, ...props }) => (
                <Button
                  {...props}
                  appearance={Button.APPEARANCES.BASIC}
                  className={clsx(className, 'ContextMenuIcon')}
                  title="more actions"
                  variant={Button.VARIANTS.GLYPH}
                />
              )}
              position={Popover.POSITIONS.BOTTOM_RIGHT}
              type={Popover.TYPES.MENU}
            >
              <CTA
                guise={CTA.GUISES.LINK}
                menuitem=""
                to={onViewForm}
              >View Form
              </CTA>

              <CTA
                destructive=""
                disabled={isDeleteOptInFormDisabled}
                guise={CTA.GUISES.LINK}
                menuitem=""
                to={() => setWidgetConfig({
                  data: {
                    optInForm,
                  },
                  key: `delete.${id}`,
                  name: 'delete',
                  type: 'dialog',
                })}
              >Delete Form
              </CTA>
            </Popover>
          ),
          fieldName: 'actions',
        },
      ];

      return standardFields;
    });
}
