import { useParams } from 'react-router-dom';

import { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import type { AudienceDict } from './audience/Audience.d.ts';


export function AudiencesDatalist() {
  const { workspaceId } = useParams() as WalledRouteParams;
  const audiences = useStore((data) => data.workspaces[workspaceId].customAudiences) as AudienceDict;
  const options = [];

  for (const [audienceId, { name }] of audiences.entries()) {
    options.push(<option key={audienceId} value={audienceId}>{name}</option>);
  }

  return (
    <datalist id="audiences-list">
      {options}
    </datalist>
  );
}
AudiencesDatalist.displayName = 'AudiencesDatalist';
