/* stylelint-disable selector-class-pattern */

._CTA_npxx0_3 {
  display: inline-block;
}

._CTA_npxx0_3[disabled] {
  cursor: not-allowed;
}

._CTA_npxx0_3[guise="Link"] {
  background: none;
  border-radius: inherit;
  color: var(--brand-accent-primary);
  padding: unset;
  text-align: inherit;
  text-decoration: none;
  user-select: text;
  white-space: break-spaces;
}

._CTA_npxx0_3[guise="Link"]:hover {
  color: var(--brand-dark);
}

._CTA_npxx0_3[guise="Link"]:focus {
  color: var(--brand-accent-teriary);
}

._CTA_npxx0_3[guise="Link"]:visited {
  color: var(--brand-accent-ancillary);
}

._CTA_npxx0_3[guise="Link"][appearance="basic"] {
  color: unset;
}

._CTA_npxx0_3[guise="Link"][appearance="danger"] {
  color: var(--colour-danger);
}
