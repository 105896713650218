import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Engagement } from '../../Engagement.d.ts';
import { CLONE_ENGAGEMENT } from './cloneEngagement.gql.mjs';


export function cloneEngagement({
  engagementId,
  overrides,
  shiftBy,
  workspaceId,
}: {
  engagementId: string,
  overrides: Partial<Engagement>,
  shiftBy: {
    amount: number,
    unit: 'weeks' | 'months',
  },
  workspaceId: string,
}) {
  return gqlOperation<{
    engagement: Engagement,
  }>(CLONE_ENGAGEMENT, {
    engagement: overrides,
    engagementId,
    shiftBy,
    workspaceId,
  })
    .then((data) => data.engagement);
}
