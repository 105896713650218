import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { OptionsCard } from '…/app/w/workspace/common/OptionsMetricCard/OptionsMetricCard.jsx';

import { Metrics } from '…/app/deprecated/components/engagement/Dashboard/metrics/index.jsx';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';
import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';

import { METRICS_EMPTY_TEXTS } from './metrics/constants.mjs';

import { BaseCard, EmptyCard } from '…/app/w/workspace/common/Cards/Cards.tsx';

import { fetchDashboardAnalytics } from './fetchDashboardAnalytics.mts';

import classes from './Dashboard.module.css';

export function EngagementDashboard() {
  const {
    numFeedbackForms,
    feedbackQuestionsMetrics,
    optInFormsCount,
    optInQuestionsMetrics,
    renderData,
    errors,
  } = useViewState()[0];

  handlePageErrors('Dashboard', errors);

  return (
    <main
      className="ContentContainer ListPage"
      data-testid="EngagementDashboardContainer"
    >
      <h1>Dashboard</h1>

      <section className={classes.TwoCardGrid}>
        {renderData?.map((data, idx) => {
          const key = `grid-${idx}`;
          return (
            <BaseCard key={key}>
              <Metrics data={data} />
            </BaseCard>
          );
        })}
      </section>

      <div className={classes.Section}>
        <div className={classes.SectionHeader}>
          <h2>{METRICS_EMPTY_TEXTS['optInQuestion'].title}</h2>

          <Chip count={`${optInFormsCount} Participants`} />
        </div>

        <section className={classes.TwoCardGrid}>
          {!optInFormsCount || optInQuestionsMetrics?.length === 0
            ? (
              <BaseCard>
                <EmptyCard {...METRICS_EMPTY_TEXTS['optInQuestion']} />
              </BaseCard>
            )
            : (
              optInQuestionsMetrics
                ?.map((q) => {
                  // ORB-741: Mysterious 'One Last Question'
                  if (q.stepName === 'fallback') {
                    q.props.title = 'Are you open to being matched twice?';
                  }
                  return q;
                })
                .map((q) => (
                  <OptionsCard
                    className={classes.ExtraPadding}
                    flag={FeatureFlag.FLAGS.dashboardAnalytics}
                    title={q.props.title}
                    questionType={q.question}
                    values={q.props.options}
                    key={q.id}
                  />
                ))
            )
          }
        </section>
      </div>

      {feedbackQuestionsMetrics.length > 0 && (
        <div className={classes.Section}>
          <div className={classes.SectionHeader}>
            <h2>{METRICS_EMPTY_TEXTS['feedbackQuestion'].title}</h2>

            <Chip count={`${numFeedbackForms} Responses`} />
          </div>

          <section className={classes.TwoCardGrid}>
            {!numFeedbackForms || feedbackQuestionsMetrics?.length === 0
              ? (
                <BaseCard>
                  <EmptyCard {...METRICS_EMPTY_TEXTS['feedbackQuestion']} />
                </BaseCard>
              )
              : (
                feedbackQuestionsMetrics
                  ?.filter((q) => !q.stepName.includes('no-chat') && !q.isDisabled && q.stepName !== 'internal_usecase')
                  .map((q) => (
                    <OptionsCard
                      className={classes.ExtraPadding}
                      flag={FeatureFlag.FLAGS.dashboardAnalytics}
                      title={q.props.title}
                      questionType={q.question}
                      values={q.props.options}
                      key={q.id}
                    />
                  ))
              )}
          </section>
        </div>
      )}
    </main>
  );
}
EngagementDashboard.displayName = 'Engagement Dashboard';

const handle = {
  sidebar: true,
  subheader: true,
  title: 'Engagement Dashboard',
};

export {
  EngagementDashboard as Component,
  fetchDashboardAnalytics as loader,
  handle,
};
