export class UIError extends AggregateError {
  code;
  name = this.constructor.name;

  constructor(
    errors: Parameters<AggregateErrorConstructor>[0],
    message: Parameters<AggregateErrorConstructor>[1],
    options: Parameters<AggregateErrorConstructor>[2] & { code: number },
  ) {
    super(errors, message, options);

    this.code = options.code;
  }
}
