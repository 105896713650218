import { clsx } from 'clsx';

import MetricCardHeader from '…/app/deprecated/components/engagement/Dashboard/metrics/MetricCardHeader/MetricCardHeader.tsx';

import classes from './SplitOut.module.css';


const calculateOptInRate = (totalOptins: number, audienceMembersCount: number | undefined) => {
  if (!audienceMembersCount) return null;

  let optInRate = (totalOptins / audienceMembersCount) * 100;
  optInRate = Math.round((optInRate + Number.EPSILON) * 10) / 10;

  return optInRate;
};

export const SplitOut = ({
  metrics: {
    audienceMembersCount,
    optIns,
    repeatMembersPercentage,
  } = {
    audienceMembersCount: 0,
    optIns: new Array(),
    repeatMembersPercentage: 0,
  },
}: {
  metrics: {
    audienceMembersCount?: number;
    optIns: Array<{ label: string, value: number }>;
    repeatMembersPercentage: number;
  }
}) => {
  const totalOptins = optIns.reduce((prevValue, currentValue) => prevValue + currentValue.value, 0);

  const optInRate = calculateOptInRate(totalOptins, audienceMembersCount);

  const repeatPercentage = Math.round((repeatMembersPercentage + Number.EPSILON) * 10) / 10 || 0;

  return (
    <div className={classes.Content}>
      <MetricCardHeader
        description="Repeat members are members who have opted-in to more than 1
          engagement. First-time members are members whose first opt-in
          was for this engagement."
        title="Opt-In Rate"
      />

      <h1>
        {optInRate ? `${optInRate}%` : 'Opt-in % not available'}
      </h1>

      {optInRate && (
        <p>
          {`${totalOptins} opt-ins out of ${audienceMembersCount} members in audience`}
        </p>
      )}

      {optIns && (
        <div className={classes.MeterContainer}>
          <div className={classes.MeterBar}>
            <div
              className={classes.MeterLeft}
              hidden={optIns[0].value === 0}
              style={{ width: `${repeatPercentage}%` }}
            >
              <span className={clsx(classes.Chip, classes.ChipPrimary)}>
                {optIns[0].value}
              </span>
            </div>

            <div
              className={classes.MeterRight}
              hidden={optIns[1].value === 0}
            >
              <span className={classes.Chip}>
                {optIns[1].value}
              </span>
            </div>
          </div>

          <div className={classes.MeterLabels}>
            <div
              className={classes.MeterLeft}
              hidden={optIns[0].value === 0}
              style={{ width: `${repeatPercentage}%` }}
            >
              <p>
                {`${optIns[0].label}`}
              </p>
            </div>

            <div
              className={classes.MeterRight}
              hidden={optIns[1].value === 0}
            >
              <p>
                {`${optIns[1].label}`}
              </p>
            </div>
          </div>
        </div>
      )}

      <div className={classes.BottomParagraph}
      >
        <h4 className={classes.Stat}>
          {`${repeatPercentage}%`}
        </h4>

        <p>
          &nbsp;of opt-ins are repeat members
        </p>
      </div>
    </div>
  );
};

SplitOut.displayName = 'SplitOut';
