import Button from 'form5/react/Button';
import Field, { type FieldProps } from 'form5/react/Field';
import Form from 'form5/react/Form';
import {
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  type ShiftBy,
  getNow,
  shiftRelativeToDates,
  shiftScheduleDate,
} from '@orbiitai/platform-utils/dates';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import {
  formatDate,
  getDateTimeObject,
} from '…/app/common/dateUtils.mts';

import { STATUSES } from '../constants.mts';

import type {
  Engagement, EngagementSchedule, EngagementsDict,
} from '../Engagement.d.ts';

import { cloneEngagement } from './gql/cloneEngagement.op.mts';


export function CloneEngagementForm(
  {
    engagement: {
      name,
      optInClosesAt,
      optInOpensAt,
      timezoneIso,
    },
    engagementId,
    setEngagements,
    setOpen,
    workspaceId,
  }: {
    // Only non-draft engagements may be cloned, so all the data we need will be there
    engagement: NonNullable<Engagement> & EngagementSchedule,
    engagementId: string,
    setEngagements: Dispatch<SetStateAction<EngagementsDict>>,
    setOpen: Dispatch<SetStateAction<boolean>>,
    workspaceId: string,
  },
) {
  const [shift, setShift] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  const now = getNow();
  const dateShiftBy: ShiftBy = {
    amount: shift,
    unit: 'weeks',
  };
  const optInClosesAtDT = getDateTimeObject(optInClosesAt!, { zone: timezoneIso });
  const optInOpensAtDT = getDateTimeObject(optInOpensAt!, { zone: timezoneIso });
  const shiftedOptInOpens = shiftScheduleDate(
    optInOpensAt!,
    timezoneIso,
    dateShiftBy,
    now,
  );
  const shiftedOptInCloses = shiftRelativeToDates(
    optInClosesAtDT,
    optInOpensAtDT,
    shiftedOptInOpens,
  );

  const onShiftChange: FieldProps['onChange'] = ({ value }) => {
    setShift(+value);
  };

  return (
    <Form
      name="clone-engagement"
      onSubmit={(_, { shiftBy, ...overrides }) => {
        setIsSaving(true);
        return cloneEngagement({
          engagementId,
          overrides,
          shiftBy: {
            amount: shift,
            unit: 'weeks',
          },
          workspaceId,
        })
          .then((clone: Engagement) => {
            setEngagements((prev) => prependEngagement(prev, clone));
            setOpen(false);
            navigate(`./${clone.id}`);
          })
          .catch((err: AggregateError | Error) => toast.error(err?.errors?.[0].message || err.message))
          .finally(() => setIsSaving(false));
      }}
    >
      <h2>Cloning “{name}”</h2>

      <Field
        arrangement={Field.ARRANGEMENTS.INLINE}
        defaultValue={`${name} (copy)`}
        label="Name"
        name="name"
        required
        type="text"
      />

      <h3>Select a start date</h3>

      <Button.Group style={{ whiteSpace: 'nowrap' }}>
        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          checked={shift === 0}
          id="shiftBy.0"
          label="Original"
          name="shiftBy"
          onChange={onShiftChange}
          type="radio"
          value={0}
          variant={Field.VARIANTS.CTA}
        />

        {[1, 2, 4].map((s) => (
          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            checked={shift === s}
            id={`shiftBy.${s}`}
            key={s}
            label={`In ${s} Week${s > 1 ? 's' : ''}`}
            name="shiftBy"
            onChange={onShiftChange}
            type="radio"
            value={s}
            variant={Field.VARIANTS.CTA}
          />
        ))}
      </Button.Group>

      {/* eslint-disable-next-line react/jsx-newline */}
      <p>Registration window: {formatDate(shiftedOptInOpens)} - {formatDate(shiftedOptInCloses)}</p>

      <p className="note">(The new engagement’s other dates will be shifted by the same amount)</p>

      <SubmitButton
        appearance={Button.APPEARANCES.AFFIRMING}
        disabled={isSaving}
        isSubmitting={isSaving}
        type="submit"
      >Duplicate
      </SubmitButton>
    </Form>
  );
}
CloneEngagementForm.displayName = 'CloneEngagementForm';

const cutoffDate = new Date('2022-01-01');

export function engagementCanBeCloned({
  createdAt,
  product,
  status,
}: Engagement) {
  if (new Date(createdAt) <= cutoffDate) return false;
  if (product === PRODUCT_TYPES.TOP_N) return false;
  if (status === STATUSES.DRAFT) return false;

  return true;
}

function prependEngagement(prev: EngagementsDict, clone: Engagement) {
  const updated: EngagementsDict = new Map();
  updated.set(clone.id, clone);
  for (const [id, eng] of prev) updated.set(id, eng);
  return updated;
}
