import type {
  Dispatch,
  DragEvent,
  SetStateAction,
} from 'react';


export type DragInfo = Partial<{
  from: number,
  to: number,
}>
export type ElementDragEvent = DragEvent<HTMLElement>;

/**
 * Determine the index of an element relative to its siblings.
 * @param target When calculating `from`, use `event.target`; when calculating `to`, use `event.currentTarget`.
 */
export function getDragItemIndex(target: HTMLElement) {
  if (!target?.parentNode) return -1;

  const idx = Array.prototype.indexOf.call(
    target.parentNode.children,
    target,
  );

  return idx;
}

export function onDragEnter(event: ElementDragEvent) {
  event.preventDefault(); // ! MUST be called for `drop` event to fire
}

export function onDragStart(
  event: ElementDragEvent,
  setDragging: Dispatch<SetStateAction<DragInfo>>,
) {
  // ! Do NOT call `event.preventDefault()`: it prevents all subsequent drag events
  if (!event.dataTransfer) return;

  event.dataTransfer.effectAllowed = 'move';

  setDragging((prev) => ({
    ...prev,
    from: getDragItemIndex(event.target),
  }));
}

export function onDragOver(
  event: ElementDragEvent,
  dragging: DragInfo & { from: number },
  setDragging: Dispatch<SetStateAction<DragInfo>>,
) {
  if (event.dataTransfer) event.dataTransfer.dropEffect = 'move';

  setNewSequence(event, dragging, setDragging);
}

export function setNewSequence(
  event: ElementDragEvent,
  dragging: DragInfo & { from: number },
  setDragging: Dispatch<SetStateAction<DragInfo>>,
) {
  if (!event.currentTarget) return;

  const to = getDragItemIndex(event.currentTarget);
  const { from } = dragging;

  if (to === from) return; // Nothing to do. Abort before enabling the `drop` event.

  event.preventDefault(); // ! MUST be called for `drop` event to fire

  if (to === dragging.to) return;

  setDragging((prev) => ({
    ...prev,
    to,
  }));
}
