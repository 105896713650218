/* eslint-disable sort-keys */

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

export const SECTIONS = {
  DASHBOARD: 'dashboard',
  DASHBOARD_EMAILS: 'dashboard-emails',
  FEEDBACK: 'feedback',
  MATCHES: 'matches',
  OPTINS: 'optins',
  SETUP: 'setup',
  SETTINGS: 'settings',
} as const;
export type Section = typeof SECTIONS[keyof typeof SECTIONS];
export const SETUP_SECTIONS = {
  CONVO_GUIDE: 'conversation-guide',
  FEEDBACK: 'feedback',
  INVITATION: 'invite',
  REMINDER: 'chaser',
  SCHEDULE: 'schedule',
  SIGNUP: 'signup',
  TEMPLATE: 'template',
} as const;
export type SetupSection = typeof SETUP_SECTIONS[keyof typeof SETUP_SECTIONS];
export type ParticipantForm = typeof SETUP_SECTIONS['FEEDBACK'] | typeof SETUP_SECTIONS['SIGNUP'];

export const STATUSES = {
  DRAFT: 'DRAFT',
  REVIEW: 'REVIEW',
  SCHEDULED: 'SCHEDULED',
  OPTINSOPEN: 'OPTINSOPEN',
  MATCHING: 'MATCHING', // Show opt-ins when the form is closed but there are no matches.
  MATCHED: 'MATCHED', // Matches have been confirmed (either automatically confirmed or by a user manually submitting confirmation).
  MEETING: 'MEETING', // Show matches when there is no feedback. If there is feedback show feedback.
  COMPLETED: 'COMPLETED',
} as const;
export type STATUS = typeof STATUSES[keyof typeof STATUSES];

export const STATUSES_ORDERED = [
  STATUSES.DRAFT,
  STATUSES.REVIEW,
  STATUSES.SCHEDULED,
  STATUSES.OPTINSOPEN,
  STATUSES.MATCHING,
  STATUSES.MATCHED,
  STATUSES.MEETING,
  STATUSES.COMPLETED,
];

export const STATUSES_LABEL_DICT = {
  [STATUSES.DRAFT]: 'Draft',
  [STATUSES.REVIEW]: 'Review',
  [STATUSES.SCHEDULED]: 'Scheduled',
  [STATUSES.OPTINSOPEN]: 'Opt-ins Open',
  [STATUSES.MATCHING]: 'Matching',
  [STATUSES.MATCHED]: 'Matched',
  [STATUSES.MEETING]: 'Meeting',
  [STATUSES.COMPLETED]: 'Completed',
} as const;

export const STATUSES_WHEN_EMAIL_DATES_EDITABLE = new Set([
  STATUSES.DRAFT,
  STATUSES.REVIEW,
  STATUSES.SCHEDULED,
]);

export const STATUS_TO_SECTION = {
  DRAFT: SECTIONS.SETUP,
  REVIEW: SECTIONS.SETUP,
  SCHEDULED: SECTIONS.OPTINS,
  OPTINSOPEN: SECTIONS.OPTINS,
  MATCHING: SECTIONS.MATCHES,
  MATCHED: SECTIONS.MATCHES,
  MEETING: SECTIONS.FEEDBACK,
  COMPLETED: SECTIONS.FEEDBACK,
};

export function getEngagementStep(status = STATUSES.DRAFT) {
  return STATUS_TO_SECTION[status];
}

export const PRODUCT_TO_GROUP_SIZE = {
  [PRODUCT_TYPES.GROUP]: {
    max: 9,
    min: 3,
  },
  [PRODUCT_TYPES.TOP_N]: {
    max: 7,
    min: 1,
  },
  [PRODUCT_TYPES.ONE_TO_ONE]: null,
  [PRODUCT_TYPES.MENTORSHIP]: null,
};
