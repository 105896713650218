import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { EngagementTemplate } from '…/app/w/workspace/engagements/Engagement.d.ts';
import type { WorkspaceId } from '../states.d.ts';
import { onError } from '../errors/errors.state.mts';

import GET_ENGAGEMENT_TEMPLATES from './getEngagementTemplates.gql';

type PagedEngagementTemplate = {
  nodes: EngagementTemplate[]
}


export function getEngagementTemplates(workspaceId: WorkspaceId | null = localStorage.getItem('workspaceId')) {
  if (!workspaceId) return Promise.reject('Cannot get engagement templates without `workspaceId`');

  return gqlOperation<{
    workspace: { engagements: PagedEngagementTemplate, engagementTemplates: EngagementTemplate[] }
  }>(GET_ENGAGEMENT_TEMPLATES, { workspaceId })
    .then(({ workspace }) => {
      const recentEngagements = workspace.engagements?.nodes?.map((engagement) => ({
        ...engagement,
        isAiRecommended: true,
        templateTags: ['Section::Recently Closed Engagements', 'Icon::recent'],
      }));
      return [...recentEngagements, ...workspace.engagementTemplates];
    })
    .then((rawTemplates) => {
      const templates = new Map();

      for (const t of rawTemplates) {
        const template: EngagementTemplate & { iconKey?: string } = t;
        let sectionName;

        for (const tag of t.templateTags) {
          const [key, value] = tag.split('::');
          switch (key) {
            case 'Icon':
              template.iconKey = value;
              break;
            case 'Section':
              sectionName = value;
              break;
          }
        }

        if (sectionName) {
          if (!templates.has(sectionName)) templates.set(sectionName, new Set());

          templates.get(sectionName)!.add(template);
        }
      }

      return templates;
    })
    .catch(onError);
}
