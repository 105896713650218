export const mapSortableFieldsToGQLConstant = {
  'company': 'COMPANY',
  'name': 'NAME',
  'role': 'ROLE',
};

export type SortableFields = keyof typeof mapSortableFieldsToGQLConstant;

export const MEMBER_STATUS = {
  BOUNCED: 'bounced',
  REFERRAL: 'referral',
  UNSUBSCRIBED: 'unsubscribed',
} as const;
export const MEMBER_STATUS_DICT = {
  [MEMBER_STATUS.BOUNCED]: 'Bounced',
  [MEMBER_STATUS.UNSUBSCRIBED]: 'Unsubscribed',
  [MEMBER_STATUS.REFERRAL]: 'Referral',
};
export type MemberStatus = typeof MEMBER_STATUS[keyof typeof MEMBER_STATUS];

export type MembersFilters = Partial<{
  STATUS: MemberStatus[],
  CREATED_AT: ISO_8601[],
}>
