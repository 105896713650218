import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';
import { useState } from 'react';
import { toast } from 'sonner';

import { updateMe } from '…/app/state/user/updateMe.op.mts';
import type { User } from '…/app/state/states.d.ts';
import { useStore } from '…/app/state/useStore.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import panelClasses from '../SettingsPanel.module.css';


export function EditMyProfile({
  className,
}: {
  className: string,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  const user = useStore((data) => data.user) as DeepRequired<User>;

  const onSubmit: OnSubmit = (delta, all) => {
    setIsSubmitting(true);

    const promise = updateMe(all).finally(() => setIsSubmitting(false));

    toast.promise(promise, {
      error: 'Updating profile failed',
      loading: 'Updating profile…',
      success: 'Profile updated',
    });

    return promise;
  };

  return (
    <main
      className={panelClasses.SettingsPanel}
      data-testid="Settings:Profile"
    >
      <h1>Profile</h1>

      <Form
        className={clsx('Card', className, panelClasses.Section)}
        name="edit-profile"
        onDirty={setDirty}
        onPristine={setDirty}
        onSubmit={onSubmit}
      >
        <fieldset style={{ flex: 1 }}>
          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={user.profile.firstName}
            fluid
            label="First Name"
            name="firstName"
            type="text"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={user.profile.lastName}
            fluid
            label="Last Name"
            name="lastName"
            type="text"
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            defaultValue={user.emails[0]}
            fluid
            label="Email"
            name="email"
            readOnly
            type="email"
          />
        </fieldset>

        <SubmitButton
          appearance={Button.APPEARANCES.PRIMARY}
          disabled={isSubmitting || !isDirty}
          isSubmitting={isSubmitting}
          type="submit"
        >Save changes
        </SubmitButton>
      </Form>
    </main>
  );
}
EditMyProfile.displayName = 'EditMyProfile';

const handle = {
  title: 'Your Profile',
};

export {
  EditMyProfile as Component,
  handle,
};
