import type { HTTPError } from 'ky';
import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import {
  type Component,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { EngagementSectionRouteParams } from '…/app/route-params.mts';

import { formatDate, getNow } from '…/app/common/dateUtils.mts';
import { downloadEngagementSection } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import type { Engagement } from '../../Engagement.d.ts';
import { useViewState } from '../state.mts';
import { updateEngagement } from '../gql/updateEngagement.op.mts';

import type { EditMatches, MatchEditingState } from './EditMatches2.tsx';
import { fetchMatches } from './fetchMatchData.op.mts';
import { restoreMatches } from './restoreMatches.op.mts';
import { EditingStatus, type EditingStatusProps } from './EditingStatus.tsx';

import classes from './MatchesHeader.module.css';


export function MatchesHeader({
  closesAt,
  dirty,
  editingEnabled,
  formId,
  isSaving,
  onAddGroup,
  onConfirmed,
  onSearch,
  readOnly,
  setState,
  size,
  status,
  total,
}: {
  closesAt: EditingStatusProps['closesAt'],
  dirty: MatchEditingState['isDirty'],
  editingEnabled: Engagement['isEditMatchesEnabled'],
  formId: HTMLFormElement['id'],
  isSaving: MatchEditingState['isSaving'],
  onAddGroup: EditMatches['onAddGroup'],
  onConfirmed: EditMatches['onConfirmed'],
  onSearch: EditMatches['onSearch'],
  readOnly: boolean,
  setState: Component<object, MatchEditingState>['setState'],
  size: Int,
  status: Engagement['status'],
  total: Int,
}) {
  const { engagementId, workspaceId } = useParams() as EngagementSectionRouteParams;
  const { engagement } = useViewState()[0];
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showRevertModal, setShowRevertModal] = useState(false);
  const [isReverting, setReverting] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  function onConfirm() {
    setShowConfirmModal(false);
    setSubmitting(true);

    return updateEngagement({
      engagement: {
        editMatchesConfirmedAt: getNow()
          .toUTC()
          .set({
            millisecond: 0,
            second: 0,
          })
          .toISO({ suppressMilliseconds: true })!,
      },
      engagementId: engagementId,
      workspaceId,
    })
      .then((updated) => {
        toast.success('Matches have been confirmed (editing is now locked)');

        onConfirmed(updated.status);
      })
      .finally(() => setSubmitting(false));
  }

  async function onRevert() {
    setShowRevertModal(false);

    await restoreMatches(engagementId)
      .catch(async (err: HTTPError) => {
        const message = (await err.response.json())?.data?.detail;
        const type = err.response.status === 409 ? 'success' : 'error';

        toast[type](message ?? 'Something went wrong; we’re looking into it.');

        throw err;
      })
      .finally(() => setReverting(false));

    const { matches, unmatched } = await fetchMatches(
      engagementId,
      workspaceId,
    );

    setState({
      available: unmatched,
      groups: matches,
    });

    toast.success('Matches have been restored to original');
  }

  return (
    <>
      <header className={clsx('SplitHeader StackableSplit', classes.Header)}>
        <div className="SplitHeader">
          <h1>
            {readOnly ? 'Matches' : 'Match Editor'}

            {' '}

            <Chip count={total} />

            {' '}

            <EditingStatus
              closesAt={closesAt}
              editingEnabled={editingEnabled}
              size={size}
              status={status}
            />
          </h1>

          {!readOnly && dirty && <p className="warning">You have unsaved changes</p>}
        </div>

        <div className={classes.FlexEnd}>
          <Form
            name="search"
            onReset={() => onSearch({ query: '' })}
            onSubmit={onSearch}
            role="search"
            style={{ flex: 1 }}
          >
            <Field
              arrangement={Field.ARRANGEMENTS.STAND_ALONE}
              name="query"
              placeholder="Search"
              type="search"
            />
          </Form>

          <fieldset className="SplitHeader" disabled={readOnly}>
            {!readOnly && (
              <Button.Group>
                <Button
                  appearance={Button.APPEARANCES.BASIC}
                  onClick={onAddGroup}
                >New Match
                </Button>

                <SubmitButton
                  disabled={!dirty}
                  form={formId}
                  isSubmitting={isSaving}
                  type="submit"
                >Save Changes
                </SubmitButton>

                <Popover
                  anchor={(props) => (
                    <SubmitButton
                      {...props}
                      appearance={Button.APPEARANCES.AFFIRMING}
                      disabled={dirty}
                      isSubmitting={isSubmitting}
                      onClick={() => setShowConfirmModal(true)}
                    >Confirm Matches
                    </SubmitButton>
                  )}
                  position={Popover.POSITIONS.BOTTOM_RIGHT}
                  type={Popover.TYPES.TOOLTIP}
                >
                  {dirty && 'Matches cannot be confirmed with unsaved chages. Discard or save before confirming.'}
                </Popover>
              </Button.Group>
            )}
          </fieldset>

          <Popover
            anchor={({
              className,
              onClick,
              ...props
            }) => (
              <CTA
                {...props}
                aria-label="more actions"
                className={clsx(className, 'ContextMenuIcon')}
                to={onClick}
                variant={CTA.VARIANTS.GLYPH}
              />
            )}
            position={Popover.POSITIONS.BOTTOM_RIGHT}
            type={Popover.TYPES.MENU}
          >
            <CTA
              disabled={size === 0}
              guise={CTA.GUISES.LINK}
              menuitem=""
              to={() => downloadEngagementSection(workspaceId, engagement, 'matches')}
            >Download CSV
            </CTA>

            {!readOnly && (
              <CTA
                appearance={Button.APPEARANCES.DANGER}
                menuitem=""
                to={() => setShowRevertModal(true)}
                variant={CTA.VARIANTS.GLYPH}
              >Reset to original
              </CTA>
            )}
          </Popover>
        </div>
      </header>

      <Dialog
        backdropped
        open={showConfirmModal}
        setOpen={setShowConfirmModal}
      >
        <h1>Confirm matches</h1>

        <p>
          This will lock all matches as they are, and you will no longer be able to edit them.
          The matches will receive their introduction email and invite on {formatDate(engagement.matchIntroAt)}.
        </p>

        <SubmitButton
          appearance={Button.APPEARANCES.AFFIRMING}
          isSubmitting={isSubmitting}
          onClick={onConfirm}
        >
          Confirm
        </SubmitButton>
      </Dialog>

      <Dialog
        backdropped
        open={showRevertModal}
        setOpen={setShowRevertModal}
      >
        <h1>Reset matches</h1>

        <p>
          This will reset all matches to their original Orbiit suggested state.
          All changes you have made will be reversed and lost.
        </p>

        <SubmitButton
          appearance={Button.APPEARANCES.DANGER}
          isSubmitting={isReverting}
          onClick={onRevert}
        >
          Reset
        </SubmitButton>
      </Dialog>
    </>
  );
}
MatchesHeader.displayName = 'MatchesHeader';
