import { ReactNode } from 'react';

import type { IFormAnswer } from './Answer.d.ts';

import classes from './Answer.module.css';

export function FormAnswer({
  allAnswerQuestions,
  freeTextQuestionsMap,
  stepName,
  selectedOptions,
  text,
}: IFormAnswer & { allAnswerQuestions: any, freeTextQuestionsMap: any, }) {
  if (text) {
    return (
      <Answer
        answers={[text]}
        key={stepName}
        title={freeTextQuestionsMap[stepName].title}
      />
    );
  }

  if (!selectedOptions || selectedOptions?.length === 0) return null;

  if (!(selectedOptions[0] in allAnswerQuestions)) return null;

  const questionData: any = allAnswerQuestions[selectedOptions[0]];

  return (
    <Answer
      answers={selectedOptions?.map((id: string) => allAnswerQuestions[id].answer)}
      key={stepName}
      title={questionData.title}
    />
  );
}

FormAnswer.displayName = 'FormAnswer';

export function Answer({
  answers,
  children,
  title,
}: {
  answers?: string[],
  children?: ReactNode,
  title: string,
}) {
  return (
    <div className={classes.AnswerCard}>
      <h4 className={classes.Title}>{title}</h4>

      {answers?.length && (
        <p>
          {answers.map((answer: string, ix: number) => (
            <span key={`${title}-${ix}`}>
              {answer}

              {ix < answers.length - 1 && <br />}
            </span>
          ))}
        </p>
      )}

      {children}
    </div>
  );
}

Answer.displayName = 'Answer';
