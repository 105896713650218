mutation UpsertMembers(
  $workspaceId: ID!
  $members: [MemberInput]!
) {
  members: createAndUpdateMembers(
    workspaceId: $workspaceId
    members: $members
  ) {
    id # the mutation will provide only the 'id' field back due to perf issues
  }
}
