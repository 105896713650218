import { formatDate } from '…/app/common/dateUtils.mts';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import type { OptInForm } from './optin-forms.d.ts';

import classes from './StatusIcon.module.css';

export function StatusIcon({
  optInForm: {
    createdAt,
    numPreviousOptinForms,
    referral,
  },
}: {
  optInForm: OptInForm,
}) {
  let status = {
    iconName: null,
    id: null,
    label: null,
  } as {
    iconName: string | null,
    id: string | null,
    label: string | null,
  };

  if (numPreviousOptinForms === null) return null;

  if (referral && createdAt) {
    if (referral?.referredByMember?.fields?.fullName) {
      status = {
        iconName: 'user referral',
        id: 'referral',
        label: `Referred by ${referral.referredByMember.fields.fullName} on ${formatDate(createdAt)}`,
      };
    } else {
      status = {
        iconName: 'user referral',
        id: 'referral',
        label: `First seen on ${formatDate(createdAt)}`,
      };
    }
  } else {
    if (numPreviousOptinForms < 1) {
      status = {
        iconName: 'user new',
        id: 'new',
        label: 'First Time User',
      };
    }
    if (numPreviousOptinForms >= 1) {
      status = {
        iconName: 'user repeat',
        id: 'repeat',
        label: 'Repeat User',
      };
    }
    if (numPreviousOptinForms >= 5) {
      status = {
        iconName: 'user super',
        id: 'super',
        label: 'Power User (5+)',
      };
    }
  }

  if (!status?.iconName) return null;

  return (
    <Popover
      anchor={() => (
        <span className={classes.StatusIcon} status={status.id}>
          <IIcon label={status.label} name={status.iconName} />
        </span>
      )}
      minimal
      position={Popover.POSITIONS.TOP_LEFT}
      type={Popover.TYPES.TOOLTIP}
    >{status.label}
    </Popover>
  );
}

StatusIcon.displayName = 'StatusIcon';
