import UseCSV from '@usecsv/react';
import { pick } from 'lodash-es';
import { useParams } from 'react-router-dom';

import { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import type { Member } from '…/app/w/workspace/audiences/audience/members/Member.d.ts';

export type MemberCSVUpdate = Pick<Member, 'email' | 'fields'>;

interface IResponseData {
  uploadId: number,
  fileName: string,
  matchedColumnsMap?: Record<string, string | Int>,
  uploadedFileHeaders?: string[],
  rows: Array<Record<string, string | number>>,
  user?: Record<string, string | number>,
  metadata?: Record<string, string | number>,
  importedRowsCount: Int,
  batch?: {
    index: Int,
    count: Int,
    totalRows: Int,
  },
}

export const USECSV_KEY = process.env.USECSV_KEY; // eslint-disable-line no-undef

const columns = [
  {
    description: 'Must be a valid email address',
    displayName: 'Email Address',
    example: 'jane@example.com',
    mustBeMatched: true,
    name: 'email',
    validationFormat: { type: 'email' },
    valueCannotBeBlank: true,
  },
  {
    displayName: 'First Name',
    example: 'Jane',
    mustBeMatched: false,
    name: 'firstName',
    validationFormat: { type: 'string' },
    valueCannotBeBlank: false,
  },
  {
    displayName: 'Last Name',
    example: 'Smith',
    mustBeMatched: false,
    name: 'lastName',
    validationFormat: { type: 'string' },
    valueCannotBeBlank: false,
  },
  {
    displayName: 'Role / Job-title',
    example: 'Community Manager',
    mustBeMatched: false,
    name: 'role',
    validationFormat: { type: 'string' },
    valueCannotBeBlank: false,
  },
  {
    displayName: 'Company Name',
    example: 'Orbiit',
    mustBeMatched: false,
    name: 'company',
    validationFormat: { type: 'string' },
    valueCannotBeBlank: false,
  },
  {
    displayName: 'Social URL',
    example: 'https://linkedin.com/in/jane-smith',
    mustBeMatched: false,
    name: 'url',
    validationFormat: { type: 'string' },
    valueCannotBeBlank: false,
  },
];

function formatToGraphQL(member: any): MemberCSVUpdate {
  const { email, ...fields } = pick(
    member,
    ['email', 'firstName', 'lastName', 'role', 'company', 'url'],
  );

  return {
    email,
    fields,
  };
}

export function CSVUpload({
  onData,
}: {
  onData(results: { data: any[] }): Promise<any>,
}) {
  const user = useStore((d) => d.user);
  const { workspaceId } = useParams() as WalledRouteParams;
  return (
    <UseCSV
      dynamicColumns={columns}
      importerKey={USECSV_KEY}
      metadata={{ workspaceId }}
      onData={(result: IResponseData) => {
        const members = result.rows.map(formatToGraphQL);
        return onData({ data: members });
      }}
      render={(openFn: () => void) => (
        <CTA
          guise={CTA.GUISES.LINK}
          menuitem=""
          to={openFn}
        >Add new members via CSV upload
        </CTA>
      )}
      user={{ userId: user.id }}
    />
  );
}
CSVUpload.displayName = 'CSVUpload';
