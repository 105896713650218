import { clsx } from 'clsx';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';

import { ENGAGEMENTS_ROUTE_TEMPLATE, ENGAGEMENT_ROUTE_TEMPLATE } from '…/app/route-params.mts';
import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { ENGAGEMENT_STATUS_STEP, PRODUCT_TO_TYPE_DICT } from '…/app/deprecated/constants.mts';
import { formatDate } from '…/app/common/dateUtils.mts';

import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';

import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { IconButton } from '…/app/common/IconButton/IconButton.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

import { STATUSES_LABEL_DICT } from '…/app/w/workspace/engagements/constants.mts';

import type{ EngagementSchedule } from '../Engagement.d.ts';
import type { EngagementViewData } from './EngagementView.d.ts';
import { engagementParams } from '../engagementParams.mts';
import { getFormAppLink } from './participantFormUtils.ts';

import { UpdateEngagementForm } from './UpdateEngagementForm.tsx';

import classes from './EngagementHeader.module.css';


const getConversationDatesLabel = (conversationDates?: string[], timezoneIso?: string): string | null => {
  if (!conversationDates || conversationDates?.length === 0 || !timezoneIso) return null;
  if (conversationDates.length === 1) return `on ${formatDate(conversationDates[0], timezoneIso)}`;
  return `${formatDate(conversationDates[0], timezoneIso)} - ${formatDate(conversationDates.slice(-1)[0], timezoneIso)}`;
};

export function EngagementHeader({
  className,
  engagement,
  setPageProps,
}: EngagementViewData<EngagementSchedule>) {
  const params = engagementParams();

  const { workspaceId } = params as WalledRouteParams;
  const user = useStore((data) => data.user);
  const workspace = useStore((data) => data.workspaces[workspaceId]);

  const {
    conversationDates,
    engagementStats,
    groupSize,
    name,
    optInFormsCount,
    optInClosesAt,
    optInOpensAt,
    product,
    status,
    timezoneIso,
    title,
  } = engagement;

  const [isEditing, setEditing] = useState(false);

  const matchingLabel = (() => {
    let ret = PRODUCT_TO_TYPE_DICT[product];
    if (groupSize > 2) ret += ` (${groupSize})`;
    return ret;
  })();
  const registrationLabel = (optInClosesAt && optInOpensAt && timezoneIso)
    ? `${formatDate(optInOpensAt, timezoneIso)} – ${formatDate(optInClosesAt, timezoneIso)}`
    : (
      <CTA to={generatePath('/w/:workspaceId/engagements/:engagementId/:section/:subSection', {
        ...params,
        subSection: 'schedule',
      })}
      >Select the dates for your engagement
      </CTA>
    );
  const conversationDatesLabel = getConversationDatesLabel(conversationDates, timezoneIso);
  const topicLabel = title ?? (
    <CTA to={generatePath('/w/:workspaceId/engagements/:engagementId/:section/:subSection', {
      ...params,
      subSection: 'template',
    })}
    >
      Plan your communication & matching criteria
    </CTA>
  );

  const backLink = params.section
    ? {
      title: 'Back to engagements list',
      tmpl: ENGAGEMENTS_ROUTE_TEMPLATE,
    }
    : {
      title: 'Back to engagement',
      tmpl: ENGAGEMENT_ROUTE_TEMPLATE,
    };

  const showFeedbackNumbers = ENGAGEMENT_STATUS_STEP[status] > 2;

  const showOptInFormLink = (ENGAGEMENT_STATUS_STEP[status] < 2 && status !== 'MATCHING' && title && optInOpensAt) ? true : false;
  const canUseRecurringLink = !!useFeatureFlag([FLAGS.recurringEngagementsLink]);
  const optInFormURL = getFormAppLink({
    engagement,
    formType: 'signup',
    isRecurringLinkEnabled: canUseRecurringLink,
    user,
    workspace,
  });

  return (
    <>
      <header
        className={clsx(classes.EngagementHeader, className)}
        data-testid="engagement-header"
      >
        <div className={classes.Name}>
          <CTA
            className={classes.NavBack}
            title={backLink.title}
            to={generatePath(backLink.tmpl, params)}
          >←
          </CTA>

          <Popover
            anchor={(props) => (<Chip status={status} {...props} />)}
            minimal
            position={Popover.POSITIONS.BOTTOM_MIDDLE}
            type={Popover.TYPES.TOOLTIP}
          >
            {STATUSES_LABEL_DICT[status]}
          </Popover>

          <h1>{name}</h1>

          <IconButton
            className={classes.EditButtonMuted}
            onClick={() => setEditing(!isEditing)}
            title="Edit engagement"
          >
            <IIcon name="pencil" />
          </IconButton>
        </div>

        <table className={classes.Numbers}>
          <thead>
            <tr>
              {!showFeedbackNumbers && showOptInFormLink && optInFormURL && <th>Opt-In Link</th>}

              <th>Type</th>

              <th>Opt-Ins</th>

              {showFeedbackNumbers && (
                <>
                  <th>Feedback</th>

                  <th>NPS</th>
                </>
              )}
            </tr>
          </thead>

          <tbody>
            <tr>
              {!showFeedbackNumbers && showOptInFormLink && optInFormURL && (
                <td>
                  <CTA
                    guise={CTA.GUISES.LINK}
                    rel="noreferrer"
                    target="_blank"
                    to={optInFormURL}
                  >{optInFormURL?.includes('series-') ? 'Recurring' : 'Unique'}
                  </CTA>
                </td>
              )}

              <td>
                <Chip>{matchingLabel}</Chip>
              </td>

              <td>{optInFormsCount ?? '-'}</td>

              {showFeedbackNumbers && (
                <>
                  <td>{engagementStats?.numFeedbackForms ?? '-'}</td>

                  <td>{engagementStats?.nps ? Math.round(engagementStats.nps) : '-'}</td>
                </>
              )}
            </tr>
          </tbody>
        </table>

        <hr className={classes.Divider} />

        <ul className={classes.Gist}>
          <li>
            <strong>Topic:</strong>

            {' '}

            {topicLabel}
          </li>

          <li>
            <strong>Registration:</strong>

            {' '}

            {registrationLabel}
          </li>

          <li>
            <strong>Conversations:</strong>

            {' '}

            {conversationDatesLabel}
          </li>
        </ul>
      </header>

      <Dialog
        backdropped
        open={!!isEditing}
        setOpen={setEditing}
      >
        <UpdateEngagementForm
          engagementId={params.engagementId}
          groupSize={groupSize}
          name={name}
          onClose={() => setEditing(false)}
          product={product}
          setPageProps={setPageProps}
          status={status}
          workspaceId={params.workspaceId}
        />
      </Dialog>
    </>
  );
}
EngagementHeader.displayName = 'EngagementHeader';
