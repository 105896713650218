import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type {
  Workspace,
  WorkspaceId,
} from '../states.d.ts';
import type { OrbiitAPI } from '../api.d.ts';
import { onError } from '../errors/errors.state.mts';

import { GET_WORKSPACE } from './getWorkspace.gql.mjs';


export function getWorkspace(workspaceId: WorkspaceId) {
  return gqlOperation<{
    workspace: OrbiitAPI.Workspace,
  }>(GET_WORKSPACE, { workspaceId })
    .then((data) => gotWorkspace(data.workspace))
    .catch(onError);
}

export function gotWorkspace(w: OrbiitAPI.Workspace): Workspace {
  // FIXME: clean this up in ORB-1264
  // This creates multiple copies of data, which is not great.
  const archivedAudiences = new Map(); // Needed for old engagements
  const builtinAudiences = new Map(); // Needed for AudiencesList
  const customAudiences = new Map(); // Needed by AnalyticsDashboard
  const audiences = new Map();

  for (const { 1: a } of w.audiences.nodes.entries()) {
    audiences.set(a.id, a);

    if (a.isArchived) archivedAudiences.set(a.id, a);
    else {
      if (a.isBuiltin) builtinAudiences.set(a.id, a);
      else customAudiences.set(a.id, a);
    }
  }

  return {
    ...w,
    archivedAudiences,
    audiences,
    builtinAudiences,
    customAudiences,
    features: new Set(w.features.map(({ name }) => name)),
  };
}
