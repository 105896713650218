.Content {
  padding: var(--grid-gutter);
  text-align: center;
}

.Content h1 {
  margin-bottom: var(--grid-gutter);
}

.MeterContainer {
  padding: var(--grid-gutter) calc(2 * var(--grid-gutter));
}

.MeterBar {
  background-color: var(--brand-subtle-light);
  border-radius: 1em;
  display: flex;
  margin-bottom: 0.75rem;
  padding: 0;
}

.MeterLabels {
  display: flex;
  flex-direction: row;
  padding: 0;
}

.MeterLabels p {
  font-weight: 700;
  justify-content: center;
}

.MeterLeft {
  display: block;
  min-width: 10%;
}

.MeterRight {
  display: block;
  flex: 1;
}

.Chip {
  background-color: transparent;
  border-radius: 1em;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 0.5rem 1rem;
}

.ChipPrimary {
  background-color: var(--brand-accent-primary);
  color: white;
}

.BottomParagraph {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
  justify-content: center;
  margin-top: 1.5rem;
}

.Stat {
  font-weight: 700;
  margin: 0;
}
