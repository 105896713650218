._SplitContent6040_1knge_1 {
  display: grid;
  gap: 1rem;
  grid-template-columns: 60% 40%;
  margin-top: 1rem;
}

._Row_1knge_8 {
  align-items: center;
  border-bottom: 1px solid var(--brand-subtle-light);
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.25rem 0.5rem;
}

._RowDisabled_1knge_18 {
  opacity: 0.375;
}

._Row_1knge_8:last-child {
  border-bottom: none;
}

