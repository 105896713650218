._Header_1o7yt_1 {
  background: white;
}

._HeaderContent_1o7yt_5 {
  padding: 2rem;
}

._Tabs_1o7yt_9 {
  align-items: center;
  box-shadow: 0 2px 4px -2px rgb(0 0 0 / 16%);
  display: flex;
  flex-direction: row;
}

._Tab_1o7yt_9 {
  border-bottom: 3px solid white;
  color: #BBB; /** FIXME: color */
  cursor: pointer;
  flex: 1 1 auto;
  padding: 1rem;
  text-align: center;
}

._Tab_1o7yt_9:hover {
  border-bottom: 3px solid #357FDA;
}

._TabSelected_1o7yt_29 {
  border-bottom: 3px solid #357FDA;
  color: black;
  font-weight: 700;
}

._Content_1o7yt_35 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  padding: 1rem;
  padding-top: 0;
}

._Title_1o7yt_44 {
  margin: 0.75rem 0 0.5rem;
  padding: 0;
}

._Lists_1o7yt_49 {
  display: flex;
  flex-direction: column;
  gap: 0.125rem;
  justify-content: flex-start;
}

._ListsScrollable_1o7yt_56 {
  max-height: 70vh;
  overflow-y: scroll;
  padding-bottom: 4rem;
}

._ListsScrollable_1o7yt_56::after {
  background: linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(255 255 255 / 100%) 100%);
  bottom: 0;
  content: "";
  height: 2rem;
  left: 0;
  position: absolute;
  right: 0;
}

._ListItem_1o7yt_72 {
  align-items: center;
  background: white;
  border: 1px solid var(--neutral-light);
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.25rem;
  padding: 0.675rem 1rem;
}

._SearchContainer_1o7yt_84 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

._Highlight_1o7yt_92 {
  background: var(--brand-subtle-light);
  color: var(--brand-accent-primary);
  font-weight: 500;
  padding: 2px 6px;
}
