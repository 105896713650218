import _memoize from 'lodash-es/memoize.js';

import type { TableHeading } from '…/app/common/Table/Table.tsx';

import type { Audience } from './Audience.d.ts';


export function extractCustomFields(workspaceCustomFields: Audience['workspaceCustomFields'] = new Array()) {
  const customFieldLabels = new Map<string, TableHeading>();
  const customFieldValues = new Map<string, string>();

  for (
    const {
      id,
      name,
      options,
    } of workspaceCustomFields
  ) {
    if (!options) continue; // skip custom heads that have nothing to select

    customFieldLabels.set(id, {
      label: name,
      wrap: true,
    });
    for (const opt of options) {
      customFieldValues.set(opt.id, opt.text);
    }
  }

  return {
    customFieldLabels,
    customFieldValues,
  };
}

export const memoizedCustomFieldsExtractor = _memoize(extractCustomFields);
