/**
 * Allows you to split an array of items into different chunks of a given (max)
 * length.
 */
export function splitArrayIntoChunks<Input extends any[]>(input: Input, chunkSize = 2000): Input[] {
  if (chunkSize < 1) throw RangeError('Chunks cannot be smaller than 1');
  if (chunkSize < 2) return input;

  const chunkCount = Math.ceil(input.length / chunkSize);
  const output = new Array(chunkCount);

  for (let c = 0; c < chunkCount; c++) output[c] = input.slice(c * chunkSize, (c + 1) * chunkSize);

  return output;
}
