import Button from 'form5/react/Button';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { Callout } from '…/app/common/Callout/Callout.tsx';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import { revokeSession } from './revokeSession.op.mts';

import classes from './CreateApiKeyDialog.module.css';

const {
  bool, func, object,
} = PropTypes;

export const RevokeApiKeyDialog = ({
  open,
  session,
  setRefresh,
  setOpen,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState(null);

  if (!session) return null;

  const handleDelete = async () => {
    setIsSaving(true);
    revokeSession({ sessionId: session.id })
      .catch(setErrors)
      .finally(() => {
        setRefresh(true);
        setIsSaving(false);
        setOpen(null);
      });
  };

  return (
    <Dialog
      backdropped
      open={!!open}
      setOpen={setOpen}
    >
      <p>
        Are you sure you want to delete the <strong>{session?.name}</strong> API Key?
      </p>

      <div className={classes.ApiKeyWarning}>
        <Callout type='error'>
          After deleting the API Key any requests made with the API Key will be rejected by our API.
        </Callout>
      </div>

      {errors && (
        <Callout type='error'>
          {errors.message}
        </Callout>
      )}

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving}
        isSubmitting={isSaving}
        onClick={handleDelete}
        type="submit"
      >
        Delete
      </SubmitButton>
    </Dialog>
  );
};

RevokeApiKeyDialog.displayName = 'RevokeApiKeyDialog';

RevokeApiKeyDialog.propTypes = {
  open: bool.isRequired,
  session: object,
  setOpen: func.isRequired,
  setRefresh: func.isRequired,
};
