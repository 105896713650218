const formTypeToRoute = {
  get default() { return this.signup },
  'feedback': 'feedback',
  'signup': 'form',
};

export function getFormAppLink({
  engagement,
  formType = 'signup',
  isRecurringLinkEnabled = false,
  step = 'start',
  user,
  workspace,
}: {
  engagement: {
    isLive: boolean,
    optInOpensAt: string,
    optInClosesAt: string,
    slug: string,
    isRecurringEnabled?: boolean,
    seriesId?: string,
  },
  formType: keyof typeof formTypeToRoute,
  isRecurringLinkEnabled?: boolean,
  step?: string,
  user: {
    emails: string[],
    profile: { firstName: string },
  }
  workspace: { slug: string },
}) {
  // Forms will only load if we have valid dates.
  if (!engagement.optInOpensAt || !engagement.optInClosesAt) return '';

  // Return the series link if the recurring link FF is enabled.
  if (isRecurringLinkEnabled && engagement?.isLive && engagement?.isRecurringEnabled && engagement?.seriesId) {
    return new URL(
      `/series/${engagement.seriesId}`,
      process.env.FORM_APP_DOMAIN,
    ).href;
  }

  const url = new URL(
    `/${formTypeToRoute[formType]}/${workspace?.slug}/${engagement.slug}/${step}`,
    process.env.FORM_APP_DOMAIN,
  );

  // For draft engagements we add testing query parameters.
  if (!engagement.isLive) {
    url.searchParams.set('_test', '1');
    url.searchParams.set('email', user.emails[0]);

    if (formType == 'signup') {
      url.searchParams.set('first_name', user.profile?.firstName);
    } else {
      url.searchParams.set('match', 'NA');
      url.searchParams.set('optin_token', 'NA');
    }
  }

  return url.href;
}
