import { Navigate, useParams } from 'react-router-dom';

import { rpcOrigin } from '…/common/origin.mts';

import {
  OrbiitBackground,
  orbiitBackgroundContentClassName,
} from '…/app/common/OrbiitBackground/OrbiitBackground.tsx';

import { useStore } from '…/app/state/useStore.mts';

import { CTA } from './w/workspace/common/CTA/CTA.tsx';


export function WelcomePage() {
  const user = useStore((data) => data.user);
  const { token } = useParams();

  if (!user.isAuthenticated) return <Navigate to="/" />;

  const loginLink = (new URL(`/auth/login?token=${token}`, rpcOrigin)).href;

  return (
    <OrbiitBackground>
      <div className={orbiitBackgroundContentClassName}>
        <h1>Welcome to Orbiit!</h1>

        <CTA
          data-testid="welcome-btn"
          fluid
          to={loginLink}
        >
          View your workspaces
        </CTA>
      </div>
    </OrbiitBackground>
  );
}
WelcomePage.displayName = 'WelcomePage';

const handle = {
  showNav: false,
  title: 'Welcome',
};

export {
  WelcomePage as Component,
  handle,
};
