/* stylelint-disable selector-class-pattern */

.CTA {
  display: inline-block;
}

.CTA[disabled] {
  cursor: not-allowed;
}

.CTA[guise="Link"] {
  background: none;
  border-radius: inherit;
  color: var(--brand-accent-primary);
  padding: unset;
  text-align: inherit;
  text-decoration: none;
  user-select: text;
  white-space: break-spaces;
}

.CTA[guise="Link"]:hover {
  color: var(--brand-dark);
}

.CTA[guise="Link"]:focus {
  color: var(--brand-accent-teriary);
}

.CTA[guise="Link"]:visited {
  color: var(--brand-accent-ancillary);
}

.CTA[guise="Link"][appearance="basic"] {
  color: unset;
}

.CTA[guise="Link"][appearance="danger"] {
  color: var(--colour-danger);
}
