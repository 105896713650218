import type { HTMLProps } from 'react';
import { clsx } from 'clsx';

import classes from './Meter.module.css';


export function Meter({
  className,
  min = 0,
  max = 100,
  value,
  ...rest
}: {
  className?: string,
  min?: number,
  max?: number,
  value: number,
} & HTMLProps<HTMLMeterElement>) {
  return (
    <meter
      className={clsx(classes.Meter, className)}
      max={max}
      min={min}
      value={value}
      {...rest}
    />
  );
}
Meter.displayName = 'Meter';
