.AudienceDropdown {
  display: flex;
  flex-direction: column;
  min-width: 15em;
  position: relative;
}

.AudienceDropdownLabel {
  align-items: center;
  background-color: white;
  border: 1px solid #DEDEDE;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  gap: 0.5em;
  line-height: 1;
  min-height: 100%;
  padding: 1em;
}

.AudienceDropdownLabel::before {
  border-bottom: 0.1em;
  border-color: initial;
  border-left: 0;
  border-radius: 0.1em;
  border-right: 0.1em;
  border-style: solid;
  border-top: 0;
  content: "";
  display: inline-block;
  height: 0.5em;
  margin-top: -0.2em;
  transform: rotate(-45deg);
  transition: transform ease-in-out var(--transition-duration-glyphs);
  width: 0.5em;
}

.AudienceDropdownMenu[open] + .AudienceDropdownLabel::before {
  transform: rotate(45deg);
}

.AudienceDropdownMenu {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: block;
  height: auto;
  left: 0;
  max-height: 0;
  min-width: 30em;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(100% + 0.2em);
  transition: max-height ease-in-out var(--transition-duration-controls);
  z-index: var(--zindex-controls);
}

.AudienceDropdownMenu[open] {
  max-height: 100ch;
  overflow-y: auto;
}

.AudienceDropdownMenu::backdrop {
  display: none;
}

.AudienceDropdownMenuInner {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
