import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';
import { log } from '…/app/logger.mts';

import { SETUP_SECTIONS } from '../../../constants.mts';

import type { Question } from './ParticipantQuestions.d.ts';
import CREATE_FEEDBACK_QUESTION from './createFeedbackQuestion.gql';
import CREATE_SIGNUP_QUESTION from './createSignupQuestion.gql';
import DELETE_FEEDBACK_QUESTION from './deleteFeedbackCustomQuestion.gql';
import DELETE_SIGNUP_QUESTION from './deleteSignupCustomQuestion.gql';
import UPDATE_FEEDBACK_QUESTION from './updateFeedbackQuestion.gql';
import UPDATE_SIGNUP_QUESTION from './updateSignupQuestion.gql';


const createMap = {
  [SETUP_SECTIONS.FEEDBACK]: CREATE_FEEDBACK_QUESTION,
  [SETUP_SECTIONS.SIGNUP]: CREATE_SIGNUP_QUESTION,
};
const deleteMap = {
  [SETUP_SECTIONS.FEEDBACK]: DELETE_FEEDBACK_QUESTION,
  [SETUP_SECTIONS.SIGNUP]: DELETE_SIGNUP_QUESTION,
};
const updateMap = {
  [SETUP_SECTIONS.FEEDBACK]: UPDATE_FEEDBACK_QUESTION,
  [SETUP_SECTIONS.SIGNUP]: UPDATE_SIGNUP_QUESTION,
};
export function setParticipantQuestion({
  engagementId,
  question: {
    id,
    isDataPrivate,
    isDeleted,
    isDisabled,
    isMatchingDisabled,
    isMatchingOpposites,
    isOrdered,
    props,
    question,
    stepName,
  },
  type,
  workspaceId,
}: {
  engagementId: string,
  question: Question,
  type: keyof typeof updateMap,
  workspaceId: string,
}) {
  if (isDeleted && !id) {
    return log.debug(`Ignoring "${stepName}", which was created and deleted in the same change-set.`);
  }

  const op = id // eslint-disable-line no-nested-ternary
    ? isDeleted
      ? deleteMap[type]
      : updateMap[type]
    : createMap[type];

  if (!op) {
    return Promise.reject(`Invalid question type: ${type}`);
  }
  if (!props) {
    log.debug(`Nothing to do (question has no props): ${stepName}`);
    return Promise.resolve();
  }

  return gqlOperation<{
    data: { question: { id: ObjectId } },
  }>(op, {
    engagementId,
    ...(!isDeleted && {
      question: {
        isDataPrivate,
        isDisabled,
        isMatchingDisabled,
        isMatchingOpposites,
        isOrdered,
        props: {
          ledeIn: props.ledeIn,
          ...(props.options && { options: props.options }),
          title: props.title,
          variableConfig: props.variableConfig,
        },
        question,
        stepName,
      },
    }),
    questionId: id,
    workspaceId,
  });
}
