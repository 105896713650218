.Table {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.Table th,
.Table td {
  padding: var(--default-padding);
}

.Table td[data-type="controls"] {
  width: 1ch;
}
