import engagementFrag from '../../gql/engagement.frag.gql.mjs';


export const INITIALISE_ENGAGEMENT_TEMPLATE = `
mutation InitialiseEngagementTemplate(
  $areasOfInterest: [EngagementQuestionOptionInput]!
  $conversationGuideId: ID!
  $conversationGuide: ConversationGuideInput!
  $description: String!
  $engagement: EngagementInput!
  $engagementId: ID!
  $title: String!
  $workspaceId: ID!
) {
   updateConversationGuide(
    conversationGuideId: $conversationGuideId,
    conversationGuide: $conversationGuide
  ) {
    id
  }

  initialiseOptInQuestions(
    areasOfInterest: $areasOfInterest
    engagementId: $engagementId
    topicDescription: $description
    topicTitle: $title
    workspaceId: $workspaceId
  ) {
    id
  }

  engagement: updateEngagement(
    engagement: $engagement
    engagementId: $engagementId
    workspaceId: $workspaceId
  ) {
    ...engagementFields
  }
}

${engagementFrag}
`;
