import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type {
  WorkspaceAdmin,
  WorkspaceId,
} from '../states.d.ts';
import type { OrbiitAPI } from '../api.d.ts';
import { onError } from '../errors/errors.state.mts';

import { gotTeammates } from './gotTeammates.mts';
import { GET_WORKSPACE_SETTINGS } from './getWorkspaceSettings.gql.mjs';


export function getWorkspaceSettings(workspaceId: WorkspaceId) {
  return gqlOperation<{
    workspace: {
      id: WorkspaceId,
      settings: WorkspaceAdmin['settings'],
      teammates: OrbiitAPI.Teammate[],
    },
  }>(GET_WORKSPACE_SETTINGS, { workspaceId })
    .then(({
      workspace: {
        settings,
        teammates,
      },
    }): WorkspaceAdmin => ({
      settings,
      teammates: gotTeammates(teammates),
    }))
    .catch(onError);
}
