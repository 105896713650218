._EngagementHeader_st97q_1 {
  align-items: center;
  background-color: #FFF;
  box-shadow: 0 6px 12px rgb(0 0 0 / 11%);
  display: grid;
  gap: var(--grid-gutter);
  grid-template-areas: "Name Numbers Divider Gist";
  grid-template-columns: auto max-content min-content max-content;
  padding: 0.5rem 1rem 1rem;
}

._Name_st97q_12 {
  align-items: center;
  align-self: center;
  display: inline-flex;
  gap: var(--grid-gutter);
  grid-area: Name;
}

._Numbers_st97q_20 {
  grid-area: Numbers;
}

._Numbers_st97q_20 th {
  font-weight: bold;
}

._Numbers_st97q_20 th,
._Numbers_st97q_20 td {
  font-size: 0.875rem;
  padding: 0.4ch;
  text-align: center;
}

._Divider_st97q_35 {
  border-left: 1px solid;
  grid-area: Divider;
  height: 80%;
  place-self: center;
}

._Gist_st97q_42 {
  font-size: 0.875rem;
}

._Gist_st97q_42 > li {
  margin: 0.125rem 0;
}

._EditButtonMuted_st97q_50 {
  color: var(--neutral-medium);
}
