.Tabs {
  align-items: end;
  display: flex;
  flex-direction: row;
}

.Tabs > .Tab {
  border-bottom: 2px solid var(--neutral-medium);
  padding: 0.75rem 1rem 0.5rem;
}

.Tab:hover,
.Tab[active] {
  background: var(--neutral-lightest);
  border-bottom: 2px solid var(--brand-dark);
  color: initial;
}

.Tab[active] {
  cursor: default;
  font-weight: 700;
}

/* extend the bottom border the rest of the width */
.Tabs::before,
.Tabs::after {
  border-bottom: 2px solid var(--neutral-medium);
  content: "";
  flex: 1;
}
