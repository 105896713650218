import { clsx } from 'clsx';

import { formatDate } from '…/app/common/dateUtils.mts';

import type { EmailStats } from './fetchEmailStatistics.mts';
import classes from './EmailStatsCard.module.css';

const SHOW_EVENTS = new Array(
  'processed',
  'dropped',
  'deferred',
  'delivered',
  'bounce',
  'open',
  'click',
);

type EventTypes = typeof SHOW_EVENTS[number];

export function EmailStatsCard({ stats }: { stats?: EmailStats }) {
  if (!stats) return null;

  const max = stats.count?.processed ?? 0;

  return (
    <div className={classes.Container}>
      <h3 className={classes.CardTitle}>
        {stats.category.replace('engagement-', '')} Email
      </h3>

      <p className={classes.CardDate}>
        Last checked: {formatDate(stats.evaluatedAt, null, true)}
      </p>

      <div>
        <div className={classes.FlexCol}>
          {SHOW_EVENTS.map((label: EventTypes) => {
            const count = stats.count?.[label] ?? 0;
            return (
              <div className={clsx(classes.FlexRow, classes.EmailStatsRow)} key={`${stats.category}-${label}`}>
                <div className={classes.FlexRow}>
                  <div style={{ textTransform: 'capitalize' }}>{label}</div>

                  <span className={classes.Stat}>
                    {(100.0 * count / max).toFixed(1)}%
                  </span>
                </div>

                {/* <div className={classes.SkeletonLoader} /> */}

                <div>{count}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
EmailStatsCard.displayName = 'EmailStatsCard';
