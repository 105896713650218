import type { ITEM_STATUSES } from '../validators.mts';

import classes from './ItemStatus.module.css';


export function ItemStatus({
  label,
  status,
}: {
  label: string,
  status: ITEM_STATUSES,
}) {
  return (
    <span className={classes.ItemStatus} data-status={status}>{label}</span>
  );
}
ItemStatus.displayName = 'ItemStatus';
