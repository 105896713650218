import { clsx } from 'clsx';

import classes from './DotLoader.module.css';


export function DotLoader({
  className = '',
  count = 3,
}: {
  className?: string,
  count?: number,
}) {
  return (
    <div className={clsx(classes.DotLoader, className)}>
      {[...Array(count)].map((v, i) => (
        <span
          className={classes.Dot}
          key={i}
          style={{'--i': i}}
        />
      ))}
    </div>
  );
}

DotLoader.displayName = 'DotLoader';
