/* eslint-disable react/jsx-max-depth */
import Button from 'form5/react/Button';
import Field, { type FieldProps } from 'form5/react/Field';
import Form, { type FormProps } from 'form5/react/Form';
import {
  type ChangeEvent,
  useCallback,
  useEffect,
} from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import {
  deriveDateFromDateTime,
  getNow,
} from '…/app/common/dateUtils.mts';

import { Banner } from '…/app/common/Banner/Banner.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';
import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { UnsavedChangesWarning } from '…/app/w/workspace/common/UnsavedChangesWarning/UnsavedChangesWarning.tsx';

import type { ScheduledEngagement } from '../../../Engagement.d.ts';

import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';

import type { ScheduleState } from './composeScheduleData.mts';
import { composeConvoDateProps, composeFormValues } from './composeScheduleData.mts';
import { TimezoneList } from './TimezoneList.jsx';
import * as constraints from './constraints.mts';
import { allowMoreConvoDates } from './validations.mts';

import Agreement from './agreement.svg';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';


export function ScheduleForm({
  state,
  disabled: disableEverything,
  engagement: {
    isLive,
    product,
  },
  isDirty,
  name,
  onChange,
  onConvoDateAdd,
  onConvoDateBlur,
  onConvoDateRemove,
  onDirty,
  onOptInClosesChange,
  onTimezoneBlur,
  onPristine,
  onReset,
  onSubmit,
}: {
  state: ScheduleState,
  disabled: boolean,
  engagement: ScheduledEngagement,
  isDirty: boolean,
  name: string,
  onChange: FieldProps['onChange'],
  onConvoDateAdd(): void,
  onConvoDateBlur: FieldProps['onBlur'],
  onConvoDateRemove(idx: Int): void,
  onDirty: FormProps['onDirty'],
  onOptInClosesChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void,
  onTimezoneBlur(event: ChangeEvent<HTMLInputElement>): void,
  onPristine: FormProps['onPristine'],
  onReset: HTMLFormElement['onReset'],
  onSubmit: FormProps['onSubmit'],
}) {
  const blocker = useBlocker(isDirty);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) { blocker.reset() }
  }, [blocker, isDirty]);

  const hasMatchEditing = !!useFeatureFlag([FeatureFlag.FLAGS.matchEditing]);

  const convoDateProps = composeConvoDateProps(product);

  const {
    conversationDates,
    isEditMatchesEnabled,
    matchIntroAt,
    oneToOneEmails: {
      chaser,
      invite,
    },
    optInClosesAt,
    optInOpensAt,
    timezoneIso,
  } = composeFormValues(state, product);

  const onClosesAtChange = useCallback<FieldProps['onChange']>((change, event) => {
    onChange?.(change, event);
    onOptInClosesChange(event);
  }, [onChange, onOptInClosesChange]);

  return (
    <Form
      name={name}
      onDirty={onDirty}
      onPristine={onPristine}
      onReset={onReset}
      onSubmit={onSubmit}
    >
      {blocker && <UnsavedChangesWarning blocker={blocker} />}

      <fieldset disabled={disableEverything}>
        <Details open>
          <Summary>
            <h2 id="registration">Participant Registration</h2>
          </Summary>

          <div className={editableSectionClasses.Panel}>
            <p>
            These dates define the window that the engagement opt-in form is open for registration.

              {' '}

              <a href="https://intercom.help/orbiit/en/articles/6732625-best-practices-for-registration-dates"><OrbiitIcon icon="InfoIcon" inline /></a>
            </p>

            <fieldset className={editableSectionClasses.Fieldset}>
              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                fluid
                label="Timezone"
                list="timezone-options"
                name="timezoneIso"
                onBlur={onTimezoneBlur}
                onChange={onChange}
                placeholder={timezoneIso}
                readOnly={constraints.timezoneIso.readonly(state, isLive)}
                required
                type="text"
                value={timezoneIso}
              />

              <TimezoneList id="timezone-options" />

              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                fluid
                label="Start"
                min={deriveDateFromDateTime(getNow())}
                name="optInOpensAt"
                onChange={onChange}
                readOnly={constraints.optInOpensAt.readonly(state, isLive)}
                required
                type="date"
                value={optInOpensAt}
              />

              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                fluid
                label="End"
                max={constraints.optInClosesAt.max(state.conversationDates[0], isLive)}
                min={constraints.optInClosesAt.min(state.optInOpensAt, isLive)}
                name="optInClosesAt"
                onChange={onClosesAtChange}
                readOnly={constraints.optInClosesAt.readonly(state, isLive)}
                required
                type="date"
                value={optInClosesAt}
              />
            </fieldset>

            <p>We recommend a timezone where members are located (or New York or London for a global audience).</p>
          </div>
        </Details>

        <fieldset
          className="HollowWrapper"
          disabled={constraints.oneToOneEmails.readonly(state, isLive)}
          readOnly={constraints.oneToOneEmails.readonly(state, isLive)}
        >
          <Details open>
            <Summary>
              <h2 id="emails">Messages to Participants</h2>
            </Summary>

            <div className={editableSectionClasses.Panel}>
              <fieldset className="SplitContent" name="oneToOneEmails">
                <fieldset
                  className="EvenSplit"
                  disabled={constraints.invite.readonly(state, isLive)}
                  name="invite"
                  readOnly={constraints.invite.readonly(state, isLive)}
                >
                  <header className="SplitHeader">
                    <h3 id="invite">Invites</h3>

                    <Field
                      aria-describedby="invite-explanation"
                      arrangement={Field.ARRANGEMENTS.INLINE}
                      checked={invite.isDisabled}
                      className={editableSectionClasses.InvertedToggle}
                      id="oneToOneEmails.invite.isDisabled"
                      label={null}
                      name="isDisabled"
                      onChange={onChange}
                      type="checkbox"
                      variant={Field.VARIANTS.TOGGLE}
                    />
                  </header>

                  <p id="invite-explanation">{invite.isDisabled ? 'We won’t' : 'We’ll' } e-mail members to opt into the engagement.</p>

                  <Field
                    aria-description="When invites are sent"
                    arrangement={Field.ARRANGEMENTS.INLINE}
                    data-testid="inviteSendAt" // [1]
                    fluid
                    id="oneToOneEmails.invite.sendAt"
                    label={null}
                    max={constraints.invite.max(state.oneToOneEmails.chaser, state.optInClosesAt)}
                    min={constraints.invite.min(state.optInOpensAt)}
                    name="sendAt"
                    onChange={onChange}
                    readOnly={invite.isDisabled}
                    required={!invite.isDisabled}
                    type="datetime-local"
                    value={invite.sendAt}
                  />
                </fieldset>

                <fieldset
                  className="EvenSplit"
                  disabled={constraints.chaser.readonly(state, isLive)}
                  name="chaser"
                  readOnly={constraints.chaser.readonly(state, isLive)}
                >
                  <header className="SplitHeader">
                    <h3 id="chaser">Reminders</h3>

                    <Field
                      aria-describedby="reminder-explanation"
                      arrangement={Field.ARRANGEMENTS.INLINE}
                      checked={chaser.isDisabled}
                      className={editableSectionClasses.InvertedToggle}
                      id="oneToOneEmails.chaser.isDisabled"
                      label={null}
                      name="isDisabled"
                      onChange={onChange}
                      type="checkbox"
                      variant={Field.VARIANTS.TOGGLE}
                    />
                  </header>

                  <p id="reminder-explanation">{chaser.isDisabled ? 'We won’t' : 'We’ll' } e-mail members members again and include the optin details.</p>

                  <Field
                    aria-description="When reminders are sent"
                    arrangement={Field.ARRANGEMENTS.INLINE}
                    data-testid="chaserSendAt" // [1]
                    fluid
                    id="oneToOneEmails.chaser.sendAt"
                    label={null}
                    max={constraints.chaser.max(state.optInClosesAt)}
                    min={constraints.chaser.min(state.oneToOneEmails.invite, state.optInOpensAt)}
                    name="sendAt"
                    onChange={onChange}
                    readOnly={chaser.isDisabled}
                    required={!chaser.isDisabled}
                    type="datetime-local"
                    value={chaser.sendAt}
                  />
                </fieldset>
              </fieldset>

              <FeatureFlag flags={[FeatureFlag.FLAGS.matchEditing]}>
                <fieldset>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a id="match-editing" />

                  <Field
                    arrangement={Field.ARRANGEMENTS.INLINE}
                    checked={isEditMatchesEnabled}
                    label={<>Match editing <Chip variant={Chip.VARIANTS.INFORMATIVE}>New</Chip></>}
                    name="isEditMatchesEnabled"
                    onChange={onChange}
                    readOnly={constraints.matchEditing.readonly(state, isLive)}
                    type="checkbox"
                    variant={Field.VARIANTS.TOGGLE}
                  />

                  <Banner>
                    <Agreement />

                    <p>
                      <strong>Review and manually edit the matches</strong> provided by Orbiit to
                      create even more powerful and meaningful connections. We will email you when
                      the matches are ready to edit. You will have to complete this before the
                      invite emails are sent out on the date you set below.
                    </p>
                  </Banner>

                  {isEditMatchesEnabled && (
                    <>
                      <p>
                        After Orbiit generates matches, you’ll have until the day before the
                        introductions go out to make any edits.
                      </p>

                      <h3 id="intros">Introductions</h3>

                      <p id="match-intro-explanation">A message will be sent introducing matched participants to each other.</p>

                      <Field
                        aria-describedby="match-intro-explanation"
                        arrangement={Field.ARRANGEMENTS.INLINE}
                        data-testid="matchIntroAt" // [1]
                        label={null}
                        // NOTE: This needs to be fixed. We should disable specific days but the
                        // form5 library (or HTML native) does not allow for that. We could throw errors.
                        max={constraints.matchIntroAt.max(state.conversationDates[0])}
                        min={constraints.matchIntroAt.min(state.matchesGenerated)}
                        name="matchIntroAt"
                        onChange={onChange}
                        readOnly={constraints.matchIntroAt.readonly(state, isLive)}
                        required
                        type="date"
                        value={matchIntroAt}
                      />
                    </>
                  )}
                </fieldset>
              </FeatureFlag>
            </div>
          </Details>
        </fieldset>

        <Details open>
          <Summary>
            <h2 id="conversations">Conversations</h2>
          </Summary>

          <div className={editableSectionClasses.Panel}>
            <p>
              Select when the conversations will occur.

              {' '}

              <a href="https://intercom.help/orbiit/en/articles/6732828-best-practices-for-conversation-dates"><OrbiitIcon icon="InfoIcon" inline /></a>
            </p>

            {conversationDates.map((date, i) => (
              <div className={editableSectionClasses.RemovableSet} key={`date-${i}`}>
                <Field
                  arrangement={Field.ARRANGEMENTS.INLINE}
                  label={`Slot ${i + 1}`}
                  min={constraints.conversationDates.min(
                    state.matchIntroAt,
                    state.optInClosesAt,
                    hasMatchEditing,
                    convoDateProps.deriver,
                  )}
                  name={`conversationDates[${i}]`}
                  onBlur={onConvoDateBlur}
                  onChange={onChange}
                  readOnly={constraints.conversationDates.readonly(state, isLive)}
                  required
                  type={convoDateProps.type}
                  value={date}
                />

                <Button
                  disabled={isLive}
                  onClick={() => onConvoDateRemove(i)}
                  variant={Button.VARIANTS.GLYPH}
                >
                ✕
                </Button>
              </div>
            ))}

            <Button
              appearance={Button.APPEARANCES.BASIC}
              className={editableSectionClasses.LeftButton}
              disabled={!allowMoreConvoDates(state.conversationDates, product)}
              onClick={onConvoDateAdd}
            >+ Add
            </Button>
          </div>
        </Details>
      </fieldset>
    </Form>
  );
}

// [1] This is needed for CuriousGeorge to identify the field (since its name is non-unique)

ScheduleForm.displayName = 'ScheduleForm';
