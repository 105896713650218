mutation CreateFeedbackQuestion(
  $workspaceId: ID!
  $engagementId: ID!
  $question: EngagementQuestionInput!
) {
  question: createEngagementFeedbackQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    feedbackQuestion: $question
  ) {
    id
  }
}
