import { clsx } from 'clsx';
import { questions as platformUtilsQuestions } from '@hivebrite/orbiit-platform-utils';
import {
  Fragment,
  useState,
} from 'react';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import editableSectionClasses from '../../EngagementSubPageEditableSection.module.css';
import { DraggableItem } from '../../DragNDrop/Draggable.tsx';

import type { AdvancedQuestionProps, QuestionOption } from '../ParticipantQuestions.d.ts';

import * as constraints from './constraints.mts';


const { initOptions } = platformUtilsQuestions;

export function AdvancedQuestion({
  custom: isCustom,
  data,
  disabled,
  heading,
  id,
  onChange,
  required,
  setDirty,
  type,
}: AdvancedQuestionProps) {
  const {
    isDeleted,
    isMatchingDisabled,
    isDataPrivate,
    isMatchingOpposites,
    isOrdered,
    ledeIn = null,
    lockedProps = new Array(),
    options,
    question = 'SingleSelectStep',
    title = '',
  } = data;
  const [dragging, setDragging] = useState({});
  const editLocks = {
    ledeIn: lockedProps.includes('ledeIn'),
    options: lockedProps.includes('options'),
    title: lockedProps.includes('title'),
  };
  const OptionSleeve = editLocks.options ? Fragment : DraggableItem;
  const optionsPath = `${id}.props.options`;

  function onItemDelete(option: QuestionOption) {
    const removedIdx = options.indexOf(option);

    const newList = new Array(options.length - 1);
    for (let i = 0, o = 0; i < newList.length; i++, o++) {
      if (i === removedIdx) { o++ }
      newList[i] = options[o];
    }

    onChange({
      id: optionsPath,
      value: newList,
    });
  }

  function onSequenceChange(cb: (options: QuestionOption[]) => QuestionOption[]) {
    const newSequence = cb(options);

    onChange({
      id: optionsPath,
      value: newSequence,
    });
  }

  return (
    <fieldset
      className={clsx(editableSectionClasses.PanelSection, 'HollowWrapper', {
        [editableSectionClasses.PendingDeletion]: isDeleted,
      })}
      name={id}
    >
      <legend className={editableSectionClasses.FieldsetHeading}>
        <h2 id={id}>{heading}</h2>

        <div className={editableSectionClasses.FieldsetHeading}>
          <Field
            aria-label="is disabled"
            arrangement={Field.ARRANGEMENTS.STAND_ALONE}
            checked={disabled ?? false}
            className={editableSectionClasses.InvertedToggle}
            id={`${id}.isDisabled`}
            label={null}
            name="isDisabled"
            onChange={onChange}
            readOnly={isDeleted || required}
            type="checkbox"
            variant="toggle"
          />

          {isCustom && (
            <Field
              appearance="danger"
              arrangement={Field.ARRANGEMENTS.STAND_ALONE}
              defaultChecked={isDeleted}
              id={`${id}.isDeleted`}
              label={<OrbiitIcon icon="DeleteIcon" inline />}
              name="isDeleted"
              onChange={onChange}
              title="delete custom question"
              type="checkbox"
              variant={Field.VARIANTS.GLYPH}
            />
          )}
        </div>
      </legend>

      <fieldset
        disabled={isDeleted}
        readOnly={isDeleted}
      >
        {id !== 'questions.timezone' && (
          <fieldset className="Well">
            <h3>Question settings</h3>

            {constraints.questionType.visible(type, isCustom) && (
              <Field
                arrangement={Field.ARRANGEMENTS.STACKED}
                as="select"
                id={`${id}.question`}
                label="What kind of question?"
                name="question"
                onChange={({ id: fieldId, value }) => {
                  onChange({
                    id: fieldId,
                    value,
                  });

                  if (value === 'MultiSelectStep') {
                    onChange({
                      id: `${id}.isOrdered`,
                      value: false,
                    });
                    onChange({
                      id: `${id}.isMatchingOpposites`,
                      value: false,
                    });
                  }

                  if (value === 'GenericTextStep') {
                    onChange({
                      id: optionsPath,
                      value: undefined,
                    });
                    onChange({
                      id: `${id}.isDataPrivate`,
                      value: true,
                    });
                    onChange({
                      id: `${id}.isMatchingDisabled`,
                      value: true,
                    });
                    onChange({
                      id: `${id}.isMatchingOpposites`,
                      value: false,
                    });
                    onChange({
                      id: `${id}.isOrdered`,
                      value: false,
                    });
                  } else if (!options.length) {
                    onChange({
                      id: optionsPath,
                      value: new Array(initOptions('')),
                    });
                  }
                }}
                value={question}
              >
                <option value="GenericTextStep">Free-response</option>

                <option value="MultiSelectStep">Select multiple options</option>

                <option value="SingleSelectStep">Select only one option</option>
              </Field>
            )}

            {constraints.isOrdered.visible(type, isCustom) && (
              <Field
                checked={isOrdered}
                id={`${id}.isOrdered`}
                label="The answers represent a scale (ordered)"
                name="isOrdered"
                onChange={({ id: fieldId, value }) => {
                  onChange({
                    id: fieldId,
                    value,
                  });

                  // When matching opposites we can't also enable ordered at this point.
                  if (value) {
                    onChange({
                      id: `${id}.isMatchingOpposites`,
                      value: false,
                    });
                  }
                }}
                readOnly={constraints.isOrdered.readOnly(disabled, data)}
                type="checkbox"
              />
            )}

            {constraints.isMatchingOpposites.visible(type, isCustom) && (
              <Field
                checked={isMatchingOpposites}
                id={`${id}.isMatchingOpposites`}
                label="Match like-for-unlike"
                name="isMatchingOpposites"
                onChange={({ id: fieldId, value }) => {
                  onChange({
                    id: fieldId,
                    value,
                  });

                  // When matching opposites we can't also enable ordered at this point.
                  if (value) {
                    onChange({
                      id: `${id}.isOrdered`,
                      value: false,
                    });
                  }
                }}
                readOnly={constraints.isMatchingOpposites.readOnly(disabled, data)}
                type="checkbox"
              />
            )}

            {constraints.isMatchingDisabled.visible(type) && (
              <Field
                checked={isMatchingDisabled}
                id={`${id}.isMatchingDisabled`}
                label="Disable Matching: Do not use this question for matching."
                name="isMatchingDisabled"
                onChange={({ id: fieldId, value }) => {
                  onChange({
                    id: fieldId,
                    value,
                  });

                  if (value) {
                    onChange({
                      id: `${id}.isMatchingOpposites`,
                      value: false,
                    });
                  }
                }}
                readOnly={constraints.isMatchingDisabled.readOnly(disabled, question)}
                type="checkbox"
              />
            )}

            {constraints.isDataPrivate.visible(type) && (
              <Field
                checked={isDataPrivate}
                id={`${id}.isDataPrivate`}
                label="Private data: Do not share this data with participants"
                name="isDataPrivate"
                onChange={onChange}
                readOnly={constraints.isDataPrivate.readOnly(disabled, question)}
                type="checkbox"
              />
            )}
          </fieldset>
        )}

        <fieldset
          disabled={disabled}
          name="props"
          readOnly={disabled}
        >
          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            fluid
            id={`${id}.props.title`}
            label="Question title"
            name="title"
            onChange={onChange}
            readOnly={editLocks.title}
            required
            value={title ?? ''}
          />

          <Field
            arrangement={Field.ARRANGEMENTS.STACKED}
            as="textarea"
            fluid
            id={`${id}.props.ledeIn`}
            label="Question description"
            name="ledeIn"
            onChange={onChange}
            readOnly={editLocks.ledeIn}
            value={ledeIn ?? ''}
          />

          {!!options?.length && (
            <>
              <h3>Answer options</h3>

              <fieldset>
                {(options as QuestionOption[]).map((option, idx) => {
                  const {
                    id: optionId,
                    text,
                  } = option;
                  const dataPath = `${id}.props.options[${idx}].text`;

                  return (
                    <OptionSleeve
                      key={optionId}
                      {...(OptionSleeve === DraggableItem && {
                        dragging,
                        id: dataPath,
                        onChange: onSequenceChange,
                        onItemDelete: () => onItemDelete(option),
                        readOnly: disabled,
                        setDirty,
                        setDragging,
                      })}
                    >
                      <Field
                        aria-label="option value"
                        arrangement={Field.ARRANGEMENTS.STAND_ALONE}
                        className={editableSectionClasses.DraggableOptionInput}
                        fluid
                        id={dataPath}
                        label={null}
                        name={optionId}
                        onChange={onChange}
                        readOnly={editLocks.options}
                        required
                        value={text}
                      />
                    </OptionSleeve>
                  );
                })}
              </fieldset>

              <Button
                appearance={Button.APPEARANCES.BASIC}
                className={editableSectionClasses.RightButton}
                disabled={editLocks.options}
                onClick={() => onChange({
                  id: `${id}.props.options[${options.length}]`,
                  value: initOptions(''),
                })}
              >Add option
              </Button>
            </>
          )}
        </fieldset>
      </fieldset>
    </fieldset>
  );
}
AdvancedQuestion.displayName = 'AdvancedQuestion';
