import ky, { type BeforeRequestHook } from 'ky';

import { rpcOrigin } from '../../../common/origin.mts';


const addWorkspaceId: BeforeRequestHook = (req) => {
  if ((new URL(req.url)).origin !== rpcOrigin) return;

  const workspaceId = localStorage.getItem('workspaceId');

  if (workspaceId) req.headers.set('x-orbiit-workspace-id', workspaceId);
};


export const rpc = ky.extend({
  hooks: {
    beforeRequest: [addWorkspaceId],
  },
  prefixUrl: rpcOrigin,
  redirect: 'follow',
});
