.Nameplate {
  background-color: var(--neutral-lightest);
  text-align: center;
}

.Nameplate,
.Card {
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  padding: var(--default-padding);
}

.Card {
  align-items: baseline;
  background-color: var(--brand-subtle-light);
  display: grid;
  gap: var(--grid-gutter);
  grid-auto-flow: column;
}

.MatchCard {
  justify-content: space-between;
}

.OptinCard {
  grid-template-columns: 10ch auto max-content;
}

.CardSubject {
  font-weight: bold;
}
