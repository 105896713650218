import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Member } from './Member.d.ts';
import DELETE_MEMBERS from './deleteMembers.gql';


export function deleteMembers(
  workspaceId: ObjectId,
  memberIds: Member['id'][],
) {
  return gqlOperation<{ result: boolean }>(DELETE_MEMBERS, {
    memberIds,
    workspaceId,
  })
    .then(({ result }) => {
      if (!result) throw result;
      return memberIds;
    });
}
