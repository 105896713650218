import { Logger } from '…/common/Logger.mts';

export const log = new Logger({ prefix: '⚙ ServiceWorker' });

// ! This MUST be a dynamic import to avoid a circular dependency:
// ? here → log-utils → storage → here (`log`)
// When switching to bundle splitting, this won't work because the SW spec does its damnedest to
// make this difficult. Instead, logging will likely need to be moved to a SharedWorker.
import('…/common/log-utils.mjs').then(({
  checkShouldPrintLogs,
  getSessionId,
  keepSessionIdSynced,
}) => {
  checkShouldPrintLogs().then((doPrint) => {
    log.doPrint = doPrint;
  });

  getSessionId().then((sessionId) => {
    log.sessionId = sessionId;
  });

  keepSessionIdSynced(log);
});
