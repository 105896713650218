import { rpc } from '…/app/common/rpc/client.mts';

import { onError } from '../errors/errors.state.mts';

import { userState } from './user.state.mts';
import { gotMe } from './gotMe.mts';


export const logout = () => rpc
  .post('auth/logout')
  .json<never>()
  .then(gotMe)
  .then(() => {
    userState.isAuthenticated = false;
    return null; // ReactRouter.Await requires _something_ is returned
  })
  .catch(onError);
