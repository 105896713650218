import { useParams } from 'react-router-dom';

import type { EngagementRouteParams } from '…/app/route-params.mts';

import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';
import { updateEngagement } from '…/app/w/workspace/engagements/engagement/gql/updateEngagement.op.mts';
import type { AudienceSelectionFormProps } from '…/app/w/workspace/common/AudienceSelection/AudienceSelectionForm.tsx';
import { AudienceSelectionForm } from '…/app/w/workspace/common/AudienceSelection/AudienceSelectionForm.tsx';


export function SelectAudience({
  onSuccess,
}: {
  onSuccess(result: Engagement): void,
}) {
  const { workspaceId, engagementId } = useParams() as EngagementRouteParams;

  const saveAudienceSelection: AudienceSelectionFormProps['onSubmit'] = async ({ audienceId }) => updateEngagement({
    engagement: {
      audienceId,
    },
    engagementId,
    workspaceId,
  })
    .then(onSuccess);

  return (
    <AudienceSelectionForm maxHeight onSubmit={saveAudienceSelection} />
  );
}
SelectAudience.displayName = 'SelectAudience';
