import { MemberCard } from '…/app/w/workspace/audiences/audience/members/MemberHistory.tsx';

import { getAnswersMap, getFreeTextQuestions } from '…/app/deprecated/utils/questionAnswerUtils.mts';

import { Answer, FormAnswer } from '…/app/w/workspace/common/Answer/Answer.tsx';
import type { EngagementFull, EngagementQuestion } from '…/app/w/workspace/engagements/Engagement.d.ts';
import type { IFormAnswer } from '…/app/w/workspace/common/Answer/Answer.d.ts';

import type { FeedbackForm } from './feedback-forms.d.ts';
import { MatchRatingChip } from './MatchRatingChip.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import classes from './FormDrawer.module.css';

export function FeedbackFormDrawer({
  feedbackForm,
  engagement,
}: {
  feedbackForm: FeedbackForm,
  engagement: EngagementFull,
}) {
  const {
    feedbackQuestions = new Array(),
  }: { feedbackQuestions: EngagementQuestion[] } = engagement || {};
  const {
    fields: {
      nps,
      matchRating,
      testimonial,
      advice,
    },
  } = feedbackForm;

  // Create a map from an answer option-id to the answer text and question title.
  const allAnswerQuestions = getAnswersMap(feedbackQuestions);
  const freeTextQuestionsMap = getFreeTextQuestions(feedbackQuestions);

  return (
    <>
      <header className={classes.Header}>
        <MemberCard fields={{ ...feedbackForm.optInForm.fields }} />
      </header>

      <div className={classes.Content}>
        <Answer title="Net Promoter Score (NPS)">
          <Chip rating={nps} />
        </Answer>

        {testimonial && (
          <Answer title="Match Rating">
            <MatchRatingChip matchRating={matchRating} />
          </Answer>
        )}

        {testimonial && (
          <Answer
            answers={[testimonial]}
            title="Testimonial"
          />
        )}

        {advice && (
          <Answer
            answers={[advice]}
            title="Advice"
          />
        )}

        {feedbackForm?.answers.map(({
          stepName, selectedOptions, text,
        }: IFormAnswer) => (
          <FormAnswer
            allAnswerQuestions={allAnswerQuestions}
            freeTextQuestionsMap={freeTextQuestionsMap}
            key={`answer-${stepName}`}
            selectedOptions={selectedOptions}
            stepName={stepName}
            text={text}
          />
        ))}
      </div>
    </>
  );
}

FeedbackFormDrawer.displayName = 'FeedbackFormDrawer';
