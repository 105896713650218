import PropTypes from 'prop-types';

import AddIcon from '../../components/icons/AddIcon.svg';
import DeleteIcon from '../../components/icons/DeleteIcon.svg';
import Dot from '../../components/icons/Dot.svg';
import DownloadIcon from '../../components/icons/DownloadIcon.svg';
import DragHandle from '../../components/icons/DragHandle.svg';
import EmailBouncedIcon from '../../components/icons/EmailBouncedIcon.svg';
import EmptyEngagementIcon from '../../components/icons/EmptyEngagementIcon.svg';
import FilterIcon from '../../components/icons/FilterIcon.svg';
import InfoIcon from '../../components/icons/InfoIcon.svg';
import MatchEye from '../../components/icons/MatchEye.svg';
import MembersIcon from '../../components/icons/MembersIcon.svg';
import ScheduleDateTime from '../../components/icons/ScheduleDateTime.svg';
import speedLines from '../../components/icons/speedLines.svg';
import SnoozedIcon from '../../components/icons/SnoozedIcon.svg';
import Timer from '../../components/icons/Timer.svg';
import TrashIcon from '../../components/icons/TrashIcon.svg';

const icons = {
  AddIcon,
  DeleteIcon,
  Dot,
  DownloadIcon,
  DragHandle,
  EmailBouncedIcon,
  EmptyEngagementIcon,
  FilterIcon,
  InfoIcon,
  MatchEye,
  MembersIcon,
  ScheduleDateTime,
  SnoozedIcon,
  speedLines,
  Timer,
  TrashIcon,
};

const IconWrapper = ({
  color,
  children,
  inline,
  verticalAlign = 'middle',
  ...rest
}) => {
  const Element = inline ? 'span' : 'div';

  return (
    <Element
      style={{
        color,
        display: inline ? 'inline-flex' : 'flex',
        justifyContent: 'center',
        verticalAlign,
      }}
      {...rest}
    >
      {children}
    </Element>
  );
};
IconWrapper.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  inline: PropTypes.bool,
};

const BUTTON_SIZES = {
  small: '0.8em',
  default: '1em',
  medium: '1.2em',
  large: '2em',
  xlarge: '3em',
  xxlarge: '4em',
};

// eslint-disable-next-line react/prop-types
export const OrbiitIcon = ({
  fill = '',
  icon,
  inline = false,
  size = 'default',
  title = '',
  ...rest
}) => {
  const ImportedIcon = icons[icon];

  if (!ImportedIcon) return null;

  const config = {
    height: BUTTON_SIZES[size],
    width: BUTTON_SIZES[size],
  };

  return (
    <IconWrapper color={fill} inline={inline} title={title} {...rest}>
      <ImportedIcon {...config} />
    </IconWrapper>
  );
};

OrbiitIcon.displayName = 'OrbiitIcon';

OrbiitIcon.propTypes = {
  fill: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(icons)),
  inline: PropTypes.bool,
  original: PropTypes.bool,
  size: PropTypes.oneOf(Object.keys(BUTTON_SIZES)),
  title: PropTypes.string,
};
