._Bureau_1xnjd_1 {
  display: flex;
  flex-direction: row;
  gap: var(--grid-gutter);
  overflow-x: clip; /* hidden inexplicably breaks Bureau’s height */
  position: relative;
}

._CloseButton_1xnjd_9 {
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  position: absolute;
  top: 0;
}

._Drawer_1xnjd_16 {
  border: none;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  min-height: 100%;
  z-index: 1;
}

._DrawerLiner_1xnjd_25 {
  padding: calc(2 * var(--grid-gutter)) var(--grid-gutter);
}

._InlineDrawer_1xnjd_29[open],
._PopoverDrawer_1xnjd_30,
._DrawerLiner_1xnjd_25 {
  max-width: 30%;
  min-width: 30ch;
  width: 50ch;
}

._InlineDrawer_1xnjd_29 {
  max-width: 0;
  min-width: 0;
  overflow: hidden;
  position: relative;
  transition-duration: var(--default-transition-duration);
  transition-property:
    max-width,
    min-width,
    width;
  transition-timing-function: var(--default-transition-easing);
  width: 0;
}

._InlineDrawer_1xnjd_29:first-child ._CloseButton_1xnjd_9 {
  left: 0;
}

._InlineDrawer_1xnjd_29:last-child ._CloseButton_1xnjd_9 {
  right: 0;
}

._PopoverDrawer_1xnjd_30 {
  margin: 0;
  transition-duration: var(--default-transition-duration);
  transition-property: translate;
  transition-timing-function: var(--default-transition-easing);
}

/* stylelint-disable-next-line no-descending-specificity */
._PopoverDrawer_1xnjd_30 ._CloseButton_1xnjd_9[variant="glyph"] { /* variant="glyph" needed for specificity */
  background-color: inherit;
}

._PopoverDrawer_1xnjd_30:not([open]) ._CloseButton_1xnjd_9 {
  display: none;
}

._PopoverDrawer_1xnjd_30:first-child {
  box-shadow: 3px 3px 6px -2px var(--text-muted);
  margin-right: 100%;
  translate: -100%;
}

._PopoverDrawer_1xnjd_30:first-child ._CloseButton_1xnjd_9 {
  border-radius:
    0
    0
    var(--border-radius)
    0;
  box-shadow: 3px 3px 6px -2px var(--text-muted);
  right: 0;
  translate: 100%;
}

._PopoverDrawer_1xnjd_30[open]:first-child {
  translate: 0;
}

._PopoverDrawer_1xnjd_30:last-child {
  box-shadow: -3px 3px 6px -2px var(--text-muted);
  margin-left: 100%;
  translate: 0;
}

._PopoverDrawer_1xnjd_30[open]:last-child {
  translate: -100%;
}

/**
 * NOTE: When there is one close-button it will be both first and
 * last child and last child will be used.
 */
._PopoverDrawer_1xnjd_30:last-child ._CloseButton_1xnjd_9 {
  border-radius:
    0
    0
    0
    var(--border-radius);
  box-shadow: -3px 3px 6px -2px var(--text-muted);
  left: 0;
  translate: -100%;
}

._Backdrop_1xnjd_121 {
  background: rgb(0 0 0 / 25%);
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-duration: var(--default-transition-duration);
  transition-property: opacity;
  transition-timing-function: var(--default-transition-easing);
}

._Backdrop_1xnjd_121[open] {
  opacity: 1;
  pointer-events: all;
}

._Shelf_1xnjd_137 {
  flex: 1;
}
