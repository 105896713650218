import Button from 'form5/react/Button';
import { useState } from 'react';
import { toast } from 'sonner';

import { Callout } from '…/app/common/Callout/Callout.tsx';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Teammate, WorkspaceAdmin } from '…/app/state/states.d.ts';

import type { getSelectedItems } from '…/app/w/workspace/common/DeSelectAll/getSelectedItems.mts';

import classes from './Forms.module.css';

const SHOW_MAX = 6;

type PrimaryEmails = Teammate['emails'][number][];

export function DeleteTeammatesForm({
  teammates,
  onSubmit,
}: {
  teammates: ReturnType<typeof getSelectedItems<WorkspaceAdmin['teammates']>>,
  onSubmit(primaryEmails: PrimaryEmails): Promise<WorkspaceAdmin['teammates']>,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const primaryEmails: PrimaryEmails = [];
  const teammateItems = [];

  for (const { emails: [primaryEmail], profile: { fullName } } of teammates.values()) {
    primaryEmails.push(primaryEmail);
    if (teammates.size <= SHOW_MAX || teammateItems.length < SHOW_MAX) {
      teammateItems.push(
        <div className={classes.Name} key={primaryEmail}>
          {teammates.size > SHOW_MAX && teammateItems.length === (SHOW_MAX - 1)
            ? `and ${(teammates.size + 1) - SHOW_MAX} more...`
            : fullName}
        </div>,
      );
    }
  }

  const teammatesLabel = primaryEmails.length > 1 ? 'teammates' : 'teammate';

  function onRemoveTeammates() {
    setIsSubmitting(true);

    const promise = onSubmit(primaryEmails).finally(() => setIsSubmitting(false));

    toast.promise(promise, {
      error: `Removing ${teammatesLabel} failed`,
      loading: `Removing ${teammatesLabel}`,
      success: `${primaryEmails.length} ${teammatesLabel} removed`,
    });

    return promise;
  }

  return (
    <>
      <h1>Delete {teammatesLabel}</h1>

      <Callout type={Callout.TYPES.WARN}>
        Once teammates are removed, they will no longer have access to this workspace.
      </Callout>

      <p>The following teammates will be removed:</p>

      <div className={classes.EnumeratedUpdateTargets}>{teammateItems}</div>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        onClick={onRemoveTeammates}
        type="submit"
      >Delete
      </SubmitButton>
    </>
  );
}
DeleteTeammatesForm.displayName = 'DeleteTeammatesForm';
