._Button_ku94t_1 {
	font: unset;
	border: none;
	cursor: pointer;
	padding: var(--default-padding);
	text-align: center;
}

._fluid_ku94t_9 { width: 100% }

._Button_ku94t_1[variant="cta"] {
	color: var(--colour-background);
	border-radius: var(--default-border-radius);
	box-shadow: var(--default-boxshadow);
	fill: var(--colour-background);
	text-transform: capitalize;
}
._Button_ku94t_1[variant="cta"][appearance="affirming"] {
	background-color: var(--colour-success);
}
._Button_ku94t_1[variant="cta"][appearance="basic"] {
	background-color: var(--colour-light);
	border-color: var(--colour-medium);
	color: var(--colour-text-deemphasised);
	fill: var(--colour-text-deemphasised);
}
._Button_ku94t_1[variant="cta"][appearance="danger"] {
	background-color: var(--colour-danger);
}
._Button_ku94t_1[variant="cta"][appearance="primary"] {
	background-color: var(--colour-primary);
}
._Button_ku94t_1[variant="cta"][appearance="warning"] {
	background-color: var(--colour-warning);
}
._Button_ku94t_1[variant="cta"] > i[role="img"] {
	font-size: 2em;
}

._Button_ku94t_1[variant="glyph"] {
	background-color: unset;
}
._Button_ku94t_1[variant="glyph"][appearance="danger"] {
	color: var(--colour-danger);
	fill: var(--colour-danger);
}
._Button_ku94t_1[variant="glyph"][appearance="primary"] {
	color: var(--colour-primary);
	fill: var(--colour-primary);
}
._Button_ku94t_1[variant="glyph"][appearance="affirming"] {
	color: var(--colour-success);
	fill: var(--colour-success);
}
._Button_ku94t_1[variant="glyph"][appearance="warning"] {
	color: var(--colour-warning);
	fill: var(--colour-warning);
}

._ButtonGroup_ku94t_60 ._Button_ku94t_1 {
	flex: 1;
}
._ButtonGroup_ku94t_60 > *:not(:last-child) ._Button_ku94t_1,
._ButtonGroup_ku94t_60 > ._Button_ku94t_1:not(:last-child) {
	border-top-right-radius: unset;
	border-bottom-right-radius: unset;
}
._ButtonGroup_ku94t_60 > *:not(:first-child) ._Button_ku94t_1,
._ButtonGroup_ku94t_60 > ._Button_ku94t_1:not(:first-child) {
	border-top-left-radius: unset;
	border-bottom-left-radius: unset;
}
