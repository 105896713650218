import type {
  KeyboardEvent,
  MouseEvent,
} from 'react';

import type { EditMatches } from './EditMatches2.tsx';
import type { Match } from './Match.d.ts';
import { addToPool } from './stateCRUD.mts';


export function onDissolveMatch(
  this: EditMatches,
  { currentTarget: { id: matchId } }: (
    KeyboardEvent<HTMLButtonElement & { id: Match['id'] }>
    | MouseEvent<HTMLButtonElement & { id: Match['id'] }>
  ),
) {
  this.setState({ isDirty: true });

  addToPool(this.state.groups.get(matchId)!.optins, this.setState.bind(this));

  this.setState(({ deletedMatches, groups }) => {
    const uDeletedMatches = new Set(deletedMatches);
    uDeletedMatches.add(matchId);

    const uGroups = new Map(groups);
    uGroups.delete(matchId);

    return {
      deletedMatches: uDeletedMatches,
      groups: uGroups,
    };
  });

  // This won’t be handled in MatchesForm because
  // the group has already been removed from `activeGroups`.
  this.lonelyGroups.delete(matchId);
}
