import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { onError } from '../errors/errors.state.mts';

import { workspacesState } from './workspaces.state.mts';
import { UPDATE_WORKSPACE_SETTINGS } from './updateWorkspaceSettings.gql.mjs';

interface EditableWorkspaceSettings {
  backgroundColor: string,
  foregroundColor: string,
}

export function updateWorkspaceSettings(workspaceId: ObjectId, settings: EditableWorkspaceSettings) {
  return gqlOperation<{ settings: EditableWorkspaceSettings }>(UPDATE_WORKSPACE_SETTINGS, {
    settings,
    workspaceId,
  })
    .then((result) => {
      Object.assign(workspacesState, result);

      return result;
    })
    .catch(onError);
}
