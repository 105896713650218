import type { HTTPError } from 'ky';
import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { log } from '…/app/logger.mts';

import { ErrorScreen } from './ErrorScreen.tsx';


/**
 * ReactRouter’s `Route.ErrorBoundary` is not compliant with React’s ErrorBoundary, so we have to
 * have a separate component to accomodate RR’s re-invented wheel.
 */
export function RouterErrorBoundary() {
  const error = useRouteError() as AggregateError & { code?: number };
  const {
    message,
    errors,
    stack,
  } = error;
  const status = errors?.length ? errors[0].response?.status : error.code;

  useEffect(() => {
    log.error({
      errors: { ...errors?.map<HTTPError[]>((err) => err.message) },
      message,
      stack: stack?.replace(/^\s+/, ''),
    });
  }, [error]);

  return (
    <ErrorScreen
      code={status}
      errors={errors}
      message={message}
      stack={stack}
    />
  );
}
RouterErrorBoundary.displayName = 'RouterErrorBoundary';
