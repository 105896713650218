import {
  Await,
  Navigate,
  defer,
  useLoaderData,
} from 'react-router-dom';

import { logout } from '…/app/state/user/logout.mts';


export function LogoutPage() {
  const { loggedOut } = useLoaderData() as {loggedOut: unknown};

  return (
    <Await resolve={loggedOut}>
      {() => <Navigate to="/" />}
    </Await>
  );
}
LogoutPage.displayName = 'LogoutPage';


const handle = { showNav: false };
function loader() {
  return defer({ loggedOut: logout() });
}

export {
  LogoutPage as Component,
  handle,
  loader,
};
