export default {
  "PanelledForm": "_PanelledForm_1lyis_1",
  "Panel": "_Panel_1lyis_1",
  "PanelSection": "_PanelSection_1lyis_49",
  "SplitPanel": "_SplitPanel_1lyis_55",
  "LeftButton": "_LeftButton_1lyis_61",
  "RightButton": "_RightButton_1lyis_65",
  "Fieldset": "_Fieldset_1lyis_69",
  "FieldsetHeading": "_FieldsetHeading_1lyis_74",
  "RemovableSet": "_RemovableSet_1lyis_81",
  "InvertedToggle": "_InvertedToggle_1lyis_95",
  "ButtonPending": "_ButtonPending_1lyis_113",
  "ManyOptions": "_ManyOptions_1lyis_119",
  "PendingDeletion": "_PendingDeletion_1lyis_124"
};import "ni:sha-256;wuFBuDe6dCV9LCXibtwT4jDIbipIQvhLdHZ_CqxrYes";