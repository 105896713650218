._Dialog_ikjuw_1 {
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 50ch;
  min-width: 33vw;
  opacity: 0;
  padding: 2em;
  pointer-events: none;
  position: fixed;
  transition-duration: var(--transition-duration-controls);
  transition-property: opacity;
}

@media (width <= 960px) {
  ._Dialog_ikjuw_1 {
    width: calc(100% - 2 * var(--grid-gutter));
  }
}

._Dialog_ikjuw_1[open] {
  opacity: 1;
  pointer-events: all;
}

._Dialog_ikjuw_1:not(._Backdropped_ikjuw_29)::backdrop {
  display: none;
}

._Backdropped_ikjuw_29::backdrop {
  background: black; /* css variables are unavailable here for some reason 😵‍💫 */
  opacity: 0.3;
}

._CloseButton_ikjuw_38 {
  position: absolute;
  right: 0;
  top: 0;
}

/* .Dialog button,
.Dialog [role="group"] {
  place-self: end;
} */
