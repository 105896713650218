import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import Button from 'form5/react/Button';

import { useAdminRole } from '…/app/common/permissions/useRoles.mjs';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { formatDate } from '…/app/common/dateUtils.mts';

import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';

import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import classes from './Section.module.css';
import parentClasses from './WorkspaceIntegrationsPage.module.css';

const {
  arrayOf,
  func,
  shape,
  string,
} = PropTypes;

export function ApiKeysSection({
  className,
  sessions,
  setCreateApiKeyDialogOpen,
  setRevokeApiKey,
}) {
  const userIsAdmin = useAdminRole();
  const isPublicApiEnabled = useFeatureFlag([FLAGS.workspacePublicAPI]);

  return (
    <section className={clsx('Card', className)}>
      <h2>API Keys</h2>

      <div className={clsx(classes.SplitContent6040, 'StackableSplit')}>
        <div className={parentClasses.SectionSplit}>
          {!isPublicApiEnabled
            ? <p>You currently don’t have access to use API Keys.</p>
            : (
              <>
                <p>
                  Your API Keys are listed below. The API Key will automatically have the same
                  rights and permissions as the user that created it. Please note that we do not display
                  your secret API keys again after you generate them.
                </p>

                {sessions.map((session) => (
                  // NOTE: this is tabular data (it should use `<table>`/`<Table>`).
                  <div className={classes.Row} key={`api-key-${session.id}`}>
                    <div>
                      <strong>{session.name}</strong>

                      <span className={parentClasses.SubText}>
                        Created: {formatDate(session.createdAt, null, true)}
                      </span>
                    </div>

                    <button
                      className={parentClasses.IconButtonDelete}
                      disabled={!userIsAdmin}
                      onClick={() => setRevokeApiKey(session)}
                    >
                      <IIcon name="trash" />
                    </button>
                  </div>
                ))}

                {!sessions.length && (
                  <div className={classes.Row}>
                    <p className="muted">You have no API keys yet.</p>
                  </div>
                )}
              </>
            )
          }

          <div style={{ marginTop: '1rem' }}>
            <Popover
              anchor={(props) => (
                <Button
                  {...props}
                  disabled={!userIsAdmin}
                  onClick={() => setCreateApiKeyDialogOpen(true)}
                >Create API Key
                </Button>
              )}
            >Only Admins can create and revoke API Keys.
            </Popover>
          </div>
        </div>

        <div className={clsx(parentClasses.SectionSplit, parentClasses.Instructions)}>
          <h3>Documentation</h3>

          <p>
            To view all the endpoints we have available please visit

            {' '}

            <a
              href="https://docs.orbiit.ai"
              rel="noreferrer"
              target="_blank"
            >https://docs.orbiit.ai
            </a>
          </p>

          <h3>Key Security</h3>

          <p>
            <strong>Treat your API Keys like passwords.</strong> Do not share your API key with others, or expose
            it in the browser or other client-side code. In order to protect the security of your account,
            Orbiit may also automatically disable any API key that we’ve found has leaked publicly.
          </p>
        </div>
      </div>
    </section>
  );
}

ApiKeysSection.propTypes = {
  sessions: arrayOf(shape({
    createdAt: string.isRequired,
    id: string.isRequired,
    name: string.isRequired,
  })).isRequired,
  setCreateApiKeyDialogOpen: func.isRequired,
  setRevokeApiKey: func.isRequired,
};

ApiKeysSection.displayName = 'ApiKeysSection';
