import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';
import type { PaginatedRecords, PaginationProps } from '…/app/common/pagination/pagination.d.ts';
import type { Sort } from '…/app/common/Table/Table.tsx';

import { GET_ENGAGEMENT_OPTINS } from './getOptInForms.gql.mjs';

import type { OptInsFilters } from './FiltersForm.tsx';
import type { OptInForm } from './optin-forms.d.ts';

interface ResponseData {
  workspace: {
    engagement: {
      optInForms: PaginatedRecords<OptInForm>,
    }
  }
}

export function getEngagementOptIns(
  workspaceId: ObjectId,
  engagementId: ObjectId,
  {
    after,
    before,
    filters,
    first,
    last,
    search,
    sort,
  }: PaginationProps & {
    search?: string,
    filters?: OptInsFilters,
    sort?: Sort<SortableFields>,
  } = {},
) {
  const numFilters = getFilterCount(filters);
  return gqlOperation<ResponseData>(GET_ENGAGEMENT_OPTINS, {
    after,
    before,
    engagementId,
    first,
    last,
    search,
    workspaceId,
    ...(filters && {
      filters,
    }),
    ...(sort && {
      sort: {
        direction: sort.ascending ? 'ASC' : 'DESC',
        field: mapSortableFieldsToGQLConstant[sort.fieldName],
      },
    }),
  }, {
    ...((search || numFilters) && {
      eventData: {
        data: JSON.stringify({
          filters,
          numFilters,
          search,
          workspaceId,
        }),
        name: 'Opt-In Forms Search & Filter',
      },
    }),
  })
    .then((data) => data.workspace.engagement);
}

export const mapSortableFieldsToGQLConstant = {
  'company': 'COMPANY',
  'name': 'NAME',
  'optInDate': 'CREATED_AT',
  'role': 'ROLE',
};

export type SortableFields = keyof typeof mapSortableFieldsToGQLConstant;

export const getFilterCount = (filters: OptInsFilters) => (
  (filters.DATETIME?.length ?? 0)
  + (filters.SELECTED_OPTIONS?.length ?? 0)
  + (filters.STATUS?.length ?? 0)
);
