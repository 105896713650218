import type { HTMLProps } from 'react';
import { clsx } from 'clsx';

import { numberPrecision } from '…/app/common/mathUtils.mts';

import classes from './Progress.module.css';


export function Progress({
  className,
  steps,
  stepsCompleted,
  ...props
}: {
  className?: string,
  steps: number,
  stepsCompleted: number,
} & HTMLProps<HTMLProgressElement>) {
  const completeness = numberPrecision(steps / stepsCompleted, 2);
  return (
    <div
      {...props}
      aria-busy={stepsCompleted < steps}
      className={clsx(classes.Progress, className)}
      role="progressbar"
      style={{ '--completeness': completeness }}
      // @ts-ignore
      value={completeness}
    />
  );
}
Progress.displayName = 'Progress';
