import {
  Navigate,
  Outlet,
  useParams,
} from 'react-router-dom';


/**
 * A temporary wrapper to migrate users with active sessions using a workspace slug.
 * FIXME: remove this after a couple weeks.
 */
export function WorkspaceWrapper() {
  const { workspaceId } = useParams();

  if (workspaceId.length !== 24) return <Navigate to="/w" />;

  return <Outlet />;
}
WorkspaceWrapper.displayName = 'WorkspaceWrapper';

export {
  WorkspaceWrapper as Component,
};
