:root {
  /* Actual brand color palette. */
  --core-dark-blue: #03033B;
  --core-blue: #008CE6;
  --core-turquoise: #3ADADA;
  --core-orange: #FF5936;
  --core-yellow: #FC0;

  /* Color palettes */

  /* Red: https://mdigi.tools/color-shades/#dc2626 */
  --red-lightest: #FFE5DB;
  --red-lighter: #F4BDBD;
  --red-light: #F4BDBD;
  --red-medium: #DC2626;
  --red: #C62020;

  /* Grey:  */

  /* Green: https://mdigi.tools/color-shades/#059669 */
  --green-lightest: #E6FEF7;
  --green-lighter: #B5FDE6;
  --green-medium: #21F8B5;
  --green: #07DE9B;
  --green-dark: #06AD79;

  --ai-text: #9029CF;
  --ai-border-color: rgb(144 41 207 / 25%);
  --ai-background: #E0BEFA;
  --ai-background-light: rgb(144 41 207 / 5%);

  --brand-dark: var(--core-dark-blue);
  --brand-light: white;
  --brand-subtle-light: #EAF0F6;
  --brand-accent-ancillary: #3460DC;
  --brand-accent-primary: var(--core-blue);
  --brand-accent-primary-hover: #0062A0;
  --brand-accent-secondary: var(--core-orange);
  --brand-accent-teriary: var(--core-turquoise);

  --pricing-plan-dark: #1D4ED8; /* FIXME: Check if this is correct, close to pricing-plan-light */
  --pricing-plan: #173EAD;
  --pricing-plan-light: var(--brand-accent-ancillary);
  --pricing-plan-lightest: #F1F5F9; /* Muted. */

  --neutral-lightest: #F8FAFC;
  --neutral-lighter: #F1F5F9;
  --neutral-light: #E2E8F0;
  --neutral-medium: #94A3B8;
  --neutral: #64748B;
  --neutral-dark: #475569;

  /* Signal color for statuses */
  --status-danger: var(--red);
  --status-success-lightest: var(--green-lightest);
  --status-success-lighter: var(--green-lighter);
  --status-success-light: var(--green-medium);
  --status-success: var(--green);
  --status-success-dark: var(--green-dark);
  --status-success-darker: #064E3B;
  --status-info: #4DAFED;
  --status-warning: #D4880F; /** #FFAC27; */

  --border-colour-subtle: #EEE;
  --border-colour-normal: #DEDEDE;
  --border-colour-error: var(--status-danger);
  --border-colour-focus: #B3DDF7;

  --engagement-status-draft: #DEDEDE;
  --engagement-status-review: #E26751;
  --engagement-status-scheduled: #D88BAF;
  --engagement-status-optins: #9870C2;
  --engagement-status-matching: #6F7ECC;
  --engagement-status-matched: #6DA2D1;
  --engagement-status-meeting: #7DD0A4;
  --engagement-status-completed: #6AAD60;

  --box-shadow:
    0 0 4px -1px rgb(0 0 0 / 8%),
    0 4px 5px 0 rgb(0 0 0 / 4%),
    0 1px 10px 0 rgb(0 0 0 / 3%);

  --border-radius: 0.5em;

  --text-muted: #0003;
  --text-neutral: var(--neutral-dark);

  --transition-duration-glyphs: 0.2s;
  --transition-duration-controls: 0.4s;

  --zindex-tooltip: 1;
  --zindex-controls: 2;
  --zindex-menu: 3;
  --zindex-modal: 5;

  /* Gradients */

  --background-selected: linear-gradient(to left, rgb(3 3 59 / 4%), 60%, transparent);
  --background-card: linear-gradient(171.85deg, #F7F7F7 2%, #FFF 20%);
  --box-shadow-card: rgb(0 0 0 / 3%) 0 20px 30px;

  /* form5 */

  --colour-background: var(--brand-light);
  --colour-danger: var(--status-danger);
  --colour-light: var(--brand-subtle-light);
  --colour-medium: var(--border-colour-normal);
  --colour-muted: var(--text-muted);
  --colour-primary: var(--brand-accent-primary);
  --colour-success: var(--brand-accent-teriary);
  --colour-text-deemphasised: var(--text-neutral);
  --colour-warning: var(--status-warning);
  --default-border-radius: var(--border-radius);
  --default-boxshadow:
    0 2px 2px -1px rgb(0 0 0 / 4%),
    0 1px 3px 0 rgb(0 0 0 / 4%),
    0 1px 3px 0 rgb(0 0 0 / 3%);
  --default-padding: 0.8em;
  --default-transition-duration: var(--transition-duration-controls);
  --default-transition-easing: ease-in-out;
  --grid-gutter: 1rem;
  --grid-gutter-half: calc(var(--grid-gutter) / 2);
}
