.IconButton {
  background-color: transparent;
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  color: var(--neutral-dark);
  cursor: pointer;
  margin: 0 0.125rem;
  padding: 0.375rem;
}

.IconButton:hover {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--brand-accent-primary);
  color: var(--brand-accent-primary-hover);
}
