export function BuildStats() {
  const formAppDomain = process.env.FORM_APP_DOMAIN; // eslint-disable-line no-undef
  const builtAt = process.env.ORBIIT_BUILT_AT; // eslint-disable-line no-undef

  if (!builtAt) return null;

  return (
    <div style={{
      bottom: '1rem',
      left: '1rem',
      position: 'fixed',
    }}
    >
      <p style={{
        color: 'rgba(255, 255, 255, 0.15)',
        fontSize: '12px',
        lineHeight: '1.25',
        textAlign: 'left',
      }}
      >
        Built at: {builtAt} UTC

        <br />

        Form App: {formAppDomain}
      </p>
    </div>
  );
}
BuildStats.displayName = 'BuildStats';
