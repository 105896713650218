import _isPlainObject from 'lodash-es/isPlainObject.js';
import type {
  Dispatch,
  SetStateAction,
} from 'react';
import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { log } from '…/app/logger.mts';

import type { Stores } from './app.store.mts';
import { stores } from './app.store.mts';

type Slicer<T> = (data: Stores) => T | Promise<T>;

export function useStore<T>(selector: Slicer<T>) {
  const slice = useRef(selector(stores)); // Cache the slice provided from the Component.
  const [state, setState] = useState<T>(() => settleSlice(slice.current));

  // Leverage `useEffect`’s dependency observation to track changes to the store (which exists
  // outside of React's knowledge).
  useEffect(() => {
    handleUpdates(slice.current, setState);
  }, [slice.current]);

  if (isPromise(state)) {
    log.debug('useStore: state slice is a promise; throwing');
    throw state.then(setState);
  }

  log.debug('useStore: state slice is NOT a promise; returning', state);
  return state;
}

type FieldName = string;
function settleSlice<T>(sliceRef: T) {
  if (isPromise<T>(sliceRef)) {
    log.debug('useStore::settleSlice(): slice is itself a promise (returning)');
    return sliceRef;
  }

  if (isPlainObject(sliceRef)) {
    log.debug('useStore::settleSlice(): slice is a plain object; checking for nested promises');
    /**
     * Initially, track the unsettled promises for the field. When they settle, overwrite the
     * promise with its fulfilled value (since the map is otherwise exactly the same and we don't
     * need the promises anymore—waste not, want not).
     */
    const promises = new Map<FieldName, Promise<T>>();
    for (const [key, val] of Object.entries(sliceRef)) {
      if (isPromise<T>(val)) promises.set(key, val);
    }

    if (promises.size) {
      log.debug(
        `useStore::settleSlice(): slice contains nested promise(s) for “${Array.from(promises.keys()).join('”, “')}”;`,
        'returning promise.',
      );
      return Promise
        .allSettled(promises.values())
        .then(function stitchResultsBackToFields(results) {
          log.debug(
            'useStore::settleSlice(): all nested promises settled;',
            'stitching results back together with their props',
          );
          const keys = Array.from(promises.keys());
          for (const [i, { reason, value }] of results.entries()) {
            if (reason) throw reason;

            promises.set(keys[i], value);
          }

          return {
            ...sliceRef,
            ...Object.fromEntries(promises),
          } as any as T;
        });
    }
  }

  return sliceRef;
}

function isPromise<T>(val: any): val is Promise<T> {
  return typeof val?.then === 'function';
}

/**
 * TypeScript doesn't like providing an async callback to `useEffect`, so wrap it here.
 * @see https://react.dev/reference/react/useEffect#fetching-data-with-effects
 */
async function handleUpdates<T>(
  sliceRef: T,
  setState: Dispatch<SetStateAction<T>>,
) {
  log.debug('useStore: change detected in slice; settling & updating react state.');
  // `settleSlice()` may or may not return a promise, but dealing with that is cumbersome, so merely
  // assume it is (non-promises can also be `awaited`), and let React Suspense cover our back.
  const newState = await settleSlice(sliceRef);
  setState(newState); // Leverage `useState` to trigger Component re-render.
}

function isPlainObject(value: unknown): value is object {
  return _isPlainObject(value);
}
