import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Engagement } from '../../Engagement.d.ts';

import { RESET_TEMPLATE } from './resetTemplate.gql.mjs';


export function resetTemplate({
  engagementId,
  workspaceId,
}: {
  engagementId: ObjectId,
  workspaceId: ObjectId,
}) {
  return gqlOperation<{ engagement: Engagement}>(RESET_TEMPLATE, {
    engagementId,
    workspaceId,
  })
    .then((data) => data.engagement);
}
