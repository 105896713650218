import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type OnSubmit } from 'form5/react/Form';
import { useState } from 'react';
import { toast } from 'sonner';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Teammate } from '…/app/state/states.d.ts';
import { Role } from '…/app/state/Role.mts';

import classes from './Forms.module.css';


export function UpsertTeammateForm({
  teammate: {
    id,
    emails: [email] = new Array(),
    profile: {
      firstName,
      lastName,
    } = {
      firstName: '',
      lastName: '',
    },
    role = Role.OBSERVER,
  } = {},
  onSubmit,
}: {
  teammate?: Partial<Teammate>,
  onSubmit(teammate: Teammate): Promise<void>,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isNew = !id;

  const onUpsert: OnSubmit = (delta, { email: primaryEmail, ...all }) => {
    setIsSubmitting(true);

    const promise = onSubmit({
      // the GQL op requires all fields
      ...all,
      emails: [primaryEmail],
    })
      .finally(() => setIsSubmitting(false));

    toast.promise(promise, {
      error: 'Saving teammate failed',
      loading: 'Saving teammate…',
      success: `Teammate ${id ? 'updated' : 'created'}`,
    });

    return promise;
  };

  return (
    <Form
      name="edit-teammate"
      onSubmit={onUpsert}
    >
      <h1>{isNew ? 'Add' : 'Edit' } teammate</h1>

      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        defaultValue={email}
        fluid
        label="Email"
        name="email"
        placeholder="alex@good.inc"
        readOnly={!isNew}
        required
        type="email"
      />

      {!isNew && ( /* readOnly fields are excluded from form submission */
        <input
          name="email"
          type="hidden"
          value={email}
        />
      )}

      <fieldset className={clsx(classes.Fieldset, 'StackableSplit')} name="profile">
        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          defaultValue={firstName}
          fluid
          label="First name"
          name="firstName"
          placeholder="Alex"
          readOnly={!isNew}
          required
        />

        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          defaultValue={lastName}
          fluid
          label="Last name"
          name="lastName"
          placeholder="Smith"
          readOnly={!isNew}
          required
        />
      </fieldset>

      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        as="select"
        defaultValue={role}
        fluid
        label="Role"
        name="role"
      >
        <option value="ADMIN">Admin</option>

        <option value="EDITOR">Editor</option>

        <option value="OBSERVER">Observer</option>
      </Field>

      <SubmitButton
        appearance={isNew ? Button.APPEARANCES.AFFIRMING : Button.APPEARANCES.PRIMARY}
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        type="submit"
      >Save
      </SubmitButton>
    </Form>
  );
}
UpsertTeammateForm.displayName = 'UpsertTeammateForm';
