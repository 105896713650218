import { rpc } from '…/app/common/rpc/client.mjs';

export type EmailStats = {
  engagement: string,
  engagement_id: string,
  evaluated_at: string,
  category: string,
  count: {
    processed?: number,
    dropped?: number,
    delivered?: number,
    deferred?: number,
    bounce?: number,
    open?: number,
    click?: number,
    human_click?: number,
    unique_click?: number,
    spam_report?: number,
    unsubscribe?: number,
    group_unsubscribe?: number,
    group_resubscribe?: number,
  },
};

export type EmailStatsResponse = {
  events: EmailStats[],
  count: number,
  timestamp: string,
};

export type EmailEvent = {
  email: string,
  event: string,
  lastTimestamp: string,
  eventsCount: number,
}

export type EmailEventsResponse = {
  events: EmailEvent[],
  count: number,
  timestamp: string,
};

export function getEngagementEmailStats({
  engagementId,
}: {
  engagementId: string,
}): Promise<EmailStatsResponse> {
  return rpc
    .post('actions/get-engagement-stats', {
      headers: {
        'Orbiit-Event-Data': JSON.stringify({ engagementId }),
        'Orbiit-Event-Name': 'Loading Engagement Email Stats',
      },
      json: {
        engagementId,
        version: 2,
      },
    })
    .json();
}

/**
 * Allows us to get all the email events for a specific list of event types for the workspace.
 *
 * NOTE: Hardcoded. We should set this to the open-date.
 */
export function getEngagementEmailEvents({
  engagementId,
  eventType,
  startTime = '2021-01-01T15:01:21.000Z',
  workspaceId,
}: {
  engagementId: string,
  eventType: string,
  startTime: string,
  workspaceId: string,
}) {
  return rpc
    .post('actions/get-email-events-in-window', {
      json: {
        endTime: (new Date()).toISOString(),
        engagementId,
        eventTypes: [eventType],
        startTime,
        workspaceId,
      },
    }).json();
}

/* eslint sort-keys: "off" */
export const MOCK_ENGAGEMENT_STATS = {
  count: 2,
  events: [
    {
      category: 'engagement-invite',
      count: {
        'processed': 1233,
        'dropped': 1,
        'delivered': 1151,
        'deferred': 14,
        'bounce': 96,
        'open': 889,
        'click': 177,
        'human_click': 177,
        'unique_click': 90,
        'spam_report': 0,
        'unsubscribe': 0,
        'group_unsubscribe': 0,
        'group_resubscribe': 0,
      },
      engagement: '65246d938b9e947649cb88e6',
      engagement_id: '65246d938b9e947649cb88e6',
      evaluated_at: '2024-01-18T10:00:42.799986+00:00',
    },
    {
      category: 'engagement-chaser',
      count: {
        'processed': 1103,
        'dropped': 99,
        'delivered': 1102,
        'deferred': 0,
        'bounce': 1,
        'open': 818,
        'click': 108,
        'human_click': 108,
        'unique_click': 53,
        'spam_report': 0,
        'unsubscribe': 0,
        'group_unsubscribe': 0,
        'group_resubscribe': 0,
      },
      engagement: '65246d938b9e947649cb88e6',
      engagement_id: '65246d938b9e947649cb88e6',
      evaluated_at: '2024-01-18T10:00:42.799986+00:00',
    },
  ],
  timestamp: '2024-01-18T10:07:28.173403+00:00',
};

export const MOCK_ENGAGEMENT_EMAIL_EVENTS = {
  count: 2,
  events: [
    {
      email: 'timmy@orbiit.ai',
      event: 'bounce',
      eventsCount: 2,
      lastTimestamp: '2023-10-10T06:01:04+00:00',
    },
    {
      email: 'james@orbiit.ai',
      event: 'bounce',
      eventsCount: 1,
      lastTimestamp: '2023-10-10T06:01:01+00:00',
    },
  ],
  timestamp: '2024-01-18T10:07:34.241971+00:00',
};
