._Nameplate_1jnsm_1 {
  background-color: var(--neutral-lightest);
  text-align: center;
}

._Nameplate_1jnsm_1,
._Card_1jnsm_7 {
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  padding: var(--default-padding);
}

._Card_1jnsm_7 {
  align-items: baseline;
  background-color: var(--brand-subtle-light);
  display: grid;
  gap: var(--grid-gutter);
  grid-auto-flow: column;
}

._MatchCard_1jnsm_21 {
  justify-content: space-between;
}

._OptinCard_1jnsm_25 {
  grid-template-columns: 10ch auto max-content;
}

._CardSubject_1jnsm_29 {
  font-weight: bold;
}
