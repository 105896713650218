import { clsx } from 'clsx';

import { templateIcons } from './defaults/icons.mts';

import type { RecentTopicTemplate, TopicTemplate } from './EngagementTemplate.d.ts';
import type { EngagementQuestion } from '…/app/w/workspace/engagements/Engagement.d.ts';
import type { QuestionOption } from '…/app/w/workspace/engagements/engagement/setup/participant-forms/ParticipantQuestions.d.ts';

import templateClasses from './EngagementTemplateSetupPage.module.css';

const SELECTABLE_QUESTIONS = new Set(['SingleSelectStep', 'MultiSelectStep']);

export function TemplateDetails({
  iconKey,
  blurb,
  description,
  title,
  optInQuestions,
}: TopicTemplate | RecentTopicTemplate) {
  const selectableQuestions = optInQuestions.filter(({
    question,
    stepName,
    isDisabled,
  }) => {
    if (!SELECTABLE_QUESTIONS.has(question)) return false;
    if (isDisabled) return false;
    if (stepName === 'timezone' || stepName === 'fallback') return false;
    return true;
  });

  const Icon = templateIcons[iconKey] ?? templateIcons.tshirt;
  return (
    <>
      <header className={templateClasses.Header}>
        <Icon className={clsx(templateClasses.CardIcon, templateClasses.DetailsIcon)} />

        <h1>{title}</h1>

        <p>{description}</p>
      </header>

      {selectableQuestions.length > 0 && (
        <>
          <h3>
            Matching Questions{' '}

            <span className="muted note">You can edit this later</span>
          </h3>

          <p>We’ll use answers to these questions to match your participants.</p>

          {selectableQuestions.map((question: EngagementQuestion) => (<MatchingQuestion key={`${question.stepName}`} question={question} />))}
        </>
      )}

      {blurb && (
        <>
          <h2>Sample <span className="muted note">You can edit this later</span></h2>

          <pre className="code Well">{blurb}</pre>
        </>
      )}
    </>
  );
}
TemplateDetails.displayName = 'TemplateDetails';

function MatchingQuestion({
  question,
}: {
  question: EngagementQuestion
}) {
  const { props } = question;
  if (!props) return null;

  const options: QuestionOption[] = props.options.filter(
    (option: QuestionOption) => !option?.isDisabled,
  );
  if (options.length < 2) return null;

  return (
    <div className={templateClasses.MatchingQuestionBlock}>
      <h4>{props.title}</h4>

      <div className={templateClasses.MatchingQuestionOptions}>
        {options[0].text}{' '}

        and{' '}

        {options.length - 1} other options.
      </div>
    </div>
  );
}
MatchingQuestion.displayName = 'MatchingQuestion';
