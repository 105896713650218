import { HTTPError } from 'ky';

import { rpc } from '…/app/common/rpc/client.mts';


export function login({ email, redirectUri }: { email: string, redirectUri: URL['href'] }) {
  return rpc
    .post('auth/login', {
      json: {
        email,
        redirectUri,
      },
    })
    .json<{ loginLink?: URL['href'] }>()
    .catch(async (err: AggregateError | HTTPError) => {
      let error = err;

      if (err instanceof HTTPError) {
        const rsp = err.response;
        try {
          error = await rsp.json();
        } catch { /* Response had no body */ }
      }

      throw error.message;
    });
}
