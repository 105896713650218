export default {
  "Content": "_Content_15gan_1",
  "MeterContainer": "_MeterContainer_15gan_10",
  "MeterBar": "_MeterBar_15gan_14",
  "MeterLabels": "_MeterLabels_15gan_22",
  "MeterLeft": "_MeterLeft_15gan_33",
  "MeterRight": "_MeterRight_15gan_38",
  "Chip": "_Chip_15gan_43",
  "ChipPrimary": "_ChipPrimary_15gan_52",
  "BottomParagraph": "_BottomParagraph_15gan_57",
  "Stat": "_Stat_15gan_66"
};import "ni:sha-256;lQH3Wq4pOs8J5ex77Jq0Uu0Yzfr_3MBSGMoaYB36_p0";