.TemplateVariables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: 0.25rem;
}

.Variable {
  font-family: monospace;
  font-size: 0.8em;
  padding: 0.25rem 0.4rem;
}
