.SettingsHeader {
  background-color: var(--brand-light);
  box-shadow: var(--box-shadow);
  padding: var(--grid-gutter);
  padding-bottom: unset;
  position: relative; /* so box-shadow is above SettingsPanel (page content) */
}

.SettingsTabs {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}
