import type { OrbiitAPI } from '…/app/state/api.d.ts';
import { transformListToMap } from '…/app/common/transformListToMap.mjs';
import type { PaginatedRecords } from '…/app/common/pagination/pagination.d.ts';

import type { Match, OptIn } from './Match.d.ts';


export function transformMatchesToGroups({ nodes: rawNodes, ...rest }: PaginatedRecords<OrbiitAPI.Match>) {
  const groups = new Map<Match['id'], Match>();

  for (const { optInForms, ...match } of rawNodes) {
    groups.set(match.id, {
      ...match,
      optins: transformListToMap<OptIn>(optInForms),
    });
  }

  return {
    nodes: groups,
    ...rest,
  };
}
