._FlexRow_3ob8k_1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
}

._Bureau_3ob8k_9 {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

._TableHeader_3ob8k_13 {
  margin-bottom: 2rem;
}

._Table_3ob8k_13 {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

._Table_3ob8k_13 td {
  vertical-align: middle;
}

._Controls_3ob8k_26 {
  align-items: center;
  display: flex;
  gap: 1ch;
}

._Controls_3ob8k_26 a[target="_blank"]::after {
  margin-top: -1em;
}

._ContextualMenu_3ob8k_36 {
  display: flex;
  flex-direction: column;
}

._FiltersDrawer_3ob8k_41 {
  background: var(--neutral-lightest);
  padding: 0;
}
