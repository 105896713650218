import { ArcElement, Chart } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { clsx } from 'clsx';

import { MeterWithLabels } from '…/app/common/Meter/MeterWithLabels.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import MetricCardHeader from '../MetricCardHeader/MetricCardHeader.tsx';
import { calcRotation } from './calcRotation.mts';

import classes from './NetPromoterScore.module.css';


Chart.register(ArcElement); // https://stackoverflow.com/a/70098543

export function NetPromoterScore({
  detractors,
  passives,
  promoters,
  value: npsValue,
}: {
  detractors: Int,
  passives: Int,
  promoters: Int,
  value: Int,
}) {
  const totalMembers = detractors + passives + promoters;

  return (
    <div className={classes.Content}>
      <MetricCardHeader
        description="Net Promoter Score (NPS) is a measure of how likely your members are to recommend these
          engagements to their peers. It is obtained using the question: “Based on your experience, how likely are you to recommend this peer conversation?”"
        title="Net Promoter Score"
      />

      <div className="SplitContent StackableSplit">
        <div className={clsx('EvenSplit', classes.DoughnutContainer)}>
          <Doughnut
            data={DOUGHNUT_DATA}
            options={DOUGHNUT_OPTIONS}
          />

          <div
            className={classes.Score}
            style={{ '--rotation': calcRotation(npsValue) }}
          >
            {npsValue}
          </div>

          <div className={clsx('SplitContent', classes.ScaleLabels)}>
            <span className={classes.ScaleStart} style={{ '--color': NPS_COLORS.detractors }}>-100</span>

            <span className={classes.ScaleEnd} style={{ '--color': NPS_COLORS.promoters }}>+100</span>
          </div>
        </div>

        <div className={clsx("EvenSplit", classes.NPSExpli)}>
          <Popover
            anchor={(props) => (
              <MeterWithLabels count={promoters} percentage={(promoters /totalMembers * 100)} {...props}>Promoters</MeterWithLabels>
            )}
            type={Popover.TYPES.TOOLTIP}
          >
            Members who responded with a score of <strong>9 or 10</strong>.
          </Popover>

          <Popover
            anchor={(props) => (
              <MeterWithLabels count={passives} percentage={(passives /totalMembers * 100)} {...props}>Passives</MeterWithLabels>
            )}
            type={Popover.TYPES.TOOLTIP}
          >
            Members who responded with a score of <strong>7 or 8</strong>.
          </Popover>

          <Popover
            anchor={(props) => (
              <MeterWithLabels count={detractors} percentage={(detractors /totalMembers * 100)} {...props}>Detractors</MeterWithLabels>
            )}
            type={Popover.TYPES.TOOLTIP}
          >
            Members who responded with a score of <strong>0 to 6</strong>.
          </Popover>
        </div>
      </div>

      <p className={classes.OrbiitCallout}>Orbiit-wide NPS is <strong>87</strong></p>
    </div>
  );
}
NetPromoterScore.displayName = 'NetPromoterScore';

const NPS_COLORS = {
  detractors: '#C4E1F4',
  passives: '#81CAF9',
  promoters: '#008CE5',
};

const DOUGHNUT_DATA = {
  datasets: [
    {
      backgroundColor: new Array(
        NPS_COLORS.detractors,
        NPS_COLORS.passives,
        NPS_COLORS.promoters,
      ),
      borderWidth: 0,
      circumference: 180,
      data: [50, 25, 25],
      hoverOffset: 2,
      label: 'NPS',
      rotation: 270,
    },
  ],
  labels: ['Promoters', 'Passives', 'Detractors'],
};

const DOUGHNUT_OPTIONS = {
  aspectRatio: 1.643,
  maintainAspectRatio: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    tooltip: { enabled: false },
  },
  responsive: true,
};
