/* eslint-disable */
export const ENGAGEMENT_STEPS = ['Set-Up', 'Opt-Ins', 'Matches', 'Feedback', 'Dashboard'];

export const ENGAGEMENT_STATUS_STEP = {
  DRAFT: 0,
  REVIEW: 0,
  SCHEDULED: 1,
  OPTINSOPEN: 1,
  // Show opt-in's when the form is closed but there are no matches
  MATCHING: 1,
  MATCHED: 3,
  // Show matches when there is no feedback. If there is feedback show feedback.
  MEETING: 3,
  COMPLETED: 3,
};

export const EDIT_STEPS = {
  EDIT_INVITE_EMAIL: 0,
  EDIT_REMINDER_EMAIL: 1,
  EDIT_CONVERSATION_GUIDE: 2,
  EDIT_SIGNUP_FORM: 3,
  EDIT_FEEDBACK_FORM: 4,
};

export const PRODUCT_TYPES = {
  ONE_TO_ONE: 'ONE_TO_ONE',
  GROUP: 'GROUP',
  TOP_N: 'TOP_N',
  // NOTE: Experimental product type, we won't support creating this in the platform. This
  // is purely there to support old engagements in large workspaces that used this.
  MENTORSHIP: 'MENTORSHIP',
} as const;
export const PRODUCT_TYPES_DB_DICT = {
  ONE_TO_ONE: 'one-to-one',
  GROUP: 'group',
  TOP_N: 'top-n',
  // NOTE: See above: Experimental product type
  MENTORSHIP: 'mentorship',
} as const;
export type PRODUCT_TYPE = typeof PRODUCT_TYPES[keyof typeof PRODUCT_TYPES];
export type PRODUCT_TYPE_DB = typeof PRODUCT_TYPES_DB_DICT[keyof typeof PRODUCT_TYPES_DB_DICT];

// The user-friendly names for the various product types.
export const PRODUCT_TO_TYPE_DICT = {
  [PRODUCT_TYPES.ONE_TO_ONE]: '1-to-1',
  [PRODUCT_TYPES.GROUP]: 'Group',
  [PRODUCT_TYPES.TOP_N]: 'Recommendation',
  // NOTE: See above: Experimental product type
  [PRODUCT_TYPES.MENTORSHIP]: 'Mentorship',
};

// TODO: Rename to question types.
export const QUESTION_STEP_NAMES = {
  // Generic
  SINGLE_SELECT_STEP: 'SingleSelectStep',
  MULTI_SELECT_STEP: 'MultiSelectStep',
  // Opt-In Form specific
  PROFESSIONAL_STEP: 'SpecificProfessionalStep',
  PHONE_STEP: 'SpecificPhoneStep',
  // Feedback Form specific
  FREE_TEXT_STEP: 'GenericTextStep',
  RATING_STEP: 'GenericRatingStep',
  NPS_STEP: 'SpecificNPSStep',
};
/* eslint-enable */

/* eslint-disable */
export const SELECTABLE_STEPS_SET = new Set([QUESTION_STEP_NAMES.SINGLE_SELECT_STEP, QUESTION_STEP_NAMES.MULTI_SELECT_STEP]);
/* eslint-enable */

/** As a map of question.stepName => data for formatting */
export const QUESTION_DATA_OVERRIDES = {
  advice: { header: 'Member Advice'}, // Feedback Form
  company_size: { header: 'Company Size' }, // Opt-In Form
  company_stage: { header: 'Company Stage' }, // Opt-In Form
  decision: { header: 'Better Decision' }, // Feedback Form
  experience: { header: 'Years of Experience' }, // Opt-In Form
  fit: { header: 'Match Rating' }, // Feedback Form
  frequency: {
    header: 'Member Preference: Frequency',
    subHeader: 'Allows you to capture the desired frequency at which members would like to be matched',
  }, // Feedback Form
  phone: { header: 'Member Phone Number' }, // Opt-In Form
  product: {
    header: 'Member Preference: Type of Experience',
    subHeader: 'Helps you better understand what type of connection to offer to your members.',
  }, // Feedback Form
  professional: { header: 'Professional Information (Role & Company Name)' }, // Opt-In Form
  recommend: { header: 'NPS' }, // Feedback Form
  reconnect: { header: 'Member Match Reconnect' }, // Feedback Form
  // TODO: ORBPROD-33 - Allow for making it a generic social step.
  social: { header: 'LinkedIn Profile' }, // Opt-In Form
  testimonial: { header: 'Member Testimonial'}, // Feedback Form
  timezone: { header: 'Time Zone' }, // Opt-In Form
  topics: { header: 'Discussion Topics' }, // Feedback Form
};

export const RESOURCES_URL = 'https://support.orbiit.ai';

export const AUDIENCE_SEARCH_OPTIONS = {
  ignoreLocation: true,
  includeScore: true,
  keys: ['name'],
};
