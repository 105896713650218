import * as ReactDOM from 'react-dom';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { routes } from './routes.tsx';
import { segmentSetup } from './segmentSetup.mts';


Helmet.displayName = 'Helmet';
RouterProvider.displayName = 'RouterProvider';

const segmentSnippet = segmentSetup({
  apiKey: process.env.SEGMENT_API_KEY, // eslint-disable-line no-undef
  env: process.env.NODE_ENV, // eslint-disable-line no-undef
});

/* eslint-disable-next-line react/no-deprecated */
ReactDOM.render(
  <>
    <RouterProvider router={createBrowserRouter(routes)} />

    {!!segmentSnippet && (
      <Helmet>
        <script name="segment">{segmentSnippet}</script>
      </Helmet>
    )}
  </>,
  document.getElementById('App'),
);
