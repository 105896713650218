._EngagementSidebar_49m4j_1 {
  background-color: var(--brand-dark);
  color: var(--brand-light);
  display: flex;
  flex-direction: column;
  font-size: 2em;
  gap: var(--grid-gutter);
  padding: 0.5rem;
}

._EngagementSidebarIcon_49m4j_11 {
  border-radius: 7px;
  color: inherit;
  padding: 4px 10px;
}

._EngagementSidebarIcon_49m4j_11:hover {
  background: rgb(255 255 255 / 10%);
}

._EngagementSidebarIcon_49m4j_11[disabled] {
  opacity: 0.7;
  pointer-events: none;
}

/* stylelint-disable-next-line selector-class-pattern */
.active._EngagementSidebarIcon_49m4j_11 {
  background: rgb(255 255 255 / 10%);
  color: var(--brand-accent-secondary);
}
