._Tabs_sqece_1 {
  align-items: end;
  display: flex;
  flex-direction: row;
}

/* extend the bottom border the rest of the width */
._Tabs_sqece_1[appearance="panelled"]::before,
._Tabs_sqece_1[appearance="panelled"]::after {
  border-bottom: 2px solid var(--neutral-medium);
  content: "";
  flex: 1;
}

._Tab_sqece_1 {
  border-bottom-style: solid;
  padding: 0.75rem 1rem 0.5rem;
}

._Tab_sqece_1:hover,
._Tab_sqece_1[class~="active"] {
  border-bottom: 2px solid;
  color: initial;
}

._Tab_sqece_1[class~="active"] {
  cursor: default;
  font-weight: 700;
}

._Tabs_sqece_1[appearance="panelled"] ._Tab_sqece_1 {
  border-bottom-color: var(--neutral-medium);
  border-bottom-width: 2px;
}

._Tabs_sqece_1[appearance="header"] ._Tab_sqece_1 {
  border-bottom-color: transparent;
  border-bottom-width: 4px;
  color: initial;
}

._Tabs_sqece_1[appearance="header"] ._Tab_sqece_1:hover,
._Tabs_sqece_1[appearance="header"] ._Tab_sqece_1[class~="active"] {
  border-bottom-color: var(--brand-accent-primary);
}

._Tabs_sqece_1[appearance="panelled"] ._Tab_sqece_1:hover,
._Tabs_sqece_1[appearance="panelled"] ._Tab_sqece_1[class~="active"] {
  background: var(--neutral-lightest);
  border-bottom-color: var(--brand-dark);
}
