import type { OrbiitAPI } from '…/app/state/api.d.ts';

import type { WorkspaceAdmin } from '../states.d.ts';

import { generateUserDisplayName } from '…/app/deprecated/utils/shared/formatting/user.mts';


export function gotTeammates(teammates: OrbiitAPI.Teammate[]) {
  const processedTeammates: WorkspaceAdmin['teammates'] = new Map();

  for (
    const {
      role,
      user,
      userId,
    } of teammates
  ) {
    processedTeammates.set(userId, {
      emails: user.emails,
      id: userId,
      profile: {
        ...user.profile,
        fullName: generateUserDisplayName(user, user.emails[0]),
      },
      role,
    });
  }

  return processedTeammates;
}
