._TimelineCard_79gas_1 {
  align-items: center;
  border-radius: 3px;
  color: inherit;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.75rem 1rem;
  position: relative;
  text-decoration: none;
}

._TimelineCard_79gas_1:hover {
  background-color: var(--neutral-lightest);
}

._IsSet_79gas_17 {
  background-color: var(--brand-light);
}

._IsUnset_79gas_21 {
  align-items: center;
  border: 1px dotted var(--neutral-medium);
  color: var(--neutral-medium);
  height: 3.5em;
  justify-content: center;
}

._DateTimes_79gas_29 {
  display: flex;
  flex: 0 0 10ch; /* 8ch = 1 icon, 2 numbers in day, 1 space, 3–4 letters in month, 1 trailing comma */
  flex-direction: column;
  font-weight: 500;
  gap: inherit;
}

._Divider_79gas_37 {
  background-color: var(--brand-subtle-light);
  border-radius: 1em;
  content: "";
  flex: none;
  height: 100%;
  width: 2px;
}

._DateTime_79gas_29 {
  align-items: baseline;
  display: flex;
  font-size: 0.875em;
  gap: 1ch;
}

._Copy_79gas_53 {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

._Title_79gas_59 {
  text-transform: capitalize;
}

._Blurb_79gas_63 {
  font-size: 0.875em;
  line-height: 1.5;
}

._Title_79gas_59,
._Blurb_79gas_63 {
  margin: 0;
}
