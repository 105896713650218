import { clsx } from 'clsx';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SerialSet, useRefinementParams } from '…/app/common/refinement/useRefinementParams.mts';
import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';
import { OrbiitLoader } from '…/app/common/OrbiitLoader/OrbiitLoader.jsx';
import { getNow } from '…/app/common/dateUtils.mts';
import { OptionsCard } from '…/app/w/workspace/common/OptionsMetricCard/OptionsMetricCard.jsx';

import { useStore } from '…/app/state/useStore.mts';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { CardsSet } from '…/app/w/workspace/common/CardsSet/CardsSet.tsx';

import { DashboardFilters } from './DashboardFilters/index.jsx';
import { MetricsCard } from './MetricsCard/index.jsx';
import { getAnalytics } from './getAnalytics.mts';

import {
  baseMetrics,
  formatAdvancedMetricsCards,
  formatEngagementCards,
  formatFeedbackQuestionCards,
  formatOptInQuestionCards,
  formatOverviewCards,
} from './formatMetrics.mts';

import styles from './Dashboard.module.css';


const initialFromDate = getNow()
  .minus({ months: 3 })
  .toISODate();
const initialUntilDate = getNow()
  .toISODate();

export function WorkspaceAnalyticsDashboard() {
  const { workspaceId } = useParams();
  const { audiences } = useStore((data) => data.workspaces[workspaceId]);

  const [metrics, setMetrics] = useState(baseMetrics);
  const [analyticsErrors, setAnalyticsErrors] = useState(new Array());
  const [areAnalyticsLoading, setAreAnalyticsLoading] = useState(true);

  const { filters: [filters, setFilters] } = useRefinementParams({
    filters: {
      audienceIds: new SerialSet(audiences.keys()),
      fromDate: initialFromDate,
      searchQuery: '',
      untilDate: initialUntilDate,
    },
  });

  useEffect(() => {
    getAnalytics({
      filters: {
        audienceIds: [...filters.audienceIds],
        fromDate: filters.fromDate,
        productTypes: new Array(0), // Unused but required by the microservice
        searchQuery: filters.searchQuery,
        untilDate: filters.untilDate,
      },
      workspaceId,
    })
      .then(({ insights }) => setMetrics(insights))
      .catch(setAnalyticsErrors)
      .finally(() => {
        setAreAnalyticsLoading(false);
      });
  }, [filters]);

  handlePageErrors('Analytics', analyticsErrors);

  if (areAnalyticsLoading) return (<OrbiitLoader />);

  const overviewCards = formatOverviewCards(metrics);
  const engagementCards = formatEngagementCards(metrics);
  const optInQuestionCards = formatOptInQuestionCards(metrics);
  const feedbackQuestionCards = formatFeedbackQuestionCards(metrics);
  const advancedMetricsCards = formatAdvancedMetricsCards(metrics);

  return (
    <main className={clsx('ContentContainer', styles.AppContent)}>
      <h1>
        <IIcon name="bar chart" /> Analytics
      </h1>

      <DashboardFilters
        filters={filters}
        onApply={setFilters}
      />

      <section className={styles.MetricsCards}>
        {!metrics.engagementCount
          ? (<p>No engagements found matching filters.</p>)
          : (
            <>
              <CardsSet title="Core Metrics">
                {overviewCards.map((data) => (
                  <MetricsCard
                    {...data}
                    key={data.title}
                    prominence={MetricsCard.PROMINENCES.SIMPLE}
                  />
                ))}
              </CardsSet>

              <CardsSet title="Engagement Overview">
                {engagementCards.map((data) => (
                  <MetricsCard
                    {...data}
                    key={data.title}
                    prominence={MetricsCard.PROMINENCES.SIMPLE}
                  />
                ))}
              </CardsSet>

              <CardsSet title="Opt-In Form Questions">
                {optInQuestionCards.map(data => (
                  <OptionsCard
                    className={styles.OptionsCard}
                    key={`${data.id}-card`}
                    {...data}
                  />
                ))}
              </CardsSet>

              <CardsSet title="Feedback Form Questions">
                {feedbackQuestionCards.map(data => (
                  <OptionsCard
                    className={styles.OptionsCard}
                    key={`${data.id}-card`}
                    {...data}
                  />
                ))}
              </CardsSet>

              <CardsSet title="Advanced Metrics">
                {advancedMetricsCards.map(data => (
                  <OptionsCard
                    className={styles.OptionsCard}
                    key={`${data.id}-card`}
                    {...data}
                  />
                ))}
              </CardsSet>
            </>
          )
        }
      </section>
    </main>
  );
}
WorkspaceAnalyticsDashboard.displayName = 'WorkspaceAnalyticsDashboard';
WorkspaceAnalyticsDashboard.mainClassName = styles.AppContent;

const handle = {
  mainClassName: WorkspaceAnalyticsDashboard.mainClassName,
  title: 'Workspace Analytics',
};

export {
  WorkspaceAnalyticsDashboard as Component,
  handle,
};
