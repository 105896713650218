export function CheckboxTw({
  description,
  disabled = false,
  label,
  value,
  variableName,
  onChange,
}: {
  description?: string;
  disabled?: boolean;
  label: string;
  value: boolean;
  variableName: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          aria-describedby={`${variableName}-description`}
          className="h-4 w-4 rounded border-gray-300 text-orbiit-dark-blue focus:ring-indigo-600 cursor-pointer"
          defaultChecked={value}
          disabled={disabled}
          id={variableName}
          name={variableName}
          onChange={onChange}
          type="checkbox"
        />
      </div>

      <div className="ml-3 text-md leading-6">
        <label className="font-medium text-gray-900" htmlFor={variableName}>
          {label}
        </label>

        {description && (
          <p className="text-gray-500 text-sm" id={`${variableName}-description`}>
            {description}
          </p>
        )}
      </div>
    </div>
  );
}

CheckboxTw.displayName = 'CheckboxTw';
