.Container {
  background-color: var(--brand-light);
}

.ContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.SidebarWrapper {
  flex: none;
}

.Content {
  width: 100%;
}
