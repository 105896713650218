.ItemStatus {
  color: var(--colourise);
}

.ItemStatus::after {
  background-color: currentcolor;
  border-radius: 100%;
  content: '';
  display: inline-block;
  height: 0.8em;
  margin-left: 1ch;
  width: 0.8em;
}

.ItemStatus[data-status="disabled"] {
  --colourise: var(--text-neutral);
}

.ItemStatus[data-status="invalid"] {
  --colourise: var(--status-danger);
}

.ItemStatus[data-status="valid"] {
  --colourise: var(--status-success);
}
