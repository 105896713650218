._Details_56xy2_1 {
  background-color: var(--brand-light);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  padding: 1rem;
}

._Summary_56xy2_8 {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 1ch;
}

._Details_56xy2_1[open] ._Summary_56xy2_8 {
  margin-bottom: var(--grid-gutter);
}

._Summary_56xy2_8::before {
  color: var(--brand-accent-primary);
  content: '›';
  font-weight: 900;
  place-self: center;
  rotate: 90deg;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: rotate;
}

._Details_56xy2_1:not([open]) ._Summary_56xy2_8::before {
  rotate: 0deg;
}
