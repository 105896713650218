.Page {
  --max-content-width: 60%;

  display: grid;
  grid-template-columns: min-content minmax(50ch, var(--max-content-width)) min-content;
  justify-content: space-between;
  margin: var(--grid-gutter);
}

@media screen and (width >= 1280px) {
  .Page {
    --max-content-width: 80%;
  }
}

.Header {
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin: 0 auto;
  max-width: 80%;
  min-width: 34ch;
  text-align: center;
}

.TemplateGroup {
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin-top: 2em;
}

.TemplateGroup + .TemplateGroup {
  margin-top: 3em;
}

.TemplateGroupTitle {
  color: var(--text-neutral);
  font-weight: 600;
  text-transform: uppercase;
}

.TemplateDetails {
  max-width: 50%;
  min-width: 50ch;
}

.TemplateSelectionCTA {
  padding-left: 1rem;
  padding-right: 1rem;
  place-self: end;
}

.Cards {
  --columns: 2;

  display: grid;
  gap: var(--grid-gutter);
  grid-auto-flow: row;
  grid-template-columns: repeat(var(--columns), 1fr);
}

@media screen and (width >= 1440px) {
  .Cards {
    --columns: 3;
  }
}

.Card {
  display: flex; /* make the label fill the area */
  position: relative;
  text-align: center;
}

.CardIcon {
  aspect-ratio: 1;
  display: block;
  height: 2.5em;
  margin-top: 1rem;
}

.DetailsIcon {
  height: 5em;
}

.CardTitle {
  text-align: center;
}

.Card > label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  gap: 1em;
  padding: 1rem;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: box-shadow, scale;
}

.CardField {
  appearance: none;
  height: 100%;
  margin: 0 !important;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.Card > label,
.CardField + label {
  box-shadow: var(--box-shadow);
}

.CardField:focus {
  outline: none;
}

.Card:hover > label,
.CardField:focus + label {
  scale: 1.02;
}

.CardField:checked + label {
  border-width: 2px;
}

.Description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AiRecommendedDetails {
  background-image: linear-gradient(
    var(--ai-background),
    1%,
    #FFFF
  );
}

.AiRecommended {
  color: var(--ai-text);
  display: inline-flex;
  gap: 0.25ch;
  left: 0;
  padding: var(--default-padding);
  position: absolute;
  top: 0;
}

.MatchingQuestionBlock {
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--default-padding);
}

.MatchingQuestionOptions {
  padding-left: 2rem;
  text-align: right;
}
