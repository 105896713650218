query GetIntegrationsData($workspaceId: ID!) {
  workspace(workspaceId: $workspaceId) {
    integrations {
      accountType
      createdAt
      id
      permissions {
        enableContactNumOptinForms
        enableContactsLastOptinFormAt
        enableCreateContacts
        enableUpdateContacts
      }
      syncLists {
        displayName
        remoteId
      }
    }
    sessions {
      createdAt
      id
      name
    }
  }
}
