mutation CreateSignupQuestion(
  $workspaceId: ID!
  $engagementId: ID!
  $question: EngagementQuestionInput!
) {
  question: createEngagementOptInQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    optInQuestion: $question
  ) {
    id
  }
}
