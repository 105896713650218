import type {
  Dispatch,
  SetStateAction,
} from 'react';
import _memoize from 'lodash-es/memoize.js';
import { memo } from 'react';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import { getNow } from '…/app/common/dateUtils.mts';
import { SELECTABLE_STEPS_SET } from '…/app/deprecated/constants.mts';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import type { EngagementQuestion } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { FiltersContent, FiltersHeader } from '…/app/common/filtering/FiltersDrawer.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';

import classes from './FiltersForm.module.css';

// NOTE: Ignore these steps as they will be present in legacy forms.
const IGNORE_STEPS = new Set(['timezone', 'fallback']);

const getClosingDateOptions = _memoize((now: ReturnType<typeof getNow>) => [
  ['Last hour', now.minus({ hours: 1 }).toISO()],
  ['Last 3 hours', now.minus({ hours: 3 }).toISO()],
  ['Today', now.startOf('day').toISO()],
  ['Since yesterday', now.minus({ days: 1 }).startOf('day')
    .toISO()],
  ['This week', now.startOf('week').toISO()],
]);


export const OPTINS_MEMBER_STATUS_DICT = {
  new: {
    icon: 'user new',
    label: 'First Time User',
  },
  referral: {
    icon: 'user referral',
    label: 'Referral',
  },
  repeat: {
    icon: 'user repeat',
    label: 'Repeat User',
  },
  super: {
    icon: 'user super',
    label: 'Power User (5+)',
  },
} as const;
export type OptinMemberStatus = keyof typeof OPTINS_MEMBER_STATUS_DICT;

export type OptInsFilters = Partial<{
  SELECTED_OPTIONS: Array<string>,
  STATUS: Array<OptinMemberStatus>,
  DATETIME: ISO_8601[],
}>

export function OptInsFiltersForm({
  filters: {
    DATETIME,
    SELECTED_OPTIONS,
    STATUS,
  },
  setFilters,
  questions,
}: {
  filters: OptInsFilters,
  setFilters: Dispatch<SetStateAction<OptInsFilters>>,
  questions: Array<EngagementQuestion>,
}) {
  const filteredQuestions: EngagementQuestion[] = filterQuestions(questions);

  return (
    <>
      <FiltersHeader />

      <FiltersContent>
        <Form
          name="engagement-filters"
          onReset={() => setFilters({
            DATETIME: new Array(),
            SELECTED_OPTIONS: new Array(),
            STATUS: new Array(),
          })}
          onSubmit={(_, all) => setFilters(all)}
        >
          <Details open>
            <Summary className={classes.Summary}>Status</Summary>

            <div className={classes.FilterOptions}>
              {(Object.keys(OPTINS_MEMBER_STATUS_DICT) as OptinMemberStatus[]).map((key) => (
                <Field
                  defaultChecked={STATUS?.includes(key)}
                  id={`STATUS.${key}`}
                  key={key}
                  label={
                    <span className={classes.LabelWithIcon}>
                      {OPTINS_MEMBER_STATUS_DICT[key].label}

                      <IIcon name={OPTINS_MEMBER_STATUS_DICT[key].icon} />
                    </span>
                  }
                  name="STATUS[]"
                  type="checkbox"
                  value={key}
                />
              ))}
            </div>
          </Details>

          {filteredQuestions.map((q: EngagementQuestion, ix: number) => (
            <Details key={q.stepName}>
              <Summary className={classes.Summary}>
                {`${q.props.title.substr(0, 30)}${q.props.title.length > 30 ? '...' : ''}`}
              </Summary>

              <div className={classes.FilterOptions}>
                {q.props.options.map((option: any) => (
                  <Field
                    defaultChecked={SELECTED_OPTIONS?.includes(option.id)}
                    id={`SELECTED_OPTIONS.${option.id}`}
                    key={option.id}
                    label={option.text}
                    name={`SELECTED_OPTIONS[${ix}]`}
                    type="checkbox"
                    value={option.id}
                  />
                ))}
              </div>
            </Details>
          ))}

          <Details open>
            <Summary className={classes.Summary}>Closing date</Summary>

            <div className={classes.FilterOptions}>
              {getClosingDateOptions(getNow()).map(({ 0: label, 1: value }) => (
                <Field
                  defaultChecked={DATETIME?.includes(value!)}
                  id={`DATETIME.${label}`}
                  key={label}
                  label={label}
                  name="DATETIME[]"
                  type="radio"
                  value={value}
                />
              ))}
            </div>
          </Details>

          <Button.Group>
            <Button appearance={Button.APPEARANCES.BASIC} type="reset">Clear</Button>

            <Button appearance={Button.APPEARANCES.PRIMARY} type="submit">Apply</Button>
          </Button.Group>
        </Form>
      </FiltersContent>
    </>
  );
}
OptInsFiltersForm.displayName = 'OptInsFiltersForm';

const filterQuestions = (questions: EngagementQuestion[]) => questions
  .filter((q) => !q?.isDisabled && !IGNORE_STEPS.has(q.stepName) && SELECTABLE_STEPS_SET.has(q.question));

export const MemoizedOptInsFiltersForm = memo(OptInsFiltersForm);
MemoizedOptInsFiltersForm.displayName = 'MemoizedOptInsFiltersForm';
