import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type {
  Workspace,
  WorkspaceId,
} from '../states.d.ts';
import type { OrbiitAPI } from '../api.d.ts';
import { onError } from '../errors/errors.state.mts';

import GET_WORKSPACES from './getWorkspaces.gql';


export function getWorkspaces() {
  return gqlOperation<{
    workspaces: OrbiitAPI.Workspace[],
  }>(GET_WORKSPACES)
    .then((data) => {
      const workspaces = new Map<WorkspaceId, Workspace>();
      for (const w of data.workspaces) workspaces.set(w.id, w);

      return workspaces;
    })
    .catch(onError);
}
