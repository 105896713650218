.Shaking {
  animation: shake 0.14s cubic-bezier(.36,.07,.19,.97) both;
  animation-delay: 2s;
  animation-iteration-count: 5;
  backface-visibility: hidden;
  color: var(--status-warning);
  translate: 0 0 0;
}

@keyframes shake {
  0%, 100% {
    translate: 0 0 0;
  }

  20% {
    translate: -22% -4% 0;
  }

  50% {
    translate: 0 -20% 0;
  }

  80% {
    translate: 22% 6% 0;
  }
}
