import { Engagement } from '../Engagement.d.ts';
import {
  SECTIONS,
  getEngagementStep,
} from '../constants.mts';


export const MENU_ITEMS = new Array(
  /* eslint-disable sort-keys */
  {
    key: SECTIONS.SETUP,
    label: 'Setup',
    icon: 'engagement setup',
  },
  {
    key: SECTIONS.OPTINS,
    label: 'Opt-ins',
    icon: 'engagement optins',
  },
  {
    key: SECTIONS.MATCHES,
    label: 'Matches',
    icon: 'engagement matching',
  },
  {
    key: SECTIONS.FEEDBACK,
    label: 'Feedback',
    icon: 'engagement feedback',
  },
  {
    key: SECTIONS.DASHBOARD,
    label: 'Dashboard',
    icon: 'engagement dashboard',
  },
  {
    key: SECTIONS.DASHBOARD_EMAILS,
    label: 'Email Statistics',
    icon: 'engagement email dashboard',
  },
  {
    key: SECTIONS.SETTINGS,
    label: 'Advanced',
    icon: 'engagement settings',
    internal: true,
  },
  /* eslint-enable sort-keys */
);

export function getEngagementStepIndices(status: Engagement['status'], section: Section) {
  const currentStep = getEngagementStep(status);
  let activeStepIdx = 0;
  let currentStepIdx = 0;

  for (const [idx, { key }] of MENU_ITEMS.entries()) {
    let done = 0;
    if (key === section) {
      activeStepIdx = idx;
      done++;
    }
    if (key === currentStep) {
      currentStepIdx = idx;
      done++;
    }
    if (done === 2) break;
  }

  return {
    activeStepIdx,
    currentStepIdx,
  };
}
