import settingsFields from './settings.frag.gql';
import teammatesFields from './teammates.frag.gql';

export const GET_WORKSPACE_SETTINGS = `
query GetWorkspaceSettings($workspaceId: ID!) {
  workspace(workspaceId: $workspaceId) {
    id
    ...settingsFields
    ...teammatesFields
  }
}

${settingsFields}
${teammatesFields}
`;
