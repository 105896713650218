import type {
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { WalledRouteParams } from '…/app/route-params.mts';

import { splitArrayIntoChunks } from '…/app/w/workspace/audiences/audience/members/splitArrayIntoChunks.mts';
import { sanitizeEmail } from '…/app/deprecated/utils/stringUtils.mts';

import { CSVUpload } from '…/app/w/workspace/common/UseCSVUpload.tsx';
import type { MemberCSVUpdate } from '…/app/w/workspace/common/UseCSVUpload.tsx';

import type { Audience } from './Audience.d.ts';
import { updateMembersState } from './updateMembersState.mts';

import type { MembersDict } from './members/Member.d.ts';
import { upsertMembers } from './members/upsertMembers.op.mts';
import { validateEmail } from '…/common/email.ts';


export function UploadMembersButton({
  audience,
  setMembers,
}: {
  audience: Audience,
  setMembers: Dispatch<SetStateAction<MembersDict>>,
}) {
  const { workspaceId } = useParams() as WalledRouteParams;

  // Determine if we send the audienceIds to the Microservice.
  // We don't do this for the primary 'All Members' Audience.
  const audienceId = !audience.isPrimary && !audience.isBuiltin && audience.id;
  const audienceIds = audienceId ? new Array(audienceId) : new Array();

  async function onData(results: { data: MemberCSVUpdate[] }) {
    // For the implementation of custom fields as provided by FlatFile.
    // console.log(results.$data.$custom) /** Object. e.g. { 'Full Name': 'Aaron X' } */

    const uploadMembers: any[] = [];
    for (const { email, ...fields } of results.data) {
      if (!email) {
        continue;
      }
      if (!validateEmail(email)) {
        /* eslint-disable-next-line no-console */
        console.error(`[x] Invalid email: ${email}`);
        continue;
      }
      /** Parse the data to format for the API. It expects a flat MemberInput */
      uploadMembers.push({
        email: sanitizeEmail(email),
        ...fields,
      });
    }
    const newMemberCount = uploadMembers.length;

    // Send ALL the uploaded members to createAndUpdateMembers, we will get back an array of ALL
    // member ids associated with the upload. Due to a bug (ORB-1046) encountered in sending
    // large CSVs to the Platform - API (timeouts after around 10 seconds) we are now
    // breaking up the request in chunks to send.
    const chunksOfNewMembers = splitArrayIntoChunks(uploadMembers, 1500);
    const upsertReqs = [];
    for (let c = chunksOfNewMembers.length-1; c > -1; c--) {
      upsertReqs[c] = upsertMembers(workspaceId, chunksOfNewMembers[c], audienceIds);
    }

    await Promise.allSettled(upsertReqs)
      .then((upsertResultChunks) => upsertResultChunks.flat())
      .then((upsertResults) => {
        let membersSuccessfullyUpserted = 0;
        // @ts-ignore The only problem here is the pedantic TS authors microsoft/TypeScript#47617
        for (const { reason, value } of upsertResults) {
          if (value) {
            membersSuccessfullyUpserted += value.length;
            updateMembersState(value, setMembers);
          } else {
            toast.error('Some members could not be saved', { description: reason });
          }
        }

        toast.success(`${membersSuccessfullyUpserted > 1 ? 'Members' : 'Member'} added`, {
          description: !audience.isBuiltin ? `to “${audience.name}”` : '',
        });
      });

    // Show a return message in the flatfile dialog.
    return `${newMemberCount} member${newMemberCount > 1 ? 's' : ''} added to “${audience.name}”`;
  }

  return (
    <CSVUpload onData={onData} />
  );
}
UploadMembersButton.displayName = 'UploadMembersButton';
