import type {
  KeyboardEvent,
  PropsWithChildren,
} from 'react';
import { clsx } from 'clsx';

import classes from './Details.module.css';


export function Details({
  children: content,
  className,
  ...rest
}: PropsWithChildren<{
  className?: string,
  onKeyDown?: (event: KeyboardEvent<HTMLDetailsElement>) => void,
  open?: boolean,
}>) {
  function onKeyDown(event: KeyboardEvent<HTMLDetailsElement>) {
    // <Details> may have a editable content, so ignore events triggered by children
    if (!event.currentTarget || event.target !== event.currentTarget) return;

    switch (event.key) {
      case 'Spacebar':
      case ' ':
        event.currentTarget.open = !event.currentTarget.open;
        event.preventDefault();
        break;
    }

    rest.onKeyDown?.(event);
  }

  return (
    <details
      {...rest}
      className={clsx(classes.Details, className)}
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      {content}
    </details>
  );
}
Details.displayName = 'Details';

export function Summary({
  children,
  className,
  ...rest
}: PropsWithChildren<{
  className?: string,
}>) {
  return (
    <summary className={clsx(classes.Summary, className)} {...rest}>{children}</summary>
  );
}
Summary.displayName = 'Summary';
