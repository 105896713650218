import userFrag from './user.frag.gql';

export const GET_ME = `
query GetMe {
  me {
    ...userFields
  }
}

${userFrag}
`;
