.ErrorDetails {
  color: var(--text-neutral);
}

.ErrorOutput {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.6;
}

.ErrorOutput::before {
  content: "";
  display: block;
  margin: 1em auto 0;
  width: 100%;
}

.ErrorDetailsToggle {
  align-items: center;
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  gap: 1ch;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  width: fit-content;
}

.ErrorDetailsToggle::marker {
  content: "";
}

.ErrorDetailsToggle::before {
  content: '›';
  font-weight: 900;
  place-self: center;
  rotate: 90deg;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: rotate;
}

.ErrorDetails:not([open]) .ErrorDetailsToggle::before {
  rotate: 0deg;
}

.Error {
  white-space: pre-wrap;
}
