/*
! Percentage values on left/right are relative to the "parent" ($0.offsetParent).
! Instead, use an absolute unit like "em" and shift $0 via translate, which is relative to self.
*/

.PopoverContainer {
  position: relative;
}

.Popover {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  font-size: 0.875rem;
  opacity: 0;
  padding: 0.125em;
  pointer-events: none;
  position: absolute;
  transition: opacity ease-in-out var(--transition-duration-controls);
  z-index: 1;
}

.Popover::after {
  background-color: inherit;
  border: inherit;
  box-shadow: inherit;
  clip-path: polygon(
    0 0,
    45% 55%,
    120% 120%,
    120% 0%
  );
  content: "";
  display: block;
  height: 1em;
  position: absolute;
  width: 1em;
}

.PopoverContainer[type="menu"] .Popover {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.PopoverContainer[type="menu"] .Popover > [menuitem] {
  border-radius: 3px;
  margin: unset;
  padding: 0.75em 1em;
}

.PopoverContainer[type="menu"] .Popover > [destructive] {
  color: darkred;
}

.PopoverContainer[type="menu"] .Popover > [menuitem]:hover {
  background: var(--brand-subtle-light);
}

.PopoverContainer[type="menu"] .Popover,
.PopoverContainer[type="popover"] .Popover {
  background-color: var(--brand-light);
  color: var(--brand-dark);
  min-width: max-content;
}

.PopoverContainer[type="tooltip"] .Popover {
  background-color: var(--brand-dark);
  color: var(--brand-light);
  padding: 0.5em 0.75em;
}

.PopoverContainer[type="popover"]:not([minimal]) .Popover,
.PopoverContainer[type="tooltip"]:not([minimal]) .Popover {
  min-width: 20ch;
}

.PopoverContainer[type="tooltip"]:not([minimal]) .Popover {
  max-width: max-content;
}

.PopoverContainer[type="tooltip"][minimal] .Popover {
  white-space: pre;
}

.Popover[open],
.PopoverContainer[type="tooltip"]:hover .Popover,
.PopoverContainer[type="tooltip"] .PopoverAnchor:focus + .Popover {
  opacity: 1;
  pointer-events: all;
}

.Popover[position~="left"] {
  left: calc(50% - 1.5em);
}

.Popover[position~="left"]::after {
  left: 1em;
}

.Popover[position~="middle"],
.Popover[position~="middle"]::after {
  left: 50%;
}

.Popover[position~="right"] {
  right: calc(50% - 1.5em);
}

.Popover[position~="right"]::after {
  right: 1em;
}

.Popover[position~="bottom"] {
  bottom: -1em; /* arrow's height */
}

.Popover[position~="bottom"]::after {
  rotate: 315deg;
  top: -1em;
}

.Popover[position="bottom left"] {
  translate: 0 100%;
}

.Popover[position="bottom left"]::after {
  translate: 0 50%;
}

.Popover[position="bottom middle"] {
  translate: -50% 100%;
}

.Popover[position="bottom middle"]::after {
  translate: -50% 50%;
}

.Popover[position="bottom right"] {
  translate: 0 100%;
}

.Popover[position="bottom right"]::after {
  translate: 0 50%;
}

.Popover[position="left"] {
  left: -1.5em;
  translate: -100% -50%;
}

.Popover[position="left"],
.Popover[position="right"] {
  top: 50%;
}

.Popover[position="right"] {
  right: -1.5em;
  translate: 100% -50%;
}

.Popover[position="left"]::after,
.Popover[position="right"]::after {
  top: 50%;
  translate: 0 -50%;
}

.Popover[position="left"]::after {
  left: calc(100% - 0.5em); /* Using 'left' instead of 'right' to trump other declaration's left */
  rotate: 45deg;
}

.Popover[position="right"]::after {
  left: -0.5em;
  rotate: -136deg;
}

.Popover[position~="top"] {
  top: -1em; /* arrow's height */
}

.Popover[position~="top"]::after {
  bottom: 0;
  rotate: 135deg;
}

.Popover[position="top left"] {
  translate: 0 -100%;
}

.Popover[position="top left"]::after {
  translate: 0 50%;
}

.Popover[position="top middle"] {
  translate: -50% -100%;
}

.Popover[position="top middle"]::after {
  translate: -50% 50%;
}

.Popover[position="top right"] {
  translate: 0 -100%;
}

.Popover[position="top right"]::after {
  translate: 0 50%;
}

.PopoverContainer[type="menu"] .PopoverAnchor,
.PopoverContainer[type="popover"] .PopoverAnchor {
  cursor: pointer;
}
