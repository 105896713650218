.Bureau {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

.TableHeader {
  margin-bottom: 2rem;
}

.Table {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.Table td {
  vertical-align: middle;
}

.Welcome {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: inherit;
  justify-content: center;
}

.Drawer {
  background: var(--neutral-lightest);
  padding: 0;
}

.RowSpacer {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  min-height: 3rem;
}
