import { Kind, parse } from 'graphql';


export function getGQLOperationProps(op: string) {
  const def = parse(op).definitions[0];

  if (def.kind !== Kind.OPERATION_DEFINITION) return {};

  const { selections } = def.selectionSet;

  const keys = new Array(selections.length);
  for (let i = 0; i < selections.length; i++) {
    const selection = selections[i];
    if (selection.kind !== Kind.INLINE_FRAGMENT) {
      keys[i] = (
        // @ts-ignore We don’t care that `alias` might not exist (hence the || name)
        selection.alias
        || selection.name
      ).value;
    }
  }

  return {
    keys,
    name: def.name?.value,
    type: def.operation,
  };
}
