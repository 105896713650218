._Bureau_2qt8i_1 {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

._TableHeader_2qt8i_5 {
  margin-bottom: 2rem;
}

._Table_2qt8i_5 {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

._Table_2qt8i_5 td {
  vertical-align: middle;
}

._Welcome_2qt8i_18 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: inherit;
  justify-content: center;
}

._Drawer_2qt8i_27 {
  background: var(--neutral-lightest);
  padding: 0;
}

._RowSpacer_2qt8i_32 {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  min-height: 3rem;
}
