.Progress {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);;
  height: 0.6em;
  overflow: hidden;
}

.Progress::before {
  background-color: var(--status-success-light);
  content: '';
  display: block;
  height: 100%;
  width: calc(100% * var(--completeness));
}

.Progress[value="1"]::before {
  background-color: var(--status-success);
}
