/* eslint-disable max-lines */
import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import {
  useEffect,
  useState,
} from 'react';
import {
  useNavigate,
  useParams,
} from 'react-router-dom';

import type { AudienceRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { downloadAudience } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { getFilterCount } from '…/app/common/refinement/getFilterCount.mts';
import { useRefinementParams } from '…/app/common/refinement/useRefinementParams.mts';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import type { DeSelectAllState } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import {
  DeSelectAll,
  onUpdateSelection,
} from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { FilterIcon } from '…/app/common/filtering/FilterIcon.tsx';

import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';
import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import {
  PAGE_ITEM_LIMIT,
  type Search,
  paginationFactory,
  resetPaginationOnRefine,
} from '…/app/common/pagination/factories.mts';
import { usePagination } from '…/app/common/pagination/usePagination.mts';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { rpc } from '…/app/common/rpc/client.mts';
import type {
  Sort,
  TableHeading,
} from '…/app/common/Table/Table.tsx';
import { Table } from '…/app/common/Table/Table.tsx';

import { AudiencesDatalist } from '../AudiencesDatalist.tsx';

import type {
  Member,
} from './members/Member.d.ts';
import { getAudiencesForMembers } from './members/getAudiencesForMembers.mts';
import { deleteMembers } from './members/deleteMembers.op.mts';
import { getAudienceMembers } from './members/getAudienceMembers.op.mts';
import { upsertMembers } from './members/upsertMembers.op.mts';

import type { Audience, AudienceDict } from './Audience.d.ts';
import type { WidgetConfig } from './widgetDict.mts';
import { widgets } from './widgetDict.mts';
import { determineAudienceIndicator } from './determineAudienceIndicator.mts';
import { memoizedCustomFieldsExtractor } from './extractCustomFields.mts';
import { getSelectedMemberData } from './getSelectedMemberData.mts';
import { getAudience } from './getAudience.op.mts';
import { renameAudience } from './renameAudience.op.mts';
import { transformMembersToTabularData } from './transformMembersToTabularData.tsx';
import { updateMembersState } from './updateMembersState.mts';
import type { MembersFilters, SortableFields } from './AudienceFilters.mts';
import { UploadMembersButton } from './UploadMembersButton.tsx';

import classes from './AudiencePage.module.css';


export function AudiencePage() {
  const {
    count: [memberCount, setMemberCount],
    cursors,
    page: [page, setPage],
    records: [members, setMembers],
  } = usePagination<Member>();
  const navigate = useNavigate();

  // Controlling which form is displayed and whether the dialog is open can't be coupled because
  // voiding the form also destroys the context for FlatFile.
  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const openWidget = (config: WidgetConfig) => {
    setWidgetConfig(config);
    if (config.type === 'dialog') {
      setDialogOpen(true);
    }
  };

  const [loading, setLoading] = useState<boolean>();

  const {
    filters: [filters, setFilters],
    search: [search, setSearch],
    sort: [sort, setSort],
  } = useRefinementParams({
    filters: {
      CREATED_AT: new Array(),
      STATUS: new Array(),
    } as MembersFilters,
    search: {
      q: '',
    } as Search,
    sort: {
      ascending: true,
      fieldName: 'name',
    } as Sort<SortableFields>,
  });

  const [selected, setSelected] = useState<DeSelectAllState>(new Map());

  const { audienceId, workspaceId } = useParams() as AudienceRouteParams;

  const isEditor = !!useEditorRole();

  const workspace = useStore((data) => data.workspaces[workspaceId]);

  const audiences = workspace.audiences as AudienceDict;
  const audience = audiences.get(audienceId);

  const Widget = widgets[widgetConfig?.name ?? ''];

  useEffect(() => {
    setLoading(true);

    const pagination = paginationFactory<Member, 'members'>(
      'members',
      {
        cursors,
        direction: page.direction,
        filters,
        limit: page.limit,
        reset: page.reset,
        search: search.q,
      },
      {
        setCount: setMemberCount,
        setRecords: setMembers,
        setSelected,
      },
    );

    getAudienceMembers(
      workspaceId,
      audienceId,
      {
        ...pagination.props,
        sort,
      },
    )
      .then(pagination.then)
      .then(({ members: { nodes } }) => getAudiencesForMembers(workspaceId, nodes.map(({ id }) => id)))
      .then((data) => setMembers((prev) => {
        const updated = new Map(prev);
        for (const [memberId, memberAudiences] of Object.entries(data)) {
          updated.set(memberId, {
            ...updated.get(memberId)!,
            // TODO: update MS response to list instead of dictionary
            // eslint-disable-next-line max-nested-callbacks
            audienceIds: new Set(memberAudiences.map((a) => Object.keys(a)[0])),
          });
        }
        return updated;
      }))
      .finally(() => setLoading(false));
  }, [
    audienceId, // Necessary because user can click on "Members" TL nav, which just change props
    filters,
    page,
    search,
    sort,
  ]);

  useEffect(() => {
    getAudience(audienceId)
      .then((data) => audiences.set(audienceId, data));
  }, [audienceId]);

  useEffect(() => {
    if (audience?.name) document.title = `Orbiit - ${audience.name}`;
  }, [audience?.name]);

  if (!audience) handlePageErrors('Audience page', [new Error('No data available for this Audience')]);

  function onBatchMembers<A extends Parameters<typeof upsertMembers>>(
    updates: A[1],
    audienceIds: A[2],
  ) {
    return upsertMembers(workspaceId, updates, audienceIds)
      .then((results) => {
        updateMembersState(results, setMembers, true);

        setDialogOpen(false);

        onUpdateSelection(selected, setSelected, false);
      });
  }

  // ORB-1563: Adding new method to remove members from a custom audience.
  function onRemoveMembersFromAudience(memberIds: Member['id'][]) {
    return rpc.post('actions/remove-memberIds-from-audiences', {
      json: {
        audienceIds: [audienceId],
        memberIds,
        workspaceId,
      },
    })
      .then(() => {
        setMembers((prev) => {
          const updated = new Map(prev);
          memberIds.forEach((id) => updated.delete(id));
          return updated;
        });

        onUpdateSelection(selected, setSelected, false);

        setSelected((prev) => {
          const updated = new Map(prev);
          memberIds.forEach((id) => updated.delete(id));
          return updated;
        });
        setMemberCount((prev) => prev - memberIds.length);

        setDialogOpen(false);
      });
  }

  function onDeleteMembers(memberIds: Member['id'][]) {
    return deleteMembers(workspaceId, memberIds)
      .then((ids) => {
        setMembers((prev) => {
          const updated = new Map(prev);
          ids.forEach((id) => updated.delete(id));
          return updated;
        });

        onUpdateSelection(selected, setSelected, false);

        setSelected((prev) => {
          const updated = new Map(prev);
          ids.forEach((id) => updated.delete(id));
          return updated;
        });
        setMemberCount((prev) => prev - ids.length);

        setDialogOpen(false);
      });
  }

  function onUpsertMember<A extends Parameters<typeof upsertMembers>>(
    update: A[1],
    audienceIds: A[2],
  ) {
    return upsertMembers(workspaceId, update, audienceIds)
      .then((result) => {
        updateMembersState(result, setMembers);

        if (!update.id) setMemberCount((prev) => prev + 1);

        setDialogOpen(false);
      });
  }

  function onAudienceUpdate(update: Audience, isDeleted?: boolean) {
    const method = isDeleted ? 'delete' : 'set';

    // ORB-1760 - 'Fix' for an error when redirect after deleting an audience
    // from the audience page. For some reason adding the console.log removes the
    // error, possibly because of some kind of race-condition of some sort.
    // Slack thread: https://orbiit.slack.com/archives/C036G7ELFFC/p1701776390935359
    /* eslint-disable-next-line no-console */
    console.log('debug', {
      method,
      update,
    });

    // Update the local state of the workspace audiences.
    (workspace.audiences as AudienceDict)[method](update.id, update);
    (workspace.customAudiences as AudienceDict)[method](update.id, update);

    if (isDeleted) navigate('../');

    setDialogOpen(false);
    setWidgetConfig(undefined);
  }

  const setRefineCriteria = resetPaginationOnRefine<SortableFields, MembersFilters>(
    setPage,
    setFilters,
    setSearch,
    setSort,
  );

  const {
    customFieldLabels,
    customFieldValues,
  } = memoizedCustomFieldsExtractor(audience.workspaceCustomFields);
  const headings = new Map([
    ...standardHeadings,
    ['checkbox', {
      label: (
        <DeSelectAll
          selected={selected}
          setSelected={setSelected}
        />
      ),
    }],
    ...customFieldLabels,
  ]);
  const typeIndicator = determineAudienceIndicator(audience);

  const selectedMembers = getSelectedMemberData(selected, members);

  return (
    <>
      <main
        className="ContentContainer"
        data-testid="AudiencePage"
      >
        <Bureau className={classes.Bureau}>
          <Shelf className="ListPage">
            <header className={clsx('SplitHeader', classes.TableHeader)}>
              <h1 className="SplitHeader">
                <IIcon
                  label={typeIndicator.label}
                  name={typeIndicator.name}
                />

                {audience.name}
              </h1>

              <fieldset className="SplitHeader" disabled={loading}>
                <FilterIcon
                  count={getFilterCount(filters)}
                  onClick={() => openWidget({
                    data: {
                      filters,
                      setFilters(_filters: MembersFilters) {
                        setRefineCriteria({
                          filters: _filters,
                          search,
                          sort,
                        });
                        setWidgetConfig(undefined);
                      },
                    },
                    key: 'filters',
                    name: 'filters',
                    type: 'drawer',
                  })}
                  testId="MembersTableFiltersButton"
                />

                <Form
                  name="search"
                  onReset={() => setRefineCriteria({
                    filters,
                    search: { q: '' },
                    sort,
                  })}
                  onSubmit={(_search: Search) => setRefineCriteria({
                    filters,
                    search: _search,
                    sort,
                  })}
                  role="search"
                >
                  <Field
                    arrangement={Field.ARRANGEMENTS.STAND_ALONE}
                    defaultValue={search.q}
                    label={null}
                    name="q"
                    placeholder="Search"
                    type="search"
                  />
                </Form>

                {(!audience?.isBuiltin || audience.isPrimary) && (
                  <>
                    <Popover
                      anchor={(props) => (
                        <Button
                          disabled={!isEditor}
                          {...props}
                        >
                          <span aria-hidden={true}>+</span> Add
                        </Button>
                      )}
                      contentClassName={classes.ContextualMenu}
                      minimal
                      position={Popover.POSITIONS.BOTTOM_RIGHT}
                      type={Popover.TYPES.MENU}
                    >
                      <CTA
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            onSubmit: onUpsertMember,
                          },
                          key: 'add',
                          name: 'add',
                          type: 'dialog',
                        })}
                      >Add new member
                      </CTA>

                      <UploadMembersButton
                        audience={audience}
                        setMembers={setMembers}
                      />
                    </Popover>

                    <Popover
                      anchor={({ className, ...props }) => (
                        <Button
                          {...props}
                          appearance={Button.APPEARANCES.BASIC}
                          className={clsx(className, 'ContextMenuIcon')}
                          disabled={!isEditor}
                          title="context menu"
                        />
                      )}
                      position={Popover.POSITIONS.BOTTOM_RIGHT}
                      type={Popover.TYPES.MENU}
                    >
                      <CTA
                        disabled={audience.isBuiltin || audience.isPrimary}
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            audience,
                            onSubmit(newName: Audience['name']) {
                              return renameAudience(workspaceId, audience.id, newName)
                                .then(onAudienceUpdate);
                            },
                          },
                          key: `rename.${audience.id}`,
                          name: 'audience.rename',
                          type: 'dialog',
                        })}
                      >Rename Audience
                      </CTA>

                      <CTA
                        disabled={audience.isBuiltin || audience.isPrimary}
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            afterSubmit() {
                              onAudienceUpdate(audience, true);
                            },
                            audience,
                          },
                          key: `delete.${audience.id}`,
                          name: 'audience.delete',
                          type: 'dialog',
                        })}
                      >Delete Audience
                      </CTA>

                      <CTA
                        disabled={selectedMembers.size === 0}
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            members: selectedMembers,
                            onSubmit: onBatchMembers,
                          },
                          key: 'batch',
                          name: 'batch',
                          type: 'dialog',
                        })}
                      >Update Members
                      </CTA>

                      <CTA
                        disabled={selectedMembers.size === 0}
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            members: selectedMembers,
                            onSubmit: onBatchMembers,
                            onlyAudience: true,
                          },
                          key: 'batch.audience',
                          name: 'batch.audience',
                          type: 'dialog',
                        })}
                      >Add Members to Audiences
                      </CTA>

                      {!(audience?.isPrimary || audience?.isBuiltin) && (
                        <CTA
                          disabled={selectedMembers.size === 0}
                          guise={CTA.GUISES.LINK}
                          menuitem=""
                          to={() => openWidget({
                            data: {
                              members: selectedMembers,
                              onSubmit: onRemoveMembersFromAudience,
                            },
                            key: 'remove',
                            name: 'remove',
                            type: 'dialog',
                          })}
                        >Remove member(s) from Audience
                        </CTA>
                      )}

                      <CTA
                        disabled={selectedMembers.size === 0}
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        to={() => openWidget({
                          data: {
                            members: selectedMembers,
                            onSubmit: onDeleteMembers,
                          },
                          key: 'delete',
                          name: 'delete',
                          type: 'dialog',
                        })}
                      >Delete member(s)
                      </CTA>

                      <CTA
                        disabled={!(members?.size > 0)}
                        guise={CTA.GUISES.LINK}
                        id={`audience-download-csv_${audienceId}`}
                        menuitem=""
                        to={() => downloadAudience(workspaceId, audience)}
                      >Download CSV
                      </CTA>
                    </Popover>
                  </>
                )}
              </fieldset>
            </header>

            <Table
              className={classes.Table}
              headings={headings}
              loading={loading}
              onSort={(_sort: Sort<SortableFields>) => setRefineCriteria({
                filters,
                search,
                sort: _sort,
              })}
              pagination={{
                onPage: setPage,
                page,
                total: memberCount,
              }}
              records={transformMembersToTabularData(
                members,
                {
                  limit: PAGE_ITEM_LIMIT,
                  page: page.number,
                },
                {
                  audiences,
                  customFieldLabelsMap: customFieldLabels,
                  customFieldValueMap: customFieldValues,
                  onSelect: ({ id, value }) => setSelected((prev) => {
                    const updated = new Map(prev);
                    updated.set(id, value);
                    return updated;
                  }),
                  onUpsertMember,
                  openWidget,
                  selected,
                },
              )}
              sort={sort}
              // sticky
            />
          </Shelf>

          <Drawer
            className={(widgetConfig?.type === 'drawer' && widgetConfig?.name === 'filters') ? classes.FiltersDrawer : ''}
            modal
            onClose={() => setWidgetConfig(undefined)}
            open={widgetConfig?.type === 'drawer'}
          >
            {widgetConfig?.type === 'drawer' && <Widget {...widgetConfig?.data} key={widgetConfig?.key} />}
          </Drawer>
        </Bureau>
      </main>

      <AudiencesDatalist />

      <Dialog
        backdropped
        open={isDialogOpen}
        setOpen={setDialogOpen}
      >
        {widgetConfig?.type === 'dialog' && <Widget {...widgetConfig?.data} key={widgetConfig?.key} />}
      </Dialog>
    </>
  );
}
AudiencePage.displayName = 'AudiencePage';

export {
  AudiencePage as Component,
};

const standardHeadings = new Map<string, TableHeading>([
  ['checkbox', {}],
  ['name', {
    label: 'Name',
    sortable: true,
  }],
  ['meta', {}],
  ['role', {
    label: 'Role',
    sortable: true,
    wrap: true,
  }],
  ['company', {
    label: 'Company',
    sortable: true,
    wrap: true,
  }],
]);
