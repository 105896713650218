._Page_1v8dp_1 {
  display: grid;
  gap: inherit;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: min-content;
}

._WithSidebar_1v8dp_8 {
  grid-template-areas:
    "Header Header"
    "Sidebar Sidebar"
    "Editor Editor";
}

._WithoutSidebar_1v8dp_15 {
  grid-template-areas:
    "Header Header"
    "Editor Editor";
}

@media (width > 960px) {
  ._WithSidebar_1v8dp_8 {
    grid-template-areas:
      "Header Header"
      "Editor Sidebar";
  }
}

._Editor_1v8dp_29 {
  align-self: start;
  grid-area: Editor;
}

._Sidebar_1v8dp_34 {
  display: flex;
  flex-direction: column;
  gap: inherit;
  grid-area: Sidebar;
}

@media (width <= 960px) {
  ._Sidebar_1v8dp_34 {
    max-height: 11lh; /* Just a bit more than 1 card so users can see there's more */
    overflow-y: auto;
  }
}

._Unmatched_1v8dp_48 {
  align-content: start;
  flex: 1;
}

._Group_1v8dp_53 {
  background-color: var(--neutral-lightest);
}

._Group_1v8dp_53:focus {
  background-color: var(--brand-subtle-light);
}

._GroupSummary_1v8dp_61 {
  display: grid;
  grid-template-columns: min-content auto min-content max-content min-content;
}

._Matches_1v8dp_66,
._Unmatched_1v8dp_48 {
  grid-template-columns: repeat(auto-fill, minmax(22ch, 1fr));
}

._Matches_1v8dp_66:empty,
._Unmatched_1v8dp_48:empty {
  background-color: var(--brand-light);
  border: 0.1em dashed var(--brand-accent-primary);
  border-radius: var(--border-radius);
  min-height: 2lh;
}

._Loading_1v8dp_79 {
  opacity: 0.6;
}

._UnmatchedWarning_1v8dp_83 {
  color: var(--status-warning);
}

._Btn_1v8dp_87 {
  padding: unset;
}

._AnnotatedText_1v8dp_91 {
  border-bottom: 1px dotted;
}
