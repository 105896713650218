.MatchCard {
  align-content: start;
  align-items: baseline;
  display: grid;
  grid-template-areas:
    "Name . RemoveBtn"
    "Role Role DragHandle"
    "Company Company Company"
    "Meta Meta Meta"
    "Availability Availability Availability";
  grid-template-columns: auto min-content min-content;
  grid-template-rows: min-content;
}

.Name {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5ch;
  grid-area: Name;
}

.Role {
  grid-area: Role;
}

.Company {
  grid-area: Company;
}

.Btn {
  padding: unset;
}

.History {
  border-color: transparent; /* border is on top of background */
  border-style: solid;
  border-width: var(--grid-gutter) 0;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  max-height: 11lh;
  overflow: auto;
  padding-bottom: unset !important;
  padding-top: unset !important;
}

.RemoveBtn {
  grid-area: RemoveBtn;
}

.DragHandle {
  grid-area: DragHandle;
}

.Meta {
  display: flex;
  flex-direction: row;
  gap: var(--grid-gutter);
  grid-area: Meta;
}

.Availability {
  grid-area: Availability;
  line-height: 1.8;
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-duration-glyphs) var(--default-transition-easing);
}

.Availability::before {
  border-top: 1px solid var(--border-colour-normal);
  content: '';
  display: block;
  margin: 0.4lh 0;
}

.Availability[data-open] {
  max-height: 10lh;
}
