import engagementFrag from './engagement.frag.gql.mjs';

export const CLONE_ENGAGEMENT = `
mutation CloneEngagement($workspaceId: ID!, $engagementId: ID!, $engagement: EngagementInput, $shiftBy: EngagementShift) {
  engagement: cloneEngagement(workspaceId: $workspaceId, engagementId: $engagementId, engagement: $engagement, shiftBy: $shiftBy) {
    ...engagementFields
  }
}

${engagementFrag}
`;
