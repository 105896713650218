@keyframes _bounce_11pbl_1 {
  0% { scale: 0; }
  50% { scale: 1; }
  100% { scale: 0; }
}

@keyframes _rotate_11pbl_1 {
  100% { rotate: 360deg; }
}

._Spinner_11pbl_11 {
  animation: _rotate_11pbl_1 2s infinite linear;
  height: 3.5em;
  margin: auto;
  place-self: center;
  position: relative;
  text-align: center;
  width: 3.5em;
}

._Dot_11pbl_21 {
  animation: _bounce_11pbl_1 2s infinite ease-in-out;
  background-color: var(--brand-accent-teriary);
  border-radius: 100%;
  display: inline-block;
  height: 60%;
  position: absolute;
  top: 0;
  width: 60%;
}

._DotTwo_11pbl_32 {
  animation-delay: -1s;
  background-color: var(--brand-accent-secondary);
  bottom: 0;
  top: auto;
}
