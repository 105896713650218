._FiltersHeader_1u2vl_1 {
  background: white;
  border-bottom: 1px solid var(--border-color);
  box-shadow: var(--box-shadow);
  padding: 1.5em;
}

._FiltersSection_1u2vl_8 {
  padding: 1.5em;
}
