._Summary_1di9f_1 {
  font-weight: 700;
}

._FilterOptions_1di9f_5 {
  margin-top: 0.5em;
}

._LabelWithIcon_1di9f_9 {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.125rem 0;
}
