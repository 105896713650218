import { clsx } from 'clsx';
import Field from 'form5/react/Field';

import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';

import type { PairedResponseQuestionProps } from './ParticipantQuestions.d.ts';


export function PairedResponseQuestion({
  choices,
  data: {
    ledeIn = '',
    lockedProps = new Array(),
    title = '',
    variableConfig = new Array(),
  },
  disabled,
  heading,
  id,
  onChange,
  required,
}: PairedResponseQuestionProps) {
  const editLocks = {
    ledeIn: lockedProps.includes('ledeIn'),
    title: lockedProps.includes('title'),
  };
  const varConfigPath = `${id}.props.variableConfig`;

  return (
    <fieldset className={clsx(editableSectionClasses.PanelSection, 'HollowWrapper')} name={id}>
      <legend className={editableSectionClasses.FieldsetHeading}>
        <h2 id={id}>{heading}</h2>

        <Field
          aria-label="is disabled"
          arrangement={Field.ARRANGEMENTS.STAND_ALONE}
          checked={disabled ?? false}
          className={editableSectionClasses.InvertedToggle}
          id={`${id}.isDisabled`}
          label={null}
          name="isDisabled"
          onChange={onChange}
          readOnly={required}
          type="checkbox"
          variant="toggle"
        />
      </legend>

      <fieldset
        disabled={disabled}
        name="props"
        readOnly={disabled}
      >
        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          fluid
          id={`${id}.props.title`}
          label="Question title"
          name="title"
          onChange={onChange}
          readOnly={editLocks.title}
          required
          value={title ?? ''}
        />

        <Field
          arrangement={Field.ARRANGEMENTS.STACKED}
          as="textarea"
          fluid
          id={`${id}.props.ledeIn`}
          label="Question description"
          name="ledeIn"
          onChange={onChange}
          readOnly={editLocks.ledeIn}
          value={ledeIn ?? ''}
        />

        <h3>Supported Choices</h3>

        <div className={editableSectionClasses.ManyOptions}>
          {choices.map(({ label, value }, idx) => (
            <Field
              defaultChecked={variableConfig.includes(value)}
              id={`${id}.props.variableConfig[${idx}]`}
              key={value}
              label={label}
              name="variableConfig"
              onChange={({ name, value: checked }) => {
                const set = new Set(variableConfig);
                const op = checked ? 'add' : 'delete';
                set[op](value);

                onChange({
                  id: varConfigPath,
                  name,
                  value: Array.from(set),
                });
              }}
              type="checkbox"
              value={value}
            />
          ))}
        </div>
      </fieldset>
    </fieldset>
  );
}
PairedResponseQuestion.displayName = 'PairedResponseQuestion';
