import { Logger } from '…/common/Logger.mts';
import {
  checkShouldPrintLogs,
  getSessionId,
  keepSessionIdSynced,
} from '…/common/log-utils.mts';

export const log = new Logger({
  doPrint: await checkShouldPrintLogs(),
  prefix: '✢ App',
  sessionId: await getSessionId(),
});

keepSessionIdSynced(log);
