._FileInput_1ipp2_1 {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	gap: calc(var(--grid-gutter) / 2);
}

._FileInputPreviews_1ipp2_9 {
	flex-direction: row;
	position: relative;
}

._FileInputPreviews_1ipp2_9 > ._FileInputButton_1ipp2_14 {
	--default-border-radius: unset;

	bottom: 0;
	opacity: 0;
	position: absolute;
	transition-duration: var(--default-transition-duration);
	transition-property: opacity;
	transition-timing-function: var(--default-transition-easing);
}

._FileInputPreviews_1ipp2_9:hover > ._FileInputButton_1ipp2_14 {
	opacity: 1;
}

._FileInputPreview_1ipp2_9 {
	align-content: center;
	background-image: repeating-conic-gradient(
		rgb(0 0 0 / 1%) 0% 25%,
		rgb(100 100 100 / 10%) 0% 50%
	);
	background-position: 0 0;
	background-size: 2em 2em;
	border: 1px solid var(--border-colour-normal);
	display: flex;
	justify-content: center;
	max-width: 100%;
	width: max-content;
}

._FileInputControl_1ipp2_44 {
	display: none;
}

._FileInputButton_1ipp2_14 {
	display: block;
	width: 100%;
}
