._BaseCard_zv41n_1 {
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  box-sizing: border-box;
  margin: 0;
  width: 100%;
}

._Enabled_zv41n_9 {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
}

._Disabled_zv41n_14 {
  background-color: var(--brand-subtle-light);
  border: 1px solid white;
  padding: 3rem;
}

._EmptyCard_zv41n_20 {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 7rem;
}

._EmptyCardTitle_zv41n_29 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

._EmptyCardDescription_zv41n_35 {
  font-style: italic;
  line-height: 1.5em;
  text-align: center;
}

._FeatureFlagDisabledCard_zv41n_41 {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
}
