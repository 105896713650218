import Field, { type FieldProps } from 'form5/react/Field';
import type {
  HTMLAttributes,
} from 'react';

import { useParams } from 'react-router-dom';

import { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import type { AudienceId } from '../../audiences/audience/Audience.d.ts';

import { AudienceListItem } from './AudienceListItem.tsx';


interface SelectionListProps {
  all?: boolean,
  selected?: Set<AudienceId>,
  type?: 'checkbox' | 'radio',
}

export function AudienceSelectionList({
  all,
  selected = new Set(),
  type = 'radio',
  ...props
}: SelectionListProps & Omit<HTMLAttributes<HTMLElement>, 'onBlur' | 'onChange'> & FieldProps,
) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const audiences = useActiveAudiences(workspaceId);

  return (
    <>
      {audiences.map((audience) => (
        <Field
          {...props}
          defaultChecked={selected.has(audience.id)}
          id={audience.id}
          key={audience.id}
          label={<AudienceListItem {...audience} />}
          name="audienceId"
          type={type}
          value={audience.id}
        />
      ))}
    </>
  );
}
AudienceSelectionList.displayName = 'AudienceSelectionList';

function useActiveAudiences(workspaceId: WalledRouteParams['workspaceId']) {
  const { audiences, archivedAudiences } = useStore((data) => data.workspaces[workspaceId]);

  const output = []; // NOTE: We are converting these to an array to leverage the existing sort. Refactor later.

  for (const audience of audiences!.values()) {
    if (!archivedAudiences!.has(audience.id)) output.push(audience);
  }

  return output;
}
