import CommunityBuildersIcon from './community-builders.svg';
import EngineersIcon from './engineers.svg';
import FinanciersIcon from './financiers.svg';
import FundraisingIcon from './fundraising.svg';
import HandInHandIcon from './hand-in-hand.svg';
import ImprovedPerformanceIcon from './improved-performance.svg';
import MarketersIcon from './marketers.svg';
import MentalHealthIcon from './metal-health.svg';
import OperatorsIcon from './operators.svg';
import PeopleLeadersIcon from './people-leaders.svg';
import RecentIcon from './recent.svg';
import SaaSLeadersIcon from './saas-leaders.svg';
import SalesBDIcon from './sales-&-bd.svg';
import TShirtIcon from './tshirt.svg';
import UniformIcon from './uniform.svg';


export const templateIcons = {
  'community-builders': CommunityBuildersIcon,
  engineers: EngineersIcon,
  financiers: FinanciersIcon,
  fundraising: FundraisingIcon,
  'hand-in-hand': HandInHandIcon,
  'improved-performance': ImprovedPerformanceIcon,
  marketers: MarketersIcon,
  'mental-health': MentalHealthIcon,
  operators: OperatorsIcon,
  'people-leaders': PeopleLeadersIcon,
  recent: RecentIcon,
  'saas-leaders': SaaSLeadersIcon,
  'sales-&-bd': SalesBDIcon,
  tshirt: TShirtIcon,
  uniform: UniformIcon,
};
export type TemplateIconName = keyof typeof templateIcons;
