import type { GraphQLFilter } from '…/app/common/filtering/filters.d.ts';
import { getGQLFilterCount } from '…/app/common/refinement/getFilterCount.mts';

import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';
import type { PaginatedRecords, PaginationProps } from '…/app/common/pagination/pagination.d.ts';
import type { Sort } from '…/app/common/Table/Table.tsx';

import { GET_ENGAGEMENT_FEEDBACK_FORMS } from './getFeedbackForms.gql.mjs';

import type { FeedbackForm } from './feedback-forms.d.ts';
import {
  type SortableFields,
  mapSortableFieldsToGQLConstant,
} from './FeedbackFormRefinements.mts';


interface ResponseData {
  workspace: {
    engagement: {
      feedbackForms: PaginatedRecords<FeedbackForm>,
    }
  }
}

export function getEngagementFeedbackForms(
  workspaceId: ObjectId,
  engagementId: ObjectId,
  {
    after,
    before,
    filters,
    first,
    last,
    search,
    sort,
  }: PaginationProps & {
    search?: string,
    filters?: GraphQLFilter[],
    sort?: Sort<SortableFields>,
  } = {},
) {
  const numFilters = getGQLFilterCount(filters);
  return gqlOperation<ResponseData>(GET_ENGAGEMENT_FEEDBACK_FORMS, {
    after,
    before,
    engagementId,
    first,
    last,
    search,
    workspaceId,
    ...(filters && {
      filters,
    }),
    ...(sort && {
      sort: {
        direction: sort.ascending ? 'ASC' : 'DESC',
        field: mapSortableFieldsToGQLConstant[sort.fieldName],
      },
    }),
  }, {
    ...((search || numFilters) && {
      eventData: {
        data: JSON.stringify({
          filters,
          numFilters,
          search,
          workspaceId,
        }),
        name: 'Feedback Forms Search & Filter',
      },
    }),
  })
    .then((data) => data.workspace.engagement);
}
