query GetEngagementTemplates($workspaceId: ID!) {
  workspace(workspaceId: $workspaceId) {
    engagements(
      first: 3,
      sort: { direction: DESC, field: OPT_IN_CLOSES_AT },
      filters: [{ field: STATUS, values: ["MATCHED", "MEETING", "COMPLETED"] }]
    ) {
      nodes {
        engagementStats {
          nps
          numOptinForms
        }
        ...engagementTemplateFields
        optInClosesAt
        timezoneIso
      }
    }
    engagementTemplates {
      ...engagementTemplateFields
    }
    id
  }
}

fragment engagementTemplateFields on Engagement {
  description
  id
  name
  oneToOneEmails {
    chaser {
      bodyJson
      callToAction
      preheader
      subject
    }
    invite {
      bodyJson
      callToAction
      preheader
      subject
    }
  }
  optInQuestions {
    isDisabled
    question
    stepName
    ... on EngagementQuestionGenericSingleSelectStep {
      isMatchingOpposites
      isOrdered
      props {
        ledeIn
        options {
          id
          text
        }
        title
      }
    }
    ... on EngagementQuestionGenericMultiSelectStep {
      isMatchingOpposites
      isOrdered
      props {
        ledeIn
        options {
          id
          text
        }
        title
      }
    }
  }
  templateId
  templateTags
  title
}
