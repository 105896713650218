// import _sortBy from 'lodash-es/sortBy.js';
import { clsx } from 'clsx';
import {
  Fragment,
  type MouseEvent,
  type PropsWithChildren,
  type ReactNode,
} from 'react';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import { OrbiitLoader } from '…/app/common/OrbiitLoader/OrbiitLoader.jsx';

import type { Paging } from '../pagination/pagination.d.ts';
import type { PagingControlsProps } from '../pagination/PagingControls.tsx';
import { PagingControls } from '../pagination/PagingControls.tsx';

import classes from './Table.module.css';


const enum SortDirection {
  ASC = 'asc',
  DSC = 'dsc',
}

type FieldName = string;

export type Sort<FieldNames> = {
  ascending: boolean,
  fieldName: FieldNames,
}

export interface TableDatum {
  display?: ReactNode,
  fieldName: FieldName,
  raw?: null | number | string,
  onClick?: (event: MouseEvent) => void,
}
export type TableHeading = Partial<{
  label: ReactNode,
  numeric: boolean,
  sortable: boolean,
  wrap: boolean,
}>
interface StaticTableProps {
  className?: string,
  emptyText?: string,
  headings: Map<FieldName, TableHeading>,
  loading?: boolean,
  minimal?: boolean,
  pagination?: {
    onPage: PagingControlsProps['onPage'],
    page: Paging,
    total: Int,
  },
  records: TableDatum[][],
  sticky?: boolean,
}
export interface TableProps<FieldNames> extends StaticTableProps {
  onSort?(sort: Sort<FieldNames>): Promise<void>,
  sort?: Sort<FieldNames>,
}

export function Table<FieldNames>({
  className,
  emptyText = 'No records found',
  headings,
  loading,
  minimal,
  onSort,
  pagination,
  records,
  sort,
  sticky,
}: TableProps<FieldNames>) {
  const headingsList = Array.from(headings.entries());

  // Pre-determine whether any column is wrappable; to reduce iteration, at the same time, count
  // columns with non-control content determine max-width for the wrappable column(s).
  const wrap = headingsList.reduce((acc, { 1: h }) => {
    if (h.wrap) acc.do ||= true;
    if (h.label) acc.cols++;

    return acc;
  }, {
    cols: 0,
    do: false,
  });

  return (
    <>
      <table
        className={clsx(classes.Table, className)}
        {...(minimal && { minimal: '' })}
        {...(sticky && { sticky: '' })}
        {...(wrap.do && { style: { '--cols': wrap.cols } })}
      >
        <thead className={classes.Head}>
          <tr>
            {headingsList.map(({
              0: name,
              1: {
                label,
                numeric,
                sortable,
              },
            }) => {
              if (sortable && !onSort) {
                /* eslint-disable-next-line no-console */
                console.error(`Field ${name} is flagged 'sortable' but no 'onSort' was provided`);
              }

              return (
                <Th
                  {...(sortable && sort?.fieldName === name && {
                    sort: sort?.ascending ? SortDirection.ASC : SortDirection.DSC,
                  })}
                  {...(sortable && {
                    onSort: () => onSort?.({
                      ascending: sort?.fieldName === name
                        ? !sort?.ascending
                        : true,
                      fieldName: name,
                    }),
                  })}
                  dataType={inferDataType({
                    label,
                    numeric,
                  })}
                  key={name}
                >
                  {label}
                </Th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {loading // eslint-disable-line no-nested-ternary
            ? ( // TODO: remove this once Audiences & Engagements have been moved into valtio
              <tr>
                <td colSpan={headings.size}>
                  <OrbiitLoader />
                </td>
              </tr>
            )
            : records.length
              ? records.map((record) => {
                const recordKey = `${record[0].raw!}`;
                record.push(...generateEmptyFieldFillers(headings, record));

                return (
                  <tr id={recordKey} key={recordKey}>
                    {record
                      .map(({
                        display,
                        fieldName,
                        raw,
                        onClick,
                      }: TableDatum) => display !== false && (
                        <td
                          data-type={inferDataType(headings.get(fieldName))}
                          key={`${recordKey}.${fieldName}`}
                          onClick={onClick}
                          {...wrap && { wrap: '' }}
                        >
                          {display || raw}
                        </td>
                      ))}
                  </tr>
                );
              })
              : null
          }
        </tbody>
      </table>

      {!loading && !records.length && (
        <div className={classes.EmptyTable}>
          <OrbiitIcon icon="EmptyEngagementIcon" size="xxlarge" />

          <h4>{emptyText}</h4>
        </div>
      )}

      {!!pagination && (<PagingControls {...pagination} />)}
    </>
  );
}
Table.displayName = 'Table';

function inferDataType({ label, numeric }: TableHeading | undefined = {}) {
  if (numeric) return 'numeric';
  if (!label) return 'controls';

  return null;
}

export function Th({
  children,
  dataType,
  onSort,
  sort,
}: PropsWithChildren<{
  dataType: string | null,
  onSort?: (event: MouseEvent) => void,
  sort?: SortDirection,
}>) {
  if (!children) return <th />;

  const InnerWrapper = onSort ? 'span' : Fragment;
  const passProps = onSort
    ? {
      sort,
      sortable: '',
    }
    : {};

  return (
    <th
      className={classes.Th}
      data-type={dataType}
      onClick={onSort}
    >
      <InnerWrapper {...passProps}>
        {children}
      </InnerWrapper>
    </th>
  );
}
Th.displayName = 'Th';

function generateEmptyFieldFillers(
  headings: StaticTableProps['headings'],
  record: TableDatum[],
) {
  const valueNames = new Set();
  const fillValues = new Set<TableDatum>();

  for (const { fieldName } of record) valueNames.add(fieldName);
  for (const fieldHeadingName of headings.keys()) {
    if (!valueNames.has(fieldHeadingName)) {
      fillValues.add({
        fieldName: fieldHeadingName,
        raw: '-',
      });
    }
  }

  return fillValues;
}
