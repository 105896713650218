/* eslint-disable react/jsx-max-depth */

import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import Field from 'form5/react/Field';
import { useState } from 'react';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import type { Engagement } from '@/app/w/workspace/engagements/Engagement.d.ts';

import { SectionTitle } from './SectionTitle.tsx';

import classes from './EngagementSettingsPage.module.css';

export function MatchingConfigurationForm({
  disabled,
  engagement,
  onSubmit,
}: {
  disabled: boolean;
  engagement: Partial<Engagement>;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: (delta: Record<string, any>) => Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  return (
    <Form
      name="form-matching-configuration"
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={(delta) => {
        setIsSubmitting(true);
        return onSubmit(delta).then(() => {
          setIsSubmitting(false);
        });
      }}
    >
      {/* @ts-ignore */}
      <fieldset disabled={isSubmitting} id="settings-engagement-matching">
        <section className={clsx('Card', classes.SectionCard)}>
          <div className={clsx(classes.Content)}>
            <SectionTitle>
              Matching Settings
            </SectionTitle>

            <Field
              defaultChecked={engagement?.isAutoExecute || false}
              disabled={disabled}
              label="Auto-Execute Matching for Engagement"
              name="isAutoExecute"
              readOnly={disabled}
              type="checkbox"
              variant="toggle"
            />

            <Field
              defaultChecked={engagement?.disableCompanyBlock || false}
              disabled={disabled}
              label="Disable company blocking when Matching"
              name="disableCompanyBlock"
              readOnly={disabled}
              type="checkbox"
              variant="toggle"
            />

            <Field
              arrangement={Field.ARRANGEMENTS.INLINE}
              defaultValue={+engagement?.batchSize || 450}
              disabled={disabled}
              fluid
              label="Batch-size for Matching"
              name="batchSize"
              readOnly={disabled}
              type="number"
            />

            {engagement.product !== PRODUCT_TYPES.ONE_TO_ONE && (
              <Field
                arrangement={Field.ARRANGEMENTS.INLINE}
                defaultValue={+engagement.groupSize}
                disabled={disabled}
                fluid
                label="Group size"
                name="groupSize"
                readOnly={disabled}
                type="number"
              />
            )}

            <div className="flex justify-end">
              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                disabled={!isDirty || isSubmitting}
                isSubmitting={isSubmitting}
                type="submit"
              >Save changes
              </SubmitButton>
            </div>
          </div>
        </section>
      </fieldset>
    </Form>
  );
}

MatchingConfigurationForm.displayName = 'MatchingConfigurationForm';
