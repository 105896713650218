/* eslint-disable react/jsx-max-depth */

import { type ReactNode } from 'react';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import classes from './EngagementSettingsPage.module.css';


export const SectionTitle = ({ children, comingSoon = false }: { children: ReactNode, comingSoon: boolean }) => (
  <div className={classes.Title}>
    <h2 className={classes.CardTitle}>
      {children}
    </h2>

    {comingSoon ? (<Chip>Coming Soon</Chip>) : null}
  </div>
);

SectionTitle.displayName = 'SectionTitle';
