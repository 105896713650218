import { clsx } from 'clsx';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import classes from './Draggable.module.css';


export function DragHandle({
  className,
}: {
  className?: string,
}) {
  return (
    <OrbiitIcon
      className={clsx(classes.DragHandle, className)}
      icon="DragHandle"
    />
  );
}

DragHandle.displayName = 'DragHandle';
