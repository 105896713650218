.LogoPreview {
  flex: 1;
}

.LogoPreview > object {
  flex: 1;
}

@media (width <= 960px) {
  .FullStack {
    align-items: stretch;
  }
}

@media (width > 960px) {
  .LogoPreview {
    max-width: 14em;
  }
}
