._MatchCard_15g4w_1 {
  align-content: start;
  align-items: baseline;
  display: grid;
  grid-template-areas:
    "Name . RemoveBtn"
    "Role Role DragHandle"
    "Company Company Company"
    "Meta Meta Meta"
    "Availability Availability Availability";
  grid-template-columns: auto min-content min-content;
  grid-template-rows: min-content;
}

._Name_15g4w_15 {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5ch;
  grid-area: Name;
}

._Role_15g4w_23 {
  grid-area: Role;
}

._Company_15g4w_27 {
  grid-area: Company;
}

._Btn_15g4w_31 {
  padding: unset;
}

._History_15g4w_35 {
  border-color: transparent; /* border is on top of background */
  border-style: solid;
  border-width: var(--grid-gutter) 0;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  max-height: 11lh;
  overflow: auto;
  padding-bottom: unset !important;
  padding-top: unset !important;
}

._RemoveBtn_15g4w_48 {
  grid-area: RemoveBtn;
}

._DragHandle_15g4w_52 {
  grid-area: DragHandle;
}

._Meta_15g4w_56 {
  display: flex;
  flex-direction: row;
  gap: var(--grid-gutter);
  grid-area: Meta;
}

._Availability_15g4w_63 {
  grid-area: Availability;
  line-height: 1.8;
  max-height: 0;
  overflow: hidden;
  transition: max-height var(--transition-duration-glyphs) var(--default-transition-easing);
}

._Availability_15g4w_63::before {
  border-top: 1px solid var(--border-colour-normal);
  content: '';
  display: block;
  margin: 0.4lh 0;
}

._Availability_15g4w_63[data-open] {
  max-height: 10lh;
}
