.FlexEnd {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.Warning {
  color: var(--status-danger);
  margin-right: 1rem;
}

.DisableEmailContainer {
  background-color: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
}
