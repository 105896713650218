fragment audienceFilterFields on Audience {
  filter @include(if: $includeFilters) {
    action
    where {
      field
      comparator
      value
    }
  }
  extendedFilter @include(if: $includeFilters) {
    action
    where {
      field
      comparator
      value
    }
  }
}
