import { clsx } from 'clsx';
import propTypes from 'prop-types';
import { useState } from 'react';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import { useParams } from 'react-router-dom';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { getNow } from '…/app/common/dateUtils.mts';

import { useStore } from '…/app/state/useStore.mts';

import { SerialSet } from '…/app/common/refinement/useRefinementParams.mts';
import { rpc } from '…/app/common/rpc/client.mts';
import { AudienceSelectionList } from '…/app/w/workspace/common/AudienceSelection/AudienceSelectionList.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';

import filterStyles from './DashboardFilters.module.css';
import audienceDropdownStyles from './AudienceDropdown.module.css';


const RANGE_SELECT_OPTIONS = [
  {
    key: 'thisMonth',
    text: 'This Month',
  },
  {
    key: 'lastMonth',
    text: '1M',
  },
  {
    key: 'past3Months',
    text: '3M',
  },
  {
    key: 'past6Months',
    text: '6M',
  },
  {
    key: 'pastYear',
    text: '1Y',
  },
  {
    key: 'forever',
    text: 'All-Time',
  },
];

const sinceToDates = {
  /* eslint-disable sort-keys, newline-per-chained-call */
  thisMonth: () => getNow().set({ day: 1 }).toISODate(),
  lastMonth: () => getNow().minus({ months: 1 }).toISODate(),
  past3Months: () => getNow().minus({ months: 3 }).toISODate(),
  past6Months: () => getNow().minus({ months: 6 }).toISODate(),
  pastYear: () => getNow().minus({ years: 1 }).toISODate(),
  forever: () => '2020-01-01',
  /* eslint-enable sort-keys, newline-per-chained-call */
};

/**
 * Format the text to display the selected audiences.
 * @param {Set<Audience['id']>} audienceIds
 * @param {Set<Audience>} audiences
 * @returns
 */
const formatAudienceFilterText = (audienceIds, audiences) => {
  if (audienceIds.size === audiences.size) {
    return 'All Audiences';
  }
  return `${audienceIds.size} Audiences`;
};

// TODO: ORB-1222 - Add product filter with select-all, clear-all buttons.
// TODO: ORB-1260 - Add audiences 'select-all', 'clear-all' buttons.
export function DashboardFilters({
  filters,
  onApply,
}) {
  const { workspaceId } = useParams();
  const { audiences } = useStore((data) => data.workspaces[workspaceId]);
  // Set up a local state to avoid hitting RPC endpoint whilst filters are actively updated
  const [formData, setFormData] = useState(filters);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [isDirty, setDirty] = useState(false);

  const setFormField = ({ name, value }) => setFormData((prev) => ({
    ...prev,
    [name]: value,
  }));

  function onAudienceToggle({ id, value }) {
    const newList = new SerialSet(formData.audienceIds);
    const op = value ? 'add' : 'delete';
    newList[op](id);

    setFormField({
      name: 'audienceIds',
      value: newList,
    });
  }

  const onDownload = ({ target: { name } }) => rpc.post(`actions/${name}`, {
    json: {
      fromDate: formData.fromDate,
      untilDate: formData.untilDate,
      workspaceId,
    },
  })
    .blob()
    .then((blob) => {
      const file = new File([blob], 'optin-breakdown-csv', { type: 'text/csv' });
      const url = URL.createObjectURL(file);
      window.open(url);
      URL.revokeObjectURL(url);
    });

  const onSinceClick = ({ target: { dataset } }) => {
    const date = sinceToDates[dataset.key]();
    setFormField({
      name: 'fromDate',
      value: date,
    });
    setFormField({
      name: 'untilDate',
      value: getNow().toISODate(),
    });
    setDirty(true);
  };

  const onSubmit = () => {
    if (!isDirty) { return }

    onApply(formData);
    setDirty(false);
    setShowForm(false);
  };

  return (
    <section className={filterStyles.DashboardFiltersContainer}>
      {!showForm && (
        <div className={filterStyles.FilterTextContainer}>
          <p>
            You are viewing your analytics from
            <VariableSpan>{formData.fromDate}</VariableSpan>
            to

            <VariableSpan>{formData.untilDate}</VariableSpan>
            for

            <VariableSpan>{formatAudienceFilterText(formData.audienceIds, audiences)}</VariableSpan>
          </p>

          <button
            className={filterStyles.FilterPanelButton}
            onClick={() => setShowForm(true)}
            type="button"
          >
            <OrbiitIcon icon="FilterIcon" />

            <span>Filters</span>
          </button>

          <FeatureFlag flags={[FeatureFlag.FLAGS.downloadOptInBreakdownCSV]}>
            <Popover
              anchor={({ className, ...props }) => (
                <button
                  {...props}
                  aria-label="context menu"
                  className={clsx(className, filterStyles.FilterPanelButton)}
                  disabled={!formData.fromDate || !formData.untilDate}
                  type="button"
                >
                  <OrbiitIcon icon="DownloadIcon" inline /> Download
                </button>
              )}
              position={Popover.POSITIONS.BOTTOM_RIGHT}
              type={Popover.TYPES.MENU}
            >
              <CTA
                guise={CTA.GUISES.LINK}
                menuitem=""
                name="optin-breakdown-csv"
                to={onDownload}
              >
                Opt-In Forms Report (CSV)
              </CTA>
            </Popover>
          </FeatureFlag>
        </div>
      )}

      {showForm && (
        <Form
          className={filterStyles.DashboardFiltersForm}
          onDirty={setDirty}
          onPristine={setDirty}
          onSubmit={onSubmit}
        >
          <Field
            label="From"
            name="fromDate"
            onChange={setFormField}
            type="date"
            value={formData.fromDate}
          />

          <Field
            label="Until"
            name="untilDate"
            onChange={setFormField}
            type="date"
            value={formData.untilDate}
          />

          <Button.Group>
            {RANGE_SELECT_OPTIONS.map(({key, text}) => (
              <Button
                appearance={Button.APPEARANCES.BASIC}
                className={filterStyles.ButtonGroupButton}
                data-key={key}
                key={key}
                onClick={onSinceClick}
              >
                {text}
              </Button>
            ))}
          </Button.Group>

          {audiences?.size && (
            <div className={audienceDropdownStyles.AudienceDropdown}>
              <div
                aria-hidden={!isDropdownOpen}
                className={audienceDropdownStyles.AudienceDropdownMenu}
                onKeyUp={({ key }) => { if (key === 'esc') setIsDropdownOpen(false); }}
                open={isDropdownOpen}
              >
                <fieldset
                  className={audienceDropdownStyles.AudienceDropdownMenuInner}
                  style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                  }}
                >
                  <AudienceSelectionList
                    onChange={onAudienceToggle}
                    selected={formData.audienceIds}
                    type="checkbox"
                  />
                </fieldset>
              </div>

              <label
                className={audienceDropdownStyles.AudienceDropdownLabel}
                onClick={() => setIsDropdownOpen(!isDropdownOpen) }
              >Audiences
              </label>
            </div>
          )}

          <Button
            disabled={!isDirty}
            title={!isDirty ? 'Nothing to apply' : null}
            type="submit"
          >Apply Filters
          </Button>
        </Form>
      )}
    </section>
  );
}
DashboardFilters.displayName = 'DashboardFilters';

const {
  arrayOf,
  func,
  string,
  node,
  shape,
} = propTypes;

DashboardFilters.propTypes = {
  filters: shape({
    audienceIds: arrayOf(string),
    fromDate: string.isRequired,
    searchQuery: string.isRequired,
    untilDate: string.isRequired,
  }).isRequired,
  onApply: func.isRequired,
};

function VariableSpan({ children }) {
  return (
    <span className={filterStyles.FilterSpan}>
      {children}
    </span>
  );
}
VariableSpan.displayName = 'VariableSpan';

VariableSpan.propTypes = {
  children: node.isRequired,
};
