export default {
  "Page": "_Page_7mvmb_1",
  "Header": "_Header_7mvmb_16",
  "TemplateGroup": "_TemplateGroup_7mvmb_26",
  "TemplateGroupTitle": "_TemplateGroupTitle_7mvmb_37",
  "TemplateDetails": "_TemplateDetails_7mvmb_43",
  "TemplateSelectionCTA": "_TemplateSelectionCTA_7mvmb_48",
  "Cards": "_Cards_7mvmb_54",
  "Card": "_Card_7mvmb_54",
  "CardIcon": "_CardIcon_7mvmb_75",
  "DetailsIcon": "_DetailsIcon_7mvmb_82",
  "CardTitle": "_CardTitle_7mvmb_86",
  "CardField": "_CardField_7mvmb_101",
  "Description": "_Description_7mvmb_128",
  "AiRecommendedDetails": "_AiRecommendedDetails_7mvmb_136",
  "AiRecommended": "_AiRecommended_7mvmb_136",
  "MatchingQuestionBlock": "_MatchingQuestionBlock_7mvmb_154",
  "MatchingQuestionOptions": "_MatchingQuestionOptions_7mvmb_163"
};import "ni:sha-256;mCp--P4ILGPhtPSnSZWzgifkzPGmresYU7Tgn-pTgvs";