import type { EditMatches } from './EditMatches2.tsx';
import type { Match, OptIn } from './Match.d.ts';

import {
  addToPool,
  removeFromGroup,
} from './stateCRUD.mts';


export function onUngroupParticipant(
  this: EditMatches,
  matchId: Match['id'],
  optin: OptIn,
) {
  this.setState({ isDirty: true });
  addToPool(optin, this.setState.bind(this));
  removeFromGroup(matchId, optin, this.setState.bind(this));
}
