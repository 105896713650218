.EngagementHeader {
  align-items: center;
  background-color: #FFF;
  box-shadow: 0 6px 12px rgb(0 0 0 / 11%);
  display: grid;
  gap: var(--grid-gutter);
  grid-template-areas: "Name Numbers Divider Gist";
  grid-template-columns: auto max-content min-content max-content;
  padding: 0.5rem 1rem 1rem;
}

.Name {
  align-items: center;
  align-self: center;
  display: inline-flex;
  gap: var(--grid-gutter);
  grid-area: Name;
}

.Numbers {
  grid-area: Numbers;
}

.Numbers th {
  font-weight: bold;
}

.Numbers th,
.Numbers td {
  font-size: 0.875rem;
  padding: 0.4ch;
  text-align: center;
}

.Divider {
  border-left: 1px solid;
  grid-area: Divider;
  height: 80%;
  place-self: center;
}

.Gist {
  font-size: 0.875rem;
}

.Gist > li {
  margin: 0.125rem 0;
}

.EditButtonMuted {
  color: var(--neutral-medium);
}
