import { errorsStore } from './errors/errors.state.mts';
import { templatesStore } from './templates/templates.store.mts';
import { userStore } from './user/user.store.mts';
import { workspacesStore } from './workspaces/workspaces.store.mts';

export const stores = {
  errors: errorsStore,
  templates: templatesStore,
  user: userStore,
  workspaces: workspacesStore,
};
export type Stores = typeof stores;
