import { getPermissionsFromAccessToken } from '…/service-worker/getPermissionsFromAccessToken.mts';

import type { Role } from '../Role.mts';
import { storeProxyFactory } from '../storeProxyFactory.mts';

import { userState } from './user.state.mts';

import { getMe } from './getMe.op.mts';


export const userStore = storeProxyFactory('Me', userState, {
  default: getMe,
});

/** Handle communication from ServiceWorker */

export function setMyPermssions(
  {
    isGlobalAdmin = false,
    permissions,
  }: {
    isGlobalAdmin: boolean,
    permissions: Array<{ role: string, workspaceId: string }>,
  },
) {
  if (!isGlobalAdmin && !permissions.length) return false;

  userStore.isAuthenticated = true;
  userStore.isGlobalAdmin = isGlobalAdmin;

  userState.permissions ??= new Map();

  userStore.permissions!.clear();
  for (const { role, workspaceId } of permissions) userStore.permissions!.set(workspaceId, role as Role);

  return true;
}

await getPermissionsFromAccessToken() // Check for (and proccess) existing token.
  .then(setMyPermssions)
  .then((tokenExists) => {
    if (tokenExists) return getMe().then((me) => Object.assign(userStore, me));
  });

const channel = new BroadcastChannel('orbiit.ai');

channel.onmessage = ({ data: { type, value } }) => {
  if (type === 'orbiit-access-update') setMyPermssions(value);
};
