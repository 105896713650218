._Chip_fkcdi_1 {
  border: 1px solid var(--brand-subtle-light);
  border-radius: 1em;
  display: inline-block;
  font-size: 0.875em;
  white-space: nowrap;
}

._Chip_fkcdi_1:not(:empty) {
  padding: 0.375em 0.675em;
}

._Chip_fkcdi_1:not([status]) {
  background: var(--brand-subtle-light);
}

._Chip_fkcdi_1[prominence="subtle"] {
  font-size: 0.75em;
  padding: 0.25rem 0.5em;
}

._Chip_fkcdi_1[variant] {
  border: 1px solid;
}

._Chip_fkcdi_1[variant="negative"] {
  background: var(--red-lightest);
  border: 1px solid var(--red-light);
  color: var(--red-medium);
}

._Chip_fkcdi_1[variant="neutral"] {
  background: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  color: var(--neutral);
}

._Chip_fkcdi_1[variant="informative"] {
  background: var(--neutral-lightest);
  color: var(--status-info);
}

._Chip_fkcdi_1[variant="positive"] {
  background: var(--green-lightest);
  border: 1px solid var(--green-lighter);
  color: var(--status-success-dark);
}

._Chip_fkcdi_1[status] {
  border: none;
  color: white;
  font-weight: 500;
}

._Chip_fkcdi_1[status]:empty {
  height: 1em;
  width: 1em;
}

._RatingChip_fkcdi_60 {
  align-items: center;
  border: none;
  display: inline-flex;
  flex-direction: row;
  gap: 0.25em;
  justify-content: flex-start;
}

._RatingIcon_fkcdi_69 {
  height: 0.875em;
  margin-bottom: 0.125em;
}

._CountChip_fkcdi_74 {
  background: rgb(52 96 220 / 10%); /* rgb(var(--brand-accent-ancillary) 0.25); */
  border: none;
  color: var(--brand-accent-ancillary);
  font-weight: 600;
}
