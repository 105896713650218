import { MemberCard } from '…/app/w/workspace/audiences/audience/members/MemberHistory.tsx';

import { getAnswersMap, getFreeTextQuestions } from '…/app/deprecated/utils/questionAnswerUtils.mts';

import { formatDate } from '…/app/common/dateUtils.mts';

import { Answer, FormAnswer } from '…/app/w/workspace/common/Answer/Answer.tsx';
import type { EngagementFull } from '…/app/w/workspace/engagements/Engagement.d.ts';

import type { IFormAnswer, OptInForm } from './optin-forms.d.ts';

import classes from './OptInFormDrawer.module.css';

export function OptInFormDrawer({
  optInForm,
  engagement,
}: {
  optInForm: OptInForm,
  engagement: EngagementFull,
}) {
  const { optInQuestions = new Array() } = engagement || {};

  // Create a map from an answer option-id to the answer text and question title.
  const allAnswerQuestions = getAnswersMap(optInQuestions);
  const freeTextQuestionsMap = getFreeTextQuestions(optInQuestions);

  return (
    <>
      <header className={classes.Header}>
        <MemberCard fields={{ ...optInForm.fields }} />
      </header>

      <div className={classes.Content}>
        <Answer
          answers={[optInForm.fields.email]}
          title="Email"
        />

        {optInForm.fields?.timezoneIso && (
          <Answer
            answers={[optInForm.fields.timezoneIso]}
            title="Timezone"
          />
        )}

        {optInForm.fields?.timeslotsUtc?.length && (
          <Answer
            /* eslint-disable-next-line max-len */
            answers={optInForm.fields.timeslotsUtc.map((date: string) => formatDate(date, optInForm.fields.timezoneIso || engagement.timezoneIso, true))}
            title="Timeslots"
          />
        )}

        {optInForm?.answers.map(({
          stepName, selectedOptions, text,
        }: IFormAnswer) => (
          <FormAnswer
            allAnswerQuestions={allAnswerQuestions}
            freeTextQuestionsMap={freeTextQuestionsMap}
            key={`answer-${stepName}`}
            selectedOptions={selectedOptions}
            stepName={stepName}
            text={text}
          />
        ))}
      </div>
    </>
  );
}

OptInFormDrawer.displayName = 'OptInFormDrawer';
