import teammatesFrag from './teammates.frag.gql';

export const UPSERT_TEAMMATE = `
mutation UpsertWorkspaceTeammate(
  $workspaceId: ID!
  $userEmail: String!
  $firstName: String
  $lastName: String
  $role: SessionRole
  $enableNotification: Boolean
) {
  workspace: setTeammateRoleInWorkspace(
    workspaceId: $workspaceId
    userEmail: $userEmail
    firstName: $firstName
    lastName: $lastName
    role: $role
    enableNotification: $enableNotification
  ) {
    ...teammatesFields
  }
}

${teammatesFrag}
`;
