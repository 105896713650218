import { ReactNode } from 'react';

import classes from './FiltersDrawer.module.css';

export function FiltersHeader({ title = 'Filters' }: { title?: string }) {
  return (
    <header className={classes.FiltersHeader}>
      <h2>{title}</h2>
    </header>
  );
}

FiltersHeader.displayName = 'FiltersHeader';

export function FiltersContent({ children }: { children: ReactNode }) {
  return (
    <section className={classes.FiltersSection}>
      {children}
    </section>
  );
}

FiltersContent.displayName = 'FiltersContent';
