._Content_tf2b3_1 {
  padding: var(--grid-gutter);
  text-align: center;
}

._NPSExpli_tf2b3_6 {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
}

._DoughnutContainer_tf2b3_12 {
  aspect-ratio: 1.643;
  position: relative;
}

._Score_tf2b3_17 {
  align-items: center;
  background-color: white;
  border-radius: 100%;
  bottom: -1em;
  box-shadow: 0 2px 10px 0 rgb(223 223 223 / 100%);
  display: flex;
  font-weight: bold;
  height: 3.9rem;
  justify-content: center;
  left: 49.2%;
  line-height: 0;
  position: absolute;
  translate: -50% -25%;
  width: 3.9rem;
}

._Score_tf2b3_17::before {
  background: url('/speedLines.svg') no-repeat center 33%;
  content: '';
  height: 250%;
  position: absolute;
  width: 250%;
}

._Score_tf2b3_17::after {
  content: '⏴';
  position: absolute;
  rotate: calc(1deg * var(--rotation)); /* add `deg` unit to raw rotation value */
  transform-origin: right center;
  translate: -50% 0;
  width: calc(1.1ch + 50%);
}

._NPSValue_tf2b3_51 {
  margin-top: var(--grid-gutter);
}

._ScaleLabels_tf2b3_55 {
  font-weight: 600;
  margin-top: -0.5em;
}

._ScaleStart_tf2b3_60 {
  color: var(--color);
  margin-left: calc(2 * var(--grid-gutter));
}

._ScaleEnd_tf2b3_65 {
  color: var(--color);
  margin-right: calc(2 * var(--grid-gutter));
}

._OrbiitCallout_tf2b3_70 {
  margin: var(--grid-gutter) auto;
  width: fit-content;
}
