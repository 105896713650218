import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import {
  useEffect,
  useState,
} from 'react';
import {
  useParams,
} from 'react-router-dom';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import { useStore } from '…/app/state/useStore.mts';
import type { WalledRouteParams } from '…/app/route-params.mts';
import { getFilterCount } from '…/app/common/refinement/getFilterCount.mts';
import { useRefinementParams } from '…/app/common/refinement/useRefinementParams.mts';
import {
  PAGE_ITEM_LIMIT,
  type Search,
  paginationFactory,
  resetPaginationOnRefine,
} from '…/app/common/pagination/factories.mts';
import { usePagination } from '…/app/common/pagination/usePagination.mts';
import type {
  Sort,
  TableHeading,
} from '…/app/common/Table/Table.tsx';
import { Table } from '…/app/common/Table/Table.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';
import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import type { WidgetConfigBase } from '…/app/common/widget.mts';

import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { FilterIcon } from '…/app/common/filtering/FilterIcon.tsx';

import type { FormConfig } from './crudForms.mts';
import type {
  Engagement,
  EngagementFilters,
  EngagementsDict,
  EngagementsSortFields,
} from './Engagement.d.ts';
import { forms } from './crudForms.mts';
import { MemoizedEngagementFiltersForm } from './FiltersForm.tsx';
import {
  type SortableFields,
  getEngagements,
} from './getEngagements.op.mts';
import { transformEngagementsToTabularData } from './transformEngagementsToTabularData.tsx';

import classes from './EngagementsList.module.css';

export interface WidgetConfig extends WidgetConfigBase {
  name: 'filters',
}

const EngagementsTable = Table<EngagementsSortFields>;

export function EngagementsListPage() {
  const { workspaceId } = useParams() as WalledRouteParams;
  const user = useStore((data) => data.user);
  const { slug: workspaceSlug } = useStore((data) => data.workspaces[workspaceId]);

  const {
    count: [count, setCount],
    cursors,
    page: [page, setPage],
    records: [engagements, setEngagements],
  } = usePagination<Engagement>();

  const [form, setForm] = useState<FormConfig>();
  const [loading, setLoading] = useState<boolean>();

  const {
    filters: [filters, setFilters],
    search: [search, setSearch],
    sort: [sort, setSort],
  } = useRefinementParams({
    filters: {
      AUDIENCE: new Array(),
      CLOSING_DATE: new Array(),
      PRODUCT_TYPE: new Array(),
      STATUS: new Array(),
    } as EngagementFilters,
    search: {
      q: '',
    } as Search,
    sort: {
      ascending: false,
      fieldName: 'optInOpensAt',
    } as Sort<SortableFields>,
  });

  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>();

  const canRecur = !!useFeatureFlag([FLAGS.recurringEngagements]);
  const canUseRecurringLink = !!useFeatureFlag([FLAGS.recurringEngagementsLink]);
  const isEditor = !!useEditorRole();

  const CRUDForm = forms[form?.name ?? ''];

  useEffect(() => {
    if (!workspaceId) return;

    setLoading(true);

    const pagination = paginationFactory<Engagement, 'engagements'>(
      'engagements',
      {
        cursors,
        direction: page.direction,
        filters,
        limit: page.limit,
        reset: page.reset,
        search: search.q,
      },
      {
        setCount: setCount,
        setRecords: setEngagements,
      },
    );

    getEngagements(
      workspaceId,
      {
        ...pagination.props,
        sort,
      },
    )
      .then(pagination.then)
      .finally(() => setLoading(false));
  }, [
    filters,
    page,
    search,
    sort,
  ]);

  const isNewWorkspace = isWorkspaceNew({
    engagements,
    filters,
    loading,
    search,
  });

  const setRefineCriteria = resetPaginationOnRefine<SortableFields, EngagementFilters>(
    setPage,
    setFilters,
    setSearch,
    setSort,
  );

  return (
    <main
      className="ContentContainer"
      data-testid="EngagementsListPage"
    >
      <Bureau className={classes.Bureau}>
        <Shelf className="ListPage">
          <header className={clsx('SplitHeader', classes.TableHeader)}>
            <h1>
              <OrbiitIcon
                fill="#fff"
                icon="MembersIcon"
                inline
                size="medium"
              />

              {' '}

              Engagements
            </h1>

            <fieldset className="SplitHeader" disabled={loading || isNewWorkspace}>
              <FilterIcon
                count={getFilterCount(filters)}
                onClick={() => setWidgetConfig({
                  data: {
                    filters,
                    onSubmit(_filters: EngagementFilters) {
                      setRefineCriteria({
                        filters: _filters,
                        search,
                        sort,
                      });
                      setWidgetConfig(undefined);
                    },
                  },
                  key: 'filters',
                  name: 'filters',
                  type: 'drawer',
                })}
                testId="EngagementsTableFilterButton"
              />

              <Form
                name="search"
                onReset={() => setRefineCriteria({
                  filters,
                  search: { q: '' },
                  sort,
                })}
                onSubmit={(_search: Search) => setRefineCriteria({
                  filters,
                  search: _search,
                  sort,
                })}
                role="search"
              >
                <Field
                  arrangement={Field.ARRANGEMENTS.STAND_ALONE}
                  defaultValue={search.q}
                  label={null}
                  name="q"
                  placeholder="Search"
                  type="search"
                />
              </Form>

              <Button
                aria-label="create a new engagement"
                onClick={() => setForm({
                  data: {
                    setEngagements,
                    workspaceId,
                  },
                  name: 'create',
                })}
              >+ New
              </Button>
            </fieldset>
          </header>

          {isNewWorkspace
            ? (
              <section className={classes.Welcome}>
                <OrbiitIcon icon="EmptyEngagementIcon" size="xxlarge" />

                <h4>Welcome to Orbiit! It only gets better from here.</h4>

                <Button
                  appearance={Button.APPEARANCES.AFFIRMING}
                  aria-label="create a new engagement"
                  onClick={() => setForm({
                    data: {
                      setEngagements,
                      workspaceId,
                    },
                    name: 'create',
                  })}
                >+ Start here
                </Button>
              </section>
            )
            : (
              <EngagementsTable
                className={classes.Table}
                headings={headings}
                loading={loading}
                onSort={(_sort) => setRefineCriteria({
                  filters,
                  search,
                  sort: _sort,
                })}
                pagination={{
                  onPage: setPage,
                  page,
                  total: count,
                }}
                records={transformEngagementsToTabularData(
                  engagements,
                  {
                    limit: PAGE_ITEM_LIMIT,
                    page: page.number,
                    user,
                    workspaceId,
                    workspaceSlug,
                  },
                  {
                    setCount,
                    setEngagements,
                    setForm,
                  },
                  {
                    canRecur,
                    canUseRecurringLink,
                    isEditor,
                  },
                )}
                sort={sort}
              />
            )
          }
        </Shelf>

        <Drawer
          className={classes.Drawer}
          modal="backdropped"
          onClose={() => setWidgetConfig(undefined)}
          open={widgetConfig?.type === 'drawer'}
        >
          {widgetConfig?.type === 'drawer' && (
            <MemoizedEngagementFiltersForm
              filters={widgetConfig.data.filters}
              setFilters={widgetConfig.data.onSubmit}
            />
          )}
        </Drawer>
      </Bureau>

      <Dialog
        backdropped
        open={!!form}
        setOpen={setForm}
      >
        <CRUDForm {...form?.data} />
      </Dialog>
    </main>
  );
}
EngagementsListPage.displayName = 'EngagementsListPage';

const handle = {
  title: 'Engagements',
};

export {
  EngagementsListPage as Component,
  handle,
};

const headings = new Map<string, TableHeading>([
  ['meta', {}],
  ['name', {
    label: 'Name',
    sortable: true,
    wrap: true,
  }],
  ['product', {
    label: 'Type',
    sortable: true,
  }],
  ['optInFormsCount', {
    label: 'Opt-ins',
    numeric: true,
    sortable: true,
  }],
  ['matchesCount', {
    label: 'Matches',
    numeric: true,
    sortable: true,
  }],
  ['nps.score', {
    label: 'NPS',
    numeric: true,
  }],
  ['audience.name', {
    label: 'Audience',
    wrap: true,
  }],
  ['optInOpensAt', {
    label: 'Start Date',
    numeric: true,
    sortable: true,
  }],
  ['optInClosesAt', {
    label: 'Close Date',
    numeric: true,
    sortable: true,
  }],
  ['actions', {}],
]);

const isWorkspaceNew = ({
  engagements,
  filters,
  loading,
  search,
}: {
  engagements: EngagementsDict,
  filters: EngagementFilters,
  loading?: boolean,
  search: Search,
}) => (
  loading === false
  && !engagements.size
  && !getFilterCount(filters)
  && !search?.q
);
