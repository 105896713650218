.FlexRow {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.FlexCol {
  display: flex;
  flex-direction: column;
}

.Container {
  padding: 1rem;
}

.EmailStatsRow {
  border-top: 1px solid var(--neutral-light);
  justify-content: space-between;
  padding: 0.375rem 0;
}

.EmailStatsRow:first-child {
  border-top: none;
}

.CardTitle {
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}

.CardDate {
  color: #AAA;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  text-transform: capitalize;
}

.Stat {
  color: var(--neutral-medium);
  display: block;
  font-size: 0.875rem;
}

@keyframes loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}

.SkeletonLoader {
  animation: loading 1s infinite;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  height: 20px;
  width: 100%;
}
