import { useParams } from 'react-router-dom';

import { useStore } from '…/app/state/useStore.mts';


export const ROLES = ['OBSERVER', 'EDITOR', 'ADMIN', 'INTERNALADMIN'];

// const OBSERVER = ROLES.indexOf('OBSERVER');
// const EDITOR = ROLES.indexOf('EDITOR');
// const ADMIN = ROLES.indexOf('ADMIN');
const INTERNALADMIN = ROLES.indexOf('INTERNALADMIN');

export const useRequiredRole = ({ level }) => {
  const { workspaceId } = useParams();
  const user = useStore((data) => data.user);

  if (!user?.isAuthenticated) { return undefined }

  const role = user.permissions.get(workspaceId);

  if (!role) return false;

  const userLevel = ROLES.indexOf(role);
  if (userLevel >= INTERNALADMIN) { return true }

  const requiredLevel = ROLES.indexOf(level);
  if (userLevel >= requiredLevel) { return true }

  return false; // Don't have permission to see this feature
};

export const useEditorRole = () => useRequiredRole({ level: 'EDITOR' });

export const useAdminRole = () => useRequiredRole({ level: 'ADMIN' });

export const useInternalAdminRole = () => useRequiredRole({ level: 'INTERNALADMIN' });
