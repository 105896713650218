import {
  Fragment,
} from 'react';


import { DeleteTeammatesForm } from './DeleteTeammatesForm.tsx';
import { UpsertTeammateForm } from './UpsertTeammateForm.tsx';


export const forms = {
  '': Fragment,
  add: UpsertTeammateForm,
  delete: DeleteTeammatesForm,
  edit: UpsertTeammateForm,
};

export interface FormConfig {
  data?: object,
  key: string,
  name: keyof typeof forms,
}
