query GetEngagementDashboard($workspaceId: ID!, $engagementId: ID!) {
  workspace(workspaceId: $workspaceId) {
    id
    engagement(id: $engagementId) {
      id
      audience {
        id
        memberCount
        name
      }
      audienceSizeTotal
      engagementStats {
        detractors
        nps
        numFeedbackForms
        promoters
        passives
      }
      feedbackFormsAggregate {
        answersStatistics {
          optionsCount {
            count
            id
          }
          stepName
        }
      }
      feedbackQuestions {
        __typename
        id
        isDisabled
        lockedProps
        question
        stepName
        ... on EngagementQuestionUnknown {
          id
        }
        ... on EngagementQuestionGenericSingleSelectStep {
          props {
            ledeIn
            options {
              id
              isDisabled
              text
            }
            title
          }
        }
        ... on EngagementQuestionGenericMultiSelectStep {
          props {
            ledeIn
            maxSelect
            maxWidth
            minSelect
            numColumns
            options {
              id
              isDisabled
              text
            }
            title
            wildcard {
              id
              isDisabled
              text
            }
          }
        }
      }
      matchesCount
      matchRating {
        rating
        stars
      }
      optInFormsAggregate {
        answersStatistics {
          optionsCount {
            count
            id
          }
          stepName
        }
      }
      optInFormsCount
      optInQuestions {
        __typename
        id
        isDisabled
        lockedProps
        question
        stepName
        ... on EngagementQuestionUnknown {
          id
        }
        ... on EngagementQuestionGenericSingleSelectStep {
          props {
            ledeIn
            options {
              id
              isDisabled
              text
            }
            title
          }
        }
        ... on EngagementQuestionGenericMultiSelectStep {
          props {
            ledeIn
            maxSelect
            maxWidth
            minSelect
            numColumns
            options {
              id
              isDisabled
              text
            }
            title
            wildcard {
              id
              isDisabled
              text
            }
          }
        }
      }
      optInRepeatedCount
      slug
      status
      title
    }
  }
}
