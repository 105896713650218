import { useParams } from 'react-router-dom';
import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type FormProps } from 'form5/react/Form';
import { useState } from 'react';
import { toast } from 'sonner';

import type { AudienceRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';

import type {
  Audience,
  AudienceId,
} from '../Audience.d.ts';
import type { getSelectedMemberData } from '../getSelectedMemberData.mts';
import { AudienceUpdateWarning } from '../AudienceUpdateWarning.tsx';

import type { Member } from './Member.d.ts';
import type { SelectedAudiences } from './AssociatedAudiencesList.tsx';
import { AssociatedAudiencesList } from './AssociatedAudiencesList.tsx';

import classes from './MemberForm.module.css';

const SHOW_MAX = 6;

type MemberIds = Member['id'][];

export function BatchUpdateMembersForm({
  members,
  onlyAudience = false,
  onSubmit,
}: {
  members: ReturnType<typeof getSelectedMemberData>,
  onlyAudience: boolean,
  onSubmit(memberIds: MemberIds, audienceIds: AudienceId[]): Promise<MemberIds>,
}) {
  const { audienceId, workspaceId } = useParams() as AudienceRouteParams;
  const audience = useStore((data) => data.workspaces[workspaceId].audiences.get(audienceId)) as Audience;

  const [isSaving, setIsSaving] = useState(false);

  const [selectedAudiences, setSelectedAudiences] = useState<SelectedAudiences>(new Map(
    (audience && !audience.isBuiltin) ? [[audience.id, audience.name]] : [],
  ));
  const isEditor = !!useEditorRole();
  const memberIds: MemberIds = [];
  const memberNames = [];
  const companyOptions: { [k: string]: string } = {};
  const roleOptions: { [k: string]: string } = {};

  for (const [id, { fields }] of members) {
    memberIds.push(id);
    if (members.size <= SHOW_MAX || memberNames.length < SHOW_MAX) {
      memberNames.push(
        <div className={classes.Name} key={id}>
          {members.size > SHOW_MAX && memberNames.length === (SHOW_MAX - 1)
            ? `and ${(members.size + 1) - SHOW_MAX} more...`
            : fields?.fullName}
        </div>,
      );
    }
    if (fields?.company) companyOptions[fields.company] = fields.company;
    if (fields?.role) roleOptions[fields.role] = fields.role;
  }

  // D = MemberUpdate & { audienceIds: AudienceId[] }
  const onBatch: FormProps['onSubmit'] = ({ audienceIds, fields }) => {
    const updates = new Array(memberIds.length);
    for (const [i, id] of memberIds.entries()) {
      updates[i] = {
        email: members.get(id)!.email,
        fields,
        id: id,
      };
    }

    setIsSaving(true);
    const promise = onSubmit(updates, audienceIds)
      .finally(() => setIsSaving(false));

    toast.promise(promise, {
      error: 'Batch update failed',
      loading: 'Batching…',
      success: `${memberIds.length} member${memberIds.length > 1 ? 's' : ''} updated`,
    });

    return promise;
  };

  return (
    <Form
      name="batch-upsert-members"
      onSubmit={onBatch}
    >
      <h1>
        {onlyAudience ? 'Add Members to Audiences' : 'Update multiple Members'}
      </h1>

      <AudienceUpdateWarning />

      <p>
        {onlyAudience ? 'Add the following Members to Audiences:' : 'You are updating the following Members:'}
      </p>

      <div className={classes.EnumeratedUpdateTargets}>{memberNames}</div>

      <fieldset disabled={!isEditor}>
        {!onlyAudience && (
          <fieldset className={classes.Fieldset} name="fields">
            <Field
              arrangement={Field.ARRANGEMENTS.STACKED}
              fluid
              label="Company"
              name="company"
              options={companyOptions}
              placeholder="Good inc."
            />

            <Field
              arrangement={Field.ARRANGEMENTS.STACKED}
              fluid
              label="Role"
              name="role"
              options={roleOptions}
              placeholder="CEO of in-house marketing"
            />
          </fieldset>
        )}

        <AssociatedAudiencesList
          label="Add to audience(s)"
          selected={selectedAudiences}
          setSelected={setSelectedAudiences}
        />

        <SubmitButton
          appearance={Button.APPEARANCES.PRIMARY}
          disabled={isSaving}
          isSubmitting={isSaving}
          type="submit"
        >Save
        </SubmitButton>
      </fieldset>
    </Form>
  );
}
BatchUpdateMembersForm.displayName = 'BatchUpdateMembersForm';
