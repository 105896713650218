._Container_19lax_1 {
  background-color: var(--brand-light);
}

._ContentWrapper_19lax_5 {
  display: flex;
  flex: 1;
  flex-direction: row;
}

._SidebarWrapper_19lax_11 {
  flex: none;
}

._Content_19lax_5 {
  width: 100%;
}
