.Header {
  grid-area: Header;
}

.FlexEnd {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
