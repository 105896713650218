import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import CREATE_SESSION from './createSession.gql';

export function createSession({ name, role }: { name: string; role: string; }) {
  return gqlOperation(CREATE_SESSION, {
    name,
    role,
  })
    .then((data) => data);
}
