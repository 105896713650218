import type {
  Dispatch,
  SetStateAction,
} from 'react';
import _memoize from 'lodash-es/memoize.js';
import { memo } from 'react';
import { capitalize } from 'lodash-es';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { getNow } from '…/app/common/dateUtils.mts';
import { SELECTABLE_STEPS_SET } from '…/app/deprecated/constants.mts';
import type { EngagementQuestion } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { FiltersContent, FiltersHeader } from '…/app/common/filtering/FiltersDrawer.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';

import {
  FEEDBACK_FORM_NPS_STATUS_DICT,
  FEEDBACK_FORM_RATING_STATUS_DICT,
  type FeedbackFormsFilters,
} from './FeedbackFormRefinements.mts';
import classes from './FiltersForm.module.css';


const getClosingDateOptions = _memoize((now: ReturnType<typeof getNow>) => [
  ['Last hour', now.minus({ hours: 1 }).toISO()],
  ['Last 3 hours', now.minus({ hours: 3 }).toISO()],
  ['Today', now.startOf('day').toISO()],
  ['Since yesterday', now.minus({ days: 1 }).startOf('day')
    .toISO()],
  ['This week', now.startOf('week').toISO()],
]);

export function FeedbackFormsFiltersForm({
  filters: {
    CREATED_AT,
    MATCH_RATING,
    NPS,
    SELECTED_OPTIONS,
  },
  setFilters,
  questions,
}: {
  filters: FeedbackFormsFilters,
  setFilters: Dispatch<SetStateAction<FeedbackFormsFilters>>,
  questions: Array<any>,
}) {
  const filteredQuestions: any[] = filterQuestions(questions);

  return (
    <>
      <FiltersHeader />

      <FiltersContent>
        <Form
          name="engagement-filters"
          onReset={() => setFilters({
            CREATED_AT: new Array(),
            MATCH_RATING: new Array(),
            NPS: new Array(),
            SELECTED_OPTIONS: new Array(),
          })}
          onSubmit={(_, all) => setFilters(all)}
        >
          <Details open>
            <Summary className={classes.Summary}>NPS</Summary>

            <div className={classes.FilterOptions}>
              {(() => {
                const output = [];

                for (const { 0: key, 1: label } of Object.entries(FEEDBACK_FORM_NPS_STATUS_DICT)) {
                  output.push(
                    <Field
                      defaultChecked={NPS?.includes(key)}
                      id={`NPS.${key}`}
                      key={key}
                      label={
                        <span className={classes.LabelWithIcon}>
                          {label}

                          <OrbiitIcon icon={`${capitalize(key)}Icon`} size="medium" />
                        </span>
                      }
                      name="NPS[]"
                      type="checkbox"
                      value={key}
                    />,
                  );
                }

                return output;
              })()}
            </div>
          </Details>

          <Details open>
            <Summary className={classes.Summary}>Match Rating</Summary>

            <div className={classes.FilterOptions}>
              {(() => {
                const output = [];

                for (const { 0: key, 1: label } of Object.entries(FEEDBACK_FORM_RATING_STATUS_DICT)) {
                  output.push(
                    <Field
                      defaultChecked={MATCH_RATING?.includes(key)}
                      id={`MATCH_RATING.${key}`}
                      key={key}
                      label={
                        <span className={classes.LabelWithIcon}>
                          {label}

                          <OrbiitIcon icon={`${capitalize(key)}Icon`} size="medium" />
                        </span>
                      }
                      name="MATCH_RATING[]"
                      type="checkbox"
                      value={key}
                    />,
                  );
                }

                return output;
              })()}
            </div>
          </Details>

          {filteredQuestions.map((q: any, ix: number) => (
            <Details key={q.stepName}>
              <Summary className={classes.Summary}>
                {`${q.props.title.substr(0, 30)}${q.props.title.length > 30 ? '...' : ''}`}
              </Summary>

              <div className={classes.FilterOptions}>
                {q.props.options.map((option: any) => (
                  <Field
                    defaultChecked={SELECTED_OPTIONS?.includes(option.id)}
                    id={`SELECTED_OPTIONS.${option.id}`}
                    key={option.id}
                    label={option.text}
                    name={`SELECTED_OPTIONS[${ix}]`}
                    type="checkbox"
                    value={option.id}
                  />
                ))}
              </div>
            </Details>
          ))}

          <Details>
            <Summary className={classes.Summary}>Closing date</Summary>

            <div className={classes.FilterOptions}>
              {getClosingDateOptions(getNow()).map(({ 0: label, 1: value }) => (
                <Field
                  defaultChecked={CREATED_AT?.includes(value!)}
                  id={`CREATED_AT.${label}`}
                  key={label}
                  label={label}
                  name="CREATED_AT[]"
                  type="radio"
                  value={value}
                />
              ))}
            </div>
          </Details>

          <Button.Group>
            <Button appearance={Button.APPEARANCES.BASIC} type="reset">Clear</Button>

            <Button appearance={Button.APPEARANCES.PRIMARY} type="submit">Apply</Button>
          </Button.Group>
        </Form>
      </FiltersContent>
    </>
  );
}
FeedbackFormsFiltersForm.displayName = 'FeedbackFormsFiltersForm';

const filterQuestions = (questions: EngagementQuestion[]) =>
  questions
    .filter((q) => !q?.isDisabled && SELECTABLE_STEPS_SET.has(q.question));

export const MemoizedFeedbackFormsFiltersForm = memo(FeedbackFormsFiltersForm);
MemoizedFeedbackFormsFiltersForm.displayName = 'MemoizedFeedbackFormsFiltersForm';
