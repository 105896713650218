.Bureau {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches */
}

.Drawer {
  background: var(--neutral-lightest);
  padding: 0;
}

.Content {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.SectionSplit {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.Instructions {
  align-items: left;
  flex: 2;
}

.Instructions h3,
.Instructions p {
  margin-bottom: 1rem;
}

.ApiKey {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.IntegrationName {
  align-items: center;
  display: flex;
  gap: 0.375rem;
}

.IconButton,
.IconButtonDelete,
.IconButtonConnect {
  background-color: transparent;
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  color: var(--neutral-dark);
  cursor: pointer;
  margin: 0 0.125rem;
  padding: 0.5rem;
}

.IconButton:hover {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--brand-accent-primary);
  color: var(--brand-accent-primary-hover);
}

.IconButtonConnect {
  background-color: var(--status-success-lightest);
  border: 1px solid var(--status-success-dark);
  color: var(--status-success-darker);
}

.IconButtonDelete:hover {
  border: 1px solid var(--status-error);
  color: var(--status-error);
}

.SubText {
  color: var(--neutral-medium);
  display: block;
  font-size: 0.75rem;
  line-height: 1em;
  margin-top: 0.375rem;
}
