import type { HTTPError } from 'ky';

import { rpc } from '…/app/common/rpc/client.mts';

import type { Engagement } from '../../Engagement.d.ts';
import type { MatchMetas, OptIn } from './Match.d.ts';


export function fetchMatchMeta(
  engagementId: Engagement['id'],
  optinIds: Array<OptIn['id']>,
) {
  return rpc
    .post('actions/match-meta', {
      json: {
        engagementId,
        optinIds,
      },
    })
    .json<MatchMetas>()
    .catch((err: HTTPError) => {
      throw Object.assign(
        new AggregateError(
          new Array(err),
          'Couldn’t retrieve match meta',
        ),
        { code: err?.response.status },
      );
    });
}
