import engagementFrag from './gql/engagement.frag.gql.mjs';

export const UPDATE_ENGAGEMENT_RECURRANCE = `
mutation UpdateEngagementRecurrance(
  $engagementId: ID!
  $engagementRecurrance: UpdateEngagementRecurranceInput!
) {
  engagement: updateEngagementRecurrance(
    engagementId: $engagementId
    engagementRecurrance: $engagementRecurrance
  ) {
    ...engagementFields
  }
}

${engagementFrag}
`;
