._AudienceDropdown_fodyf_1 {
  display: flex;
  flex-direction: column;
  min-width: 15em;
  position: relative;
}

._AudienceDropdownLabel_fodyf_8 {
  align-items: center;
  background-color: white;
  border: 1px solid #DEDEDE;
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  gap: 0.5em;
  line-height: 1;
  min-height: 100%;
  padding: 1em;
}

._AudienceDropdownLabel_fodyf_8::before {
  border-bottom: 0.1em;
  border-color: initial;
  border-left: 0;
  border-radius: 0.1em;
  border-right: 0.1em;
  border-style: solid;
  border-top: 0;
  content: "";
  display: inline-block;
  height: 0.5em;
  margin-top: -0.2em;
  transform: rotate(-45deg);
  transition: transform ease-in-out var(--transition-duration-glyphs);
  width: 0.5em;
}

._AudienceDropdownMenu_fodyf_38[open] + ._AudienceDropdownLabel_fodyf_8::before {
  transform: rotate(45deg);
}

._AudienceDropdownMenu_fodyf_38 {
  background-color: white;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  display: block;
  height: auto;
  left: 0;
  max-height: 0;
  min-width: 30em;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: calc(100% + 0.2em);
  transition: max-height ease-in-out var(--transition-duration-controls);
  z-index: var(--zindex-controls);
}

._AudienceDropdownMenu_fodyf_38[open] {
  max-height: 100ch;
  overflow-y: auto;
}

._AudienceDropdownMenu_fodyf_38::backdrop {
  display: none;
}

._AudienceDropdownMenuInner_fodyf_68 {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}
