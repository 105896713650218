import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import { DateTime } from 'luxon';

import { formatRelativeDate } from '…/app/common/dateUtils.mts';

import type { TableDatum, TableHeading } from '…/app/common/Table/Table.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import { composeCompanyRole } from '…/app/w/workspace/audiences/audience/members/MemberHistory.tsx';

import type {
  FeedbackForm,
  FeedbackFormsDict,
  Member,
  WidgetConfig,
} from './feedback-forms.d.ts';
import { MatchRatingChip } from './MatchRatingChip.tsx';

import classes from './FeedbackFormsPage.module.css';


export const tableHeadings = new Map<string, TableHeading>([
  ['name', {
    label: 'Name',
    sortable: true,
    wrap: true,
  }],
  ['testimonial', {
    label: 'Testimonial',
    wrap: true,
  }],
  ['participants', {
    label: 'Matched With',
    wrap: true,
  }],
  ['nps', {
    label: 'NPS',
    numeric: true,
    sortable: true,
    wrap: true,
  }],
  ['matchRating', {
    label: 'Match Rating',
    numeric: true,
    sortable: true,
    wrap: true,
  }],
  ['createdAtDate', {
    label: 'Date',
    numeric: true,
    sortable: true,
  }],
  ['actions', {}],
]);

export function transformFeedbackFormsToTabularData(
  feedbackForms: FeedbackFormsDict,
  {
    page,
    limit,
  }: {
    page: Int,
    limit: Int,
  },
  {
    setWidgetConfig,
  }: {
    setWidgetConfig: (config: WidgetConfig) => void,
  },
) {
  const start = (page - 1) * limit;
  const end = page * limit;

  return Array.from(feedbackForms.values())
    .slice(start, end)
    .map((feedbackForm: FeedbackForm) => {
      const {
        id,
        createdAt,
        fields,
        optInForm: {
          fields: {
            email, fullName, role, company,
          },
        },
      } = feedbackForm;
      const {
        matchRating,
        nps,
        testimonial,
      } = fields || {};

      const counterparts = feedbackForm.match.members.filter((o: Member) => o.id !== feedbackForm.member.id);
      const onViewForm = () => setWidgetConfig({
        data: { feedbackForm },
        key: `feedback.${id}`,
        name: 'form',
        type: 'drawer',
      });

      const standardFields: TableDatum[] = [
        {
          display: false,
          fieldName: 'key',
          raw: id,
        },
        {
          display: (
            <div className={clsx(classes.FlexCol)}>
              <h4>
                {fullName || email}
              </h4>

              <h5 className={classes.SubTitle}>
                {composeCompanyRole(role, company)}
              </h5>
            </div>
          ),
          fieldName: 'name',
          onClick: onViewForm,
          raw: fullName,
        },
        {
          display: testimonial
            ? (<blockquote className={classes.Testimonial}>{testimonial}</blockquote>)
            : null,
          fieldName: 'testimonial',
          onClick: onViewForm,
          raw: testimonial,
        },
        {
          display: (
            <ul className={clsx(classes.FlexCol)}>
              {counterparts.map((counterpart: Member) => (
                <li key={`${id}.${counterpart.id}`}>
                  <span>{counterpart.fields.fullName}</span>

                  {' '}

                  <span className={classes.SubTitle}>
                    {composeCompanyRole(counterpart.fields.role, counterpart.fields.company)}
                  </span>
                </li>
              ))}
            </ul>
          ),
          fieldName: 'participants',
          onClick: onViewForm,
          raw: counterparts.map((member: Member) => member.fields.fullName).join(', '),
        },
        {
          display: (
            <Chip rating={nps} />
          ),
          fieldName: 'nps',
          onClick: onViewForm,
          raw: nps,
        },
        {
          display: (
            <MatchRatingChip matchRating={matchRating} />
          ),
          fieldName: 'matchRating',
          onClick: onViewForm,
          raw: matchRating,
        },
        {
          display: (<time dateTime={createdAt}>{formatRelativeDate(DateTime.fromISO(createdAt))}</time>),
          fieldName: 'createdAtDate',
          raw: createdAt,
        },
        {
          display: (
            <Popover
              anchor={({ className, ...props }) => (
                <Button
                  {...props}
                  appearance={Button.APPEARANCES.BASIC}
                  className={clsx(className, 'ContextMenuIcon')}
                  title="more actions"
                  variant={Button.VARIANTS.GLYPH}
                />
              )}
              position={Popover.POSITIONS.BOTTOM_RIGHT}
              type={Popover.TYPES.MENU}
            >
              <CTA
                guise={CTA.GUISES.LINK}
                menuitem=""
                to={onViewForm}
              >View Form
              </CTA>
            </Popover>
          ),
          fieldName: 'actions',
        },
      ];

      return standardFields;
    });
}
