._AdviceFigure_1kx22_1 {
  max-height: 20ch;
  max-width: 20ch;
  width: 60%;
}

._Tips_1kx22_7 > li {
  display: grid;
  gap: 0.6em;
  grid-template-columns: min-content auto;
  margin: var(--grid-gutter) 0;
}

._Tips_1kx22_7 > li::before {
  content: '👉';
}
