import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';
import { rpc } from '…/app/common/rpc/client.mts';

import type {
  AudienceId,
} from '../Audience.d.ts';

import type {
  MemberUpdate,
} from './Member.d.ts';
import UPSERT_MEMBERS_DETAILS from './upsertMembers.gql';


export async function upsertMembers(
  workspaceId: ObjectId,
  updates: MemberUpdate | MemberUpdate[],
  audienceIds?: AudienceId[],
) {
  if (!Array.isArray(updates)) updates = [updates];

  const members = new Array(updates.length);
  for (
    const [i, {
      email,
      fields,
    }] of updates.entries()
  ) {
    members[i] = {
      email,
      ...fields,
    };
  }

  return gqlOperation<{ members: UpdateResult[] }>(UPSERT_MEMBERS_DETAILS, {
    members,
    workspaceId,
  })
    .then((data) => data.members.map(({ id }, i) => ({
      ...(updates as MemberUpdate[])[i],
      id,
    } as UpdateResult)))
    .then(async (results) => {
      const memberIds = new Array(results.length);
      for (const [i, result] of results.entries()) {
        memberIds[i] = result.id;
        if (audienceIds?.length) result.audienceIds = audienceIds;
      }
      if (audienceIds?.length) {
        await rpc.post('actions/add-memberIds-to-audiences', {
          json: {
            audienceIds,
            memberIds,
            workspaceId,
          },
        });
      }

      return results;
    });
}

type UpdateResult = MemberUpdate & { audienceIds?: AudienceId[] };
