import type { MappedQuestions, Question } from './ParticipantQuestions.d.ts';


export function mapQuestions(questions: Question[]) {
  const map: MappedQuestions = { custom: [] };
  let customQuestionIdx = 0;

  for (const q of questions) {

    if (q.stepName.startsWith('custom_')) {
      map.custom.push({
        ...q,
        target: `questions.custom[${customQuestionIdx++}]`,
      });
    } else {
      map[q.stepName] = {
        ...q,
        target: `questions.${q.stepName}`,
      };
    }
  }

  return map;
}
