import { Fragment } from 'react';

import { DeleteAudienceForm } from './audience/DeleteAudienceForm.tsx';
import { UpsertAudienceForm } from './audience/UpsertAudienceForm.tsx';


export const forms = {
  '': Fragment,
  clone: UpsertAudienceForm,
  create: UpsertAudienceForm,
  delete: DeleteAudienceForm,
  upsert: UpsertAudienceForm,
};

export interface FormConfig {
  data?: object,
  key: string,
  name: keyof typeof forms,
}
