import type { GraphQLFilter } from '…/app/common/filtering/filters.d.ts';
import { getGQLFilterCount } from '…/app/common/refinement/getFilterCount.mts';
import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';
import type { PaginationProps } from '…/app/common/pagination/pagination.d.ts';
import type { Sort } from '…/app/common/Table/Table.tsx';

import { GET_AUDIENCE_MEMBERS } from './getAudienceMembers.gql.mjs';

import type { Members } from './Member.d.ts';
import {
  type SortableFields,
  mapSortableFieldsToGQLConstant,
} from '../AudienceFilters.mts';


export function getAudienceMembers(
  workspaceId: ObjectId,
  audienceId: ObjectId,
  {
    after: membersAfterCursor,
    before: membersBeforeCursor,
    filters,
    first: firstNMembers,
    last: lastNMembers,
    search,
    sort,
  }: GetAudienceMembersProp = {} as GetAudienceMembersProp,
) {
  const numFilters = getGQLFilterCount(filters);
  return gqlOperation<{ audience: { members: Members } }>(GET_AUDIENCE_MEMBERS, {
    audienceId,
    firstNMembers,
    lastNMembers,
    membersAfterCursor,
    membersBeforeCursor,
    search,
    workspaceId,
    ...(filters && {
      filters,
    }),
    ...(sort && {
      sort: {
        direction: sort.ascending ? 'ASC' : 'DESC',
        field: mapSortableFieldsToGQLConstant[sort.fieldName],
      },
    }),
  }, {
    ...((search || numFilters) && {
      eventData: {
        data: JSON.stringify({
          filters,
          numFilters,
          search,
          workspaceId,
        }),
        name: 'Members Search & Filter',
      },
    }),
  })
    .then((data) => data.audience);
}

interface GetAudienceMembersProp extends PaginationProps {
  filters: GraphQLFilter[],
  sort?: Sort<SortableFields>,
}
