mutation AddListToSync(
  $workspaceId: ID!,
  $integrationId: ID!,
  $remoteId: String!,
  $displayName: String!
) {
  completed: addListToSync(
    workspaceId: $workspaceId,
    integrationId: $integrationId,
    remoteId: $remoteId,
    displayName: $displayName
  )
}
