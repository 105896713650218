import { clsx } from 'clsx';
import Form from 'form5/react/Form';
import {
  type Component,
  type PropsWithChildren,
  useEffect,
} from 'react';
import {
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';

import { UnsavedChangesWarning } from '…/app/w/workspace/common/UnsavedChangesWarning/UnsavedChangesWarning.tsx';

import type {
  EditMatches,
  MatchEditingState,
} from './EditMatches2.tsx';
import { MatchGroup } from './MatchGroup.tsx';
import { getPageSlices } from './getPageSlices.mts';
import classes from './Matches.module.css';


export function MatchesForm({
  children,
  deletedMatches,
  dragging,
  formId,
  groups,
  isDirty,
  isLoading,
  lonelyGroups,
  // meta,
  onDrop,
  onDissolveMatch,
  onFocusGroup,
  onUngroupParticipant,
  onSave,
  page,
  readOnly,
  setDragging,
  setState,
}: PropsWithChildren<{
  deletedMatches: MatchEditingState['deletedMatches'],
  dragging: EditMatches['dragging'],
  formId: HTMLFormElement['id'],
  groups: MatchEditingState['groups'],
  isDirty: MatchEditingState['isDirty'],
  isLoading: MatchEditingState['isLoading'],
  lonelyGroups: EditMatches['lonelyGroups'],
  // meta: EditMatches['state']['meta'],
  onDrop: EditMatches['onDrop'],
  onDissolveMatch: EditMatches['onDissolveMatch'],
  onFocusGroup: EditMatches['onFocusGroup'],
  onUngroupParticipant: EditMatches['onUngroupParticipant'],
  onSave: EditMatches['onSave'],
  page: MatchEditingState['page'],
  readOnly: boolean,
  setDragging: EditMatches['setDragging'],
  setState: Component<object, MatchEditingState>['setState'],
}>) {
  const blocker = useBlocker(isDirty);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) { blocker.reset() }
  }, [blocker, isDirty]);

  const activeGroups = new Map(groups);

  for (const group of activeGroups.values()) {
    if (deletedMatches.has(group.id)) activeGroups.delete(group.id);

    if (group.optins.size < 2) lonelyGroups.add(group.id);
    else lonelyGroups.delete(group.id);
  }

  const {
    previous,
    current,
    following,
  } = getPageSlices(activeGroups, page);

  const setMemberToInspect = (v: MatchEditingState['memberToInspect']) => setState({ memberToInspect: v });

  return (
    <Form
      className={clsx(classes.Editor, { [classes.Loading]: isLoading })}
      id={formId}
      name="matches"
      onDirty={(v: boolean) => setState({ isDirty: v })}
      onPristine={(v: boolean) => setState({ isDirty: v })}
      onSubmit={onSave}
    >
      {blocker && <UnsavedChangesWarning blocker={blocker} />}

      <fieldset disabled={readOnly}>
        {previous.map((match) => (
          <MatchGroup
            dragging={dragging}
            hidden
            key={match.id}
            match={match}
            onDissolveMatch={onDissolveMatch}
            onDrop={onDrop}
            onUngroupParticipant={onUngroupParticipant}
            readOnly={readOnly}
            setDragging={setDragging}
            setMemberToInspect={setMemberToInspect}
          />
        ))}

        {current.map((match) => (
          <MatchGroup
            dragging={dragging}
            key={match.id}
            match={match}
            // meta={meta}
            onDissolveMatch={onDissolveMatch}
            onDrop={onDrop}
            onFocusGroup={onFocusGroup}
            onUngroupParticipant={onUngroupParticipant}
            readOnly={readOnly}
            setDragging={setDragging}
            setMemberToInspect={setMemberToInspect}
          />
        ))}

        {following.map((match) => (
          <MatchGroup
            dragging={dragging}
            hidden
            key={match.id}
            match={match}
            onDissolveMatch={onDissolveMatch}
            onDrop={onDrop}
            onUngroupParticipant={onUngroupParticipant}
            readOnly={readOnly}
            setDragging={setDragging}
            setMemberToInspect={setMemberToInspect}
          />
        ))}

        {Array.from(deletedMatches).map((id) => (
          <input
            id={`deleted[${id}]`}
            key={id}
            name="deleted[]"
            type="hidden"
            value={id}
          />
        ))}
      </fieldset>

      {children}
    </Form>
  );
}
MatchesForm.displayName = 'MatchesForm';
