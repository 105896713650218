import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Engagement } from '../../Engagement.d.ts';

import { UPDATE_ENGAGEMENT } from './updateEngagement.gql.mjs';


export function updateEngagement({
  engagement,
  engagementId,
  workspaceId,
}: {
  engagement: Partial<Engagement>,
  engagementId: ObjectId,
  workspaceId: ObjectId,
}) {
  return gqlOperation<{ engagement: Engagement}>(UPDATE_ENGAGEMENT, {
    engagement,
    engagementId: engagement.id || engagementId,
    workspaceId,
  })
    .then((data) => data.engagement);
}
