import type {
  Dispatch,
  SetStateAction,
} from 'react';
import _memoize from 'lodash-es/memoize.js';
import { memo } from 'react';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import { getNow } from '…/app/common/dateUtils.mts';

import { FiltersContent, FiltersHeader } from '…/app/common/filtering/FiltersDrawer.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';

import { MEMBER_STATUS_DICT, type MembersFilters } from './AudienceFilters.mts';
import filterClasses from './FiltersForm.module.css';

const getCreatedAtOptions = _memoize((now: ReturnType<typeof getNow>) => [
  ['Today', now.startOf('day').toISO()],
  ['This week', now.startOf('week').toISO()],
  ['This month', now.startOf('month').toISO()],
]);


export function FiltersForm({
  filters: {
    CREATED_AT,
    STATUS,
  },
  setFilters,
}: {
  filters: MembersFilters,
  setFilters: Dispatch<SetStateAction<MembersFilters>>,
}) {
  return (
    <>
      <FiltersHeader />

      <FiltersContent>
        <Form
          name="engagement-filters"
          onReset={() => setFilters({
            CREATED_AT: new Array(),
            STATUS: new Array(),
          })}
          onSubmit={(_, all) => setFilters(all)}
        >
          <Details open>
            <Summary className={filterClasses.Summary}>Status</Summary>

            <div className={filterClasses.FilterOptions}>
              {(() => {
                const output = [];

                for (const { 0: key, 1: label } of Object.entries(MEMBER_STATUS_DICT)) {
                  output.push(
                    <Field
                      defaultChecked={STATUS?.includes(key)}
                      id={`STATUS.${key}`}
                      key={key}
                      label={label}
                      name="STATUS[]"
                      type="checkbox"
                      value={key}
                    />,
                  );
                }

                return output;
              })()}
            </div>
          </Details>

          <Details open>
            <Summary className={filterClasses.Summary}>Created</Summary>

            <div className={filterClasses.FilterOptions}>
              {getCreatedAtOptions(getNow()).map(({ 0: label, 1: value }) => (
                <Field
                  defaultChecked={CREATED_AT?.includes(value!)}
                  id={`CREATED_AT.${label}`}
                  key={label}
                  label={label}
                  name="CREATED_AT[]"
                  type="radio"
                  value={value}
                />
              ))}
            </div>
          </Details>

          <Button.Group>
            <Button appearance={Button.APPEARANCES.BASIC} type="reset">Clear</Button>

            <Button appearance={Button.APPEARANCES.PRIMARY} type="submit">Apply</Button>
          </Button.Group>
        </Form>
      </FiltersContent>
    </>
  );
}
FiltersForm.displayName = 'FiltersForm';

export const MemoizedFiltersForm = memo(FiltersForm);
MemoizedFiltersForm.displayName = 'MemoizedFiltersForm';
