.SelectedAudiences {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
}

.RemovableButton::after {
  content: '×';
  margin-left: 0.4em;
}
