import { ReactErrorBoundary } from './ErrorBoundary/ReactErrorBoundary.tsx';


export function Error404(): never {
  setTimeout(() => {
    location.assign('/?redirectUri='); // Avoid redirecting back to a nonexistent route.
  }, 1_000);

  throw Object.assign(new Error('That route doesn\'t exist.'), { code: 404 });
}
Error404.displayName = 'Error404';

export function BoundedError404() {
  return (
    <ReactErrorBoundary>
      <Error404 />
    </ReactErrorBoundary>
  );
}
BoundedError404.displayName = 'BoundedError404';

export { BoundedError404 as Component };
