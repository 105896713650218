._Content_15gan_1 {
  padding: var(--grid-gutter);
  text-align: center;
}

._Content_15gan_1 h1 {
  margin-bottom: var(--grid-gutter);
}

._MeterContainer_15gan_10 {
  padding: var(--grid-gutter) calc(2 * var(--grid-gutter));
}

._MeterBar_15gan_14 {
  background-color: var(--brand-subtle-light);
  border-radius: 1em;
  display: flex;
  margin-bottom: 0.75rem;
  padding: 0;
}

._MeterLabels_15gan_22 {
  display: flex;
  flex-direction: row;
  padding: 0;
}

._MeterLabels_15gan_22 p {
  font-weight: 700;
  justify-content: center;
}

._MeterLeft_15gan_33 {
  display: block;
  min-width: 10%;
}

._MeterRight_15gan_38 {
  display: block;
  flex: 1;
}

._Chip_15gan_43 {
  background-color: transparent;
  border-radius: 1em;
  display: flex;
  font-weight: 600;
  justify-content: center;
  padding: 0.5rem 1rem;
}

._ChipPrimary_15gan_52 {
  background-color: var(--brand-accent-primary);
  color: white;
}

._BottomParagraph_15gan_57 {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.125rem;
  justify-content: center;
  margin-top: 1.5rem;
}

._Stat_15gan_66 {
  font-weight: 700;
  margin: 0;
}
