.AppContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: calc(2 * var(--grid-gutter));
}

.MetricsCards {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem;
}

.OptionsCard {
  width: calc(50% - (1rem * 1 / 2)); /* [1] 1 gap to spread across 2 items */
}
