export default {
  "Bureau": "_Bureau_c50ln_1",
  "Drawer": "_Drawer_c50ln_5",
  "Content": "_Content_c50ln_10",
  "SectionSplit": "_SectionSplit_c50ln_16",
  "Instructions": "_Instructions_c50ln_22",
  "ApiKey": "_ApiKey_c50ln_32",
  "IntegrationName": "_IntegrationName_c50ln_39",
  "IconButton": "_IconButton_c50ln_45",
  "IconButtonDelete": "_IconButtonDelete_c50ln_46",
  "IconButtonConnect": "_IconButtonConnect_c50ln_47",
  "SubText": "_SubText_c50ln_74"
};import "ni:sha-256;AwiTjtIyID7WXPIzZ1_q_KBz7bRZCUtICfws7mD8phU";