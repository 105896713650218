._Header_57byu_1 {
  grid-area: Header;
}

._FlexEnd_57byu_5 {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
