._Page_7mvmb_1 {
  --max-content-width: 60%;

  display: grid;
  grid-template-columns: min-content minmax(50ch, var(--max-content-width)) min-content;
  justify-content: space-between;
  margin: var(--grid-gutter);
}

@media screen and (width >= 1280px) {
  ._Page_7mvmb_1 {
    --max-content-width: 80%;
  }
}

._Header_7mvmb_16 {
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin: 0 auto;
  max-width: 80%;
  min-width: 34ch;
  text-align: center;
}

._TemplateGroup_7mvmb_26 {
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin-top: 2em;
}

._TemplateGroup_7mvmb_26 + ._TemplateGroup_7mvmb_26 {
  margin-top: 3em;
}

._TemplateGroupTitle_7mvmb_37 {
  color: var(--text-neutral);
  font-weight: 600;
  text-transform: uppercase;
}

._TemplateDetails_7mvmb_43 {
  max-width: 50%;
  min-width: 50ch;
}

._TemplateSelectionCTA_7mvmb_48 {
  padding-left: 1rem;
  padding-right: 1rem;
  place-self: end;
}

._Cards_7mvmb_54 {
  --columns: 2;

  display: grid;
  gap: var(--grid-gutter);
  grid-auto-flow: row;
  grid-template-columns: repeat(var(--columns), 1fr);
}

@media screen and (width >= 1440px) {
  ._Cards_7mvmb_54 {
    --columns: 3;
  }
}

._Card_7mvmb_54 {
  display: flex; /* make the label fill the area */
  position: relative;
  text-align: center;
}

._CardIcon_7mvmb_75 {
  aspect-ratio: 1;
  display: block;
  height: 2.5em;
  margin-top: 1rem;
}

._DetailsIcon_7mvmb_82 {
  height: 5em;
}

._CardTitle_7mvmb_86 {
  text-align: center;
}

._Card_7mvmb_54 > label {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-weight: normal;
  gap: 1em;
  padding: 1rem;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: box-shadow, scale;
}

._CardField_7mvmb_101 {
  appearance: none;
  height: 100%;
  margin: 0 !important;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

._Card_7mvmb_54 > label,
._CardField_7mvmb_101 + label {
  box-shadow: var(--box-shadow);
}

._CardField_7mvmb_101:focus {
  outline: none;
}

._Card_7mvmb_54:hover > label,
._CardField_7mvmb_101:focus + label {
  scale: 1.02;
}

._CardField_7mvmb_101:checked + label {
  border-width: 2px;
}

._Description_7mvmb_128 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

._AiRecommendedDetails_7mvmb_136 {
  background-image: linear-gradient(
    var(--ai-background),
    1%,
    #FFFF
  );
}

._AiRecommended_7mvmb_136 {
  color: var(--ai-text);
  display: inline-flex;
  gap: 0.25ch;
  left: 0;
  padding: var(--default-padding);
  position: absolute;
  top: 0;
}

._MatchingQuestionBlock_7mvmb_154 {
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--default-padding);
}

._MatchingQuestionOptions_7mvmb_163 {
  padding-left: 2rem;
  text-align: right;
}
