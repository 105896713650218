._ApiKeyContainer_1fgug_1 {
  background: var(--brand-subtle-light);
  border-radius: 0.5rem;

  /* cursor: pointer; */
  margin-top: 1rem;
  padding: 1.5rem;
  word-wrap: break-word;
}

._ApiKeyWarning_1fgug_11 {
  background: var(--brand-subtle-light);
  border-radius: 0.5rem;
  padding: 1rem;
}
