import type { ReactNode } from 'react';
import { clsx } from 'clsx';

import classes from './OrbiitBackground.module.css';

export function OrbiitBackground({ children }: { children: ReactNode }) {
  return (
    <main className={clsx('ContentContainer', classes.OrbiitBackground)}>
      {children}
    </main>
  );
}
OrbiitBackground.displayName = 'OrbiitBackground';

export const orbiitBackgroundContentClassName = classes.Content;
export const orbiitBackgroundCardClassName = classes.Card;
