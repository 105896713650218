/*
! Percentage values on left/right are relative to the "parent" ($0.offsetParent).
! Instead, use an absolute unit like "em" and shift $0 via translate, which is relative to self.
*/

._PopoverContainer_idgvc_6 {
  position: relative;
}

._Popover_idgvc_6 {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  font-size: 0.875rem;
  opacity: 0;
  padding: 0.125em;
  pointer-events: none;
  position: absolute;
  transition: opacity ease-in-out var(--transition-duration-controls);
  z-index: 1;
}

._Popover_idgvc_6::after {
  background-color: inherit;
  border: inherit;
  box-shadow: inherit;
  clip-path: polygon(
    0 0,
    45% 55%,
    120% 120%,
    120% 0%
  );
  content: "";
  display: block;
  height: 1em;
  position: absolute;
  width: 1em;
}

._PopoverContainer_idgvc_6[type="menu"] ._Popover_idgvc_6 {
  display: flex;
  flex-direction: column;
  text-align: left;
}

._PopoverContainer_idgvc_6[type="menu"] ._Popover_idgvc_6 > [menuitem] {
  border-radius: 3px;
  margin: unset;
  padding: 0.75em 1em;
}

._PopoverContainer_idgvc_6[type="menu"] ._Popover_idgvc_6 > [destructive] {
  color: darkred;
}

._PopoverContainer_idgvc_6[type="menu"] ._Popover_idgvc_6 > [menuitem]:hover {
  background: var(--brand-subtle-light);
}

._PopoverContainer_idgvc_6[type="menu"] ._Popover_idgvc_6,
._PopoverContainer_idgvc_6[type="popover"] ._Popover_idgvc_6 {
  background-color: var(--brand-light);
  color: var(--brand-dark);
  min-width: max-content;
}

._PopoverContainer_idgvc_6[type="tooltip"] ._Popover_idgvc_6 {
  background-color: var(--brand-dark);
  color: var(--brand-light);
  padding: 0.5em 0.75em;
}

._PopoverContainer_idgvc_6[type="popover"]:not([minimal]) ._Popover_idgvc_6,
._PopoverContainer_idgvc_6[type="tooltip"]:not([minimal]) ._Popover_idgvc_6 {
  min-width: 20ch;
}

._PopoverContainer_idgvc_6[type="tooltip"]:not([minimal]) ._Popover_idgvc_6 {
  max-width: max-content;
}

._PopoverContainer_idgvc_6[type="tooltip"][minimal] ._Popover_idgvc_6 {
  white-space: pre;
}

._Popover_idgvc_6[open],
._PopoverContainer_idgvc_6[type="tooltip"]:hover ._Popover_idgvc_6,
._PopoverContainer_idgvc_6[type="tooltip"] ._PopoverAnchor_idgvc_87:focus + ._Popover_idgvc_6 {
  opacity: 1;
  pointer-events: all;
}

._Popover_idgvc_6[position~="left"] {
  left: calc(50% - 1.5em);
}

._Popover_idgvc_6[position~="left"]::after {
  left: 1em;
}

._Popover_idgvc_6[position~="middle"],
._Popover_idgvc_6[position~="middle"]::after {
  left: 50%;
}

._Popover_idgvc_6[position~="right"] {
  right: calc(50% - 1.5em);
}

._Popover_idgvc_6[position~="right"]::after {
  right: 1em;
}

._Popover_idgvc_6[position~="bottom"] {
  bottom: -1em; /* arrow's height */
}

._Popover_idgvc_6[position~="bottom"]::after {
  rotate: 315deg;
  top: -1em;
}

._Popover_idgvc_6[position="bottom left"] {
  translate: 0 100%;
}

._Popover_idgvc_6[position="bottom left"]::after {
  translate: 0 50%;
}

._Popover_idgvc_6[position="bottom middle"] {
  translate: -50% 100%;
}

._Popover_idgvc_6[position="bottom middle"]::after {
  translate: -50% 50%;
}

._Popover_idgvc_6[position="bottom right"] {
  translate: 0 100%;
}

._Popover_idgvc_6[position="bottom right"]::after {
  translate: 0 50%;
}

._Popover_idgvc_6[position="left"] {
  left: -1.5em;
  translate: -100% -50%;
}

._Popover_idgvc_6[position="left"],
._Popover_idgvc_6[position="right"] {
  top: 50%;
}

._Popover_idgvc_6[position="right"] {
  right: -1.5em;
  translate: 100% -50%;
}

._Popover_idgvc_6[position="left"]::after,
._Popover_idgvc_6[position="right"]::after {
  top: 50%;
  translate: 0 -50%;
}

._Popover_idgvc_6[position="left"]::after {
  left: calc(100% - 0.5em); /* Using 'left' instead of 'right' to trump other declaration's left */
  rotate: 45deg;
}

._Popover_idgvc_6[position="right"]::after {
  left: -0.5em;
  rotate: -136deg;
}

._Popover_idgvc_6[position~="top"] {
  top: -1em; /* arrow's height */
}

._Popover_idgvc_6[position~="top"]::after {
  bottom: 0;
  rotate: 135deg;
}

._Popover_idgvc_6[position="top left"] {
  translate: 0 -100%;
}

._Popover_idgvc_6[position="top left"]::after {
  translate: 0 50%;
}

._Popover_idgvc_6[position="top middle"] {
  translate: -50% -100%;
}

._Popover_idgvc_6[position="top middle"]::after {
  translate: -50% 50%;
}

._Popover_idgvc_6[position="top right"] {
  translate: 0 -100%;
}

._Popover_idgvc_6[position="top right"]::after {
  translate: 0 50%;
}

._PopoverContainer_idgvc_6[type="menu"] ._PopoverAnchor_idgvc_87,
._PopoverContainer_idgvc_6[type="popover"] ._PopoverAnchor_idgvc_87 {
  cursor: pointer;
}
