import type {
  Section,
  SetupSection,
} from '…/app/w/workspace/engagements/constants.mts';


export const WALLED_ROUTE_TEMPLATE = '/w/:workspaceId';
export interface WalledRouteParams {
  [k: string]: string | undefined,
  workspaceId: string,
}

export const AUDIENCE_ROUTE_TEMPLATE = `${WALLED_ROUTE_TEMPLATE}/audiences/:audienceId`;
export interface AudienceRouteParams extends WalledRouteParams {
  audienceId: string,
}

export const ENGAGEMENTS_ROUTE_TEMPLATE = `${WALLED_ROUTE_TEMPLATE}/engagements`;

export const ENGAGEMENT_ROUTE_TEMPLATE = `${ENGAGEMENTS_ROUTE_TEMPLATE}/:engagementId`;
export interface EngagementRouteParams extends WalledRouteParams {
  engagementId: string,
}
export const ENGAGEMENT_SECTION_ROUTE_TEMPLATE = `${ENGAGEMENT_ROUTE_TEMPLATE}/:section`;
export interface EngagementSectionRouteParams extends EngagementRouteParams {
  section: Section,
}
export const ENGAGEMENT_SETUP_ROUTE_TEMPLATE = `${ENGAGEMENT_SECTION_ROUTE_TEMPLATE}/:subSection`;
export interface EngagementSetupRouteParams extends EngagementSectionRouteParams {
  subSection: SetupSection,
}
