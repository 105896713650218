import engagementFrag from './engagement.frag.gql.mjs';

export const CANCEL_ENGAGEMENT = `
mutation CancelEngagement($workspaceId: ID!, $engagementId: ID!) {
  engagement: cancelEngagement(workspaceId: $workspaceId, engagementId: $engagementId) {
    ...engagementFields
  }
}

${engagementFrag}
`;
