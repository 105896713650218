export default {
  "MatchCard": "_MatchCard_15g4w_1",
  "Name": "_Name_15g4w_15",
  "Role": "_Role_15g4w_23",
  "Company": "_Company_15g4w_27",
  "Btn": "_Btn_15g4w_31",
  "History": "_History_15g4w_35",
  "RemoveBtn": "_RemoveBtn_15g4w_48",
  "DragHandle": "_DragHandle_15g4w_52",
  "Meta": "_Meta_15g4w_56",
  "Availability": "_Availability_15g4w_63"
};import "ni:sha-256;gld0g6LaSahjRz72shFK2tH0UGP_CI-QDxL8Yij7GBc";