import type { LoaderFunctionArgs } from 'react-router-dom';
import { defer } from 'react-router-dom';
import { toast } from 'sonner';

import { getQuestionAnswers } from '…/app/deprecated/utils/questionAnswerUtils.mts';

import { gqlOperation } from '…/app/common/rpc/gqlOperation.mjs';

import {
  STATUSES,
  STATUSES_ORDERED,
} from '…/app/w/workspace/engagements/constants.mts';

import GET_ENGAGEMENT_DASHBOARD_DATA from './getDashboardData.gql';
import type { EngagementAnalytics } from './EngagementAnalytics.d.ts';


export async function fetchDashboardAnalytics({ params: { engagementId, workspaceId }}: LoaderFunctionArgs) {
  const errors: Error[] = [];
  const data = await gqlOperation<{ workspace: { engagement: EngagementAnalytics } }>(
    GET_ENGAGEMENT_DASHBOARD_DATA,
    {
      engagementId,
      workspaceId,
    },
    {
      eventData: {
        data: JSON.stringify({ workspace: workspaceId }),
        name: 'View Engagement Dashboard',
      },
    },
  )
    .then(gotDashboard)
    .catch((err) => {
      toast.error('Failed to fetch analytics', { description: err?.message });
      errors.push(err);
    });

  return defer({
    ...data,
    errors,
  });
}

export function gotDashboard({ workspace }: { workspace: { engagement: EngagementAnalytics } }) {
  if (!workspace?.engagement) {
    throw Object.assign(Error('Could not load dashboard data'), { code: 1 });
  }

  const { engagement } = workspace;

  const optInQuestionsMetrics = getQuestionAnswers(
    engagement.optInQuestions,
    engagement.optInFormsAggregate?.answersStatistics,
  );

  const feedbackQuestionsMetrics = getQuestionAnswers(
    engagement.feedbackQuestions,
    engagement.feedbackFormsAggregate?.answersStatistics,
  );

  const renderData = [];
  const statisticsData = [
    {
      label: 'Audience Size',
      value: engagement?.audienceSizeTotal,
    },
    {
      label: 'Opt-Ins',
      value: engagement?.optInFormsCount,
    },
    {
      label: 'Matches',
      value: engagement?.matchesCount,
    },
    {
      label: 'Feedback Forms',
      value: engagement?.engagementStats?.numFeedbackForms,
    },
  ];
  renderData.push({
    type: 'statistics',
    metrics: statisticsData,
  });

  if (
    STATUSES_ORDERED.indexOf(engagement.status) >= STATUSES_ORDERED.indexOf(STATUSES.OPTINSOPEN)
    && engagement?.optInFormsCount > 0
  ) {
    const optInFirstTimeCount = engagement?.optInFormsCount - engagement?.optInRepeatedCount;
    const repeatMembersPercentage = (engagement?.optInRepeatedCount / engagement?.optInFormsCount) * 100;

    const splitOutData = {
      audienceMembersCount: engagement?.audienceSizeTotal,
      optIns: [
        {
          label: 'Repeat',
          value: engagement?.optInRepeatedCount,
        },
        {
          label: 'First time',
          value: optInFirstTimeCount,
        },
      ],
      repeatMembersPercentage,
    };
    renderData.push({
      type: 'split-out',
      metrics: splitOutData,
      status: engagement?.status,
    });
  } else {
    renderData.push({
      type: 'split-out',
      metrics: null,
      status: engagement?.status,
    });
  }

  const feedbackStarted = engagement?.engagementStats?.numFeedbackForms > 0;
  if (feedbackStarted) {
    renderData.push({
      type: 'nps',
      metrics: {
        value: Math.round(engagement?.engagementStats?.nps || 0),
        detractors: engagement?.engagementStats?.detractors,
        passives: engagement?.engagementStats?.passives,
        promoters: engagement?.engagementStats?.promoters,
      },
    });

    renderData.push({
      type: 'rating',
      metrics: {
        globalAverage: 4.2,
        subtitle: 'Orbiit-wide average match rating is',
        title: 'Average Match Rating',
        average: engagement?.matchRating?.rating,
        values: engagement?.matchRating?.stars,
      },
    });
  } else {
    renderData.push({
      type: 'nps',
      metrics: null,
    });
    renderData.push({
      type: 'rating',
      metrics: null,
    });
  }

  return {
    numFeedbackForms: engagement?.engagementStats?.numFeedbackForms,
    feedbackQuestionsMetrics,
    optInFormsCount: engagement?.optInFormsCount,
    optInQuestionsMetrics,
    renderData,
  };
}
