._Content_1pwqf_1 {
  padding: var(--grid-gutter);
  text-align: center;
}

._GridRow_1pwqf_6 {
  align-items: center;
  display: grid;
  grid-gap: 0.375rem;
  grid-template-columns: 1fr 6fr 1fr;
  margin-bottom: 0.25rem;
}

._Header_1pwqf_14 {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

._Bottom_1pwqf_22 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}
