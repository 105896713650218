mutation UpdateFeedbackQuestion(
  $workspaceId: ID!,
  $engagementId: ID!,
  $questionId: ID!,
  $question: EngagementQuestionInput!
) {
  question: updateEngagementFeedbackQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    feedbackQuestionId: $questionId
    feedbackQuestion: $question
  ) {
    id
  }
}
