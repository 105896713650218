@media (width <= 960px) {
  ._TeamTable_ss3yd_2 td:nth-child(4),
  ._TeamTable_ss3yd_2 th:nth-child(4) {
    display: none;
  }
}

._RoleChip_ss3yd_8 {
  color: var(--brand-light);
  text-transform: capitalize;
}

._RoleChip_ss3yd_8[role="ADMIN"] {
  background-color: var(--brand-dark);
}

._RoleChip_ss3yd_8[role="EDITOR"] {
  background-color: var(--brand-accent-ancillary);
}

._RoleChip_ss3yd_8[role="OBSERVER"] {
  background-color: var(--neutral-medium);
}
