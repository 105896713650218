import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import DELETE_LIST_TO_SYNC from './deleteListToSync.gql';
import type { GraphQLCompleted } from './integration.d.ts';

export function deleteListToSync({
  integrationId,
  remoteId,
  workspaceId,
}: {
  integrationId: string;
  remoteId: string;
  workspaceId: string;
}) {
  return gqlOperation<GraphQLCompleted>(DELETE_LIST_TO_SYNC, {
    integrationId,
    remoteId,
    workspaceId,
  }, {
    eventData: {
      data: JSON.stringify({
        integrationId,
        remoteId,
        workspaceId,
      }),
      name: 'Unlink List to Sync',
    },
  })
    .then(({ completed }) => completed);
}
