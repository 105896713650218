.FilterIconButton {
  appearance: none;
  background: var(--colour-light);
  border: 1px solid var(--colour-medium);
  border-radius: 100vmax; /* ensure spherical left/right ends */
  color: var(--colour-text-deemphasised);
  padding: 0;
  padding-right: 0.75rem;
}

.FilterIconButton[count="0"] {
  padding: 0.375rem 0.75rem;
}

.FilterIconButton:hover {
  background: var(--brand-subtle-light);
}

.FilterIcon {
  align-items: center;
  color: var(--colour-text-deemphasised);
  gap: 0.5em;
}

.FilterIcon:hover {
  color: var(--brand-accent-primary);
}

.FilterIcon::before {
  align-items: center;
  aspect-ratio: 1;
  border-radius: 100%;
  box-sizing: content-box;
  color: var(--brand-light);
  content: attr(count);
  display: flex;
  font-size: 0.75em;
  height: 1em;
  justify-content: center;
  padding: 1em;
}

.FilterIcon[count="0"]::before {
  background-color: var(--neutral-light);
  display: none;
}

.FilterIcon:not([count="0"]) {
  color: var(--brand-accent-primary);
}

.FilterIcon:not([count="0"])::before {
  background-color: var(--brand-accent-primary);
}
