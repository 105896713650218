.Banner {
  --horz-spacing: calc(3 * var(--grid-gutter));

  align-items: center;
  display: grid;
  gap: var(--horz-spacing);
  grid-template-columns: 10ch auto;
  padding:
    var(--grid-gutter)
    var(--horz-spacing);
}

.GradientBackground {
  align-items: center;
  background: linear-gradient(90deg, var(--core-blue) 0%, var(--core-turquoise) 36%, var(--core-blue) 85%);
  box-shadow: 0 2px 20px rgb(0 0 0 / 25%);
  display: flex;
  gap: 0.375rem;
  justify-content: center;
  padding: 0.75rem 1.125rem;
  position: relative;
}

.GradientBackground p {
  color: var(--core-dark-blue);
  font-weight: bold;
}

.BannerCTA {
  background: white;
  border-radius: 7px;
  color: var(--core-dark-blue);
  font-weight: bold;
  margin-left: 0.5rem;
  padding: 0.375rem 0.5rem 0.25rem;
}

.CloseButton {
  color: white;
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  position: absolute;
  right: 0.5rem;
}

.CloseButton:hover {
  color: var(--core-turquoise);
}
