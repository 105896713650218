import { clsx } from 'clsx';

import classes from './MatchIcon.module.css';


export function MatchIcon({
  className,
  match,
  ...props
}: {
  className?: string,
  match: Match,
} & Partial<HTMLElement>) {
  return (
    <i
      className={clsx(classes.MatchIcon, className)}
      // @ts-ignore Custom attribute
      match={match}
      {...props}
      role="img"
    />
  );
}

MatchIcon.displayName = 'MatchIcon';
const MATCH = MatchIcon.MATCH = {
  PARTIAL: 'partial',
  SET: 'set',
} as const;
type Match = typeof MATCH[keyof typeof MATCH];
