import { log } from '…/app/logger.mts';

import type {
  FreeResponseQuestionData,
  MappedQuestions,
  MultipleChoiceQuestionData,
} from './ParticipantQuestions.d.ts';
import {
  ParticipantQuestionType,
} from './questions.config.mts';


export function getQuestionData(
  questions: MappedQuestions,
  name: keyof MappedQuestions,
  type: ParticipantQuestionType,
): FreeResponseQuestionData | MultipleChoiceQuestionData | Record<string, never> {
  const q = questions[name];

  if (!q) {
    log.error(`data missing for question ${name}`);
    return {};
  }

  return {
    target: q.target,
    ...q.props,
    ...(type === ParticipantQuestionType.MultipleChoice && {
      isDataPrivate: q.isDataPrivate,
      isDeleted: q.isDeleted,
      isMatchingDisabled: q.isMatchingDisabled,
      isMatchingOpposites: q.isMatchingOpposites,
      isOrdered: q.isOrdered,
      lockedProps: q.lockedProps,
      question: q.question,
    }),
  };
}
