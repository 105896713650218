import type { ReactNode } from 'react';
import type {
  unstable_Blocker as Blocker,
} from 'react-router-dom';

import Button from 'form5/react/Button';

import { Dialog } from '../Dialog/Dialog.tsx';


export function UnsavedChangesWarning({ blocker }: { blocker: Blocker }) {
  let Content: ReactNode;

  switch (blocker.state) {
    case 'blocked':
      Content = (
        <>
          <h1>Unsaved changes will be lost</h1>

          <p>Are you sure you want to do that?</p>

          <Button.Group>
            <Button
              appearance={Button.APPEARANCES.DANGER}
              onClick={() => blocker.proceed?.()}
            >
              Leave
            </Button>

            <Button
              appearance={Button.APPEARANCES.AFFIRMING}
              onClick={() => blocker.reset?.()}
            >
              Stay
            </Button>
          </Button.Group>
        </>
      );
      break;
    case 'proceeding':
      Content = '';
      break;
  }

  if (!Content) return null;

  return (
    <Dialog
      backdropped
      open
      setOpen={() => blocker.reset?.()}
    >
      {Content}
    </Dialog>
  );
}
UnsavedChangesWarning.displayName = 'UnsavedChangesWarning';
