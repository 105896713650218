._TimelineCards_7gayy_1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

._TimelineBlock_7gayy_7 {
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 0;
  padding: 0.25rem;
  position: relative;
  text-decoration: none;
}

._TimelineBlock_7gayy_7 > h2 {
  border-bottom: 2px solid var(--brand-subtle-light);
  font-size: 1.2rem;
  line-height: 1em;
  margin-bottom: 0.5rem;
  padding: 0.75rem;
  text-align: center;
}
