._FlexEnd_9ypuc_1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

._Warning_9ypuc_8 {
  margin-right: 1rem;
}

._TimezoneNotification_9ypuc_12 {
  background-color: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  border-radius: var(--border-radius);
  padding: 1rem;
}

._Footer_9ypuc_19 {
  margin-top: var(--grid-gutter);
}
