import type {
  Dispatch,
  SetStateAction,
} from 'react';
import _merge from 'lodash-es/merge.js';

import type {
  Member,
  MembersDict,
} from './members/Member.d.ts';


export function updateMembersState(
  result: Member | Member[],
  setMembers: Dispatch<SetStateAction<MembersDict>>,
  isBatch = false,
) {
  setMembers((prev) => {
    const was1 = !Array.isArray(result);
    const results = was1 ? [result] : result;
    const init = was1 && !prev.has(result.id)
      ? [
        [result.id, result],
        ...prev,
      ]
      : prev;

    const updated = new Map(init);

    for (const incomingVal of results) {
      const oldVal = updated.get(incomingVal.id)!;
      const newVal = _merge({}, oldVal, incomingVal);
      if (isBatch) { // Batch on audiences is only additive, and _merge doesn't deep-merge Set()s
        newVal.audienceIds = new Set([
          ...(oldVal.audienceIds ?? new Array()),
          ...(incomingVal.audienceIds ?? new Array()),
        ]);
      }
      if (newVal.fields) newVal.fields.fullName = `${newVal.fields.firstName} ${newVal.fields.lastName}`;
      updated.set(incomingVal.id, newVal);
    }

    return updated;
  });
}
