.AnswerCard {
  background: white;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-card);
  margin-bottom: 0.75em;
  padding: 0.75em;
}

.Title {
  margin-bottom: 0.5rem;
}
