import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Audience } from './Audience.d.ts';
import DELETE_AUDIENCES from './deleteAudiences.gql';


export async function deleteAudiences(
  workspaceId: ObjectId,
  audienceIds: Audience['id'][],
) {
  const { success } = await gqlOperation<{ success: boolean }>(DELETE_AUDIENCES, {
    audienceIds,
    workspaceId,
  });

  return success;
}
