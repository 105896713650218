import { ReactNode } from 'react';

import classes from './CardsSet.module.css';

export function CardsSet({
  title,
  children,
}: {
  title?: string,
  children: ReactNode,
}) {
  return (
    <div className={classes.Cards}>
      {title && (<h3 className={classes.Title}>{title}</h3>)}

      {children}
    </div>
  );
}

CardsSet.displayName = 'CardsSet';

CardsSet.mainClassName = classes.CardsSet;
