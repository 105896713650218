._Progress_16sk6_1 {
  background-color: var(--brand-subtle-light);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);;
  height: 0.6em;
  overflow: hidden;
}

._Progress_16sk6_1::before {
  background-color: var(--status-success-light);
  content: '';
  display: block;
  height: 100%;
  width: calc(100% * var(--completeness));
}

._Progress_16sk6_1[value="1"]::before {
  background-color: var(--status-success);
}
