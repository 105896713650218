import { log } from '…/app/logger.mts';


type Collection = Map<PropertyKey, unknown>;
type Item = Record<string, unknown>;
type State = Collection | Item;

type CRUDActor = (...args: any[]) => Promise<unknown | never>;
interface ItemGetters {
  [k: string]: CRUDActor,
  default: CRUDActor,
}
interface CollectionGetters {
  [k: string]: CRUDActor | undefined,
  default: CRUDActor,
  item?: ItemGetters,
}

const INTERNAL_SLOTS = new Set([
  'toJSON',
  '@@toStringTag',
]);

/**
 * Use this utility to generate a store section (ex `workspaces`).
 * @param label A human-friendly name for debugging purposes.
 * @param state A reference to a "state" object (a plain object or a `Map<ObjectId, any>`). The
 * state object itself can be mutated outside the context of the store (`useStore()`), but changes
 * will not trigger re-renders.
 * @param getters When a record is read from the store, a getter is used to fetch the value
 * when it does not exist in cache. This should ONLY ever be omitted for a subStore proxy.
 */
export function storeProxyFactory<
  StateModel extends State,
  StoreModel = StateModel,
  Getters = StateModel extends Collection ? CollectionGetters : ItemGetters,
>(
  label: string,
  state: StateModel,
  getters = {} as Getters,
) {
  /**
   * A cache of active requests to prevent duplicates. All reads that would result in a request are
   * instead pointed to the single active request (so when that request settles, all the reads settle
   * at once, and the active request is removed from the cache).
   */
  const activeReqs = new Map<string | symbol, Promise<unknown>>();
  const isCollection = state instanceof Map;
  const crudders: StateCRUDders<State> = isCollection
    ? mapCRUD
    : objCRUD;

  return new Proxy(state, {
    deleteProperty(target, prop) {
      return crudders.remove(target, prop);
    },
    get(target, prop = '') {
      if ( // We care only about our state's own fields, so bypass the proxy for anything not ours.
        typeof prop === 'symbol' // State/store use only string keys
        || INTERNAL_SLOTS.has(prop)
        || isCollection && prop in Map.prototype
        || !isCollection && prop in Object.prototype
      ) {
        const ret = Reflect.get(target, prop);
        return typeof ret === 'function'
          ? ret.bind(target) // A quirk in Proxy alters context, so restore the expected context.
          : ret;
      }

      log.debug(`${label} StoreProxy::get(): looking for “${prop}” in`, target);

      let activeReq = activeReqs.get(prop);

      if (activeReq) {
        log.debug(`${label} StoreProxy::get(): active request related to “${prop}”.`);
        if (prop in Promise.prototype) {
          // When there is an active request, `then`s can be attached, and they need to be
          // explicitly bound to their target context (because of a quirk in how Proxy works).
          return Reflect.get(activeReq, prop).bind(activeReq);
        }
        return activeReq;
      }

      if ( // state is not empty
        isCollection && Reflect.get(target, 'size')
        || !isCollection && Object.keys(target).length
      ) {
        // ? Only relevent when the state is not empty and the active request has already settled;
        // ? otherwise, we need to proceed to requesting data.
        // ! Moving this around leads to an endless loop or missing data.
        if (prop in Promise.prototype) {
          // `await` continuously calls `then` until reaching the end of the chain, which is
          // signalled by no more `then`s. So return `undefined` here because we've reached the end
          // of the chain (otherwise, it would have entered a different code-branch above).
          return;
        }
      }

      if (crudders.has(target, prop)) {
        log.debug(`${label} StoreProxy::get(): returning nested prop “${prop}”.`);
        return crudders.read(target, prop, {
          getters,
          label,
        });
      }

      const itemId = isObjectId(prop)
        ? prop
        : crudders.read(target, 'id') as ObjectId;
      const getter = isObjectId(prop)
        ? getters.item?.default
        : getters[prop] ?? getters.default;

      if (!getter) {
        const err = `${label} StoreProxy::get(): no getter to handle “${prop}” (req param “${itemId}”).`;
        log.error(err);
        throw new Error(err);
      }

      log.debug(`${label} StoreProxy::get(): fetching for “${prop}” (req param “${itemId}”).`);
      activeReq = getter(itemId)
        .then((result) => {
          log.debug(`${label} StoreProxy::get(): fetch for “${prop}” succeeded.`);
          if (isCollection && itemId) {
            crudders.write(target, itemId, result);
          } else {
            crudders.overwrite(target, result);
          }

          log.debug(`${label} StoreProxy::get(): settling state read for “${prop}”.`);
          return crudders.read(
            target,
            prop,
            {
              // @ts-ignore
              getter: async () => log.error(
                '${label} StoreProxy::get():',
                `A view requested “${prop}” from store “${label}”,`,
                'but it’s not available after settling the provided GQL operation.',
              ),
              label,
            },
          );
        })
        .finally(() => {
          activeReqs.delete(prop);
        });

      activeReqs.set(prop, activeReq);
      if (!(prop in Promise.prototype)) crudders.write(target, prop, activeReq);

      return activeReq;
    },
    set(target, prop, value) {
      crudders.write(target, prop, value);
      return true;
    },
  }) as any as StoreModel;
}

function isObjectId(p: string): p is ObjectId {
  return p?.length === 24;
}

interface StateCRUDders<Model extends State> {
  [Symbol.toStringTag]: string, // To differentiate in stack trace

  empty(target: Model): void,
  has(target: Model, key: PropertyKey): boolean,
  overwrite(target: Model, replacement: Model): Model,
  read(
    target: Model,
    key: PropertyKey,
    subStoreConfig?: {
      getters?: Model extends Collection ? CollectionGetters : ItemGetters,
      label: string,
    },
  ): unknown | undefined,
  remove(target: Model, key: PropertyKey): boolean,
  write(target: Model, key: PropertyKey, value: unknown): unknown,
}

export const mapCRUD: StateCRUDders<Collection> = {
  [Symbol.toStringTag]: 'MapCRUD',

  empty(collection) {
    return collection.clear();
  },
  has(collection, key) {
    return collection.has(key);
  },
  overwrite(collection, replacement) {
    for (const [key, value] of replacement.entries()) collection.set(key, value);
    return collection;
  },
  read(collection, key, { getters, label } = { label: '' }) {
    const val = collection.get(key);
    if (val && typeof val === 'object') {
      return storeProxyFactory(
        label,
        val as State,
        getters?.item,
      );
    }
    return val;
  },
  remove(collection, key) {
    return collection.delete(key);
  },
  write(collection, key, value) {
    collection.set(key, value);
    return collection.get(key);
  },
};

export const objCRUD: StateCRUDders<Item> = {
  [Symbol.toStringTag]: 'ObjCRUD',

  empty(item) {
    for (const key of Object.keys(item)) delete item[key];
  },
  has(item, key) {
    return Reflect.has(item, key);
  },
  overwrite(item, replacement) {
    for (const [key, value] of Object.entries(replacement)) item[key] = value;
    return item;
  },
  read(item, key) {
    return Reflect.get(item, key);
  },
  remove(item, key) { // @ts-ignore 2538 "Type 'symbol' cannot be used as an index type" is a bug in TS
    return key in item && delete item[key];
  },
  write(item, key, value) {
    Reflect.set(item, key, value);
    return Reflect.get(item, key);
  },
};
