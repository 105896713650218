import { createTransaction, storage } from '…/common/storage.mts';

import type { Logger } from './Logger.mjs';


const channel = new BroadcastChannel('orbiit.ai');
export function keepSessionIdSynced(logger: Logger) {
  channel.onmessage = ({ data: { type, value } }) => {
    if (type === 'orbiit-access-update') logger.sessionId = value.sub;
  };
}

/**
 * ! The utilities below CANNOT exist within Logger{} because of circular dependencies.
 */

export const LOG_OUTPUT_TOGGLE_KEY = 'print_logs';

export async function checkShouldPrintLogs() {
  const searchParamsPrintFlagValue = await checkSearchParamsForPrintFlag();

  // ! `createTransaction` does NOT return the settled value of its callback; it returns the final
  // ! result of the transaction, so in order to get the result of multiple actions, they must be
  // ! handled separately.
  let putting: Promise<boolean | IDBValidKey> = Promise.resolve(false);

  if (searchParamsPrintFlagValue != null) {
    putting = storage.then(
      (db: IDBDatabase) => createTransaction(db,
        (store: IDBObjectStore) => store.put(searchParamsPrintFlagValue, LOG_OUTPUT_TOGGLE_KEY),
      ),
    );
  }

  return putting.then(() => storage.then(
    (db: IDBDatabase) => createTransaction<boolean>(db,
      (store: IDBObjectStore) => store.get(LOG_OUTPUT_TOGGLE_KEY),
    ),
  ));
}

async function checkSearchParamsForPrintFlag() {
  if ( // @ts-ignore Env/context is only configurable in tsconfig, which would apply to everything.
    typeof ServiceWorkerGlobalScope !== 'undefined'
  ) return checkFromSWThreadForPrintFlag();

  return checkFromMainThreadForPrintFlag();
}

function checkFromMainThreadForPrintFlag() {
  return getPrintFlagValue(new URLSearchParams(location.search));
}

export async function checkFromSWThreadForPrintFlag() {
  /* eslint-env serviceworker */
  const clients = await self
    // @ts-ignore Env/context is only configurable in tsconfig, which would apply to everything.
    .clients
    .matchAll({ includeUncontrolled: true }); // TODO: remove this option?

  for (const client of clients) {
    const clientUrl = new URL(client.url);

    const value = getPrintFlagValue(clientUrl.searchParams);

    if (value != null) return value;
  }
}
/**
 * @returns Undefined when flag is not present; otherwise, boolean for the flag value.
 */
export function getPrintFlagValue(searchParams: URLSearchParams) {
  if (!searchParams.has(LOG_OUTPUT_TOGGLE_KEY)) return;

  const value = searchParams.get(LOG_OUTPUT_TOGGLE_KEY);

  return value == null || value !== 'false';
}

export const getSessionId = () => storage.then<ObjectId>(
  (db: IDBDatabase) => createTransaction<string>(db,
    (store: IDBObjectStore) => store.get('sessionId'),
  ),
);
