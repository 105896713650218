import type { PropsWithChildren } from 'react';

import { Meter } from './Meter.tsx';

import { appendPercent } from '../appendPercent.mts';
import { numberPrecision } from '../mathUtils.mts';

import classes from './MeterWithLabels.module.css';


export function MeterWithLabels({
  children: label,
  className,
  disablePercentage = false,
  percentage, // Floating point number between 0 - 100
  count,
}: PropsWithChildren<{
  className?: HTMLElement['className'],
  disablePercentage?: boolean,
  count: Int,
  percentage: number, // Floating point number between 0 - 100
}>) {
  return (
    <div className={className}>
      <div className={classes.OptionTextContainer}>
        <span className={classes.OptionText}>
          {label}
        </span>

        <span
          className={classes.OptionTextLight}
        >
          {disablePercentage ? count : `(${count}) ${appendPercent(numberPrecision(percentage, 1), true)}`}{/* eslint-disable-line react/jsx-newline */}
        </span>
      </div>

      <Meter value={percentage} />
    </div>
  );
}
MeterWithLabels.displayName = 'MeterWithLabels';
