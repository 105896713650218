/* eslint-disable react/jsx-max-depth */

import {
  useEffect,
  useState,
} from 'react';
import { clsx } from 'clsx';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useRefinementParams } from '…/app/common/refinement/useRefinementParams.mts';
import { getFilterCount } from '…/app/common/refinement/getFilterCount.mts';
import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { usePagination } from '…/app/common/pagination/usePagination.mts';
import type { Sort } from '…/app/common/Table/Table.tsx';
import { Table } from '…/app/common/Table/Table.tsx';
import {
  PAGE_ITEM_LIMIT,
  type Search,
  paginationFactory,
  resetPaginationOnRefine,
} from '…/app/common/pagination/factories.mts';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { FilterIcon } from '…/app/common/filtering/FilterIcon.tsx';

import { downloadEngagementSection } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { tableHeadings, transformFeedbackFormsToTabularData } from './transformFeedbackFormsToTabularData.tsx';
import { getEngagementFeedbackForms } from './getFeedbackForms.op.mts';
import type { FeedbackFormsFilters, SortableFields } from './FeedbackFormRefinements.mts';
import { MemoizedFeedbackFormsFiltersForm } from './FiltersForm.tsx';
import { FeedbackFormDrawer } from './FormDrawer.tsx';
import type {
  FeedbackForm,
  WidgetConfig,
} from './feedback-forms.d.ts';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import classes from './FeedbackFormsPage.module.css';


export function EngagementFeedbackForms() {
  const [
    {
      engagement,
      params,
    },
  ] = useViewState();
  const { workspaceId } = params as WalledRouteParams;

  const {
    count: [count, setCount],
    cursors,
    page: [page, setPage],
    records: [data, setData],
  } = usePagination<FeedbackForm>();

  const {
    filters: [filters, setFilters],
    search: [search, setSearch],
    sort: [sort, setSort],
  } = useRefinementParams({
    filters: {
      CREATED_AT: new Array(),
      MATCH_RATING: new Array(),
      NPS: new Array(),
      SELECTED_OPTIONS: new Array(),
    } as FeedbackFormsFilters,
    search: {
      q: '',
    } as Search,
    sort: {
      ascending: false,
      fieldName: 'createdAt',
    } as Sort<SortableFields>,
  });

  const [widgetConfig, setWidgetConfig] = useState<WidgetConfig>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);

    const pagination = paginationFactory<FeedbackForm, 'feedbackForms'>(
      'feedbackForms',
      {
        cursors,
        direction: page.direction,
        filters,
        limit: page.limit,
        reset: page.reset,
        search: search.q,
      },
      {
        setCount: setCount,
        setRecords: setData,
      },
    );

    getEngagementFeedbackForms(
      workspaceId,
      engagement.id,
      {
        ...pagination.props,
        sort,
      },
    )
      .then(pagination.then)
      .finally(() => setLoading(false));
  }, [
    filters,
    page,
    search,
    sort,
  ]);

  const setRefineCriteria = resetPaginationOnRefine<SortableFields, EngagementFilters>(
    setPage,
    setFilters,
    setSearch,
    setSort,
  );
  const filtersCount = getFilterCount(filters);

  return (
    <main
      className="ContentContainer"
      data-testid="EngagementFeedbackFormsPage"
    >
      <Bureau className={classes.Bureau}>
        <Shelf className="ListPage">
          <header className={clsx('SplitHeader', classes.ExtraMarginBottom)}>
            <h1 className="SplitHeader">
              Feedback Forms

              {' '}

              <Chip count={count} />
            </h1>

            <fieldset className="SplitHeader" disabled={loading}>
              <FilterIcon
                count={filtersCount}
                onClick={() => setWidgetConfig({
                  data: {
                    filters,
                    onSubmit: (_filters: FeedbackFormsFilters) => {
                      setRefineCriteria({
                        filters: _filters,
                        search,
                        sort,
                      });
                      setWidgetConfig(undefined);
                    },
                  },
                  key: 'filters',
                  name: 'filters',
                  type: 'drawer',
                })}
                testId="FeedbackFormsTableFilterButton"
              />

              <Form
                name="search"
                onReset={() => setRefineCriteria({
                  filters,
                  search: { q: '' },
                  sort,
                })}
                onSubmit={(_search: Search) => setRefineCriteria({
                  filters,
                  search: _search,
                  sort,
                })}
                role="search"
              >
                <Field
                  arrangement={Field.ARRANGEMENTS.STAND_ALONE}
                  defaultValue={search.q}
                  label={null}
                  name="q"
                  placeholder="Search"
                  type="search"
                />
              </Form>

              <Popover
                anchor={({ className, ...props }) => (
                  <Button
                    {...props}
                    appearance={Button.APPEARANCES.BASIC}
                    aria-label="context menu"
                    className={clsx(className, 'ContextMenuIcon')}
                  />
                )}
                position={Popover.POSITIONS.BOTTOM_RIGHT}
                type={Popover.TYPES.MENU}
              >
                <CTA
                  disabled={data.size === 0}
                  guise={CTA.GUISES.LINK}
                  menuitem=""
                  to={() => downloadEngagementSection(workspaceId, engagement, 'feedback-forms')}
                >Download Feedback CSV
                </CTA>

                <CTA
                  disabled={data.size === 0}
                  guise={CTA.GUISES.LINK}
                  menuitem=""
                  to={() => downloadEngagementSection(workspaceId, engagement, 'nochat-forms')}
                >Download No-Chat CSV
                </CTA>
              </Popover>
            </fieldset>
          </header>

          <Table
            className={classes.Table}
            emptyText={
              (filtersCount === 0 && search?.q === '')
                ? 'No Feedback Forms have come in yet.'
                : 'No Feedback Forms match your criteria.'
            }
            headings={tableHeadings}
            loading={loading}
            onSort={(_sort: Sort<SortableFields>) => setRefineCriteria({
              filters,
              search,
              sort: _sort,
            })}
            pagination={{
              onPage: setPage,
              page,
              total: count,
            }}
            records={transformFeedbackFormsToTabularData(
              data,
              {
                limit: PAGE_ITEM_LIMIT,
                page: page.number,
              },
              {
                setWidgetConfig,
              },
            )}
            sort={sort}
          />
        </Shelf>

        <Drawer
          className={classes.Drawer}
          modal="backdropped"
          onClose={() => setWidgetConfig(undefined)}
          open={widgetConfig?.type === 'drawer'}
        >
          {(widgetConfig?.type === 'drawer' && !!widgetConfig?.data) && (
            <>
              {(widgetConfig.data?.feedbackForm && widgetConfig.name === 'form') && (
                <FeedbackFormDrawer
                  engagement={engagement}
                  feedbackForm={widgetConfig.data.feedbackForm}
                />
              )}

              {(widgetConfig.data?.filters && widgetConfig.name === 'filters') && (
                <MemoizedFeedbackFormsFiltersForm
                  filters={widgetConfig.data.filters}
                  questions={engagement?.feedbackQuestions ?? []}
                  setFilters={widgetConfig.data.onSubmit}
                />
              )}
            </>
          )}
        </Drawer>
      </Bureau>
    </main>
  );
}

EngagementFeedbackForms.displayName = 'Engagement Feedback Forms';

const handle = {
  sidebar: true,
  subheader: true,
  title: 'Engagement Feedback Forms',
};

export {
  EngagementFeedbackForms as Component,
  handle,
};
