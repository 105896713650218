import type {
  Dispatch,
  DragEvent,
  SetStateAction,
} from 'react';

import type { Match, OptIn } from './Match.d.ts';


export type DragInfo = Partial<{
  from: Match['id'],
  target: OptIn,
  to: Match['id'],
}>
export type DragItemEvent = DragEvent<HTMLElement & { parentNode: HTMLFieldSetElement }>;
export type DropzoneEvent = DragEvent<HTMLFieldSetElement>;
export type SetDragging = Dispatch<SetStateAction<DragInfo>>;

export function onDragStart(
  event: DragItemEvent,
  optin: OptIn,
  setDragging: SetDragging,
) {
  // ! Do NOT call `event.preventDefault()`: it prevents all subsequent drag events
  if (!event.dataTransfer || !event.currentTarget) return;

  event.dataTransfer.effectAllowed = 'move';

  const from = event.currentTarget.parentNode.id;

  setDragging({
    from,
    target: optin,
  });
}

// Dropzone events

export function onDragEnter(event: DropzoneEvent) {
  event.preventDefault(); // ! MUST be called for `drop` event to fire
}

export function onDragOver(
  event: DropzoneEvent,
  dragging: DragInfo,
  setDragging: SetDragging,
) {
  if (event.dataTransfer) event.dataTransfer.dropEffect = 'move';

  setNewMatch(event, dragging, setDragging);
}

export function setNewMatch(
  event: DropzoneEvent,
  dragging: DragInfo,
  setDragging: SetDragging,
) {
  if (!event.currentTarget) return;

  const to = event.currentTarget.id;
  const { from } = dragging;

  if (to === from) return; // Nothing to do. Abort before enabling the `drop` event.

  event.preventDefault(); // ! MUST be called for `drop` event to fire

  if (to === dragging.to) return;

  setDragging({ to });
}
