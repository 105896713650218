const InfoIcon = (props) => (
  <svg
    {...props}
    fill="none"
    height="19"
    viewBox="0 0 19 19"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.87225 8.47266H9.44159C9.20526 8.47266 9.01367 8.66321 9.01367 8.89826V14.0164C9.01367 14.2515 9.20526 14.442 9.44159 14.442H9.87225C10.1086 14.442 10.3002 14.2515 10.3002 14.0164V8.89826C10.3002 8.66321 10.1086 8.47266 9.87225 8.47266Z"
      fill="#4E4E4E"
    />

    <path
      d="M9.87225 5.49219H9.44159C9.20526 5.49219 9.01367 5.68274 9.01367 5.91779V6.77173C9.01367 7.00679 9.20526 7.19734 9.44159 7.19734H9.87225C10.1086 7.19734 10.3002 7.00679 10.3002 6.77173V5.91779C10.3002 5.68274 10.1086 5.49219 9.87225 5.49219Z"
      fill="#4E4E4E"
    />

    <path
      d="M9.65625 17.4239C11.1818 17.4239 12.6732 16.974 13.9416 16.131C15.2101 15.288 16.1988 14.0898 16.7826 12.688C17.3664 11.2862 17.5192 9.74363 17.2215 8.25544C16.9239 6.76726 16.1893 5.40027 15.1105 4.32735C14.0318 3.25443 12.6574 2.52376 11.1611 2.22774C9.66481 1.93172 8.11388 2.08365 6.70442 2.66431C5.29497 3.24497 4.09028 4.22829 3.24271 5.48991C2.39514 6.75153 1.94275 8.2348 1.94275 9.75214C1.94275 11.7868 2.75542 13.7382 4.20198 15.1769C5.64855 16.6157 7.61051 17.4239 9.65625 17.4239ZM9.65625 18.7035C7.87622 18.7035 6.13616 18.1785 4.65612 17.1949C3.17608 16.2113 2.02253 14.8133 1.34134 13.1777C0.66015 11.542 0.48192 9.74221 0.829186 8.00581C1.17645 6.26942 2.03362 4.67444 3.29229 3.42257C4.55096 2.17071 6.15461 1.31817 7.90044 0.972783C9.64627 0.627393 11.4559 0.80466 13.1004 1.48217C14.7449 2.15967 16.1505 3.30699 17.1395 4.77903C18.1284 6.25107 18.6562 7.98173 18.6562 9.75214C18.6562 12.1262 17.708 14.403 16.0202 16.0817C14.3324 17.7604 12.0432 18.7035 9.65625 18.7035Z"
      fill="#4E4E4E"
    />
  </svg>
);

export default InfoIcon;
