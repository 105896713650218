._SubPage_xlgdz_1 {
  background-color: var(--brand-subtle-light);
  display: flex;
  flex-direction: row;
}

@media (width <= 960px) {
  ._SubPage_xlgdz_1 {
    flex-direction: column;
  }
}

._Section_xlgdz_13 {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

._SplitContent_xlgdz_21 {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  order: -1;
}

._Header_xlgdz_29 {
  align-items: center;
  font-size: 1.5em;
}

._HeaderButtons_xlgdz_34 {
  font-size: 0.875rem;
  font-weight: normal;
  white-space: nowrap;
}

._Title_xlgdz_40 {
  display: inline;
  text-transform: capitalize;
}

._Header_xlgdz_29 ._Title_xlgdz_40 {
  font-size: inherit;
}

._NavBack_xlgdz_49 {
  font-weight: bold;
  text-decoration: none;
}

@media (width <= 960px) {
  ._EditableSection_xlgdz_55 {
    order: 1;
  }
}

@media (width > 960px) {
  ._EditableSection_xlgdz_55 {
    width: 60%;
  }
}

._EditableSection_xlgdz_55 {
  background-color: var(--brand-light);
  box-shadow: var(--box-shadow);
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (width > 960px) {
  ._Sidebar_xlgdz_75 {
    max-width: 50ch;
    width: 40%;
  }
}

._Sidebar_xlgdz_75 {
  align-items: center;
  padding-left: 3rem;
  padding-right: 3rem;
}
