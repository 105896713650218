import { rpc } from '…/app/common/rpc/client.mts';


// TODO: Optimize query to not request the data when feature flags are not present
// but still show the cards with the feature flag to indicate what users are missing
// out on.
export function getAnalytics({
  filters,
  workspaceId,
}: {
  filters: {
    audienceIds?: string[],
    fromDate: string,
    productTypes?: string[],
    untilDate: string,
  },
  workspaceId: string,
}) {
  return rpc.post('actions/get-workspace-insights', {
    json: {
      ...filters,
      workspaceId,
    },
  })
    .json();
}
