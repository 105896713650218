export default {
  "Content": "_Content_tf2b3_1",
  "NPSExpli": "_NPSExpli_tf2b3_6",
  "DoughnutContainer": "_DoughnutContainer_tf2b3_12",
  "Score": "_Score_tf2b3_17",
  "NPSValue": "_NPSValue_tf2b3_51",
  "ScaleLabels": "_ScaleLabels_tf2b3_55",
  "ScaleStart": "_ScaleStart_tf2b3_60",
  "ScaleEnd": "_ScaleEnd_tf2b3_65",
  "OrbiitCallout": "_OrbiitCallout_tf2b3_70"
};import "ni:sha-256;iWqsfWzlvP4aFy86TdfbhVOpXudqajhkSvAjs9h4JSE";