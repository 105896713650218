import { createTransaction, storage } from '…/common/storage.mts';

import { log } from './logger.mts';
import { parsePermissionsFromToken } from './getPermissionsFromAccessToken.mts';


export type TokenKey = 'access' | 'refresh';

const channel = new BroadcastChannel('orbiit.ai');

/**
 * An isomorphic (between the SPA and a Service Worker) store for Tokens.
 */
export const tokens = {
  delete(key: TokenKey): Promise<void> {
    log.info(`deleting ${key} token`);

    return storage.then((db) => createTransaction(db, (store) => store.delete(key)));
  },
  get(key: TokenKey): Promise<string | undefined> {
    log.info(`getting ${key} token`);

    return storage.then((db) => createTransaction(db, (store) => store.get(key)));
  },
  set(key: TokenKey, token: string) {
    log.info(`setting ${key} token to ${token}`);

    let saveSessionId: Promise<void | IDBValidKey> = Promise.resolve();

    if (key === 'access') {
      const decodedToken = parsePermissionsFromToken(token);

      saveSessionId = storage.then((db) => createTransaction(db, (store) => store.put(decodedToken.sub, 'sessionId')));

      channel.postMessage({
        type: 'orbiit-access-update',
        value: decodedToken,
      });
    }

    return Promise.all([
      storage.then((db) => createTransaction(db, (store) => store.put(token, key))),
      saveSessionId,
    ]);
  },
};
