._ErrorDetails_15btl_1 {
  color: var(--text-neutral);
}

._ErrorOutput_15btl_5 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  line-height: 1.6;
}

._ErrorOutput_15btl_5::before {
  content: "";
  display: block;
  margin: 1em auto 0;
  width: 100%;
}

._ErrorDetailsToggle_15btl_19 {
  align-items: center;
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  gap: 1ch;
  margin-left: auto;
  margin-right: auto;
  padding: 1em;
  width: fit-content;
}

._ErrorDetailsToggle_15btl_19::marker {
  content: "";
}

._ErrorDetailsToggle_15btl_19::before {
  content: '›';
  font-weight: 900;
  place-self: center;
  rotate: 90deg;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: rotate;
}

._ErrorDetails_15btl_1:not([open]) ._ErrorDetailsToggle_15btl_19::before {
  rotate: 0deg;
}

._Error_15btl_1 {
  white-space: pre-wrap;
}
