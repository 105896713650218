.Section {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

.SectionHeader {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.TwoCardGrid {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  margin-top: 1rem;
  place-items: stretch;
}

.ExtraPadding {
  padding: 2rem;
}
