import type { PropsWithChildren } from 'react';
import { clsx } from 'clsx';

import classes from './StickyHeader.module.css';
export function StickyHeader({
  className = '',
  children,
  ...props
}: PropsWithChildren<{
  [key: string]: unknown,
  className?: string,
}>) {
  return (
    <div className={clsx(classes.StickyHeader, className)} {...props}>
      {children}
    </div>
  );
}

StickyHeader.displayName = 'StickyHeader';
