import type { HTTPError } from 'ky';

import { rpc } from '…/app/common/rpc/client.mjs';
import { transformListToMap } from '…/app/common/transformListToMap.mjs';

import type { OptIn } from './Match.d.ts';


export function fetchUnmatched(engagementId: ObjectId) {
  return rpc
    .post('actions/unmatched-optins', {
      json: {
        engagementId,
      },
    })
    .json<{ optins: OptIn[] }>()
    .catch((err: HTTPError) => {
      throw Object.assign(
        new AggregateError(
          new Array(err),
          'Couldn’t retrieve unmatched members',
        ),
        { code: err?.response.status },
      );
    })
    .then((data) => transformListToMap(data.optins));
}
