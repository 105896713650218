.DraggableItem {
  align-items: center;
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-template-columns: min-content auto min-content;
  margin: calc(-1 * var(--grid-gutter));
  padding: var(--grid-gutter);
}

.DragHandle {
  cursor: grab;
}

.DragHandle:active {
  cursor: grabbing;
}
