export const enum DEVICE_TYPE {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export function getDeviceType({ userAgentData }: Navigator) {
  if (userAgentData && 'mobile' in userAgentData) {
    return userAgentData.mobile ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP;
  }
}
