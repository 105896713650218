import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type {
  Member,
  MemberHistory,
} from './Member.d.ts';
import GET_MEMBER_HISTORY from './getMemberHistory.gql';


export function getMemberHistory(
  workspaceId: ObjectId,
  memberId: Member['id'],
) {
  return gqlOperation<{ workspace: { history: MemberHistory } }>(GET_MEMBER_HISTORY, {
    memberId,
    workspaceId,
  })
    .then((data) => data.workspace.history);
}
