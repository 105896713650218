mutation UpdateConversationGuide($conversationGuideId: ID!, $conversationGuide: ConversationGuideInput!) {
  conversationGuide: updateConversationGuide(
    conversationGuideId: $conversationGuideId,
    conversationGuide: $conversationGuide
  ) {
    id
    isDisabled
    json
  }
}
