.SettingsPanel {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  margin: calc(2 * var(--grid-gutter)) var(--grid-gutter);
  padding-top: 1.5rem;
}

@media (width <= 960px) {
  .SettingsPanel > h1 {
    margin-left: var(--grid-gutter);
    margin-right: var(--grid-gutter);
  }
}

@media (width > 960px) {
  .SettingsPanel {
    margin-left: auto;
    margin-right: auto;
    width: 80ch;
  }
}

.Section {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  display: flex;
  flex-direction: column;
  gap: inherit;
  margin-top: 1rem;
}


@media (width > 960px) {
  .Section {
    padding: calc(2 * var(--grid-gutter));
  }

  .Section > button {
    place-self: end;
  }
}
