import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

const MetricCard = ({
  icon, value, label,
}) => (
  <div className="bg-white rounded-lg shadow-sm p-6 flex items-center space-x-4 w-full">
    <div className="bg-gray-100 rounded-full px-4 py-3">
      <div className="text-blue-500">
        {icon}
      </div>
    </div>

    <div>
      <div className="text-4xl font-bold">{value}</div>

      <div className="text-gray-500 text-xl">{label}</div>
    </div>
  </div>
);

MetricCard.displayName = 'MetricCard';

const TopMembers = () => {
  const members = [
    {
      matches: 8,
      name: 'John Mobbin',
    },
    {
      matches: 6,
      name: 'Nicky Mobbin',
    },
    {
      matches: 6,
      name: 'James Dean',
    },
    {
      matches: 4,
      name: 'Timmy Tester',
    },
    {
      matches: 4,
      name: 'John Johnson',
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-4 max-w-sm">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Top Members</h2>

        {/* <button className="text-blue-500 flex items-center">
          See all
          <ChevronRight className="w-4 h-4 ml-1" />
        </button> */}
      </div>

      <ul>
        {members.map((member, index) => (
          <li className="flex items-center justify-between py-2" key={index}>
            <div className="flex items-center">
              <IIcon className="w-5 h-5 text-purple-500 mr-2" name="filter" />

              <span className="font-medium">{member.name}</span>
            </div>

            <span className="text-gray-500">{member.matches} matches</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

TopMembers.displayName = 'TopMembers';

export function HomePage() {
  return (
    <>
      <header>
        Foo
      </header>

      <div className="ContentContainer">
        {/** NOTE: https://play.tailwindcss.com/wxvvCUlz2I */}
        <div className="mx-auto p-2 md:w-[1200px] border border-black sm:w-full md:grid md:grid-cols-4 gap-4">
          <div className="md:col-span-3 sm:w-full border border-dotted border-blue-600 flex flex-col gap-2 p-2">
            <div className="border border-dotted border-red-500">
      Column one - Block one
            </div>

            <div className="flex justify-between gap-4">
              <MetricCard
                icon={<IIcon className="w-5 h-5 text-purple-500 mr-2" name="filter" />}
                label="Average NPS"
                value="90"
              />

              <MetricCard
                icon={<IIcon name="funnel" />}
                label="Matches"
                value="1,311"
              />

              <MetricCard
                icon={<IIcon name="pencil" />}
                label="Opt-in rate"
                value="27%"
              />
            </div>

            <div className="border border-dotted border-red-500">
      Column one - Block one
            </div>
          </div>

          <div className="md:col-span-1 sm:w-full border border-dotted border-blue-500 flex flex-col gap-2 p-2">
            <div className="border border-dotted border-red-500">
      Column Two - Block one
            </div>

            <TopMembers />

            <TopMembers />
          </div>
        </div>

      </div>
    </>
  );
}
HomePage.displayName = 'HomePage';

export {
  HomePage as Component,
};
