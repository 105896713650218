import PropTypes from 'prop-types';

import {
  ROLES,
  useRequiredRole,
} from './useRoles.mjs';

const { node, oneOf } = PropTypes;

const RequiredRole = ({ level, children }) => {
  const havePermission = useRequiredRole({ level });

  if (havePermission) {
    return children;
  }
  return null;
};
RequiredRole.displayName = 'RequiredRole';

RequiredRole.propTypes = {
  children: node,
  level: oneOf(ROLES),
};

export const EditorRole = ({ children }) => <RequiredRole level="EDITOR">{children}</RequiredRole>;
EditorRole.displayName = 'EditorRole';

EditorRole.propTypes = {
  children: node,
};

export const AdminRole = ({ children }) => <RequiredRole level="ADMIN">{children}</RequiredRole>;
AdminRole.displayName = 'AdminRole';

AdminRole.propTypes = {
  children: node,
};

export const InternalAdminRole = ({ children }) => <RequiredRole level="INTERNALADMIN">{children}</RequiredRole>;
InternalAdminRole.displayName = 'InternalAdminRole';

InternalAdminRole.propTypes = {
  children: node,
};
