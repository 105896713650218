import { clsx } from 'clsx';
import PropTypes from 'prop-types';

import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import { Meter } from '…/app/common/Meter/Meter.tsx';

import classes from './MeterWithPopover.module.css';


const {
  arrayOf,
  elementType,
  number,
  oneOfType,
  string,
} = PropTypes;

export function MeterWithPopover({
  ContainerTag = 'div',
  className: meterClassName,
  high,
  low,
  max,
  min,
  optimum,
  value,
}) {
  return (
    <ContainerTag className={clsx(classes.MeterContainer, meterClassName)}>
      <span className={classes.MeterBound}>{min}</span>

      <Popover
        anchor={({ className, ...props }) => (
          <Meter {...props} className={className} />
        )}
        containerClassName={classes.MeterPopoverContainer}
        high={high}
        low={low}
        max={max}
        min={min}
        optimum={optimum}
        style={{
          bottom: '2.25em',
          left: `${(Math.abs(min) + optimum) / (Math.abs(min) + max) * 100}%`,
          minWidth: 'fit-content',
          whiteSpace: 'nowrap',
        }}
        type={Popover.TYPES.TOOLTIP}
        value={value}
      >
        {optimum} (benchmark)
      </Popover>

      <span className={classes.MeterBound}>{max}</span>
    </ContainerTag>
  );
}

MeterWithPopover.displayName = 'MeterWithPopover';

MeterWithPopover.propTypes = {
  ContainerTag: oneOfType([
    elementType,
    string,
  ]),
  className: oneOfType([
    arrayOf(string),
    string,
  ]),
  high: number.isRequired,
  low: number.isRequired,
  max: number.isRequired,
  min: number.isRequired,
  optimum: number,
  value: number.isRequired,
};
