._LogoPreview_25glg_1 {
  flex: 1;
}

._LogoPreview_25glg_1 > object {
  flex: 1;
}

@media (width <= 960px) {
  ._FullStack_25glg_10 {
    align-items: stretch;
  }
}

@media (width > 960px) {
  ._LogoPreview_25glg_1 {
    max-width: 14em;
  }
}
