import audienceFrag from './audience.frag.gql';

export const COPY_AUDIENCE = `
mutation CopyAudience($audienceId: ID!, $workspaceId: ID!, $name: String!) {
  clone: copyAudience(
    audienceId: $audienceId
    name: $name
    workspaceId: $workspaceId
  ) {
    ...audienceFields
  }
}

${audienceFrag}
`;

