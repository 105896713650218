mutation DeleteListToSync(
  $workspaceId: ID!,
  $integrationId: ID!,
  $remoteId: String!
) {
  completed: deleteListToSync(
    workspaceId: $workspaceId,
    integrationId: $integrationId,
    remoteId: $remoteId
  )
}
