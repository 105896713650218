._SetupGuide_1u7r1_1 {
  align-items: center;
  display: grid;
  gap: var(--grid-gutter);
  grid-template-areas: "Steps Image Guide";
  grid-template-columns: 1.5fr auto 1.5fr;
  position: relative;
}

._CloseButton_1u7r1_10 {
  position: absolute;
  right: 0;
  top: 0;
}

._Steps_1u7r1_16 {
  grid-area: Steps;
  place-self: start;
}

._Steps_1u7r1_16 [aria-current="true"] {
  font-weight: bold;
}

._Img_1u7r1_25 {
  grid-area: Image;
}

._Guide_1u7r1_29 {
  grid-area: Guide;
}

._Title_1u7r1_33,
._GuideLink_1u7r1_34 {
  font-size: 1.4em;
}

._GuideLink_1u7r1_34 {
  color: inherit;
  font-weight: bold;;
}
