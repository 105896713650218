.SetupCard {
  background: linear-gradient(
    195deg,
    color-mix(
      in srgb,
      var(--colourise),
      transparent 74%
    ),
    transparent 10%
  );
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  padding: calc(2 * var(--grid-gutter));
}

.SetupCard[data-status="complete"] {
  --colourise: var(--status-success);
}

.SetupCard[data-status="incomplete"] {
  --colourise: var(--status-danger);
}

.SetupCard[data-status="warn"] {
  --colourise: var(--status-warning);
}

.SetupCardHeader:not(:only-child)::after {
  border-top: 1px solid var(--border-colour-normal);
  content: '';
  grid-column: 2 / -1;
}

.SetupCardHeader h3 {
  font-style: italic;
  font-weight: unset;
}

.Status {
  color: var(--colourise);
  text-align: right;
}

.Status::before {
  background-color: var(--colourise);
  color: white;
  display: inline-block;
  height: 1.4em;
  margin-right: 1em;
  text-align: center;
  width: 1.4em;
}

.Status[data-status="complete"]::before {
  border-radius: 100%;
  content: '✓';
}

.Status[data-status="incomplete"]::before {
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  content: '!';
}

.Status[data-status="warn"]::before {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  content: '!';
}

.SetupCardHeader,
.SetupCardItems,
.SetupCardItem {
  align-items: center;
  display: grid;
  gap: var(--grid-gutter);
}

.SetupCardHeader {
  grid-template-columns: 4ch auto minmax(15ch, max-content);;
}

.SetupCardItems {
  grid-template-columns: 4ch auto;
}

.SetupCardItem {
  grid-column: 2;
  grid-template-columns: auto max-content;
}

.WithIcon .SetupCardItem {
  grid-template-columns: 2ch auto max-content;
}
