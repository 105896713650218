import Button from 'form5/react/Button';
import { useState } from 'react';
import { useReward } from 'react-rewards';
import { useParams } from 'react-router-dom';

import { EngagementRouteParams } from '…/app/route-params.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { updateEngagement } from '…/app/w/workspace/engagements/engagement/gql/updateEngagement.op.mts';


export function SubmitEngagement({
  onSuccess,
}: {
  onSuccess(result: Engagement): void,
}) {
  const { engagementId, workspaceId } = useParams() as EngagementRouteParams;
  const [isSubmitting, setSubmitting] = useState(false);
  const { reward: confettiReward } = useReward('submit-for-review', 'confetti');

  async function onSubmit() {
    setSubmitting(true);

    return updateEngagement({
      engagement: {
        isInReview: true,
      },
      engagementId,
      workspaceId,
    })
      .then(async (d) => {
        confettiReward();
        await new Promise((r) => setTimeout(r, 1_000)); // Artificially delay the dialogue closing so confetti has time
        return d;
      })
      .then(onSuccess)
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <h1>Submit Engagement for review</h1>

      <p>By clicking Confirm below, I acknowledge:</p>

      <p>
        All email, signup & feedback form copy, dates, audience list, and engagement title is final and approved;
        these <strong>cannot be changed for this round</strong>.
      </p>

      <p>
        The <strong>member data I have provided includes an email address and first name</strong>,

        and that if it does not, <em>Orbiit cannot move forward</em> with the creation of this engagement.
      </p>

      <SubmitButton
        appearance={Button.APPEARANCES.PRIMARY}
        disabled={isSubmitting}
        id="submit-for-review"
        isSubmitting={isSubmitting}
        onClick={onSubmit}
        type="submit"
      >Submit for review
      </SubmitButton>
    </>
  );
}
SubmitEngagement.displayName = 'SubmitEngagement';
