import { omit } from 'lodash-es';
import { clsx } from 'clsx';

import { formatDate } from '…/app/common/dateUtils.mts';

import { timezones } from '…/app/common/timezones.mts';
import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';

import type { ScheduleState } from './composeScheduleData.mts';

import { TimelineCard } from './TimelineCard.jsx';
import { TimelineDot } from './TimelineDot.tsx';

import classes from './ScheduleTimeline.module.css';


export function ScheduleTimeline({
  // Note: these are DateTime objects that already have been adjusted with `timezoneIso`
  conversationDates,
  isEditMatchesEnabled,
  matchEditingCloses,
  matchIntroAt,
  matchesGenerated,
  oneToOneEmails: {
    chaser,
    invite,
  },
  optInClosesAt,
  optInOpensAt,
  timezoneIso,
}: ScheduleState) {
  return (
    <>
      <p>All dates below are <strong>{timezones[timezoneIso]?.alternativeName}</strong> (engagementʼs time-zone).</p>

      <div className={classes.TimelineCards}>
        <div className={clsx('Card', classes.TimelineBlock)}>
          <h2>Registration window</h2>

          <TimelineCard dates={formatDate(optInOpensAt)} {...cardsCopy.registrationOpens} />

          <TimelineCard
            dates={!invite.isDisabled ? formatDate(invite.sendAt) : null}
            title={invite.isDisabled ? 'Invite email is disabled' : cardsCopy.invite.title}
            {...omit(cardsCopy.invite, ['title'])}
          />

          <TimelineCard
            dates={!chaser.isDisabled ? formatDate(chaser.sendAt) : null}
            title={chaser.isDisabled ? 'Reminder email is disabled' : cardsCopy.chaser.title}
            {...omit(cardsCopy.chaser, ['title'])}
          />

          <TimelineCard dates={formatDate(optInClosesAt)} {...cardsCopy.registrationCloses} />
        </div>

        <TimelineDot />

        <div className={clsx('Card', classes.TimelineBlock)}>
          <h2>Matching</h2>

          <TimelineCard dates={formatDate(matchesGenerated)} {...cardsCopy.matchesGenerated} />

          {isEditMatchesEnabled
            ? (
              <FeatureFlag flags={[FeatureFlag.FLAGS.matchEditing]}>
                <>
                  <TimelineCard
                    dates={isEditMatchesEnabled ? formatDate(matchEditingCloses) : null}
                    {...cardsCopy.matchEditingCloses}
                  />

                  <TimelineCard dates={formatDate(matchIntroAt)} {...cardsCopy.intros} />
                </>
              </FeatureFlag>
            )
            : (
              <TimelineCard
                // dates={formatDate(deriveDateFromDateTime(matchesGenerated.plus({ hours: 24 })))}
                dates={formatDate(matchesGenerated)}
                {...cardsCopy.intros}
              />
            )}
        </div>

        <TimelineDot />

        <div className={clsx('Card', classes.TimelineBlock)}>
          <h2>Conversations</h2>

          <TimelineCard
            dates={conversationDates.map((date) => date && formatDate(date))}
            {...cardsCopy.conversations}
          />
        </div>
      </div>
    </>
  );
}

ScheduleTimeline.displayName = 'ScheduleTimeline';

const cardsCopy = {
  chaser: {
    blurb: 'Second email is sent via Orbiit.',
    icon: 'mute',
    target: 'chaser',
    title: 'Reminder Emails Go Out',
  },
  conversations: {
    blurb: 'Great conversations happen! View the feedback in the platform.',
    icon: 'cuppa',
    target: 'conversations',
    title: 'conversation dates',
  },
  intros: {
    blurb: 'Participants receive their introductions and calendar invites.',
    icon: 'handshake',
    target: 'intros',
    title: 'Introductions Go Out',
  },
  invite: {
    blurb: 'Opt-in link included.',
    icon: 'envelope',
    target: 'invite',
    title: 'Invite Emails Go Out',
  },
  matchEditingCloses: {
    blurb: 'The window to edit matches ends.',
    icon: 'rings',
    target: 'registration',
    title: 'Match editing closes',
  },
  matchesGenerated: {
    blurb: 'The match algorithm will create the matches.',
    icon: 'rings',
    target: 'registration',
    title: 'Matching',
  },
  registrationCloses: {
    blurb: 'Members will not be able to register or be added after this date.',
    icon: 'padlock',
    target: 'registration',
    title: 'registration closes',
  },
  registrationOpens: {
    blurb: 'Opt-in link is live.',
    icon: 'document',
    target: 'registration',
    title: 'Registration opens',
  },
};
