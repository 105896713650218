/* eslint-disable react/jsx-max-depth */

import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import Field from 'form5/react/Field';
import {
  type ReactNode,
  useState,
} from 'react';
import { generatePath } from 'react-router-dom';
import { toast } from 'sonner';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { StickyHeader } from '…/app/w/workspace/common/StickyHeader/StickyHeader.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { STATUSES, STATUSES_ORDERED } from '…/app/w/workspace/engagements/constants.mts';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import { updateEngagement } from '../gql/updateEngagement.op.mts';
import subpageClasses from '../setup/EngagementSubPage.module.css';

import classes from './EngagementSettingsPage.module.css';


const SectionTitle = ({ children, comingSoon = false }: { children: ReactNode, comingSoon: boolean }) => (
  <div className={classes.Title}>
    <h2
      className={classes.CardTitle}
      style={{
        '-moz-background-clip': 'text',
        '-webkit-background-clip': 'text',
      }}
    >
      {children}
    </h2>

    {comingSoon ? (<Chip>Coming Soon</Chip>) : null}
  </div>
);

SectionTitle.displayName = 'SectionTitle';

export function EngagementSettingsPage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState();
  const {
    engagementId,
    workspaceId,
  } = params;

  const title = 'Advanced Settings';

  const [isSubmitting, setSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  async function onSubmit(delta: Record<string, any>) {
    setSubmitting(true);
    return updateEngagement({
      engagement: delta,
      engagementId,
      workspaceId,
    })
      .then((updatedData: any) => {
        setPageProps((prev) => ({
          ...prev,
          engagement: updatedData,
        }));
        toast.success('Settings saved.');
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  const statusIx = STATUSES_ORDERED.indexOf(engagement.status);
  const canEditInviteSettings = statusIx < STATUSES_ORDERED.indexOf(STATUSES.OPTINSOPEN);
  const canEditMatchingSettings = statusIx < STATUSES_ORDERED.indexOf(STATUSES.MATCHING);
  const canEditMatchExecutionSettings = canEditMatchingSettings;

  return (
    <main
      className={clsx(className, classes.Page)}
      data-testid="EngagementSettingsPage"
    >
      <Form
        name="engagement-template"
        onDirty={() => setDirty(true)}
        onPristine={() => setDirty(false)}
        onSubmit={onSubmit}
      >
        <StickyHeader className={classes.Header}>
          <div>
            <CTA
              className={subpageClasses.NavBack}
              title="Back to engagement"
              to={generatePath('/w/:workspaceId/engagements/:engagementId', params)}
            >←
            </CTA>

            {' '}

            <h1 className={subpageClasses.Title}>Experimental: {title}</h1>
          </div>

          <SubmitButton
            appearance={Button.APPEARANCES.PRIMARY}
            disabled={!isDirty || isSubmitting}
            isSubmitting={isSubmitting}
            type="submit"
          >Save
          </SubmitButton>
        </StickyHeader>

        <fieldset disabled={isSubmitting} id="settings-engagement-matching">
          <section className={clsx('Card', classes.SectionCard)}>
            <div className={clsx(classes.Content)}>
              <SectionTitle>
                Matching Settings
              </SectionTitle>

              <blockquote>
                Matching execution settings.
              </blockquote>

              <Field
                defaultChecked={engagement?.isAutoExecute || false}
                disabled={!canEditMatchingSettings}
                label="Auto-Execute Matching for Engagement"
                name="isAutoExecute"
                readOnly={!canEditMatchingSettings}
                type="checkbox"
                variant="toggle"
              />

              <blockquote>
                Post-matching execution settings.
              </blockquote>

              {engagement.product === PRODUCT_TYPES.ONE_TO_ONE && (
                <Field
                  defaultChecked={engagement?.sendNoshowEmail || false}
                  disabled={!canEditMatchExecutionSettings}
                  label="Send No-Show Emails (Victim + Offender) for 1:1 Engagements"
                  name="sendNoshowEmail"
                  readOnly={!canEditMatchExecutionSettings}
                  type="checkbox"
                  variant="toggle"
                />
              )}

              <Field
                defaultChecked={engagement?.sendNotMatchedEmail || false}
                disabled={!canEditMatchExecutionSettings}
                label="Send Emails to Unmatched Participants"
                name="sendNotMatchedEmail"
                readOnly={!canEditMatchExecutionSettings}
                type="checkbox"
                variant="toggle"
              />

              <blockquote>
                Configuration of the matching algorithm.
              </blockquote>

              <Field
                defaultChecked={engagement?.disableCompanyBlock || false}
                disabled={!canEditMatchingSettings}
                label="Disable company blocking when Matching"
                name="disableCompanyBlock"
                readOnly={!canEditMatchingSettings}
                type="checkbox"
                variant="toggle"
              />

              <Field
                arrangement={Field.ARRANGEMENTS.INLINE}
                defaultValue={+engagement?.batchSize || 450}
                disabled={!canEditMatchingSettings}
                fluid
                label="Batch-size for Matching"
                name="batchSize"
                readOnly={!canEditMatchingSettings}
                type="number"
              />

              {engagement.product !== PRODUCT_TYPES.ONE_TO_ONE && (
                <Field
                  arrangement={Field.ARRANGEMENTS.INLINE}
                  defaultValue={+engagement.groupSize}
                  disabled={!canEditMatchingSettings}
                  fluid
                  label="Group size"
                  name="groupSize"
                  readOnly={!canEditMatchingSettings}
                  type="number"
                />
              )}
            </div>
          </section>
        </fieldset>

        {/* @ts-ignore */}
        <fieldset id="settings-engagement-conversation">
          <section className={clsx('Card', classes.SectionCard)}>
            <div className={clsx(classes.Content)}>
              <SectionTitle>Conversations Settings</SectionTitle>

              <blockquote>
                Configuration of the Conversations
              </blockquote>

              <Field
                defaultValue={+engagement?.durationMinutes || false}
                disabled={!canEditMatchExecutionSettings}
                fluid
                label="Meeting duration in minutes"
                max={8 * 60}
                min={15}
                name="durationMinutes"
                readOnly={!canEditMatchExecutionSettings}
                type="number"
              />
            </div>
          </section>
        </fieldset>

        {/* @ts-ignore */}
        <fieldset id="settings-engagement-whitelabling">
          <section className={clsx('Card', classes.SectionCard)}>
            <div className={clsx(classes.Content)}>
              <SectionTitle>
                Whitelabeling Settings

                {' '}

                <Chip>
                 EXPERIMENTAL
                </Chip>
              </SectionTitle>

              <blockquote>
                Configuration of branding and whitelabeling settings specifically for the Engagement.

                {' '}

                These settings will not apply to other Engagements unless they have copied/recurred this Engagement.
              </blockquote>

              <p>
                <span aria-label="rotating-light" role="img">🚨</span>

                {' '}

                <strong>
                  The email address HAS to be valid and end with @orbiit.app. If this is not the case the

                  {' '}

                  emails will break and not go out. We are NOT safeguarding against this at this moment.
                </strong>
              </p>

              <Field
                defaultValue={engagement?.emailsSentFromAddress || 'connection@orbiit.app'}
                disabled={!canEditInviteSettings}
                fluid
                label="Emails from address"
                name="emailsSentFromAddress"
                readOnly={!canEditInviteSettings}
                type="text"
              />

              {/* <Field
                defaultValue={engagement?.emailsSentFromName || 'WORKSPACE NAME by ORBIIT'}
                fluid
                label="Emails from name"
                name="emailsSentFromName"
                disabled={!canEditInviteSettings}
                readOnly={!canEditInviteSettings}
                type="text"
              /> */}
            </div>
          </section>
        </fieldset>

        {/* @ts-ignore */}
        <fieldset
          disabled
          id="settings-workspace"
          readOnly
        >
          <section className={clsx('Card', classes.SectionCard)}>
            <div className={clsx(classes.Content)}>
              <SectionTitle comingSoon>Workspace Settings</SectionTitle>

              <blockquote>
                Settings that will be stored against the Workspace. These will then automatically apply to

                {' '}

                ALL Engagements unless they have overriding settings.
              </blockquote>

              <Field
                defaultValue={engagement?.emailsSentFromAddress || 'connection@orbiit.app'}
                fluid
                label="Emails from address"
                name="emailsSentFromAddress"
                type="text"
              />
            </div>
          </section>
        </fieldset>
      </Form>
    </main>
  );
}
EngagementSettingsPage.displayName = 'EngagementSettingsPage';

const handle = {
  sidebar: true,
  title: 'Engagement Setup Settings',
};

export {
  EngagementSettingsPage as Component,
  handle,
};
