import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import noop from 'lodash-es/noop.js';
import { type KeyboardEvent, useState } from 'react';

import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';
import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { adjustAndFormatDate, formatDate } from '…/app/common/dateUtils.mts';

import { timezones } from '…/app/common/timezones.mts';
import { browserTimezone } from '…/common/device-info/browser.mts';
import { MatchIcon } from '…/app/common/Icon/MatchIcon.tsx';
import { Details, Summary } from '…/app/common/Details/Details.tsx';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';


import type {
  Match,
} from './Match.d.ts';
import { GroupName } from './GroupName.tsx';
import { MatchCard } from './MatchCard/MatchCard.tsx';
import * as dragHandlers from './onDrag.mts';
import type { EditMatches, MatchEditingState } from './EditMatches2.tsx';


import classes from './Matches.module.css';


export function MatchGroup({
  dragging,
  hidden,
  match,
  // meta,
  onDissolveMatch,
  onDrop,
  onFocusGroup,
  onUngroupParticipant,
  readOnly,
  setDragging,
  setMemberToInspect,
}: {
  dragging: EditMatches['dragging'],
  hidden?: true,
  match: Match,
  // meta?: EditMatches['state']['meta'],
  onDissolveMatch: EditMatches['onDissolveMatch'],
  onDrop: EditMatches['onDrop'],
  onFocusGroup?: EditMatches['onFocusGroup'],
  onUngroupParticipant: EditMatches['onUngroupParticipant'],
  readOnly: boolean,
  setDragging: EditMatches['setDragging'],
  setMemberToInspect(memberToInspect?: MatchEditingState['memberToInspect']): void,
}) {
  const {
    id: matchId,
    optins,
    product,
    scheduling: {
      datetimeLive,
      timezone,
    },
  } = match;
  const [showAvailability, setShowAvailability] = useState(false);
  const hasMatchMeta = useFeatureFlag(new Array(FLAGS.matchMeta));

  const onKeyDown = (event: KeyboardEvent<HTMLDetailsElement>) => {
    // <Details> may have a editable content, so ignore events triggered by children
    if (!event.currentTarget || event.target !== event.currentTarget) return;

    switch (event.key) {
      case 'Backspace':
      case 'Delete':
        onDissolveMatch(event);
        event.preventDefault();
        break;
      case 'Clear':
      case 'Escape':
        event.currentTarget.blur();
        break;
    }
  };

  return (
    <fieldset
      className="HollowWrapper"
      hidden={hidden}
      name={matchId}
    >
      <Details
        className={classes.Group}
        id={matchId}
        onFocus={hasMatchMeta ? onFocusGroup : noop}
        onKeyDown={onKeyDown}
        open
      >
        <Summary className={classes.GroupSummary}>
          <GroupName {...match} readOnly={readOnly} />

          <Button
            appearance={Button.APPEARANCES.BASIC}
            className={classes.Btn}
            onClick={() => setShowAvailability((prev) => !prev)}
            variant={Button.VARIANTS.GLYPH}
          >
            <OrbiitIcon
              icon="ScheduleDateTime"
              inline
              size="medium"
              title="Show availability"
            />
          </Button>

          {datetimeLive
            ? (
              <Popover
                anchor={({className, ...props}) => (
                  <time
                    className={clsx(className, classes.AnnotatedText)}
                    dateTime={datetimeLive}
                    {...props}
                  >
                    {adjustAndFormatDate(datetimeLive, browserTimezone, 'datetime', timezones[browserTimezone].abbreviation)}
                  </time>
                )}
                minimal
                type="tooltip"
              >
                {formatDate(datetimeLive, timezone, product !== PRODUCT_TYPES.TOP_N)} (member’s time)
              </Popover>
            )
            : (
              <Popover
                anchor={(props) => <abbr {...props} title="To be determined">TBD</abbr>}
                type={Popover.TYPES.TOOLTIP}
              >
                A timeslot will be selected when changes are saved
              </Popover>
            )
          }

          {!readOnly && (
            <Button
              appearance={Button.APPEARANCES.DANGER}
              className={classes.Btn}
              id={matchId}
              onClick={onDissolveMatch}
              variant={Button.VARIANTS.GLYPH}
            >
              <MatchIcon match={MatchIcon.MATCH.SET} title="Disolve match" />
            </Button>
          )}
        </Summary>

        <fieldset
          className={classes.Matches}
          id={matchId}
          onDragEnter={dragHandlers.onDragEnter}
          onDragOver={(event) => dragHandlers.onDragOver(event, dragging, setDragging)}
          onDrop={onDrop}
        >
          {Array.from(optins.values()).map((optin) => optin && (
            <MatchCard
              {...optin.member.fields}
              dragging={dragging}
              key={optin.id}
              matchId={matchId}
              // meta={meta?.[optin.id]}
              onRemove={onUngroupParticipant}
              optin={optin}
              readOnly={readOnly}
              setDragging={setDragging}
              setMemberToInspect={setMemberToInspect}
              showAvailability={showAvailability}
            />
          ))}
        </fieldset>
      </Details>
    </fieldset>
  );
}
MatchGroup.displayName = 'MatchGroup';
