import { NavLink, generatePath } from 'react-router-dom';

import { ENGAGEMENT_SECTION_ROUTE_TEMPLATE } from '…/app/route-params.mts';

import { useInternalAdminRole } from '…/app/common/permissions/useRoles.mjs';

import { IIcon } from '../../common/Icon/Icon.tsx';
import { Popover } from '../../common/Popover/Popover.tsx';

import { engagementParams } from '../engagementParams.mts';

import type { EngagementViewData } from './EngagementView.d.ts';
import { MENU_ITEMS, getEngagementStepIndices } from './engagementMenuSteps.mts';
import classes from './EngagementSidebar.module.css';


export function EngagementSidebar({ engagement: { status } }: EngagementViewData[0]) {
  const isInternalAdmin = useInternalAdminRole();
  const params = engagementParams();
  const { currentStepIdx } = getEngagementStepIndices(status, params.section);

  return (
    <aside className={classes.EngagementSidebar}>
      {MENU_ITEMS.map(({
        key,
        icon,
        internal,
        label,
      }, i) => {
        const disabled = (
          i > currentStepIdx
          && i < MENU_ITEMS.length - 3 // NOTE: Dashboard, Email Dashboard, & Advanced are always visible.
        );

        if (internal && !isInternalAdmin) return null;

        return (
          <NavLink
            className={classes.EngagementSidebarIcon}
            disabled={disabled}
            key={key}
            to={generatePath(ENGAGEMENT_SECTION_ROUTE_TEMPLATE, {
              ...params,
              section: key,
            })}
          >
            <Popover
              anchor={(props) => (<IIcon name={icon} {...props} />)}
              minimal
              position={Popover.POSITIONS.RIGHT}
              type={Popover.TYPES.TOOLTIP}
            >{label}
            </Popover>
          </NavLink>
        );
      })}
    </aside>
  );
}

EngagementSidebar.displayName = 'EngagementSidebar';
