import type {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import Button from 'form5/react/Button';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import { DragHandle } from './DragHandle.tsx';
import type { DragInfo, ElementDragEvent } from './onDrag.ts';
import { resequence } from './resequence.mts';
import * as dragHandlers from './onDrag.ts';
import classes from './Draggable.module.css';


// !! Beware the difference between `event.currentTarget` and `event.target`.
export function DraggableItem({
  children,
  dragging, // ! event.dataTransfer cannot be used to hold this because it can be read only onDrop
  onChange,
  onItemDelete,
  readOnly,
  setDirty,
  setDragging,
}: PropsWithChildren<{
  dragging: DragInfo,
  onChange: Dispatch<SetStateAction<unknown[]>>,
  onItemDelete: () => void,
  readOnly?: boolean,
  setDirty: Dispatch<SetStateAction<boolean>>,
  setDragging: Dispatch<SetStateAction<DragInfo>>,
}>) {
  function onDrop(event: ElementDragEvent) {
    if (!event.currentTarget) return;

    onChange((prev) => resequence(prev, dragging as Required<DragInfo>));
    setDragging({});
    setDirty(true);
  }

  return (
    <div
      className={classes.DraggableItem}
      draggable={!readOnly}

      onDragEnter={dragHandlers.onDragEnter}
      onDragOver={(event) => dragHandlers.onDragOver(event, dragging, setDragging)}
      onDragStart={(event) => dragHandlers.onDragStart(event, setDragging)}
      onDrop={onDrop}
    >
      <DragHandle />

      {children}

      <Button onClick={onItemDelete} variant={Button.VARIANTS.GLYPH}>
        <OrbiitIcon icon="TrashIcon" />
      </Button>
    </div>
  );
}
DraggableItem.displayName = 'DraggableItem';
