import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { Audience } from './Audience.d.ts';
import { GET_AUDIENCE } from './getAudience.gql.mjs';


export function getAudience(audienceId: ObjectId) {
  return gqlOperation<{ audience: Audience }>(GET_AUDIENCE, {
    audienceId,
    includeFilters: true,
  })
    .then((data) => data.audience);
}
