import userFrag from './user.frag.gql';

export const UPDATE_MY_PROFILE = `
mutation UpdateMyProfile($firstName: String!, $lastName: String!) {
  me: updateMyProfile(firstName: $firstName, lastName: $lastName) {
    ...userFields
  }
}

${userFrag}
`;
