/* eslint-disable react/jsx-max-depth */

import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import Field from 'form5/react/Field';
import { useState } from 'react';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import type { Engagement } from '@/app/w/workspace/engagements/Engagement.d.ts';

import { SectionTitle } from './SectionTitle.tsx';

import classes from './EngagementSettingsPage.module.css';

export function ConversationSettingsForm({
  disabled,
  engagement,
  onSubmit,
}: {
  disabled: boolean;
  engagement: Engagement;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  onSubmit: (delta: Record<string, any>) => Promise<void>;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setDirty] = useState(false);

  return (
    <Form
      name="form-matching-notifications"
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={(delta) => {
        setIsSubmitting(true);
        return onSubmit(delta).then(() => {
          setIsSubmitting(false);
        });
      }}
    >
      {/* @ts-ignore */}
      <fieldset>
        <section className={clsx('Card', classes.SectionCard)}>
          <div className={clsx(classes.Content)}>
            <SectionTitle>Conversations Settings</SectionTitle>

            <Field
              defaultValue={+engagement?.durationMinutes || false}
              disabled={disabled}
              fluid
              label="Meeting duration in minutes"
              max={8 * 60}
              min={15}
              name="durationMinutes"
              readOnly={disabled}
              type="number"
            />

            <div className="flex justify-end">
              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                disabled={!isDirty || isSubmitting}
                isSubmitting={isSubmitting}
                type="submit"
              >Save changes
              </SubmitButton>
            </div>
          </div>
        </section>
      </fieldset>
    </Form>
  );
}

ConversationSettingsForm.displayName = 'ConversationSettingsForm';
