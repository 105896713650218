import { useState } from 'react';
import Button from 'form5/react/Button';
import { useParams } from 'react-router-dom';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { WalledRouteParams } from '…/app/route-params.mts';

import { deleteOptIns } from './deleteOptIns.op.mts';
import type { OptInForm } from './optin-forms.d.ts';

export function DeleteOptInsForm({
  optInForm,
  onSubmit,
}: {
  optInForm: OptInForm,
  onSubmit: (id: string) => void,
}) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const { id, fields: { fullName } } = optInForm;

  const [isSaving, setIsSaving] = useState(false);

  return (
    <>
      <h1>Delete Opt-In Form</h1>

      <p>
        Are you sure you want to delete the Opt-In Form for <strong>{fullName}</strong>?

        {' '}

        This action can not be reversed.
      </p>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSaving}
        isSubmitting={isSaving}
        onClick={() => {
          setIsSaving(true);
          return deleteOptIns(workspaceId, [id])
            .then(() => onSubmit(id))
            .finally(() => setIsSaving(false));
        }}
        type="submit"
      >Delete
      </SubmitButton>
    </>
  );
}

DeleteOptInsForm.displayName = 'DeleteOptInsForm';
