import _get from 'lodash-es/get.js';

import type { Audience } from '…/app/w/workspace/audiences/audience/Audience.d.ts';
import type {
  EngagementFull,
  EngagementSchedule,
  EngagementTemplate,
  MessageJson,
} from '../../../Engagement.d.ts';
import { adjustUTCtoTimezone, getNow } from '…/app/common/dateUtils.mts';


export enum ITEM_STATUSES {
  DISABLED = 'disabled',
  INVALID = 'invalid',
  VALID = 'valid',
}
export enum SECTION_STATUSES {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  NEW = 'new',
  WARN = 'warn',
}

export enum ERRORS {
  BODY_EMPTY = 'Empty content',
  NOT_SET = 'Not set',
  NOT_SET_UP = 'Not set up',
  OUTDATED = 'Needs updating',
}

interface Validation<S extends SECTION_STATUSES | ITEM_STATUSES> {
  label: string,
  status: S,
}

type ItemValidator = (e: EngagementFull, ...rest: unknown[]) => Validation<ITEM_STATUSES>;

type SectionItemValidation = Partial<{ [key: string]: Validation<ITEM_STATUSES> }>;
type SectionValidation = (
  SectionItemValidation
  & { _section: Validation<SECTION_STATUSES> }
)
type SectionValidator<E = EngagementFull> = (e: E & { audience: Audience }) => SectionValidation;


export const audience: SectionValidator = (e) => {
  if (!e.audienceId) {
    return {
      _section: {
        label: 'Select Audience',
        status: SECTION_STATUSES.NEW,
      },
    };
  }

  if (!e.audience!.memberCount) {
    return {
      _section: {
        label: 'No members',
        status: SECTION_STATUSES.WARN,
      },
    };
  }

  return {
    _section: {
      label: 'Looks great',
      status: SECTION_STATUSES.COMPLETE,
    },
  };
};

export const experience: SectionValidator<keyof EngagementTemplate> = (e) => {
  if (!e.title?.length) {
    return {
      _section: {
        label: 'Select Template',
        status: SECTION_STATUSES.NEW,
      },
    };
  }

  const output = {
    chaser: run(oneToOneEmailTemplate, e, 'chaser'),
    convoGuide: run(convoGuide, e),
    description: run(simpleField, e, 'description'),
    feedback: run(participantForm, e, 'feedback'),
    invite: run(oneToOneEmailTemplate, e, 'invite'),
    signup: run(participantForm, e, 'optIn'),
  };

  return deriveSectionValidation(output);
};

export const schedule: SectionValidator<keyof EngagementSchedule> = (e) => {
  if (e.isLive) {
    return {
      _section: sectionIrelevant,
      chaser: itemIrelevant,
      convoDates: itemIrelevant,
      invite: itemIrelevant,
      ...(e.isEditMatchesEnabled && { matchIntroAt: itemIrelevant }),
      optInClosesAt: itemIrelevant,
      optInOpensAt: itemIrelevant,
    };
  }

  if (e.oneToOneEmails == null) {
    return {
      _section: {
        label: 'Set Schedule',
        status: SECTION_STATUSES.NEW,
      },
    };
  }

  const output = {
    chaser: run(oneToOneEmailSchedule, e, 'chaser'),
    convoDates: run(dateField, e, 'conversationDates[0]', true),
    invite: run(oneToOneEmailSchedule, e, 'invite'),
    ...(e.isEditMatchesEnabled && { matchIntroAt: run(dateField, e, 'matchIntroAt') }),
    optInClosesAt: run(dateField, e, 'optInClosesAt', true),
    optInOpensAt: run(dateField, e, 'optInOpensAt', true),
  };

  if (Object.keys(output).every((itemName) => output[itemName]!.label === ERRORS.NOT_SET)) {
    return {
      _section: {
        label: 'Set Schedule',
        status: SECTION_STATUSES.NEW,
      },
    };
  }

  return deriveSectionValidation(output);
};

const submittableStatuses = new Set([
  SECTION_STATUSES.COMPLETE,
  SECTION_STATUSES.WARN,
]);
export const isEngagementReady = (validations: ReturnType<typeof all>) => {
  const sections = Object.keys(validations) as Array<keyof typeof validations>;
  for (const section of sections) if (!submittableStatuses.has(validations[section]._section.status)) return false;

  return true;
};

// Cache these because they're static (no need to keep multiple copies in memory)
const itemDisabled = {
  label: 'Disabled',
  status: ITEM_STATUSES.DISABLED,
};
const itemIrelevant = {
  label: '',
  status: ITEM_STATUSES.DISABLED,
};
const itemOk = {
  label: 'OK',
  status: ITEM_STATUSES.VALID,
};

function run(validator: ItemValidator, ...args: unknown[]) {
  try {
    if (validator(...args) === null) return itemDisabled;
    return itemOk;
  } catch (err) {
    return {
      label: err,
      status: ITEM_STATUSES.INVALID,
    };
  }
}

const sectionBad = {
  label: 'Needs changes',
  status: SECTION_STATUSES.INCOMPLETE,
};
const sectionGood = {
  label: 'Looks great',
  status: SECTION_STATUSES.COMPLETE,
};
const sectionIrelevant = {
  label: 'Set',
  status: SECTION_STATUSES.COMPLETE,
};

function deriveSectionValidation(output: SectionItemValidation) {
  if (Object.keys(output).every((itemName) => output[itemName]!.status !== ITEM_STATUSES.INVALID)) {
    return Object.assign(output, {
      _section: sectionGood,
    });
  }

  return Object.assign(output, {
    _section: sectionBad,
  });
}

export const all = (e: EngagementFull) => ({
  audience: audience(e),
  experience: experience(e),
  schedule: schedule(e),
});

export const convoGuide: ItemValidator = ({ conversationGuide }) => {
  if (!conversationGuide?.id) throw ERRORS.NOT_SET_UP;

  if (!messageJson(conversationGuide.json)) throw ERRORS.BODY_EMPTY;
};

export const messageJson = (json: MessageJson | null) => !!json?.child.length;

type EmailName = keyof EngagementFull['oneToOneEmails'];

export const oneToOneEmailSchedule: ItemValidator = (e, emailName: EmailName) => {
  const path = `oneToOneEmails.${emailName}`;
  const email = _get(e, path);

  if (email.isDisabled) return null;

  dateField(e, `${path}.sendAt`);

  return true;
};

export const oneToOneEmailTemplate: ItemValidator = ({ oneToOneEmails }, emailName: EmailName) => {
  if (oneToOneEmails == null || !(emailName in oneToOneEmails)) throw ERRORS.NOT_SET_UP;

  const email = oneToOneEmails[emailName];

  if (email.isDisabled) return null;

  for (const { label, name } of EMAIL_TEMPLATE_FIELDS) if (!email[name]) throw `${label} is empty`;

  if (!messageJson(email.bodyJson)) throw ERRORS.BODY_EMPTY;

  return true;
};
const EMAIL_TEMPLATE_FIELDS = new Set<{ label: string, name: keyof EngagementFull['oneToOneEmails'][EmailName] }>([
  {
    label: 'Call to Action',
    name: 'callToAction',
  },
  {
    label: 'Pre-header',
    name: 'preheader',
  },
  {
    label: 'Subject',
    name: 'subject',
  },
]);

export const participantForm: ItemValidator = (e, formName: 'feedback' | 'optIn') => {
  const fieldName: 'feedbackQuestions' | 'optInQuestions' = `${formName}Questions`;
  const field = e[fieldName];

  if (field == null) throw ERRORS.NOT_SET_UP;

  for (const question of field) {
    if (question.isDisabled || !question.props) continue;

    const { title } = question.props;
    if (!title) throw `${question.stepName}’s title is empty (and not disabled)`;

    const { options } = question.props;
    if (options) {
      if (options.length < 2) throw `${title} has fewer than 2 options`;
      for (const [idx, opt] of options.entries()) if (!opt.text) throw `${title}’s ${formatOrdinal(idx+1)} option is empty`;
    }
  }

  return true;
};

const dateField: ItemValidator = (e, fieldName: string, ignoreTime?: true) => {
  const val = _get(e, fieldName);

  if (!val) throw ERRORS.NOT_SET;

  let now = getNow();
  let dt = adjustUTCtoTimezone(val, e.timezoneIso);

  if (ignoreTime) {
    /* eslint-disable sort-keys */
    now = now.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    dt = dt.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    /* eslint-enable sort-keys */
  }

  if (+dt < +now) throw ERRORS.OUTDATED;

  return true;
};
const simpleField: ItemValidator = (e, fieldName: string) => {
  if (!_get(e, fieldName)) throw ERRORS.NOT_SET;

  return true;
};

const pr = new Intl.PluralRules('en-GB', { type: 'ordinal' });

type PluralRules = Exclude<Intl.LDMLPluralRule, 'many' | 'zero'>;
const suffixes: Record<PluralRules, string> = {
  /* eslint-disable sort-keys */
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
  /* eslint-enable sort-keys */
};
const formatOrdinal = (n: Int) => {
  const rule = pr.select(n) as PluralRules;
  const suffix = suffixes[rule];
  return `${n}${suffix}`;
};
