import { Suspense, useEffect } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { Toaster } from 'sonner';

import { OrbiitLoader } from '…/app/common/OrbiitLoader/OrbiitLoader.jsx';

import { UIError } from '…/app/common/errors/UIError.ts';

import { AppHeader } from './AppHeader.tsx';
import { ReactErrorBoundary } from './ErrorBoundary/ReactErrorBoundary.tsx';
import { BlankNav } from './BlankNav.jsx';

import './global.module.css';


/**
 * This component is necessary to facilitate interop between React Router & the contexts
 * otherwise the contexts don't have access to Route info
 */
export function OrbiitApp() {
  const {
    showNav = true,
    title = '',
  } = useMatches()
    .at(-1)
    ?.handle
    || {} as any;

  useEffect(() => {
    document.title = `Orbiit - ${title}`;
  }, [title]);

  if ('brave' in navigator && location.hostname.includes('localhost')) {
    return (
      <ReactErrorBoundary>
        <BraveError />
      </ReactErrorBoundary>
    );
  }

  return (
    <ReactErrorBoundary>
      <Toaster
        closeButton
        position="bottom-right"
        richColors
        toastOptions={{
          style: {
            padding: '1em',
          },
        }}
      />

      <Suspense fallback={<BlankNav />}>
        {showNav && <AppHeader />}
      </Suspense>

      <Suspense fallback={<OrbiitLoader />}>
        <Outlet />
      </Suspense>
    </ReactErrorBoundary>
  );
}
OrbiitApp.displayName = 'OrbiitApp';

export { OrbiitApp as Component };

function BraveError(): never {
  const details = new Error(
    'A bug in Brave, detailed in https://github.com/brave/brave-browser/issues/25855',
  );
  delete details.stack;
  const alternative = new Error(
    'Opera and Vivaldi are other privacy-oriented browsers: https://www.opera.com/ or https://vivaldi.com/',
  );
  delete alternative.stack;

  const err = new UIError(
    [details, alternative],
    'Brave breaks login. Please use another browser.',
    { code: 400 },
  );
  delete err.stack;

  throw err;
}
BraveError.displayName = 'BraveError';
