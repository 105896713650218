import {
  Link,
  NavLink,
  generatePath,
  useParams,
} from 'react-router-dom';
import _find from 'lodash-es/find.js';

import { RESOURCES_URL } from '…/app/deprecated/constants.mts';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { FeatureFlag } from '…/app/common/permissions/Features/FeatureFlag.tsx';
import { ProfileAvatar } from '…/app/common/ProfileAvatar/ProfileAvatar.tsx';

import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { AcquisitionBanner } from '…/app/common/Banner/Banner.tsx';

import classes from './AppHeader.module.css';


export function AppHeader() {
  const { workspaceId } = useParams() as WalledRouteParams;
  const {
    isGlobalAdmin,
    permissions,
    profile,
  } = useStore((data) => data.user);
  const { primaryAudience, notifications } = useStore((data) => data.workspaces[workspaceId]);
  const acquisitionBanner = _find(notifications, (notification) => notification.identifier === 'AcquisitionBanner');

  const isLogoutEnabled = useFeatureFlag([FLAGS.logout]);

  return (
    <>
      <header className={classes.AppHeader}>
        <Link style={{ display: 'flex' }} to="/w">
          <img
            alt="Orbiit"
            className={classes.Masthead}
            src="https://storage.googleapis.com/orbiit-assets/logos/Orbiit-LogoMark-platform.png"
          />
        </Link>

        <nav className={classes.NavLinks} data-testid="AppNav">
          <NavLink
            className={classes.NavLink}
            to={generatePath('/w/:workspaceId/audiences/:audienceId', {
              audienceId: primaryAudience,
              workspaceId,
            })}
          >Members
          </NavLink>

          <NavLink
            className={classes.NavLink}
            end
            to={generatePath('/w/:workspaceId/audiences', { workspaceId })}
          >Audiences
          </NavLink>

          <NavLink
            className={classes.NavLink}
            to={generatePath('/w/:workspaceId/engagements/', { workspaceId })}
          >Engagements
          </NavLink>

          <FeatureFlag flags={[FeatureFlag.FLAGS.navigationResources]}>
            <a
              className={classes.NavLink}
              href={RESOURCES_URL}
              rel="noreferrer"
              target="_blank"
            >Resources
            </a>
          </FeatureFlag>

          <FeatureFlag flags={[FeatureFlag.FLAGS.dashboardAnalytics]}>
            <NavLink className={classes.NavLink} to={generatePath('/w/:workspaceId/analytics', { workspaceId })}>Analytics</NavLink>
          </FeatureFlag>
        </nav>

        <div className={classes.UserWidget}>
          {profile?.fullName}

          {isGlobalAdmin && <span title="global admin">★</span>}

          <Popover
            anchor={(props) => (
              <ProfileAvatar
                as="button"
                name={profile?.firstName}
                role={permissions?.get(workspaceId)}
                size="1.125em"
                src={profile?.imageUrl}
                type="button"
                {...props}
              />
            )}
            position={Popover.POSITIONS.BOTTOM_RIGHT}
            type={Popover.TYPES.MENU}
          >
            <CTA
              guise={CTA.GUISES.LINK}
              menuitem=""
              to={generatePath('/w/:workspaceId/settings/profile', { workspaceId })}
            >
            Profile
            </CTA>

            <CTA
              guise={CTA.GUISES.LINK}
              menuitem=""
              to={generatePath('/w/:workspaceId/settings/team', { workspaceId })}
            >
            Team
            </CTA>

            <CTA
              guise={CTA.GUISES.LINK}
              menuitem=""
              to={generatePath('/w/:workspaceId/settings/integrations', { workspaceId })}
            >
            Integrations
            </CTA>

            {isLogoutEnabled && (
              <CTA
                guise={CTA.GUISES.LINK}
                menuitem=""
                to="/logout"
              >
              Logout
              </CTA>
            )}
          </Popover>
        </div>
      </header>

      {acquisitionBanner && (
        <AcquisitionBanner properties={acquisitionBanner.properties} />
      )}
    </>
  );
}
AppHeader.displayName = 'AppHeader';
