import {
  Fragment,
} from 'react';

import { ArchiveEngagementForm } from './engagement/ArchiveEngagementForm.tsx';
import { CloneEngagementForm } from './engagement/CloneEngagementForm.tsx';
import { CreateEngagementForm } from './engagement/CreateEngagementForm.tsx';
import { RecurEngagementForm } from './engagement/RecurEngagementForm.tsx';


export const forms = {
  '': Fragment,
  archive: ArchiveEngagementForm,
  clone: CloneEngagementForm,
  create: CreateEngagementForm,
  recur: RecurEngagementForm,
};

export interface FormConfig {
  data?: object,
  name: keyof typeof forms,
}
