import type { PRODUCT_TYPE } from '…/app/deprecated/constants.mts';
import { PRODUCT_TYPES } from '…/app/deprecated/constants.mts';

import type { Choice } from './ParticipantQuestions.d.ts';


export const enum ParticipantQuestionType {
  FreeResponse = 'free-response',
  MultipleChoice = 'multiple-choice',
  PairedResponse = 'paired-response',
}

export interface QuestionsConfig {
  choices?: Choice[],
  heading: string,
  name: string,
  required?: boolean,
  type: ParticipantQuestionType,
}

type ParticipantFormQuestions = Record<string, QuestionsConfig>;
type ParticipantFormSections = Map<string, {
  info?: string,
  questions: QuestionsConfig[],
}>;

export const feedback: ParticipantFormQuestions = {
  advice: {
    heading: 'Member Advice',
    name: 'advice',
    required: true,
    type: ParticipantQuestionType.FreeResponse,
  },
  decision: {
    heading: 'Better Decision',
    name: 'decision',
    type: ParticipantQuestionType.MultipleChoice,
  },
  fit: {
    heading: 'Match Rating',
    name: 'fit',
    required: true,
    type: ParticipantQuestionType.FreeResponse,
  },
  frequency: {
    heading: 'Member Preference: Frequency',
    name: 'frequency',
    type: ParticipantQuestionType.MultipleChoice,
  },
  product: {
    heading: 'Member Preference: Type of Experience',
    name: 'product',
    type: ParticipantQuestionType.MultipleChoice,
  },
  recommend: {
    heading: 'NPS',
    name: 'recommend',
    required: true,
    type: ParticipantQuestionType.FreeResponse,
  },
  reconnect: {
    heading: 'Member Match Reconnect',
    name: 'reconnect',
    type: ParticipantQuestionType.MultipleChoice,
  },
  testimonial: {
    heading: 'Member Testimonial',
    name: 'testimonial',
    required: true,
    type: ParticipantQuestionType.FreeResponse,
  },
  topics: {
    heading: 'Discussion Topics',
    name: 'topics',
    type: ParticipantQuestionType.MultipleChoice,
  },
};

const signup: ParticipantFormQuestions = {
  asks: {
    heading: 'Discussion Topics',
    name: 'asks',
    type: ParticipantQuestionType.MultipleChoice,
  },
  company_size: {
    heading: 'Company Size',
    name: 'company_size',
    type: ParticipantQuestionType.MultipleChoice,
  },
  company_stage: {
    heading: 'Company Stage',
    name: 'company_stage',
    type: ParticipantQuestionType.MultipleChoice,
  },
  experience: {
    heading: 'Years of Experience',
    name: 'experience',
    type: ParticipantQuestionType.MultipleChoice,
  },
  phone: {
    heading: 'Phone Number',
    name: 'phone',
    type: ParticipantQuestionType.FreeResponse,
  },
  professional: {
    heading: 'Professional Information (Role & Company Name)',
    name: 'professional',
    type: ParticipantQuestionType.FreeResponse,
  },
  social: {
    choices: [
      {
        label: 'Facebook',
        value: 'facebook',
      },
      {
        label: 'Github',
        value: 'github',
      },
      {
        label: 'Instagram',
        value: 'instagram',
      },
      {
        label: 'Linkedin',
        value: 'linkedin',
      },
      {
        label: 'Medium',
        value: 'medium',
      },
      {
        label: 'Patreon',
        value: 'patreon',
      },
      {
        label: 'Pinterest',
        value: 'pinterest',
      },
      {
        label: 'Tiktok',
        value: 'tiktok',
      },
      {
        label: 'Twitter',
        value: 'twitter',
      },
      {
        label: 'Url',
        value: 'url',
      },
      {
        label: 'Youtube',
        value: 'youtube',
      },
    ],
    heading: 'Member Social Link',
    name: 'social',
    type: ParticipantQuestionType.PairedResponse,
  },
  timezone: {
    heading: 'Timezone',
    name: 'timezone',
    required: true,
    type: ParticipantQuestionType.MultipleChoice,
  },
};

function getFeedbackConfig(product: PRODUCT_TYPE) {
  const sect1 = {
    questions: [
      feedback.fit,
      feedback.recommend,
      feedback.testimonial,
      feedback.decision,
      feedback.topics,
      feedback.product,
      feedback.frequency,
      feedback.advice,
    ],
  };
  const config: ParticipantFormSections = new Map([
    ['Feedback Questions', sect1],
  ]);

  if (product === PRODUCT_TYPES.ONE_TO_ONE) {
    sect1.questions.push(feedback.reconnect);
  }

  return Array.from(config.entries());
}

function getSignupConfig(product: PRODUCT_TYPE) {
  const matchingCriteria = {
    info: 'Questions in this section can be used for matching. Choose strategic criteria that will allow \
    for relevant connections to be made between participants.',
    questions: [
      signup.asks,
      signup.experience,
      signup.company_size,
      signup.company_stage,
    ],
  };
  const aboutTheParticipant = {
    info: 'These details allow you to segment and personalize engagements better. \
      If a participant has previously provided their information, their responses will auto-populate.',
    questions: [
      signup.professional,
      signup.social,
      signup.phone,
    ],
  };
  const config: ParticipantFormSections = new Map([
    ['About the Participant', aboutTheParticipant],
    ['Matching Criteria', matchingCriteria],
  ]);

  if (product === PRODUCT_TYPES.ONE_TO_ONE) {
    // TODO: Make sure this is required.
    matchingCriteria.questions.push(signup.timezone);
  }

  return Array.from(config.entries());
}

export const questionConfigGetters = {
  feedback: getFeedbackConfig,
  signup: getSignupConfig,
};
