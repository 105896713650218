import { clsx } from 'clsx';
import { string } from 'prop-types';

import classes from './OrbiitLoader.module.css';


export function OrbiitLoader({
  className,
}) {
  return (
    <div className={clsx(classes.Spinner, className)}>
      <div className={classes.Dot} />

      <div className={clsx(classes.Dot, classes.DotTwo)} />
    </div>
  );
}
OrbiitLoader.displayName = 'OrbiitLoader';

OrbiitLoader.propTypes = {
  className: string,
};
