query GetMemberHistory($workspaceId: ID!, $memberId: ID!) {
  workspace(workspaceId: $workspaceId) {
    history: member(id: $memberId) {
      id
      matchCards {
        matchDate
        matchRatingGiven
        matchRatingReceived
        members {
          email
          fields {
            company
            fullName
            role
          }
        }
      }
      optInForms {
        createdAt
        engagement {
          id
          name
          product
        }
      }
    }
  }
}
