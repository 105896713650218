import { decodePermissionsList } from '@hivebrite/orbiit-platform-utils/permissions/permissions-list';

import { decodeJWT } from './decodeJWT.mts';
import { tokens } from './tokens.mts';


interface RawPermissions {
  isGlobalAdmin: boolean;
  permissions: Array<{ role: string, workspaceId: string }>;
  sub: string;
}

/**
 * @returns When `accessToken` is not provided, it must first be retrieved from storage (which is
 * asynchronous), so the return is wrapped in a promise (the settled value is otherwise the same).
 */
export function getPermissionsFromAccessToken() {
  return tokens.get('access').then(parsePermissionsFromToken);
}

export function parsePermissionsFromToken(accessToken: string | null): RawPermissions {
  let isGlobalAdmin;
  let permissions = new Array();
  let sub;

  if (accessToken) {
    const data = decodeJWT(accessToken);

    ({ isGlobalAdmin, sub } = data);
    permissions = decodePermissionsList(data.scopes);
  }

  return {
    isGlobalAdmin,
    permissions,
    sub,
  };
}
