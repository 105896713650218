import PropTypes from 'prop-types';
import {
  Navigate,
  generatePath,
} from 'react-router-dom';

import { useStore } from '…/app/state/useStore.mts';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';

import {
  OrbiitBackground,
  orbiitBackgroundCardClassName,
  orbiitBackgroundContentClassName,
} from '…/app/common/OrbiitBackground/OrbiitBackground.tsx';

import { BuildStats } from './BuildStats.jsx';
import classes from './WorkspacesListPage.module.css';


export function WorkspacesListPage () {
  const { profile } = useStore((data) => data.user);
  const workspaces = useStore((data) => data.workspaces);

  // If we have a user and only one workspace, automatically redirect.
  if (workspaces.size === 1) {
    return (
      <Navigate
        to={generatePath('/w/:workspaceId', {
          workspaceId: workspaces.keys().next().value,
        })}
      />
    );
  }

  return (
    <OrbiitBackground>
      <div className={orbiitBackgroundContentClassName} data-testid="WorkspacesListPage">
        <img
          alt="Orbiit.ai"
          src="https://storage.googleapis.com/orbiit-assets/logos/logo_white_fullcolor.png"
          style={{
            marginBottom: '2rem',
            maxWidth: '120px',
          }}
        />

        <div className={orbiitBackgroundCardClassName}>
          <h1>Welcome back <strong>{profile?.firstName}</strong>!</h1>

          <WorkspacesList workspaces={workspaces} />
        </div>

        <BuildStats />
      </div>
    </OrbiitBackground>
  );
}
WorkspacesListPage.displayName = 'WorkspacesListPage';

function WorkspacesList({
  workspaces,
}) {
  const links = [];
  for (const [id, { name }] of workspaces.entries()) {
    links.push(
      <li key={id}>
        <CTA
          fluid
          guise={CTA.GUISES.BUTTON}
          to={generatePath('/w/:workspaceId', { workspaceId: id })}
        >
          {name}
        </CTA>
      </li>,
    );
  }

  return (
    <>
      <p>Select your workspace</p>

      <ul className={classes.WorkspacesLinks} data-testid="list-of-workspace-links">
        {links}
      </ul>

      <CTA
        appearance="danger"
        to="/logout"
      >
        Logout
      </CTA>
    </>
  );
}
WorkspacesList.displayName = 'WorkspacesList';

const {
  arrayOf,
  object,
  string,
} = PropTypes;
WorkspacesList.propTypes = {
  workspaceIds: arrayOf(string),
  workspaces: object,
};

const handle = {
  showNav: false,
  title: 'Workspaces',
};

export {
  WorkspacesListPage as Component,
  handle,
};
