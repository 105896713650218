.Summary {
  font-weight: 700;
}

.FilterOptions {
  margin-top: 0.5em;
}

.LabelWithIcon {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.125rem 0;
}
