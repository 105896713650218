import { Component, type PropsWithChildren } from 'react';

import type { UIError } from '…/app/common/errors/UIError.ts';

import { log } from '…/app/logger.mts';

import type { ErrorState } from './ErrorState.d.ts';
import { ErrorScreen } from './ErrorScreen.tsx';


/**
 * ! This component MUST be simple with no chance of throwing. Do NOT do anything complicated.
 *
 * A React-compliant ErrorBoundary. Since ReactRouter’s Route.ErrorBoundary is not compliant with
 * React’s, this contains the logic and passes off the data to `<ErrorScreen>` for rendering.
 */
export class ReactErrorBoundary extends Component<PropsWithChildren, null | ErrorState> {
  static displayName = 'ReactErrorBoundary';

  state: null | ErrorState = null;

  static getDerivedStateFromError(error: UIError) {
    // Update state so the next render will show the fallback UI.
    return {
      code: error?.code,
      errors: error?.errors,
      message: error?.message ?? 'Something went wrong',
      stack: error?.stack,
    };
  }

  componentDidCatch(error: UIError, { componentStack }: { componentStack: string }) {
    const { errors, ...state } = this.state!;

    log.error({
      ...state,
      errors: { ...errors?.map(({ message }) => message) },
      stack: componentStack.replace(/^\s+/, ''),
    });
  }

  render() {
    if (!this.state) { return this.props.children }

    return (<ErrorScreen {...this.state} />);
  }
}
