.Tabs {
  align-items: end;
  display: flex;
  flex-direction: row;
}

/* extend the bottom border the rest of the width */
.Tabs[appearance="panelled"]::before,
.Tabs[appearance="panelled"]::after {
  border-bottom: 2px solid var(--neutral-medium);
  content: "";
  flex: 1;
}

.Tab {
  border-bottom-style: solid;
  padding: 0.75rem 1rem 0.5rem;
}

.Tab:hover,
.Tab[class~="active"] {
  border-bottom: 2px solid;
  color: initial;
}

.Tab[class~="active"] {
  cursor: default;
  font-weight: 700;
}

.Tabs[appearance="panelled"] .Tab {
  border-bottom-color: var(--neutral-medium);
  border-bottom-width: 2px;
}

.Tabs[appearance="header"] .Tab {
  border-bottom-color: transparent;
  border-bottom-width: 4px;
  color: initial;
}

.Tabs[appearance="header"] .Tab:hover,
.Tabs[appearance="header"] .Tab[class~="active"] {
  border-bottom-color: var(--brand-accent-primary);
}

.Tabs[appearance="panelled"] .Tab:hover,
.Tabs[appearance="panelled"] .Tab[class~="active"] {
  background: var(--neutral-lightest);
  border-bottom-color: var(--brand-dark);
}
