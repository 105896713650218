import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import ARCHIVE_ENGAGEMENTS from './archiveEngagements.gql';

export function archiveEngagements({ engagementIds, workspaceId }) {
  const ids = Array.isArray(engagementIds)
    ? engagementIds
    : [engagementIds];

  return gqlOperation(ARCHIVE_ENGAGEMENTS, {
    ids,
    workspaceId,
  });
}
