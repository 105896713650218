import * as snippet from '@segment/snippet';


export function segmentSetup({ apiKey, env }: Partial<{ apiKey: string, env: string }>) {
  if (!apiKey || env === 'test') return '';

  const opts = {
    apiKey,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  const method = env === 'development'
    ? 'max'
    : 'min';

  return snippet[method](opts);
}
