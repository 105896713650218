._PanelledForm_1lyis_1 {
  align-items: end; /* "end" because of the flipping to get the scrollbar on top */
  display: flex;
  flex-direction: row;
  gap: 2rem;
  overflow-x: scroll;
  padding-bottom: 1rem; /* "bottom" because of the flipping to get the scrollbar on top */
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

._PanelledForm_1lyis_1::-webkit-scrollbar {
  height: 0.5em;
  top: 0;
  width: 0.5em;
}

._PanelledForm_1lyis_1::-webkit-scrollbar,
._PanelledForm_1lyis_1::-webkit-scrollbar-thumb {
  border-radius: 1em;
}

._PanelledForm_1lyis_1::-webkit-scrollbar-track {
  background-color: var(--brand-subtle-light);
}

._PanelledForm_1lyis_1::-webkit-scrollbar-thumb {
  background-color: var(--brand-dark);
}

._Panel_1lyis_1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  scroll-snap-align: start;
  scroll-snap-stop: always;
}

/* Move scrollbar to the top */
._PanelledForm_1lyis_1,
._PanelledForm_1lyis_1 ._Panel_1lyis_1 {
  rotate: x 180deg;
}

._PanelledForm_1lyis_1 ._Panel_1lyis_1 {
  flex: 0 0 calc(100% - 3rem); /* 3rem = gap + panel padding */
}

._PanelSection_1lyis_49 ~ ._PanelSection_1lyis_49::before {
  border: 1px solid var(--border-colour-normal);
  content: "";
  margin: 0.5rem -1rem;
}

._SplitPanel_1lyis_55 {
  display: grid;
  gap: calc(2 * var(--grid-gutter));
  grid-template-columns: 1fr 1fr;
}

._LeftButton_1lyis_61 {
  place-self: start;
}

._RightButton_1lyis_65 {
  place-self: end;
}

._Fieldset_1lyis_69 {
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
}

._FieldsetHeading_1lyis_74 {
  align-items: baseline;
  display: flex;
  gap: 1em;
  justify-content: space-between;
}

._RemovableSet_1lyis_81 {
  align-items: baseline;
  display: flex;
  flex-direction: row;
}

._Panel_1lyis_1 input:disabled,
._Fieldset_1lyis_69 input:disabled {
  background-color: var(--neutral-lighter);
  border: 1px solid var(--neutral-light);
  color: var(--neutral);
  opacity: 0.75;
}

._InvertedToggle_1lyis_95 {
  cursor: pointer;
}

._InvertedToggle_1lyis_95[variant="toggle"]:checked::after {
  background-color: var(--neutral-medium);
  translate: calc(-50% + 1px);
}

._InvertedToggle_1lyis_95[variant="toggle"]:not(:checked)::after {
  background-color: var(--colour-primary);
  translate: calc(50% + 1px);
}

._InvertedToggle_1lyis_95[readonly][variant="toggle"]:not(:checked)::after {
  background-color: var(--colour-text-deemphasised);
}

._ButtonPending_1lyis_113 {
  height: 1em;
  margin: 0 0.5em;
  width: 1em;
}

._ManyOptions_1lyis_119 {
  columns: 2;
  line-height: 2;
}

._PendingDeletion_1lyis_124 {
  color: var(--text-muted) !important;
}
