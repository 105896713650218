/* eslint-disable sort-keys */

import type { Paging } from '…/app/common/pagination/pagination.d.ts';
import type { Match } from './Match.d.ts';


export function getPageSlices(
  all: Map<Match['id'], Match>,
  page: Paging,
) {
  const arr = Array.from(all.values());

  const curr = {
    start: (page.number - 1) * page.limit,
    end: page.number * page.limit,
  };

  return {
    previous: arr.slice(0, curr.start || 0),
    current: arr.slice(curr.start, curr.end),
    following: arr.slice(curr.end),
  };
}
