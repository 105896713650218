import type { EngagementSetupRouteParams } from '…/app/route-params.mts';
import type { Section, SetupSection } from './constants.mts';


export function engagementParams(pathname = location.pathname) {
  const segments = pathname.replace('/', '').split('/');
  const params: EngagementSetupRouteParams = {
    engagementId: segments[3],
    section: segments[4] as Section,
    subSection: segments[5] as SetupSection,
    workspaceId: segments[1],
  };

  return params;
}

/*
segments = {
  0: 'w',
  1: workspaceId,
  2: 'engagements',
  3: engagementId,
  4: section,
  5: subSection,
}
*/
