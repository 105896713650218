import clsx from 'clsx';
import PropTypes from 'prop-types';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import classes from './EmailTemplateVariables.module.css';


const dateFormatExample = 'The format will be the equivalent of Monday Dec 24';
const TEMLATE_VARIABLES = new Map([
  ['${MATCHING_AT}', `The date that matches are made and match introduction emails are sent out. ${dateFormatExample}`],
  ['${OPT_IN_CLOSES_AT}', `The closing date of the Opt-In Form. ${dateFormatExample}`],
  ['${OPT_IN_OPENS_AT}', `The opening date of the Opt-In Form. ${dateFormatExample}`],
  ['${WORKSPACE_NAME}', 'The name of your workspace.'],
]);

export function EmailTemplateVariables({ emailBody }) {
  if (!emailBody) return null;

  const chips = [];

  for (const [key, description] of TEMLATE_VARIABLES.entries()) {
    chips.push(
      <Popover
        anchor={({ className, ...props }) => (
          <Chip {...props} className={clsx(className, classes.Variable)} />
        )}
        as="li"
        containerClassName={classes.TooltipContainer}
        key={`email-var-${key}`}
        label={key}
        type={Popover.TYPES.TOOLTIP}
        variant={emailBody.includes(key)
          ? Chip.VARIANTS.INFORMATIVE
          : Chip.VARIANTS.NEUTRAL
        }
      >
        {description}
      </Popover>,
    );
  }

  return (
    <div>
      <blockquote className="note">
        Currently the following template variables are supported
        (hover over each variable to read more about what they do):
      </blockquote>

      <ul className={classes.TemplateVariables}>{chips}</ul>
    </div>
  );
}

EmailTemplateVariables.displayName = 'EmailTemplateVariables';

EmailTemplateVariables.propTypes = {
  emailBody: PropTypes.string,
};
