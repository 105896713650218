import type {
  Match,
  Matches,
  OptIn,
} from './Match.d.ts';

export const selectGroupOptins = (
  groups: Matches,
  activeGroupId: Match['id'],
) => Array
  .from(groups.get(activeGroupId).optins.keys())
  .sort();

export const composeGroupMetaKey = (optinIds: Array<OptIn['id']>) => optinIds.join(',');

export const selectAndComposeGroupMetaKey = (
  ...args: Parameters<typeof selectGroupOptins>
) => composeGroupMetaKey(selectGroupOptins(...args));
