import type {
  Dispatch,
  SetStateAction,
} from 'react';

import type { SerialParams, SerialValue } from '…/app/common/refinement/useRefinementParams.mts';
import { transformFilterMapToGQL } from '…/app/common/filtering/transform.mts';

import type { Sort } from '../Table/Table.tsx';

import type {
  PaginatedRecords,
  PaginationProps,
  Paging,
  PagingInputs,
} from './pagination.d.ts';


interface BasicRecord { id: unknown }
type SetCount = (count: Int) => void;
type SetRecords<Record extends BasicRecord> = Dispatch<SetStateAction<Map<Record['id'], Record>>>;
type SetSelected<Record extends BasicRecord> = Dispatch<SetStateAction<Map<Record['id'], boolean>>>;

export const PAGE_ITEM_LIMIT: Int = 30;

export type Search = { q: string };

type SetPage = Dispatch<SetStateAction<Paging>>;
type SetFilters<Filters extends SerialParams> = Dispatch<SetStateAction<Filters>>;
type SetSearch = Dispatch<SetStateAction<Search>>;
type SetSort<Fields extends SerialValue> = Dispatch<SetStateAction<Sort<Fields>>>;

/**
 * Page must be reset whenever the search query changes.
 */
export function resetPaginationOnRefine<Fields extends SerialValue, Filters extends SerialParams>(
  setPage: SetPage,
  setFilters: SetFilters<Filters>,
  setSearch: SetSearch,
  setSort: SetSort<Fields>,
) {
  return function resetPageOnRefine({
    filters,
    search,
    sort,
  }: {
    filters?: Filters,
    search?: Search,
    sort?: Sort<Fields>,
  }) {
    updateRefinement<Filters>(setFilters, filters);
    updateRefinement<Search>(setSearch, search);
    updateRefinement<Fields>(setSort, sort);

    setPage((prev) => ({
      ...prev,
      number: 1,
      reset: true,
    }));
  };
}
export type SetPaginatedSearch = ReturnType<typeof resetPaginationOnRefine>;

function updateRefinement<R extends SerialParams>(
  setter: Dispatch<SetStateAction<R>>,
  vals?: R,
) {
  if (vals == null) return;

  setter((prev) => ({
    ...prev,
    ...vals,
  }));
}

export function paginationPropsFactory<Filters extends SerialParams<string[]>>({
  cursors,
  direction,
  filters,
  limit = PAGE_ITEM_LIMIT,
  reset,
  search,
}: PagingInputs<Filters>): PaginationProps {

  return {
    ...(direction === 'forward' && {
      ...(!reset && { after: cursors.current.end }),
      first: limit,
    }),
    ...(direction === 'backward' && {
      ...(!reset && { before: cursors.current.start }),
      last: limit,
    }),
    ...(!direction && {
      first: limit,
    }),
    ...(filters && {
      filters: transformFilterMapToGQL<Filters>(filters),
    }),
    ...(search && {
      search,
    }),
  };
}

interface PaginationSetters<Record extends BasicRecord> {
  setCount: SetCount,
  setRecords: SetRecords<Record>,
  setSelected?: SetSelected<Record>,
}

export function paginationThenFactory<
  Record extends BasicRecord,
  CollectionName extends string,
>(
  collectionName: CollectionName,
  {
    cursors,
    reset,
  }: PagingInputs,
  {
    setCount,
    setRecords,
    setSelected,
  }: PaginationSetters<Record>,
) {
  return function then<Data extends {
    [k in CollectionName]: PaginatedRecords<Record>
  }>(data: Data): Data {
    const {
      nodes,
      pageInfo: { endCursor, startCursor },
      totalCount,
    } = data[collectionName];

    cursors.current = {
      end: endCursor,
      start: startCursor,
    };

    setCount(totalCount);

    setRecords(function updateRecordsAfterPaging(prev) {
      const updated = new Map(reset ? new Array() : prev);
      for (const record of nodes.values()) updated.set(record.id, record);
      return updated;
    });

    setSelected?.(function updateSelectedAfterPaging(prev) {
      const updated = new Map(reset ? new Array() : prev);
      for (const { id } of nodes.values()) updated.set(id, updated.get(id) ?? false);
      return updated;
    });

    return data;
  };
}

export function paginationFactory<
  Record extends BasicRecord,
  CollectionName extends string,
>(
  collectionName: CollectionName,
  pagingInputs: PagingInputs,
  setters: PaginationSetters<Record>,
) {
  const props = paginationPropsFactory(pagingInputs);

  const then = paginationThenFactory(
    collectionName,
    pagingInputs,
    setters,
  );

  return {
    props,
    then,
  };
}
