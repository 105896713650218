._FlexEnd_1ertq_1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

._Warning_1ertq_8 {
  color: var(--status-danger);
  margin-right: 1rem;
}

._DisableEmailContainer_1ertq_13 {
  background-color: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1.5rem;
}
