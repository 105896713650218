.MatchIcon {
  align-items: center;
  display: inline-flex;
  flex-direction: row;
  height: 1em;
  justify-content: space-between;
  opacity: 0.6;
  transition-duration: var(--transition-duration-controls);
  transition-property: opacity;
  transition-timing-function: var(--default-transition-easing);
  vertical-align: middle;
  width: 2.5em;
}

.MatchIcon::before,
.MatchIcon::after {
  border-color: inherit;
  border-radius: 100%;
  border-style: solid;
  border-width: 0.1em;
  content: "";
  display: inline-block;
  height: 0.9em; /* 1em - border */
  transition-duration: var(--transition-duration-glyphs);
  transition-property: color, translate;
  transition-timing-function: var(--default-transition-easing);
  translate: 0;
  width: 0.9em; /* 1em - border */
}

.MatchIcon[match="partial"]::after {
  opacity: 0.5;
}

.MatchIcon[match="set"]::before {
  translate: 50%;
}

.MatchIcon[match="set"]::after {
  translate: -50%;
}

button > .MatchIcon[match="set"]:hover {
  color: var(--status-error);
  opacity: 1;
}

button > .MatchIcon[match="set"]:hover::before {
  translate: 0;
}

button > .MatchIcon[match="set"]:hover::after {
  translate: 0;
}

/*
Animate circles together
button:hover > .MatchIcon:not([match="set"])::before {
  translate: calc(100% / 3);
}

button:hover > .MatchIcon:not([match="set"])::after {
  translate: calc(100% / -3);
}
*/
