.Content {
  padding: var(--grid-gutter);
  text-align: center;
}

.NPSExpli {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
}

.DoughnutContainer {
  aspect-ratio: 1.643;
  position: relative;
}

.Score {
  align-items: center;
  background-color: white;
  border-radius: 100%;
  bottom: -1em;
  box-shadow: 0 2px 10px 0 rgb(223 223 223 / 100%);
  display: flex;
  font-weight: bold;
  height: 3.9rem;
  justify-content: center;
  left: 49.2%;
  line-height: 0;
  position: absolute;
  translate: -50% -25%;
  width: 3.9rem;
}

.Score::before {
  background: url('/speedLines.svg') no-repeat center 33%;
  content: '';
  height: 250%;
  position: absolute;
  width: 250%;
}

.Score::after {
  content: '⏴';
  position: absolute;
  rotate: calc(1deg * var(--rotation)); /* add `deg` unit to raw rotation value */
  transform-origin: right center;
  translate: -50% 0;
  width: calc(1.1ch + 50%);
}

.NPSValue {
  margin-top: var(--grid-gutter);
}

.ScaleLabels {
  font-weight: 600;
  margin-top: -0.5em;
}

.ScaleStart {
  color: var(--color);
  margin-left: calc(2 * var(--grid-gutter));
}

.ScaleEnd {
  color: var(--color);
  margin-right: calc(2 * var(--grid-gutter));
}

.OrbiitCallout {
  margin: var(--grid-gutter) auto;
  width: fit-content;
}
