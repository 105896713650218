import Button from 'form5/react/Button';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { WalledRouteParams } from '…/app/route-params.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { Engagement } from '…/app/w/workspace/engagements/Engagement.d.ts';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import { resetTemplate } from '…/app/w/workspace/engagements/engagement/gql/resetTemplate.op.mts';


export function RemoveTemplate({
  onSuccess,
}: {
  onSuccess(result: Engagement): void,
}) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const { engagement } = useViewState()[0];
  const [isSubmitting, setSubmitting] = useState(false);

  async function removeEngagementTopic() {
    setSubmitting(true);

    return resetTemplate({
      engagementId: engagement.id,
      workspaceId,
    })
      .then(onSuccess)
      .finally(() => {
        setSubmitting(false);
      });
  }

  return (
    <>
      <h1>Remove template</h1>

      <p>
        Are you sure you want to remove the selected template
        <em>{engagement?.title}</em>
        and all associated edits made?
      </p>

      <p>
        <strong>You will not be able to retrieve the edits made to this template once removing.</strong>
      </p>

      <SubmitButton
        appearance={Button.APPEARANCES.DANGER}
        disabled={isSubmitting}
        isSubmitting={isSubmitting}
        onClick={removeEngagementTopic}
        type="submit"
      >Remove
      </SubmitButton>
    </>
  );
}
RemoveTemplate.displayName = 'RemoveTemplate';
