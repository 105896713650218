import type { DeSelectAllState } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';

import type { MembersDict } from './members/Member.d.ts';


export function getSelectedMemberData(
  selected: DeSelectAllState,
  members: MembersDict,
) {
  const selectedMembers: MembersDict = new Map();

  for (const [id, isSelected] of selected.entries()) {
    if (isSelected) selectedMembers.set(id, members.get(id)!);
  }

  return selectedMembers;
}
