import { clsx } from 'clsx';

import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';

import type {
  OptIn,
  OptIns,
} from './Match.d.ts';
import type { DragInfo, DropzoneEvent } from './onDrag.mts';
import type { EditMatches } from './EditMatches2.tsx';
import { MatchCard } from './MatchCard/MatchCard.tsx';
import * as dragHandlers from './onDrag.mts';

import classes from './Matches.module.css';


export function AvailableMembers({
  available,
  dragging,
  editable,
  meta,
  onDrop,
  setDragging,
  setMemberToInspect,
}: {
  available: OptIns,
  dragging: DragInfo,
  editable: boolean,
  meta: EditMatches['state']['meta'],
  onDrop(event: DropzoneEvent): void,
  setDragging(dragging: DragInfo): void,
  setMemberToInspect(member: OptIn['member'] | undefined): void,
}) {
  return (
    <aside className={clsx('Well', classes.Sidebar)}>
      <header className="SplitHeader">
        <h2>{editable ? 'Available' : 'Unmatched'}</h2>

        <Popover
          anchor={({ className, ...props }) => (
            <OrbiitIcon
              className={clsx(className, { [classes.UnmatchedWarning]: available.size })}
              icon="InfoIcon"
              size="medium"
              {...props}
            />
          )}
          position={Popover.POSITIONS.TOP_RIGHT}
          type={Popover.TYPES.TOOLTIP}
        >
          {editable
            ? 'Unmatched participants will not be included in the engagement.'
            : 'These participants have not been matched.'
          }
        </Popover>
      </header>

      <fieldset
        className={classes.Unmatched}
        id="unmatched"
        onDragEnter={dragHandlers.onDragEnter}
        onDragOver={(event) => dragHandlers.onDragOver(event, dragging, setDragging)}
        onDrop={onDrop}
      >
        {Array.from(available.values()).map((optin) => (
          <MatchCard
            dragging={dragging}
            key={optin.id}
            meta={meta[optin.id]}
            optin={optin}
            readOnly={!editable}
            setDragging={setDragging}
            setMemberToInspect={setMemberToInspect}
          />
        ))}
      </fieldset>
    </aside>
  );
}

AvailableMembers.displayName = 'AvailableMembers';
