._OptionsCardHeaderContainer_hhjh7_1,
._OptionTextContainer_hhjh7_2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

._OptionsItemsWrapper_hhjh7_8 {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

._OptionWrapper_hhjh7_13 {
  margin-bottom: 0.75rem;
}

._OptionTextContainer_hhjh7_2 {
  margin-bottom: 0.125rem;
}

._OptionText_hhjh7_2,
._OptionTextLight_hhjh7_22 {
  display: block;
  font-size: 0.8rem;
  font-weight: 400;
}

._OptionText_hhjh7_2 {
  color: var(--brand-subtle-dark);
}

._OptionTextLight_hhjh7_22 {
  color: var(--neutral-dark);
}

._MoreButtonContainer_hhjh7_36 {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
