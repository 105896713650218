export const enum BROWSER {
  BRAVE = 'brave',
  CHROME = 'chrome',
  VIVALDI = 'vivaldi',
  FIREFOX = 'firefox',
  SAFARI = 'safari',
}

const browsersByProp = new Map([
  ['brave', BROWSER.BRAVE],
  ['chrome', BROWSER.CHROME],
  ['vivaldi', BROWSER.VIVALDI],
  ['firefox', BROWSER.FIREFOX],
]);

const vendors = new Map([
  ['Apple Computer, Inc.', BROWSER.SAFARI],
]);

export function getBrowser(navigator: Navigator) {
  for (const [key, browserName] of browsersByProp.entries()) {
    if (key in navigator) return browserName;
  }

  for (const [vendor, browserName] of vendors.entries()) {
    if (navigator.vendor === vendor) return browserName;
  }
}

export const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
