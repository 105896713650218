import { HTTPError } from 'ky';

import { rpc } from '…/app/common/rpc/client.mts';

import { onError } from '../errors/errors.state.mts';
import type { WorkspaceAdmin } from '../states.d.ts';

import { workspacesState } from './workspaces.state.mts';


export function uploadWorkspaceLogo(workspaceId: ObjectId, logo: File) {
  const body = new FormData();
  body.set('logo', logo);

  return rpc(`workspaces/${workspaceId}/upload-logo`, {
    body,
    method: 'POST',
  })
    .json<WorkspaceAdmin>()
    .then((result) => {
      Object.assign(workspacesState, result);

      return result;
    })
    .catch((err) => {
      if (err instanceof HTTPError && err?.response.status === 500 && location.hostname.includes('localhost')) {
        console.warn('Expected Error', err);

        return {};
      }

      throw err;
    })
    .catch(onError);
}
