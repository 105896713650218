export const mapSortableFieldsToGQLConstant = {
  'createdAt': 'CREATED_AT',
  'matchRating': 'MATCH_RATING',
  'name': 'NAME',
  'nps': 'NPS',
};

export type SortableFields = keyof typeof mapSortableFieldsToGQLConstant;

export const FEEDBACK_FORM_NPS_STATUS = {
  DETRACTOR: 'detractor',
  NEUTRAL: 'neutral',
  PROMOTER: 'promotor', // NOTE: Typo, should be promoter, but this is used in the api.
} as const;
export const FEEDBACK_FORM_NPS_STATUS_DICT = {
  [FEEDBACK_FORM_NPS_STATUS.PROMOTER]: 'Promoter',
  [FEEDBACK_FORM_NPS_STATUS.NEUTRAL]: 'Neutral',
  [FEEDBACK_FORM_NPS_STATUS.DETRACTOR]: 'Detractor',
} as const;
export type FeedbackFormNPSStatus = typeof FEEDBACK_FORM_NPS_STATUS[keyof typeof FEEDBACK_FORM_NPS_STATUS];

export const FEEDBACK_FORM_RATING_STATUS = {
  AVERAGE: 'average',
  EXCELLENT: 'excellent',
  GREAT: 'great',
  POOR: 'poor',
} as const;
export const FEEDBACK_FORM_RATING_STATUS_DICT = {
  [FEEDBACK_FORM_RATING_STATUS.EXCELLENT]: 'Perfect',
  [FEEDBACK_FORM_RATING_STATUS.GREAT]: 'Great',
  [FEEDBACK_FORM_RATING_STATUS.AVERAGE]: 'Average',
  [FEEDBACK_FORM_RATING_STATUS.POOR]: 'Poor',
} as const;
/* eslint-disable-next-line max-len */
export type FeedbackFormRatingStatus = typeof FEEDBACK_FORM_RATING_STATUS[keyof typeof FEEDBACK_FORM_RATING_STATUS];

export type FeedbackFormsFilters = {
  CREATED_AT: ISO_8601[],
  MATCH_RATING: FeedbackFormRatingStatus[],
  NPS: FeedbackFormNPSStatus[],
  SELECTED_OPTIONS: string[],
};
