import { SELECTABLE_STEPS_SET } from '…/app/deprecated/constants.mts';
import type { EngagementQuestion } from '…/app/w/workspace/engagements/Engagement.js';

// NOTE: Legacy timezone question
const IGNORE_STEPS = new Set(['timezone', 'fallback']);

/**
 * Filters a list of questions and extracts the enabled selectable questions
 */
export const getSelectableQuestions = (questions: EngagementQuestion[]) => questions
  .filter((q) => q !== null && !q.isDisabled && !IGNORE_STEPS.has(q.stepName) && SELECTABLE_STEPS_SET.has(q.question));

/**
 * Process an array of form questions and form answers and filter and aggregate
 * the data for the answers for the supported question types.
 */
export const getQuestionAnswers = (questions: EngagementQuestion[], answerStatistics) => {
  if (!questions) return [];
  if (!answerStatistics) return [];

  const filteredOptInQuestions = getSelectableQuestions(questions).map((q) => {
    const { props, stepName } = q;
    const { options, wildcard } = props;

    const answer = answerStatistics.find((a) => a.stepName === stepName);
    const totalCount = answer?.optionsCount.reduce((prev, opt) => prev + opt.count, 0);
    const newOptions = [...options];

    if (wildcard) newOptions.push(wildcard);

    const mappedOptions = newOptions
      .filter((o) => !o.isDisabled)
      .map((opt) => {
        const answerCount = answer?.optionsCount.find((o) => o.id === opt.id);
        const percentage = answerCount ? (answerCount?.count / totalCount) * 100 : 0;
        return {
          ...opt,
          percentage,
          count: answerCount?.count || 0,
        };
      });

    return {
      ...q,
      props: {
        ...props,
        options: mappedOptions,
      },
    };
  });

  return filteredOptInQuestions;
};

/**
 * For a selectable question return a map of all the answer options so we can grab
 * the question title & answer text from the answer id.
 *
 * @param {Object} q question
 *
 * @returns {Object<string: Object>}
 */
export const getSelectableQuestionAnswersMap = (q: EngagementQuestion) => {
  let questionAnswerMap = {};

  const { props, question } = q;
  const { ledeIn, options, title, wildcard } = props;

  options.forEach((option) => {
    const aux = {
      [option.id]: {
        answer: option.text,
        title: title || ledeIn,
        type: question,
      },
    };
    questionAnswerMap = {
      ...questionAnswerMap,
      ...aux,
    };
  });

  if (wildcard) {
    const option = { ...wildcard };
    const aux = {
      [option.id]: {
        answer: option.text,
        title: title || ledeIn,
        type: question,
      },
    };
    questionAnswerMap = {
      ...questionAnswerMap,
      ...aux,
    };
  }

  return questionAnswerMap;
};

/**
 * For a given set of questions create a map of all answer ids to the answer text / question title.
 *
 * @param {Array<Object>} questions
 *
 * @returns {Object<string: Object>} Map of answer id to answer text / question title
 */
export const getAnswersMap = (questions: EngagementQuestion[]) => {
  // Create an array of all selectable questions with their answers.
  // We currently only expose selectable questions that were enabled.
  const answersMapPerQuestion = getSelectableQuestions(questions).map(getSelectableQuestionAnswersMap);

  let answersMapForAllQuestions = {};
  answersMapPerQuestion.forEach((q) => {
    answersMapForAllQuestions = {
      ...answersMapForAllQuestions,
      ...q,
    };
  });

  return answersMapForAllQuestions;
};

/**
 * Retrieve the Free Text questions
 *
 * @param {Array<Object>} questions
 *
 * @returns {Object<string: Object>} Map of answer id to answer text / question title
 */
export const getFreeTextQuestions = (questions: EngagementQuestion[]) => {
  const freeTextQuestions = questions.filter((q) => q !== null && !q.isDisabled && q.question === 'GenericTextStep');

  let freeTextQuestionsMap: Record<EngagementQuestion['stepName'], {
    question: EngagementQuestion['question'],
    title: EngagementQuestion['props']['title'],
  }> = {};
  freeTextQuestions.forEach(({ question, stepName, props: { title } }) => {
    freeTextQuestionsMap[stepName] = { title, question };
  });

  return freeTextQuestionsMap;
}
