import type {
  Dispatch,
  SetStateAction,
} from 'react';
import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import {
  Link,
  generatePath,
} from 'react-router-dom';

import { log } from '…/app/logger.mts';
import { ENGAGEMENT_STATUS_STEP, PRODUCT_TO_TYPE_DICT } from '…/app/deprecated/constants.mts';
import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';
import { downloadEngagementSection } from '…/app/deprecated/utils/shared/data/downloads.mjs';

import { formatDate } from '…/app/common/dateUtils.mts';
import { Popover } from '…/app/w/workspace/common/Popover/Popover.tsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { getFormAppLink } from '…/app/w/workspace/engagements/engagement/participantFormUtils.ts';

import type {
  Engagement,
  EngagementsDict,
  ScheduledEngagement,
} from './Engagement.d.ts';

import { engagementCanBeArchived } from './engagement/ArchiveEngagementForm.tsx';
import { engagementCanBeCloned } from './engagement/CloneEngagementForm.tsx';

import statusClasses from './engagement/EngagementStatusIndicator.module.css';
import classes from './EngagementsList.module.css';

import type { FormConfig } from './crudForms.mts';
import { STATUSES_LABEL_DICT } from './constants.mts';


export function transformEngagementsToTabularData(
  engagements: EngagementsDict<ScheduledEngagement>,
  {
    page,
    limit,
    user,
    workspaceId,
    workspaceSlug,
  }: {
    page: Int,
    limit: Int,
    user: any,
    workspaceId: string,
    workspaceSlug: string,
  },
  {
    setCount,
    setEngagements,
    setForm,
  }: {
    setCount: Dispatch<SetStateAction<number>>,
    setEngagements: Dispatch<SetStateAction<EngagementsDict<ScheduledEngagement>>>,
    setForm: Dispatch<SetStateAction<FormConfig | undefined>>,
  },
  flags: Record<string, boolean>,
) {
  const start = (page - 1) * limit;
  const end = page * limit;

  return Array.from(engagements.values()).slice(start, end)
    .map((engagement) => {
      const {
        id: engagementId,
        audience,
        description,
        engagementStats,
        groupSize,
        matchesCount,
        name: engagementName,
        optInClosesAt,
        optInFormsCount,
        optInOpensAt,
        product,
        recurrence,
        status,
        timezoneIso,
      } = engagement;

      // NOTE: We are switching over to pre-computed Engagement Stats in the coming weeks/months.
      if (optInFormsCount) {
        if (!engagement?.engagementStats?.numOptinForms) {
          log.warn(`[Missing] Engagement Stats vs optInFormsCount - ${engagement.id}`, {
            engagementId: engagement.id,
            workspaceId,
          });
        } else {
          if (engagement.engagementStats.numOptinForms !== optInFormsCount) {
            log.error(`[Invalid] Engagement Stats (${engagement.engagementStats.numOptinForms}) vs optInFormsCount (${optInFormsCount}) - ${engagement.id}`, {
              engagementId: engagement.id,
              workspaceId,
            });
          }
        }
      }

      const isClonable = engagementCanBeCloned(engagement);

      // NOTE: `description` and `optInOpensAt` is the proxy for the Opt-In form being viewable. It avoids loading
      // all questions but is a reliable proxy.
      const showOptInFormLink = (ENGAGEMENT_STATUS_STEP[status] < 2 && status !== 'MATCHING' && description && optInOpensAt) ? true : false;
      const optInFormLink = getFormAppLink({
        engagement,
        isRecurringLinkEnabled: flags.canUseRecurringLink,
        user,
        workspace: { slug: workspaceSlug },
      });

      const isRecurring = recurrence && recurrence.isRecurringEnabled && recurrence.seriesId;

      return [
        {
          display: false,
          fieldName: 'key',
          raw: engagementId,
        },
        {
          display: (
            <Popover
              anchor={(props) => (
                <span aria-label={status} className={clsx(props.className, statusClasses.StatusIndicator)} />
              )}
              containerClassName={statusClasses.StatusPopoverContainer}
              minimal
              type={Popover.TYPES.TOOLTIP}
            >
              {STATUSES_LABEL_DICT[status]}
            </Popover>
          ),
          fieldName: 'meta',
        },
        {
          display: (
            <span className={classes.RowSpacer}>
              <Link
                tabIndex={0} // FIXME: ORB-1827 why is this necessary?
                to={generatePath('/w/:workspaceId/engagements/:engagementId', {
                  engagementId,
                  workspaceId,
                })}
              >
                {engagementName}
              </Link>

              {isRecurring && (
                <CTA
                  disabled={!flags.canRecur}
                  id={`recurring.${engagementId}`}
                  title="Recurring"
                  to={() => setForm({
                    data: {
                      engagement,
                      engagementId,
                      setEngagements,
                      setOpen: setForm,
                      workspaceId,
                    },
                    name: 'recur',
                  })}
                  variant={Button.VARIANTS.GLYPH}
                >
                  <OrbiitIcon
                    icon="Timer"
                    inline
                    size="small"
                    title={getCadence(recurrence.interval, recurrence.repeat)}
                  />
                </CTA>
              )}
            </span>
          ),
          fieldName: 'name',
          raw: engagementName,
        },
        {
          fieldName: 'product',
          raw: `${PRODUCT_TO_TYPE_DICT[product]}${groupSize > 2 ? ` (${groupSize})` : ''}`,
        },
        {
          fieldName: 'optInFormsCount',
          raw: optInFormsCount,
        },
        {
          fieldName: 'matchesCount',
          raw: matchesCount > 0 ? matchesCount : null,
        },
        {
          fieldName: 'nps.score',
          raw: engagementStats?.nps ? Math.round(engagementStats.nps) : null,
        },
        {
          display: audience && (
            <Link
              id={`audience.${engagementId}`}
              to={generatePath('/w/:workspaceId/audiences/:audienceId', {
                audienceId: audience.id,
                workspaceId,
              })}
            >
              {audience.name}
            </Link>
          ),
          fieldName: 'audience.name',
          raw: audience?.name,
        },
        {
          display: optInOpensAt ? formatDate(optInOpensAt, timezoneIso) : undefined,
          fieldName: 'optInOpensAt',
          raw: optInOpensAt,
        },
        {
          display: optInClosesAt ? formatDate(optInClosesAt, timezoneIso) : undefined,
          fieldName: 'optInClosesAt',
          raw: optInClosesAt,
        },
        {
          display: (
            <Popover
              anchor={({ className, ...props }) => (
                <Button
                  {...props}
                  appearance={Button.APPEARANCES.BASIC}
                  className={clsx(className, 'ContextMenuIcon')}
                  id={`more-actions_${engagementId}`}
                  title="more actions"
                  variant={Button.VARIANTS.GLYPH}
                />
              )}
              position={Popover.POSITIONS.BOTTOM_RIGHT}
              type={Popover.TYPES.MENU}
            >
              <CTA
                disabled={!flags.isEditor || !isClonable}
                guise={CTA.GUISES.LINK}
                id={`clone_${engagementId}`}
                menuitem=""
                to={() => setForm({
                  data: {
                    engagement,
                    engagementId,
                    setEngagements,
                    setOpen: setForm,
                    workspaceId,
                  },
                  name: 'clone',
                })}
              >Copy Engagement
              </CTA>

              <CTA
                disabled={!flags.canRecur}
                guise={CTA.GUISES.LINK}
                id={`recur_${engagementId}`}
                menuitem=""
                to={() => setForm({
                  data: {
                    engagement,
                    engagementId,
                    setEngagements,
                    setOpen: setForm,
                    workspaceId,
                  },
                  name: 'recur',
                })}
              >Recurring Settings
              </CTA>

              {showOptInFormLink && (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                  {(isRecurring && flags.canUseRecurringLink)
                    ? (
                      <CTA
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        rel="noreferrer"
                        target="_blank"
                        to={optInFormLink}
                      >Recurring Opt-In Form Link
                      </CTA>
                    )
                    : (
                      <CTA
                        guise={CTA.GUISES.LINK}
                        menuitem=""
                        rel="noreferrer"
                        target="_blank"
                        to={optInFormLink}
                      >Unique Opt-In Form Link
                      </CTA>
                    )}
                </>
              )}

              <CTA
                disabled={!(optInFormsCount > 0)}
                guise={CTA.GUISES.LINK}
                id={`download-optinforms-csv_${engagementId}`}
                menuitem=""
                to={() => downloadEngagementSection(workspaceId, engagement, 'optin-forms')}
              >Download Opt-In Forms CSV
              </CTA>

              <CTA
                disabled={!(matchesCount > 0)}
                guise={CTA.GUISES.LINK}
                id={`download-matches-csv_${engagementId}`}
                menuitem=""
                to={() => downloadEngagementSection(workspaceId, engagement, 'matches')}
              >Download Matches CSV
              </CTA>

              <CTA
                disabled={!(engagementStats?.nps > -100)}
                guise={CTA.GUISES.LINK}
                id={`download-matches-csv_${engagementId}`}
                menuitem=""
                to={() => downloadEngagementSection(workspaceId, engagement, 'feedback-forms')}
              >Download Feedback CSV
              </CTA>

              <CTA
                destructive=""
                disabled={!engagementCanBeArchived(status, optInFormsCount)}
                guise={CTA.GUISES.LINK}
                id={`delete_${engagementId}`}
                menuitem=""
                to={() => setForm({
                  data: {
                    engagement,
                    engagementId,
                    setCount,
                    setEngagements,
                    setOpen: setForm,
                    workspaceId,
                  },
                  name: 'archive',
                })}
              >Delete Engagement
              </CTA>
            </Popover>
          ),

          fieldName: 'actions',
        },
      ];
    });
}

function getCadence(interval: Engagement['interval'], repeat: Engagement['repeat']) {
  if (!interval || !repeat) return;

  return interval > 1
    ? `${interval} ${repeat}s` // ! this is not robust pluralisation
    : `${repeat}ly`;
}
