.Page {
  display: flex;
  flex-flow: row wrap;
  flex-direction: column;
  gap: calc(2 * var(--grid-gutter));
  margin: 0 auto;
  max-width: 960px;
  padding: calc(2 * var(--grid-gutter));
  padding-bottom: 4rem;
}

.Header {
  align-items: center;
  border-bottom: 1px solid var(--border-colour-normal);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.Title {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SectionCard {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 0 0.25rem; /** To ensure the borders flow nicely under the StickyHeader */
}

@media (width <= 960px) {
  .SectionCard {
    flex-direction: column;
  }
}

.Content {
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.CardTitle {
  background-image: linear-gradient(270deg, rgb(85 187 247) 0%, rgb(25 100 187) 100%);
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

input[type="number"] {
  max-width: 100px;
}

.EngagementStatsWrapper {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;
  margin-bottom: 1rem;
}
