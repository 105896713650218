import { toast } from 'sonner';

import {
  PRODUCT_TO_TYPE_DICT,
  PRODUCT_TYPES,
} from '…/app/deprecated/constants.mts';

import type { DragInfo } from '../setup/DragNDrop/onDrag.ts';

import type { EditMatches } from './EditMatches2.tsx';

import type { DropzoneEvent } from './onDrag.mts';

import {
  addToPool,
  removeFromPool,
} from './stateCRUD.mts';


export function onDrop(
  this: EditMatches,
  event: DropzoneEvent,
) {
  if (!event.currentTarget) return;

  const {
    engagement,
  } = this.props;
  const {
    groups,
  } = this.state;

  const {
    from: fromId,
    target,
    to: toId,
  } = this.dragging;

  if (toId !== 'unmatched') {
    const groupOptins = groups.get(toId)!.optins;

    if (groupOptins.has(target.id)) {
      return toast.error(
        engagement.product === PRODUCT_TYPES.ONE_TO_ONE
          ? `Cannot pair ${target.member.fields?.fullName} with themselves`
          : `${target.member.fields?.fullName} is already in that group`,
      );
    }

    if (engagement.product === PRODUCT_TYPES.ONE_TO_ONE && groupOptins.size === 2) {
      return toast.error(`${PRODUCT_TO_TYPE_DICT[engagement.product]} engagements can have only 2 participants`);
    }
  }

  if (fromId === 'unmatched') removeFromPool(target, this.setState.bind(this));
  if (toId === 'unmatched') addToPool(target, this.setState.bind(this));

  this.setState((prev) => {
    const updated = new Map(prev.groups);

    if (fromId !== 'unmatched') {
      const fromGroup = updated.get(fromId)!;
      const optins = new Map(fromGroup.optins);
      optins.delete(target.id);
      fromGroup.optins = optins;
    }
    if (toId !== 'unmatched') {
      updated.get(toId)!.optins.set(target.id, target);
    }

    return { groups: updated };
  });

  this.dragging = {} as DragInfo;
  this.setState({ isDirty: true });
}
