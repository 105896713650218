fragment audienceFields on Audience {
  id
  createdAt
  createdBy
  name
  integration {
    accountType
  }
  isBuiltin
  isPrimary
  memberCount
  updatedAt
  updatedBy
  workspaceCustomFields {
    id
    name
    options {
      id
      text
    }
  }
}
