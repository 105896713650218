._Table_1i3b2_1 {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

._Table_1i3b2_1 th,
._Table_1i3b2_1 td {
  padding: var(--default-padding);
}

._Table_1i3b2_1 td[data-type="controls"] {
  width: 1ch;
}
