.TimelineCard {
  align-items: center;
  border-radius: 3px;
  color: inherit;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 0.75rem 1rem;
  position: relative;
  text-decoration: none;
}

.TimelineCard:hover {
  background-color: var(--neutral-lightest);
}

.IsSet {
  background-color: var(--brand-light);
}

.IsUnset {
  align-items: center;
  border: 1px dotted var(--neutral-medium);
  color: var(--neutral-medium);
  height: 3.5em;
  justify-content: center;
}

.DateTimes {
  display: flex;
  flex: 0 0 10ch; /* 8ch = 1 icon, 2 numbers in day, 1 space, 3–4 letters in month, 1 trailing comma */
  flex-direction: column;
  font-weight: 500;
  gap: inherit;
}

.Divider {
  background-color: var(--brand-subtle-light);
  border-radius: 1em;
  content: "";
  flex: none;
  height: 100%;
  width: 2px;
}

.DateTime {
  align-items: baseline;
  display: flex;
  font-size: 0.875em;
  gap: 1ch;
}

.Copy {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.Title {
  text-transform: capitalize;
}

.Blurb {
  font-size: 0.875em;
  line-height: 1.5;
}

.Title,
.Blurb {
  margin: 0;
}
