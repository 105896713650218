._OptionTextContainer_vt4gp_1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
}

._OptionText_vt4gp_1,
._OptionTextLight_vt4gp_9 {
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
}

._OptionText_vt4gp_1 {
  color: var(--brand-subtle-dark);
}

._OptionTextLight_vt4gp_9 {
  color: var(--neutral-dark);
}
