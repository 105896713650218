.MeterContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 2em;
  gap: 0.4em;
  justify-content: space-between;
  line-height: 1;
  position: relative;
  width: 100%;
}

.MeterBound {
  flex: none;
  font-size: 0.5em;
}

.MeterPopoverContainer {
  flex: 1;
  position: relative;
}
