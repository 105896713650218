._Container_t28p4_1 {
  background-color: transparent;
  height: 4rem;
  margin: -0.75rem auto -0.875rem;
  position: relative;
  width: auto;
}

._Line_t28p4_9 {
  background-image: linear-gradient(
    to bottom,
    rgb(0 0 0 / 0%) 0%,
    var(--neutral-medium) 50%,
    rgb(0 0 0 / 0%) 100%
  );
  bottom: 0;
  left: calc(50% - 1px);
  position: absolute;
  top: 0;
  width: 2px;
}

._Dot_t28p4_23 {
  background-color: var(--neutral-medium);
  border-radius: 50%;
  height: 8px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 8px;
}

._Label_t28p4_34 {
  box-sizing: border-box;
  font-size: 0.875rem;
  left: calc(50% + 1.75rem);
  padding: 0.25rem;
  position: absolute;
  top: calc(50% + 1px);

  /* transform: translate(-50%, -50%); */
}
