import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'sonner';

import { rpc } from '…/app/common/rpc/client.mts';
import { handlePageErrors } from '…/app/common/errors/handlePageErrors.mts';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';

import {
  Bureau,
  Drawer,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';

// import type { WidgetConfigBase } from '…/app/common/widget.mts';

import { getWorkspaceIntegrationsData } from './getWorkspaceIntegrationsData.op.mts';

import { CreateApiKeyDialog } from './CreateApiKeyDialog.jsx';
import { RevokeApiKeyDialog } from './RevokeApiKeyDialog.jsx';
import { ApiKeysSection } from './ApiKeysSection.jsx';
import { IntegrationsSection } from './IntegrationsSection.jsx';

import { IntegrationsSettingsDrawer } from './IntegrationsSettingsDrawer.jsx';

import { addListToSync } from './addListToSync.op.mts';
import { deleteListToSync } from './deleteListToSync.op.mts';

import panelClasses from '../SettingsPanel.module.css';
import classes from './WorkspaceIntegrationsPage.module.css';

// export interface WidgetConfig extends WidgetConfigBase {
//   name: 'settings'
// }


export function WorkspaceIntegrationsPage() {
  const { workspaceId } = useParams();

  const [sessions, setSessions] = useState([]);
  const [integrations, setIntegrations] = useState(new Map());
  const [refresh, setRefresh] = useState(true);
  const [createApiKeyDialogOpen, setCreateApiKeyDialogOpen] = useState(false);
  const [revokeApiKey, setRevokeApiKey] = useState(null);

  const addIntegrationList = ({
    displayName, integrationId, remoteId,
  }) => {
    toast.promise(addListToSync({
      displayName,
      integrationId,
      remoteId,
      workspaceId,
    }).then((completed) => {
      if (completed) {
        setRefresh(true);
        rpc.post('actions/integration-sync-list-id', {
          json: {
            integrationId,
            listIds: [remoteId],
          },
        });
      }
    }), {
      error: 'Failed to add the list',
      loading: 'Saving...',
      success: `List ${displayName} added`,
    });
  };
  const deleteIntegrationList = ({
    integrationId, remoteId,
  }) => {
    toast.promise(deleteListToSync({
      integrationId,
      remoteId,
      workspaceId,
    }).then((completed) => {
      if (completed) setRefresh(true);
    }), {
      error: 'Failed to unlink the list',
      loading: 'Saving...',
      success: 'List unlinked',
    });
  };

  const [widgetConfig, setWidgetConfig] = useState(); // NOTE: Add typing <WidgetConfig>

  useEffect(() => {
    if (!refresh) return;

    getWorkspaceIntegrationsData({ workspaceId })
      .then((data) => {
        setSessions(data.workspace.sessions);
        const integrationsMap = new Map(data.workspace.integrations.map(i => [i.id, i]));
        setIntegrations(integrationsMap);
        // Reset widget data so it has accurate state.
        if (widgetConfig?.data?.settings) {
          setWidgetConfig(prev => {
            const newWidgetConfig = { ...prev };
            newWidgetConfig.data.settings = integrationsMap.get(widgetConfig.data.settings.id);
            return newWidgetConfig;
          });
        }
      })
      .catch(handlePageErrors)
      .finally(() => {
        setRefresh(false);
      });
  }, [refresh]);

  const canEditSettings = useEditorRole();
  if (canEditSettings !== undefined && !canEditSettings) handlePageErrors('Unknown', new Error('Access denied'));

  return (
    <>
      <Bureau className={classes.Bureau} data-testid="Settings:Integrations">
        <Shelf>
          <div className={panelClasses.SettingsPanel}>
            <h1>Workspace Integrations</h1>

            <ApiKeysSection
              className={panelClasses.Section}
              sessions={sessions}
              setCreateApiKeyDialogOpen={setCreateApiKeyDialogOpen}
              setRevokeApiKey={setRevokeApiKey}
            />

            <IntegrationsSection
              addIntegrationList={addIntegrationList}
              className={panelClasses.Section}
              deleteIntegrationList={deleteIntegrationList}
              integrations={integrations}
              setWidgetConfig={setWidgetConfig}
            />
          </div>
        </Shelf>

        <Drawer
          className={classes.Drawer}
          modal="backdropped"
          onClose={() => setWidgetConfig(undefined)}
          open={widgetConfig?.type === 'drawer'}
        >
          {(widgetConfig?.type === 'drawer' && widgetConfig?.data?.settings?.accountType) && (
            <IntegrationsSettingsDrawer
              lists={widgetConfig.data.lists}
              onAdd={widgetConfig.data.onAdd}
              onUnlink={widgetConfig.data.onUnlink}
              settings={widgetConfig.data.settings}
            />
          )}
        </Drawer>
      </Bureau>

      <CreateApiKeyDialog
        open={createApiKeyDialogOpen}
        setOpen={setCreateApiKeyDialogOpen}
        setRefresh={setRefresh}
      />

      <RevokeApiKeyDialog
        open={Boolean(revokeApiKey)}
        session={revokeApiKey}
        setOpen={setRevokeApiKey}
        setRefresh={setRefresh}
      />
    </>
  );
}
WorkspaceIntegrationsPage.displayName = 'WorkspaceIntegrationsPage';

const handle = {
  title: 'Workspace Integrations',
};

export {
  WorkspaceIntegrationsPage as Component,
  handle,
};

