import type { OrbiitAPI } from '…/app/state/api.d.ts';
import type { WorkspaceAdmin } from '…/app/state/states.d.ts';

import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { workspacesStore } from './workspaces.store.mts';

import { gotTeammates } from './gotTeammates.mts';
import { UPSERT_TEAMMATE } from './upsertTeammate.gql.mjs';


export function removeTeammates(workspaceId: ObjectId, primaryEmails: string[]) {
  return Promise.all(
    primaryEmails.map((userEmail) => gqlOperation<{
      workspace: {
        teammates: OrbiitAPI.Teammate[],
      },
    }>(UPSERT_TEAMMATE, {
      userEmail,
      workspaceId,
    })),
  )
    .then(([finalResult]): WorkspaceAdmin['teammates'] => gotTeammates(finalResult.workspace.teammates))
    .then((processedTeammates) => {
      const wTeammates = workspacesStore.get(workspaceId)!.teammates;

      wTeammates.clear();
      for (const [id, t] of processedTeammates.entries()) wTeammates.set(id, t);

      return processedTeammates;
    });
}
