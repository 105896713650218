import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import UPDATE_CONVERSATION_GUIDE from './updateConversationGuide.gql';


export function updateConversationGuide({
  conversationGuide: {
    id,
    ...conversationGuide
  },
}) {
  return gqlOperation(UPDATE_CONVERSATION_GUIDE, {
    conversationGuide,
    conversationGuideId: id,
  })
    .then((data) => ({
      engagement: { conversationGuide: data.conversationGuide },
    }));
}
