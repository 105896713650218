import { CHIP_VARIANTS } from '…/app/w/workspace/common/Chip/chip-options.mts';

import type { MatchMeta } from '../Match.d.ts';


export const qualityToLabel = new Array(
  'Don’t match', // 0
  'Poor', // 1
  'Neutral', // 2
  'Good', // 3
  'Great', // 4
  'Perfect', // 5
);

export function qualityToSentiment(q: MatchMeta['quality']) {
  if (q < 2) return CHIP_VARIANTS.NEGATIVE;
  if (q > 3) return CHIP_VARIANTS.POSITIVE;
  if (q === 2) return CHIP_VARIANTS.NEUTRAL;
  return CHIP_VARIANTS.INFORMATIVE;
}
