.StatusIcon {
  border-radius: 50%;
  padding: 0 2px 1px;
}

.StatusIcon[status="super"] {
  background: #FFFBEB;
  color: #FBBF24;
}

.StatusIcon[status="repeat"] {
  background: #ECFDF5;
  color: #047857;
}

.StatusIcon[status="referral"],
.StatusIcon[status="new"] {
  background: #E8EDFB;
  color: #173EAD;
}
