import type { HTTPError } from 'ky';

import type { EngagementRouteParams } from '…/app/route-params.mts';
import type { OrbiitAPI } from '…/app/state/api.d.ts';

import type { PaginatedRecords, PaginationProps } from '…/app/common/pagination/pagination.d.ts';
import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { transformMatchesToGroups } from './transformMatchesToGroups.mts';
import GET_PAGINATED_MATCHES from './getPaginatedMatches.gql';


export function fetchMatchGroups(
  engagementId: EngagementRouteParams['engagementId'],
  workspaceId: EngagementRouteParams['workspaceId'],
  paginationProps: PaginationProps = {} as PaginationProps,
) {
  return gqlOperation<{
    engagement: {
      matches: PaginatedRecords<OrbiitAPI.Match>,
      matchesCount: Int,
    },
  }>(GET_PAGINATED_MATCHES, {
    engagementId,
    workspaceId,
    ...paginationProps,
  })
    .catch((err: HTTPError) => {
      throw Object.assign(
        new AggregateError(
          new Array(err),
          'Couldn’t retrieve matches',
        ),
        { code: err?.response.status },
      );
    })
    .then((data) => (transformMatchesToGroups(data.engagement.matches)));
}
