import type { ReactNode } from 'react';

import classes from './Callout.module.css';


export function Callout({ children, type = 'warn' }: { children: ReactNode, type: Type }) {
  return (
    <blockquote
      className={classes.Callout}
      // @ts-ignore
      type={type}
    >
      {children}
    </blockquote>
  );
}
Callout.displayName = 'Callout';

const TYPES = {
  AFFIRM: 'affirm',
  ERROR: 'error',
  INFO: 'info',
  WARN: 'warn',
} as const;
type Type = typeof TYPES[keyof typeof TYPES];

Callout.TYPES = TYPES;
