import type { CSSProperties } from 'react';
import Form from 'form5/react/Form';
import { useState } from 'react';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';

import type { AudienceId } from '../../audiences/audience/Audience.d.ts';
import { AudienceSelectionList } from './AudienceSelectionList.tsx';


export interface AudienceSelectionFormProps {
  all?: boolean,
  className?: HTMLElement['className'],
  maxHeight?: true | CSSProperties['maxHeight'],
  multiple?: boolean,
  onSubmit(
    delta: AudienceSelectionFormProps['multiple'] extends true
      ? { audienceId: AudienceId[] }
      : { audienceId: AudienceId },
    all: object,
  ): Promise<unknown>,
  selected?: Set<AudienceId>,
}

export function AudienceSelectionForm({
  all,
  maxHeight,
  multiple,
  selected = new Set(),
  ...props
}: AudienceSelectionFormProps) {
  const [isSubmitting, setSubmitting] = useState(false);
  const inputType = multiple ? 'checkbox' : 'radio';

  const onSubmit: AudienceSelectionFormProps['onSubmit'] = (...args) => {
    setSubmitting(true);

    return props.onSubmit(...args).finally(() => setSubmitting(false));
  };

  return (
    <Form {...props} onSubmit={onSubmit}>
      <h1>Select {multiple ? 'audiences' : 'an audience'}</h1>

      <fieldset style={{
        maxHeight: maxHeight === true ? '50vh' : maxHeight,
        overflowY: 'auto',
      }}
      >
        <AudienceSelectionList selected={selected} type={inputType} />
      </fieldset>

      <SubmitButton isSubmitting={isSubmitting} type="submit">Select</SubmitButton>
    </Form>
  );
}
AudienceSelectionForm.displayName = 'AudienceList';
