import PropTypes from 'prop-types';
import { clsx } from 'clsx';

import Button from 'form5/react/Button';

import { DotLoader } from '…/app/common/DotLoader/DotLoader.tsx';

import classes from './SubmitButton.module.css';

const {
  bool, string, node,
} = PropTypes;

export function SubmitButton({
  className = '',
  children,
  isSubmitting = false,
  ...props
}) {
  return (
    <Button
      className={clsx(className, isSubmitting && classes.Submitting)}
      {...props }
    >
      {isSubmitting
        ? <DotLoader className={className} count={3} />
        : children
      }
    </Button>
  );
}

SubmitButton.displayName = 'SubmitButton';

SubmitButton.propTypes = {
  children: node,
  className: string,
  isSubmitting: bool,
};
