import type {
  Dispatch,
  SetStateAction,
} from 'react';
import Field, { type FieldProps } from 'form5/react/Field';


export type DeSelectAllState<ID = ObjectId> = Map<ID, boolean>;

export function DeSelectAll({
  selected,
  setSelected,
}: {
  selected: DeSelectAllState,
  setSelected: Dispatch<SetStateAction<DeSelectAllState>>,
}) {
  return (
    <Field
      checked={!areAnyUnselected(selected)}
      label={null}
      name="de/selectAll"
      onChange={() => onUpdateSelection(selected, setSelected)}
      title="select all"
      type="checkbox"
    />
  );
}
DeSelectAll.displayName = 'De/SelectAll';

export function onUpdateSelection(
  selected: DeSelectAllState,
  setSelected: Dispatch<SetStateAction<DeSelectAllState>>,
  valueForAll = areAnyUnselected(selected),
) {
  setSelected((prev) => {
    const updated = new Map(prev);

    for (const memberId of updated.keys()) updated.set(memberId, valueForAll);

    return updated;
  });
}

export function areAnySelected(selected: DeSelectAllState) {
  const vals = new Set(selected.values());
  if (!vals.size) return false;
  return vals.has(true);
}
export function areAnyUnselected(selected: DeSelectAllState) {
  const vals = new Set(selected.values());
  if (!vals.size) return true;
  return vals.has(false);
}

export function SelectCheckbox({
  ariaLabel,
  checked = false,
  id,
  name,
  onChange,
}: {
  ariaLabel: string,
  checked: boolean,
  id: string,
  name: string,
  onChange: FieldProps['onChange'],
}) {
  return (
    <Field
      aria-label={ariaLabel}
      checked={checked}
      id={id}
      label={null}
      name={name}
      onChange={onChange}
      type="checkbox"
    />
  );
}
SelectCheckbox.displayName = 'SelectCheckbox';
