import { clsx } from 'clsx';
import Field from 'form5/react/Field';

import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';

import type { FreeResponseQuestionProps } from './ParticipantQuestions.d.ts';


export const FreeResponseQuestion = ({
  data: {
    ledeIn = '',
    lockedProps = new Array(),
    title = '',
  } = {}, // Some questions are initialised without `props`
  disabled,
  heading,
  id,
  onChange,
  required,
}: FreeResponseQuestionProps) => (
  <fieldset className={clsx(editableSectionClasses.PanelSection, 'HollowWrapper')} name={id}>
    <legend className={editableSectionClasses.FieldsetHeading}>
      <h2 id={id}>{heading}</h2>

      <Field
        aria-label="is disabled"
        arrangement={Field.ARRANGEMENTS.STAND_ALONE}
        checked={disabled ?? false}
        className={editableSectionClasses.InvertedToggle}
        id={`${id}.isDisabled`}
        label={null}
        name="isDisabled"
        onChange={onChange}
        readOnly={required}
        type="checkbox"
        variant="toggle"
      />
    </legend>

    <fieldset disabled={disabled} name="props">
      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        fluid
        id={`${id}.props.title`}
        label="Question title"
        name="title"
        onChange={onChange}
        readOnly={lockedProps.includes('title')}
        required
        value={title ?? ''}
      />

      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        as="textarea"
        fluid
        id={`${id}.props.ledeIn`}
        label="Question description"
        name="ledeIn"
        onChange={onChange}
        readOnly={lockedProps.includes('ledeIn')}
        value={ledeIn ?? ''}
      />
    </fieldset>
  </fieldset>
);
FreeResponseQuestion.displayName = 'FreeResponseQuestion';
