import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { CREATE_ENGAGEMENT } from './createEngagement.gql.mjs';


export function createEngagement({
  engagement,
  workspaceId,
}) {
  return gqlOperation(CREATE_ENGAGEMENT, {
    engagement,
    workspaceId,
  })
    .then((data) => data.engagement);
}
