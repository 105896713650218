export function resequence<T>(prev: T[], dragged: { from: number, to: number }) {
  const itemDragged = prev[dragged.from];
  const remainingItems = prev.filter((item, index) => index !== dragged.from);

  const newSequence = [
    ...remainingItems.slice(0, dragged.to),
    itemDragged,
    ...remainingItems.slice(dragged.to),
  ];

  return newSequence;
}
