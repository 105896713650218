._EnumeratedUpdateTargets_2vb2e_1 {
  display: grid;
  gap: var(--grid-gutter-half);
  grid-template-columns: repeat(3, 1fr);
}

._Fieldset_2vb2e_7 {
  grid-template-columns: 1fr 1fr;
}

._Name_2vb2e_11 {
  background: var(--neutral-lightest);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  display: block;
  margin-bottom: var(--grid-gutter-half);
  padding: var(--grid-gutter-half);
}

._FullField_2vb2e_20 {
  grid-column: 1 / -1;
}

._CollapsingHeader_2vb2e_24 {
  cursor: pointer;
  margin: var(--grid-gutter) 0;
}

._ListInput_2vb2e_29 {
  display: grid;
  gap: inherit;
  grid-template-columns: auto min-content;
}

._AddFieldCTA_2vb2e_35 {
  place-self: start;
}
