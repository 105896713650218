import type { DeSelectAllState } from '…/app/w/workspace/common/DeSelectAll/DeSelectAll.tsx';


export function getSelectedItems<Collection extends Map<unknown, unknown>>(
  selected: DeSelectAllState,
  collection: Collection,
) {
  const selectedItems: Collection = new Map();

  for (const [id, isSelected] of selected.entries()) {
    if (isSelected) selectedItems.set(id, collection.get(id)!);
  }

  return selectedItems;
}
