import { useParams } from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import type { FLAGS } from './FLAGS.mts';


export function useFeatureFlag(flags: FLAGS[]) {
  const { workspaceId } = useParams() as WalledRouteParams;
  const { isGlobalAdmin } = useStore((data) => data.user);
  const { features } = useStore((data) => data.workspaces[workspaceId]);

  if (!features) { return }

  if (isGlobalAdmin) { return true }

  for (const flag of flags) if (features.has(flag)) { return true }

  return false;
}
