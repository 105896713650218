.SetupGuide {
  align-items: center;
  display: grid;
  gap: var(--grid-gutter);
  grid-template-areas: "Steps Image Guide";
  grid-template-columns: 1.5fr auto 1.5fr;
  position: relative;
}

.CloseButton {
  position: absolute;
  right: 0;
  top: 0;
}

.Steps {
  grid-area: Steps;
  place-self: start;
}

.Steps [aria-current="true"] {
  font-weight: bold;
}

.Img {
  grid-area: Image;
}

.Guide {
  grid-area: Guide;
}

.Title,
.GuideLink {
  font-size: 1.4em;
}

.GuideLink {
  color: inherit;
  font-weight: bold;;
}
