import { formatDate } from '…/app/common/dateUtils.mts';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

import type { EmailEvent } from './fetchEmailStatistics.mts';
import classes from './EmailStatRow.module.css';

export function EmailStatRow({ stat }: { stat: EmailEvent }) {
  return (
    <div className={classes.EmailEventRow}>
      <div><Chip>{stat.event}</Chip></div>

      <div>{stat.email}</div>

      <div>{formatDate(stat.lastTimestamp, null, true)}</div>
    </div>
  );
}
EmailStatRow.displayName = 'EmailStatRow';
