.Page {
  display: grid;
  gap: inherit;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: min-content;
}

.WithSidebar {
  grid-template-areas:
    "Header Header"
    "Sidebar Sidebar"
    "Editor Editor";
}

.WithoutSidebar {
  grid-template-areas:
    "Header Header"
    "Editor Editor";
}

@media (width > 960px) {
  .WithSidebar {
    grid-template-areas:
      "Header Header"
      "Editor Sidebar";
  }
}

.Editor {
  align-self: start;
  grid-area: Editor;
}

.Sidebar {
  display: flex;
  flex-direction: column;
  gap: inherit;
  grid-area: Sidebar;
}

@media (width <= 960px) {
  .Sidebar {
    max-height: 11lh; /* Just a bit more than 1 card so users can see there's more */
    overflow-y: auto;
  }
}

.Unmatched {
  align-content: start;
  flex: 1;
}

.Group {
  background-color: var(--neutral-lightest);
}

.Group:focus {
  background-color: var(--brand-subtle-light);
}

.GroupSummary {
  display: grid;
  grid-template-columns: min-content auto min-content max-content min-content;
}

.Matches,
.Unmatched {
  grid-template-columns: repeat(auto-fill, minmax(22ch, 1fr));
}

.Matches:empty,
.Unmatched:empty {
  background-color: var(--brand-light);
  border: 0.1em dashed var(--brand-accent-primary);
  border-radius: var(--border-radius);
  min-height: 2lh;
}

.Loading {
  opacity: 0.6;
}

.UnmatchedWarning {
  color: var(--status-warning);
}

.Btn {
  padding: unset;
}

.AnnotatedText {
  border-bottom: 1px dotted;
}
