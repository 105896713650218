mutation DeleteFeedbackCustomQuestion(
  $workspaceId: ID!,
  $engagementId: ID!,
  $questionId: ID!,
) {
  question: deleteEngagementFeedbackCustomQuestion(
    workspaceId: $workspaceId
    engagementId: $engagementId
    feedbackQuestionId: $questionId
  ) {
    id
  }
}
