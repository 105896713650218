import _mergeWith from 'lodash-es/mergeWith.js';
import { useEffect, useState } from 'react';
import {
  useOutletContext,
  useMatches as useRouteMatches,
} from 'react-router-dom';

import type {
  EngagementContainerData,
  EngagementOutlet,
  EngagementViewConfig,
  EngagementViewData,
} from './EngagementView.d.ts';
import type { Engagement } from '../Engagement.d.ts';
import { engagementParams } from '../engagementParams.mts';


/**
 * Tracks generic engagement data for all views (via the router), page-specific data, and errors.
 *
 * **This should be used by only `<EngagementContainer>`.**
 *
 * @returns A useState-like tuple containing the section’s state and update function.
 */
export function useContainerState<E>() {
  const containerData = (() => {
    const routeMatches = useRouteMatches();
    const stepsUp = 4 - routeMatches.length; // 4 = '/' (1) + 'w' (1) + workspaceId (1) + 'engagements' (1)
    return routeMatches.at(stepsUp)!.data as EngagementContainerData<E>;
  })();

  const pageData = (useRouteMatches().at(-1)!.data ?? pageDataPlaceholder);
  const [state, setState] = useState(() => _mergeWith({}, containerData, pageData, mergePageData));

  // `genericData` doesn’t change, and since it’s the result of a spread, it’s a new object (with
  // the same contents) every time. That would cause an endless loop if watched by `useEffect`.
  // `pageData` does change (on every navigation, and when `setPageProps` is called).
  // This unfortunately leads to an extraneous re-render, but it's necessary to keep `state` updated.
  useEffect(() => {
    if (pageData !== containerData) setState((prev) => _mergeWith({}, prev, pageData, mergePageData));
  }, [pageData]);

  return [
    // Re-computing this via useEffect + setState adds an extra cycle, during which time the page
    // component tries to render, but its data is queued for the _next_ render cycle, causing
    // rendering to fail.
    // This may lead to a split-second discrepency where state data incorrectly trumps data just
    // loaded from the API; but that will be corrected in the incoming cycle triggered by useEffect
    // (which corrects the discrepency).
    _mergeWith({}, containerData, pageData, state, mergePageData),
    setState,
  ];
}

const mergePageData = (destValue: unknown, incomingValue: unknown, key: string) => {
  // Some fields are not merge-able, like email body's.
  // Perhaps we should merely check for _any_ array, and always take the incoming instead of letting
  // lodash try to merge the arrays?
  if (key === 'bodyJson' || key === 'json') return incomingValue;
};

const pageDataPlaceholder = { errors: new Array() };

/**
 * Whether "Engagement Sidebar" & "Engagement Header" should be present.
 */
export function useContainerLayout() {
  return (useRouteMatches().at(-1)?.handle ?? {}) as EngagementViewConfig;
}

/**
 * **This should be used by route children of `<EngagementContainer>`.**
 *
 * @returns A useState-like tuple containing the section’s state and update function.
 */
export function useViewState<E = Engagement>(): EngagementViewData<E> {
  // ! `params` must be within the view hook (NOT useContainerState) to avoid becoming stale
  const params = engagementParams();
  const context = useOutletContext() as EngagementOutlet<E>;

  if (!context) throw Error(USE_VIEW_STATE_INVALID_INVOCATION);

  const {
    className,
    pageProps,
    setPageProps,
  } = context;

  return [
    {
      ...pageProps,
      className,
      params,
    },
    setPageProps,
  ];
}

const USE_VIEW_STATE_INVALID_INVOCATION = '\
A component calling useViewState must be a child of `<EngagementContainer>` that is rendered via \
`<ReactRouter.Outlet>`.\
';
