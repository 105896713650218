import type { Component } from 'react';

import { ObjectId } from 'bson';

import type { Engagement } from '../../Engagement.d.ts';

import type {
  Match,
  OptIn,
  OptIns,
} from './Match.d.ts';
import type { MatchEditingState } from './EditMatches2.tsx';


export function addGroup(
  setState: Component<object, MatchEditingState>['setState'],
  product: Engagement['product'],
) {
  setState(({ groups }) => {
    const matchId = (new ObjectId()).toHexString() as Match['id'];
    const updated = new Map([
      [
        matchId, {
          id: matchId,
          optins: new Map(),
          product,
          scheduling: {},
        } as Match,
      ],
      ...groups,
    ]);

    return { groups: updated };
  });
}

export function removeFromGroup(
  matchId: Match['id'],
  optin: OptIn,
  setState: Component<object, MatchEditingState>['setState'],
) {
  setState(({ groups }) => {
    const updated = new Map(groups);
    const group = updated.get(matchId)!;
    const optins = new Map(group.optins);
    optins.delete(optin.id);
    group.optins = optins;

    return { groups: updated };
  });
}

export function addToPool(
  optins: OptIn | OptIns,
  setState: Component<object, MatchEditingState>['setState'],
) {
  if (!(optins instanceof Map)) optins = new Map([[optins.id, optins]]);

  setState(({ available }) => {
    const updated = new Map(available);
    for (const [optinId, optin] of (optins as OptIns).entries()) updated.set(optinId, optin);

    return { available: updated };
  });
}
export function removeFromPool(
  optins: OptIn | OptIns,
  setState: Component<object, MatchEditingState>['setState'],
) {
  if (!(optins instanceof Map)) optins = new Map([[optins.id, optins]]);

  setState(({ available }) => {
    const updated = new Map(available);
    for (const optinId of (optins as OptIns).keys()) updated.delete(optinId);
    return { available: updated };
  });
}
