import engagementFrag from './engagement.frag.gql.mjs';

export const CREATE_ENGAGEMENT = `
mutation CreateEngagement($workspaceId: ID!, $engagement: EngagementInput!) {
  engagement: createEngagement(workspaceId: $workspaceId, engagement: $engagement) {
    ...engagementFields
  }
}

${engagementFrag}
`;
