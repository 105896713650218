.Bureau {
  display: flex;
  flex-direction: row;
  gap: var(--grid-gutter);
  overflow-x: clip; /* hidden inexplicably breaks Bureau’s height */
  position: relative;
}

.CloseButton {
  font-size: 1.25rem;
  padding: 0.5rem 0.75rem 0.5rem 1rem;
  position: absolute;
  top: 0;
}

.Drawer {
  border: none;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  min-height: 100%;
  z-index: 1;
}

.DrawerLiner {
  padding: calc(2 * var(--grid-gutter)) var(--grid-gutter);
}

.InlineDrawer[open],
.PopoverDrawer,
.DrawerLiner {
  max-width: 30%;
  min-width: 30ch;
  width: 50ch;
}

.InlineDrawer {
  max-width: 0;
  min-width: 0;
  overflow: hidden;
  position: relative;
  transition-duration: var(--default-transition-duration);
  transition-property:
    max-width,
    min-width,
    width;
  transition-timing-function: var(--default-transition-easing);
  width: 0;
}

.InlineDrawer:first-child .CloseButton {
  left: 0;
}

.InlineDrawer:last-child .CloseButton {
  right: 0;
}

.PopoverDrawer {
  margin: 0;
  transition-duration: var(--default-transition-duration);
  transition-property: translate;
  transition-timing-function: var(--default-transition-easing);
}

/* stylelint-disable-next-line no-descending-specificity */
.PopoverDrawer .CloseButton[variant="glyph"] { /* variant="glyph" needed for specificity */
  background-color: inherit;
}

.PopoverDrawer:not([open]) .CloseButton {
  display: none;
}

.PopoverDrawer:first-child {
  box-shadow: 3px 3px 6px -2px var(--text-muted);
  margin-right: 100%;
  translate: -100%;
}

.PopoverDrawer:first-child .CloseButton {
  border-radius:
    0
    0
    var(--border-radius)
    0;
  box-shadow: 3px 3px 6px -2px var(--text-muted);
  right: 0;
  translate: 100%;
}

.PopoverDrawer[open]:first-child {
  translate: 0;
}

.PopoverDrawer:last-child {
  box-shadow: -3px 3px 6px -2px var(--text-muted);
  margin-left: 100%;
  translate: 0;
}

.PopoverDrawer[open]:last-child {
  translate: -100%;
}

/**
 * NOTE: When there is one close-button it will be both first and
 * last child and last child will be used.
 */
.PopoverDrawer:last-child .CloseButton {
  border-radius:
    0
    0
    0
    var(--border-radius);
  box-shadow: -3px 3px 6px -2px var(--text-muted);
  left: 0;
  translate: -100%;
}

.Backdrop {
  background: rgb(0 0 0 / 25%);
  inset: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition-duration: var(--default-transition-duration);
  transition-property: opacity;
  transition-timing-function: var(--default-transition-easing);
}

.Backdrop[open] {
  opacity: 1;
  pointer-events: all;
}

.Shelf {
  flex: 1;
}
