import type {
  Dispatch,
  SetStateAction,
} from 'react';
import { clsx } from 'clsx';
import Button from 'form5/react/Button';

import { formatDate } from '…/app/common/dateUtils.mts';
import { OrbiitIcon } from '…/app/deprecated/ODS/OrbiitIcon/index.jsx';

import { MatchIcon } from '…/app/common/Icon/MatchIcon.tsx';
import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import { DragHandle } from '…/app/w/workspace/engagements/engagement/setup/DragNDrop/DragHandle.tsx';

import type {
  Match,
  MatchMeta,
  OptIn,
} from '../Match.d.ts';
import type { DragInfo } from '../onDrag.mts';
import * as dragHandlers from '../onDrag.mts';

import { qualityToLabel, qualityToSentiment} from './meta-utils.mts';
import classes from './MatchCard.module.css';


type ParticipantFields = NonNullable<OptIn['member']['fields']>;

export function MatchCard({
  matchId,
  meta,
  onRemove,
  optin,
  readOnly,
  setDragging,
  setMemberToInspect,
  showAvailability,
}: {
  matchId?: Match['id'],
  meta: MatchMeta,
  onRemove?: (matchId: Match['id'], optin: OptIn) => void,
  optin: OptIn & { member: { fields: ParticipantFields } },
  readOnly?: boolean,
  setDragging: Dispatch<SetStateAction<DragInfo>>,
  setMemberToInspect: Dispatch<SetStateAction<{ id: OptIn['member']['id'], fields: OptIn['member']['fields'] }>>,
  showAvailability: boolean,
}) {
  const {
    fields: {
      timeslotsUtc,
      timezoneIso,
    },
    id: optinId,
    member: {
      fields,
      id: memberId,
    },
  } = optin;
  const {
    company,
    role,
    firstName,
    lastName,
    fullName = `${firstName} ${lastName}`,
  } = fields;

  return (
    <hgroup
      className={clsx('Card', classes.MatchCard)}
      draggable={!readOnly}
      onDragStart={(event) => dragHandlers.onDragStart(event, optin, setDragging)}
    >
      <h4 className={classes.Name}>
        {fullName}

        {' '}

        <Button
          className={classes.Btn}
          onClick={() => setMemberToInspect({
            fields,
            id: memberId,
          })}
          variant={Button.VARIANTS.GLYPH}
        >
          <OrbiitIcon
            icon="MatchEye"
            inline
            size="large"
            title="View match history"
          />
        </Button>
      </h4>

      {role && (<p className={classes.Role}>{role}</p>)}

      {company && (<p className={classes.Company}>@ {company}</p>)}

      {!readOnly && (
        <>
          {matchId && onRemove && (
            <Button
              appearance={Button.APPEARANCES.DANGER}
              className={clsx(classes.RemoveBtn, classes.Btn)}
              onClick={() => onRemove(matchId, optin)}
              variant={Button.VARIANTS.GLYPH}
            >
              <MatchIcon match={MatchIcon.MATCH.SET} title="Remove from match" />
            </Button>
          )}

          <Button
            appearance={Button.APPEARANCES.DANGER}
            className={classes.DragHandle}
            variant={Button.VARIANTS.GLYPH}
          >
            <DragHandle />
          </Button>

          {meta && (
            <div className={classes.Meta}>
              <Chip variant={qualityToSentiment(meta.quality)}>
                {qualityToLabel[meta?.quality]}
              </Chip>

              {!meta?.hasMetBefore && (<Chip>First time meeting</Chip>)}
            </div>
          )}
        </>
      )}

      {timeslotsUtc?.length && (
        <ul className={classes.Availability} data-open={showAvailability ? '' : null}>
          {timeslotsUtc?.map((timeslot) => (<li key={timeslot}>{formatDate(timeslot, timezoneIso, true)}</li>))}
        </ul>
      )}

      <input
        key={optinId}
        name="optinIds[]"
        type="hidden"
        value={optinId}
      />
    </hgroup>
  );
}
MatchCard.displayName = 'MatchCard';
