import { clsx } from 'clsx';
import propTypes from 'prop-types';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

import classes from './TimelineCard.module.css';


export function TimelineCard({
  blurb,
  dates,
  icon,
  target,
  title,
}) {
  const Title = (<h4 className={classes.Title}>{title}</h4>);
  const datetimes = Array.isArray(dates) ? dates : [dates];
  const isSet = !!datetimes[0]?.length;

  return (
    <a
      className={clsx(
        classes.TimelineCard,
        {
          // 'Card': isSet,
          [classes.IsSet]: isSet,
          [classes.IsUnset]: !isSet,
        },
      )}
      href={`#${target}`}
    >
      {isSet
        ? (
          <>
            <div className={classes.DateTimes}>
              {datetimes.map((datetime) => {
                if (!datetime) return null;

                return (
                  <span className={classes.DateTime} key={`${title} ${datetime}`}>
                    <IIcon name={icon} />

                    <time dateTime={datetime}>
                      {datetime}
                    </time>
                  </span>
                );
              })}
            </div>

            <div className={classes.Divider} />

            <div className={classes.Copy}>
              {Title}

              <p className={classes.Blurb}>{blurb}</p>
            </div>
          </>
        )
        : Title
      }
    </a>
  );
}
TimelineCard.displayName = 'TimelineCard';

const {
  arrayOf,
  oneOfType,
  string,
} = propTypes;
TimelineCard.propTypes = {
  blurb: string,
  dates: oneOfType([
    arrayOf(string),
    string,
  ]),
  icon: string,
  target: string.isRequired,
  title: string.isRequired,
};
