import type {
  CSSProperties,
  DOMAttributes,
  ElementType,
  HTMLAttributes,
  ReactNode,
} from 'react';
import { clsx } from 'clsx';
import {
  useEffect,
  useState,
} from 'react';

import styles from './Popover.module.css';


const POSITIONS = {
  BOTTOM_LEFT: 'bottom left',
  BOTTOM_MIDDLE: 'bottom middle',
  BOTTOM_RIGHT: 'bottom right',
  LEFT: 'left',
  RIGHT: 'right',
  TOP_LEFT: 'top left',
  TOP_MIDDLE: 'top middle',
  TOP_RIGHT: 'top right',
} as const;
type POSITIONS = typeof POSITIONS[keyof typeof POSITIONS];

const TYPES = {
  MENU: 'menu',
  POPOVER: 'popover',
  TOOLTIP: 'tooltip',
} as const;
type TYPES = typeof TYPES[keyof typeof TYPES];

const PERSIST_OPEN_TYPES = new Set([
  TYPES.MENU,
  TYPES.POPOVER,
]);

export function Popover({
  as: Tag,
  anchor: Anchor,
  children: content,
  contentClassName,
  containerClassName,
  minimal,
  open = false,
  position = Popover.POSITIONS.TOP_MIDDLE,
  style,
  type = Popover.TYPES.POPOVER,
  ...others
}: {
  as?: ElementType,
  anchor: ElementType,
  children?: ElementType | ReactNode,
  contentClassName?: string,
  containerClassName?: string,
  minimal?: boolean,
  open?: boolean,
  position?: POSITIONS,
  style?: CSSProperties,
  type?: TYPES,
} & HTMLAttributes<HTMLElement>) {
  const [isOpen, setOpen] = useState(open);

  /**
   * Any click that makes it to the window’s listener is outside the popover.
   */
  function closeOnOutsideClick() {
    setOpen(false);
  }

  /**
   * Stop event propegation so it does not reach the `click` listener on window.
   */
  function isolateInsideClick(e: MouseEvent) {
    e.stopPropagation();
  }

  useEffect(() => {
    if (type !== 'menu') return;

    if (isOpen) window.addEventListener('click', closeOnOutsideClick);

    return () => window.removeEventListener('click', closeOnOutsideClick);
  }, [isOpen]);

  if (!content) {
    return (
      <Anchor className={clsx(styles.PopoverAnchor, containerClassName)} />
    );
  }

  if (type === TYPES.MENU) Tag ??= 'menu';
  else Tag ??= 'aside';

  const eventProps: Partial<DOMAttributes<HTMLElement>> = {
    onKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          setOpen(true);
          break;
        case 'Clear':
        case 'Escape':
          setOpen(false);
          event.currentTarget.blur();
          break;
      }
      others?.onKeyDown?.(event);
    },
    ...(PERSIST_OPEN_TYPES.has(type) && {
      onClick(event) {
        setOpen(!isOpen);
        others?.onClick?.(event);
      },
    }),
  };

  const Content = isComponent(content) && content;

  return (
    <span
      className={clsx(styles.PopoverContainer, containerClassName)}
      minimal={minimal ? '' : null}
      type={type}
    >
      <Anchor
        tabIndex={0} // Make it focusable
        {...others}
        className={styles.PopoverAnchor}
        {...eventProps}
      />

      {(type === TYPES.TOOLTIP || isOpen) && ( // Render contents only when open to avoid accessibility traps
        <Tag
          className={clsx(
            styles.Popover,
            styles[position],
            contentClassName,
          )}
          onClick={isolateInsideClick}
          open={isOpen}
          position={position}
          role={TYPE_TO_ROLE[type]}
          style={style}
        >
          {Content
            ? <Content open={isOpen} />
            : content
          }
        </Tag>
      )}
    </span>
  );
}
Popover.displayName = 'Popover';
Popover.POSITIONS = POSITIONS;
Popover.TYPES = TYPES;

function isComponent(e: unknown): e is ElementType {
  return typeof e === 'function';
}

const TYPE_TO_ROLE = {
  [TYPES.MENU]: 'menu',
  [TYPES.POPOVER]: 'tooltip',
  [TYPES.TOOLTIP]: 'tooltip',
};
