import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import type { User } from '../states.d.ts';
import { onError } from '../errors/errors.state.mts';

import { GET_ME } from './getMe.gql.mjs';
import { didntGotMe, gotMe } from './gotMe.mts';


export function getMe() {
  return gqlOperation<{
    me?: User,
  }>(GET_ME)
    .then(gotMe)
    .catch(onError)
    .catch(didntGotMe);
}
