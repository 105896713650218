import _get from 'lodash-es/get.js';
import type { Options as KyOptions } from 'ky';
import { HTTPError } from 'ky';


import { rpc } from './client.mts';
import { getGQLOperationProps } from './getGQLOperationProps.mts';
import { shouldRedirectAuthFailure } from './shouldRedirectAuthFailure.mts';


interface EventData {
  name?: string;
  data?: string;
}

export interface GQLRequestOptions {
  cacheOption?: RequestCache;
  eventData?: EventData;
}

/* eslint-disable max-params */
export async function gqlOperation<ResponseData = unknown>(
  operation: string,
  variables?: object,
  options?: GQLRequestOptions,
): Promise<ResponseData> {
  const op = operation.trim(); // remove extra whitespace from string templates

  const {
    keys,
    name: operationName,
  } = getGQLOperationProps(op);

  if (!operationName?.length) {
    throw new Error(`[RPC Client :: GQL]: Operation name is required but is missing.\n${op}`);
  }

  const reqConfig: KyOptions = {
    cache: options?.cacheOption || 'default',
    headers: {
      ...(options?.eventData?.name && {
        'orbiit-event-data': options.eventData.data,
        'orbiit-event-name': options.eventData.name,
      }),
    },
    json: {
      operationName,
      query: op,
      variables,
    },
  };

  return rpc
    .post('graphql', reqConfig)
    .json<GQLResponse<ResponseData>>()
    .then(({
      data,
      errors,
    }) => {
      // FIXME: after https://orbiitai.atlassian.net/browse/ORB-1209 lands
      // consume for https://orbiitai.atlassian.net/browse/ORB-1459
      // also update spec
      // errorsState.set(reqConfig, new AggregateError(collectErrors(errors)));

      if (errors) return Promise.reject(new AggregateError(collectErrors(errors)));

      if (keys) {
        const missing = [];
        for (const key of keys) if (!_get(data, key)) missing.push(new Error(`"${key}" has no data`));

        if (missing.length) return Promise.reject(new AggregateError(missing, `Errant response to "${operationName}"`));
      }

      return data;
    })
    .catch((error) => {
      if (error.name === 'TimeoutError') { // FIXME: Does not hit the ServiceWorker due to Chromium#1451746
        return Promise.reject(new HTTPError(
          new Response(undefined, {
            status: 588,
            statusText: 'Response timeout',
          }),
          new Request('graphql', reqConfig as RequestInit),
          // @ts-ignore
          {},
        ));
      }

      return Promise.reject(error);
    })
    .catch(async (err: AggregateError | HTTPError | TypeError) => {
      if (
        err instanceof AggregateError
        || err instanceof TypeError
      ) {
        return Promise.reject(err);
      }

      const searchParams = new URLSearchParams(location.search);
      const rsp = err.response;

      if (
        rsp.status === 401
        || rsp.status === 499
      ) {
        if (shouldRedirectAuthFailure({
          ...location,
          searchParams,
        })) {
          location.assign(`/?redirectUri=${encodeURIComponent(location.href)}`);
        }
      } else {
        const message = await rsp.text();

        if (message) throw new AggregateError([err], message); // ex 'ORB: abc'

        const { errors } = await rsp.json().catch(() => ({}));

        if (errors?.length) throw new AggregateError(collectErrors(errors));
      }

      throw err;
    });
}

export function collectErrors(errs: GraphQLErrorData[]) {
  const output = new Array(errs.length);

  for (const { 0: idx, 1: err } of errs.entries()) {
    output[idx] = new GraphQLError(err);
  }

  return output;
}

export class GraphQLError extends Error {
  name = this.constructor.name;

  constructor({ locations, message }: GraphQLErrorData) {
    super();

    this.message = `${this.name}: ${message}`;

    if (locations) {
      this.stack = this.message;
      for (const { column, line } of locations) this.stack += `\n    at line ${line} column ${column}`;
    }
  }
}
/* eslint-enable max-params */

interface GraphQLErrorData {
  locations?: Array<{
    column: number,
    line: number,
  }>;
  message: string;
  path?: string[];
  [key: string]: unknown;
}

interface GQLResponse<Data> {
  data: Data,
  errors?: GraphQLErrorData[],
}

interface EventData {
  name?: string;
  data?: string;
}
