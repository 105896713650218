.Bureau {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches */
}

.Header {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
}

.Cards {
  align-items: stretch; /* Add this line */
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.SectionCard {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  margin: 0 0.25rem; /** To ensure the borders flow nicely under the StickyHeader */
}

@media (width <= 960px) {
  .SectionCard {
    flex-direction: column;
  }
}

.Content {
  display: flex;
  flex: 3;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.QuickFilterButton {
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  margin-left: 0.25rem;
  padding: 0.375rem 0.5rem;
}

.QuickFilterButton:hover {
  background: var(--brand-subtle-light);
  color: var(--brand-accent-ancillary);
}

.QuickFilterButtonSelected {
  background: var(--brand-subtle-light);
  border: 1px solid var(--brand-accent-ancillary);
  color: var(--brand-accent-dark);
}

.CardTitle {
  margin-bottom: 0.5rem;
  text-transform: capitalize;
}
