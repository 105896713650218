import { useState } from 'react';
import {
  Link,
  generatePath,
  useNavigate,
} from 'react-router-dom';

import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form, { type FormProps } from 'form5/react/Form';

import { useStore } from '…/app/state/useStore.mts';

import { Progress } from '…/app/common/Progress/Progress.tsx';
import { rpc } from '…/app/common/rpc/client.mts';

import {
  OrbiitBackground,
  orbiitBackgroundCardClassName,
  orbiitBackgroundContentClassName,
} from '…/app/common/OrbiitBackground/OrbiitBackground.tsx';


export function SandboxPage() {
  const user = useStore((data) => data.user);

  const [error, setError] = useState();
  const [stepsCompleted, setFormSuccess] = useState(0);
  const [sandboxInfo, setSandboxInfo] = useState<{ id: string, name: string }>();

  const navigate = useNavigate();

  const onSubmit: FormProps['onSubmit'] = async (_, {
    initialUser,
    templateId,
    ...all
  }) => {
    createSandbox({
      initialUsers: [initialUser],
      ...all,
    })
      .then(({ workspaceId }) => {
        setFormSuccess((prev) => prev + 1);
        return hydrateSandbox(workspaceId, templateId as string);
      })
      .then((workspaceId) => {
        setFormSuccess((prev) => prev + 1);
        setSandboxInfo({
          id: workspaceId,
          name: all.workspaceName as string,
        });
        setTimeout(() => navigate(generatePath('/w/:workspaceId', { workspaceId })), 1_000);
      })
      .catch(setError);
  };

  return (
    <OrbiitBackground>
      <div className={orbiitBackgroundContentClassName} data-testid="SandboxPage">
        <img
          alt="Orbiit.ai"
          src="https://storage.googleapis.com/orbiit-assets/logos/logo_white_fullcolor.png"
          style={{
            marginBottom: '2rem',
            maxWidth: '120px',
          }}
        />

        {!!stepsCompleted && (
          <div className={orbiitBackgroundCardClassName}>
            <Progress
              steps={2}
              stepsCompleted={stepsCompleted}
            />

            {!!sandboxInfo && (
              <p>
                Sandbox for
                {' '}

                <Link
                  to={generatePath('/w/:workspaceId', { workspaceId: sandboxInfo.id })}
                >
                  {sandboxInfo.name}
                </Link>

                {' '}
                is ready!
              </p>
            )}
          </div>
        )}

        <Form className={orbiitBackgroundCardClassName} onSubmit={onSubmit}>
          <Button.Group>
            <Field
              id="templateId.VentureCapital"
              label="Venture Capital"
              name="templateId"
              required
              type="radio"
              value="peer-connect"
              variant="cta"
            />

            <Field
              id="templateId.PaidMembership"
              label="Paid Membership"
              name="templateId"
              required
              type="radio"
              value="peer-connect"
              variant="cta"
            />

            <Field
              id="templateId.CustomerCommunity"
              label="Customer Community"
              name="templateId"
              required
              type="radio"
              value="saas-leader-connect"
              variant="cta"
            />
          </Button.Group>

          <Field
            autoComplete="organization"
            label="Workspace"
            name="workspaceName"
            placeholder="Good inc."
            required
            type="text"
          />

          <fieldset name="initialUser">
            <Field
              autoComplete="given-name"
              defaultValue={user?.profile?.firstName}
              label="First name"
              name="firstName"
              placeholder="Alex"
              required
              type="text"
            />

            <Field
              autoComplete="family-name"
              defaultValue={user?.profile?.lastName}
              label="Last name"
              name="lastName"
              placeholder="Smith"
              required
              type="text"
            />

            <Field
              autoComplete="email"
              defaultValue={user?.emails?.[0]}
              label="Email"
              name="email"
              placeholder="alex@example.com"
              required
              type="email"
            />
          </fieldset>

          <input
            name="initialFakeMembers"
            type="hidden"
            value={250}
          />

          <Button
            appearance={Button.APPEARANCES.AFFIRMING}
            type="submit"
          >
            Create sandbox
          </Button>
        </Form>

        {!!error && (
          <>
            <p>Error:</p>

            <p>{JSON.stringify(error)}</p>
          </>
        )}
      </div>
    </OrbiitBackground>
  );
}
SandboxPage.displayName = 'SandboxPage';

const handle = { showNav: false };

export {
  SandboxPage as Component,
  handle,
};

async function createSandbox(json: object) {
  return rpc.post('actions/workspace-create-sandbox', { json }).json<{ workspaceId: ObjectId }>();
}
async function hydrateSandbox(workspaceId: ObjectId, templateId: ObjectId) {
  return rpc.post('actions/workspace-sandbox-create-engagement', {
    json: {
      templateId,
      workspaceId,
    },
  }).then(() => workspaceId);
}
