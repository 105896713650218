import { clsx } from 'clsx';
import {
  useEffect,
  useState,
} from 'react';
import { get } from 'lodash-es';

import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';
import {
  Bureau,
  Shelf,
} from '…/app/common/Drawer/Drawer.tsx';
import { formatDate } from '…/app/common/dateUtils.mts';
import { OrbiitLoader } from '…/app/common/OrbiitLoader/OrbiitLoader.jsx';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';
import { BaseCard, EmptyCard } from '…/app/w/workspace/common/Cards/Cards.tsx';

import {
  type EmailEvent,
  getEngagementEmailEvents,
  getEngagementEmailStats,
  // MOCK_ENGAGEMENT_EMAIL_EVENTS,
  // MOCK_ENGAGEMENT_STATS,
} from './fetchEmailStatistics.mts';
import { EmailStatsCard } from './EmailStatsCard.tsx';
import { EmailStatRow } from './EmailStatRow.tsx';
import type { EmailStatsResponse } from './fetchEmailStatistics.mts';

import classes from './EmailStatisticsPage.module.css';

export function EngagementEmailStatisticsPage() {
  const [
    {
      engagement,
      params,
    },
  ] = useViewState();
  const { workspaceId, engagementId } = params;

  const [isLoadingEmailStats, setIsLoadingEmailStats] = useState(true);

  const [engagementEmailStats, setEngagementEmailStats] = useState(null);
  const [engagementEmailEventsStatsType, setEngagementEmailEventsStatsType] = useState('bounce');
  const [engagementEmailEventsStats, setEngagementEmailEventsStats] = useState(null);

  useEffect(() => {
    getEngagementEmailStats({ engagementId }).then((data: EmailStatsResponse) => {
      setEngagementEmailStats(data);
    })
      .finally(() => setIsLoadingEmailStats(false))
      .catch((err: AggregateError) => {
        console.error(err);
      });
    // setEngagementEmailStats(MOCK_ENGAGEMENT_STATS); // DEBUG and local dev.
  }, []);

  useEffect(() => {
    getEngagementEmailEvents({
      engagementId,
      eventType: engagementEmailEventsStatsType,
      startTime: engagement.optInOpensAt,
      workspaceId,
    }).then((data: any) => {
      setEngagementEmailEventsStats(data);
    })
      .catch((err: AggregateError) => {
        console.error(err);
      });
    // setEngagementEmailEventsStats(MOCK_ENGAGEMENT_EMAIL_EVENTS); // DEBUG and local dev.
  }, [engagementEmailEventsStatsType]);

  return (
    <main
      className="ContentContainer"
      data-testid="EngagementEmailStatisticsPage"
    >
      <Bureau className={classes.Bureau}>
        <Shelf className="ListPage">
          <header className={classes.Header}>
            <h1>Email Statistics</h1>

            <Chip>New</Chip>
          </header>

          {isLoadingEmailStats && (
            <OrbiitLoader />
          )}

          {!isLoadingEmailStats && (
            <section className={classes.Cards}>
              {['Invite', 'Chaser'].map((emailName: string) => {
                const emailType = emailName.toLowerCase();
                const stats = engagementEmailStats?.events.find((obj: any) => obj.category === `engagement-${emailType}`);

                if (!stats) {
                  const isEmailDisabled = get(engagement, `oneToOneEmails.${emailType}.isDisabled`, false);
                  const emailSendAt = get(engagement, `oneToOneEmails.${emailType}.sendAt`, false);
                  let text = 'We are awaiting the data for this email.';
                  if (isEmailDisabled) text = 'This email is disabled.';
                  else if (emailSendAt && new Date(emailSendAt) > new Date()) {
                    text = `This email is scheduled to go out ${formatDate(emailSendAt, engagement.timezoneIso, true)}`;
                  }
                  return (
                    <BaseCard key={`stats-${emailType}`}>
                      <EmptyCard
                        text={text}
                        title={`${emailName} Email`}
                      />
                    </BaseCard>
                  );
                }

                return (
                  <BaseCard key={`stats-${emailType}`}>
                    <EmailStatsCard
                      key={`${stats.category}-${stats.evaluated_at}`}
                      stats={stats}
                    />
                  </BaseCard>
                );
              })}
            </section>
          )}

          {engagementEmailEventsStats && (
            <section className={classes.Cards}>
              <BaseCard>
                <div className={clsx(classes.Content)}>
                  <div className="SplitHeader">
                    <h3 className={classes.CardTitle}>
                      By Event Type
                    </h3>

                    <div>
                      {['bounce', 'click', 'open'].map((eventType: string) => (
                        <button
                          className={clsx(
                            classes.QuickFilterButton,
                            engagementEmailEventsStatsType === eventType ? classes.QuickFilterButtonSelected : '',
                          )}
                          key={`stats-btn-${eventType}`}
                          onClick={() => setEngagementEmailEventsStatsType(eventType)}
                        >
                          {eventType}
                        </button>
                      ))}
                    </div>
                  </div>

                  <div>
                    {engagementEmailEventsStats?.events?.map((stat: EmailEvent) => (
                      <EmailStatRow key={`${obj.email}-${obj.event}`} stat={stat} />
                    ))}
                  </div>
                </div>
              </BaseCard>

              <BaseCard>
                <div className={clsx(classes.Content)}>
                  <div className="SplitHeader">
                    <h3 className={classes.CardTitle}>
                      Search by Email
                    </h3>

                    <div>
                      <Chip>Coming Soon</Chip>
                    </div>
                  </div>
                </div>
              </BaseCard>
            </section>
          )}
        </Shelf>
      </Bureau>
    </main>
  );
}
EngagementEmailStatisticsPage.displayName = 'EngagementEmailStatisticsPage';

const handle = {
  sidebar: true,
  title: 'Email Statistics',
};

export {
  EngagementEmailStatisticsPage as Component,
  handle,
};
