._EnumeratedUpdateTargets_oh9u2_1 {
  display: grid;
  gap: var(--grid-gutter-half);
  grid-template-columns: repeat(3, 1fr);
}

._Fieldset_oh9u2_7 {
  grid-template-columns: 1fr 1fr;
}

._Name_oh9u2_11 {
  background: var(--neutral-lightest);
  border: 1px solid var(--border-colour-normal);
  border-radius: var(--border-radius);
  display: block;
  margin-bottom: var(--grid-gutter-half);
  padding: var(--grid-gutter-half);
}
