.Content {
  padding: var(--grid-gutter);
  text-align: center;
}

.GridRow {
  align-items: center;
  display: grid;
  grid-gap: 0.375rem;
  grid-template-columns: 1fr 6fr 1fr;
  margin-bottom: 0.25rem;
}

.Header {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
}

.Bottom {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}
