.FlexEnd {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.Warning {
  margin-right: 1rem;
}

.TimezoneNotification {
  background-color: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.Footer {
  margin-top: var(--grid-gutter);
}
