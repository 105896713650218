import type { GraphQLFilter } from '../filtering/filters.d.ts';
import type { SerialParams } from './useRefinementParams.mts';


export function getFilterCount<Filters extends SerialParams<Int[]>>(filters: Filters) {
  let count = 0;
  for (const key of Object.keys(filters)) count += filters[key].length ?? 0;
  return count;
}

export function getGQLFilterCount(filters?: GraphQLFilter[]) {
  let count = 0;
  if (filters?.length) for (const { values } of filters) count += values.length ?? 0;
  return count;
}
