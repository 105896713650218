import type { CSSProperties, ElementType } from 'react';
import { clsx } from 'clsx';
import { useState } from 'react';

import type { Role } from '…/app/state/Role.mts';

import classes from './ProfileAvatar.module.css';


export function ProfileAvatar({
  as: Tag = 'div',
  className,
  name,
  role = 'OBSERVER' as Role.OBSERVER,
  size = '1.25em',
  src,
  style,
  ...props
}: {
  as: ElementType,
  className: string,
  name: string,
  role?: Role,
  size?: CSSProperties['fontSize'],
  src: URL['href'],
  style?: HTMLElement['style'],
}) {
  const [isImageBad, setImageBad] = useState<boolean>(!src);

  return (
    <Tag
      {...props}
      className={clsx(classes.ProfileAvatar, className)}
      role={role}
      style={{
        ...style ?? {},
        '--size': size,
      }}
      title={`role: ${role}`}
    >
      {isImageBad
        ? <span className={classes.FallbackInitial}>{name?.charAt(0)}</span>
        : (
          <img
            className={classes.ProfileImage}
            onError={() => setImageBad(true)}
            src={src}
          />
        )
      }
    </Tag>
  );
}
ProfileAvatar.displayName = 'ProfileAvatar';
