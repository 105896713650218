@keyframes bounce {
  0% { scale: 0; }
  50% { scale: 1; }
  100% { scale: 0; }
}

@keyframes rotate {
  100% { rotate: 360deg; }
}

.Spinner {
  animation: rotate 2s infinite linear;
  height: 3.5em;
  margin: auto;
  place-self: center;
  position: relative;
  text-align: center;
  width: 3.5em;
}

.Dot {
  animation: bounce 2s infinite ease-in-out;
  background-color: var(--brand-accent-teriary);
  border-radius: 100%;
  display: inline-block;
  height: 60%;
  position: absolute;
  top: 0;
  width: 60%;
}

.DotTwo {
  animation-delay: -1s;
  background-color: var(--brand-accent-secondary);
  bottom: 0;
  top: auto;
}
