import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form, { type FormProps } from 'form5/react/Form';
import _find from 'lodash-es/find.js';
import {
  type Dispatch,
  type SetStateAction,
  useState,
} from 'react';
import {
  generatePath,
  useNavigate,
} from 'react-router-dom';
import { toast } from 'sonner';

import { formatDate } from '…/app/common/dateUtils.mts';

import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import {
  ENGAGEMENT_SECTION_ROUTE_TEMPLATE,
  ENGAGEMENT_SETUP_ROUTE_TEMPLATE,
} from '…/app/route-params.mts';
import { useStore } from '…/app/state/useStore.mts';

import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { CTA } from '…/app/w/workspace/common/CTA/CTA.tsx';
import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';
import { Dialog } from '…/app/w/workspace/common/Dialog/Dialog.tsx';

import { SETUP_SECTIONS } from '…/app/w/workspace/engagements/constants.mts';
import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';

import type { Engagement, EngagementTemplate } from '../../../Engagement.d.ts';
import { setupPageConfig } from '../setupPageConfig.mts';
import subpageClasses from '../EngagementSubPage.module.css';

import { templateIcons } from './defaults/icons.mts';

import type { RecentTopicTemplate, TopicTemplate } from './EngagementTemplate.d.ts';
import { TemplateDetails } from './TemplateDetails.tsx';
import { setEngagementTemplate } from './setEngagementTemplate.op.mjs';

import classes from './EngagementTemplateSetupPage.module.css';


export function EngagementTemplateSetupPage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState<Engagement & EngagementTemplate>();
  const {
    engagementId,
    subSection,
    workspaceId,
  } = params;

  const {
    title,
  } = setupPageConfig[subSection];

  const [isSaving, setIsSaving] = useState(false);
  const [isDirty, setDirty] = useState(false);

  // [1] The API initialises these values as `null` but does not itself accept those values 🤬
  const [selected, setSelected] = useState<EngagementTemplate>();

  const templates = useStore((data) => data.templates);

  const navigate = useNavigate();
  const isEditor = useEditorRole();

  const onSubmit: FormProps['onSubmit'] = (delta) => {
    if (!isDirty && delta != null) return;

    setIsSaving(true);
    return setEngagementTemplate({
      areasOfInterest: _find(selected?.optInQuestions, { stepName: 'asks' })?.props.options || new Array(),
      conversationGuide: engagement.conversationGuide,
      description: selected?.description || engagement.description || '', // [1]
      engagementId,
      oneToOneEmails: selected?.oneToOneEmails || {
        chaser: {},
        invite: {},
      },
      title: selected?.title || engagement.title || '', // [1]
      workspaceId,
    })
      .then((updatedEngagement) => {
        setIsSaving(false);
        setPageProps((prev) => ({
          ...prev,
          engagement: updatedEngagement,
        }));

        return navigate(generatePath(ENGAGEMENT_SETUP_ROUTE_TEMPLATE, {
          ...params,
          subSection: SETUP_SECTIONS.SIGNUP,
        }));
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      });
  };

  const onBypass = () => onSubmit(null, null, null);

  return (
    <main
      className={clsx(className, classes.Page)}
      data-testid="EngagementTemplateSetupPage"
    >
      <div>
        <CTA
          className={subpageClasses.NavBack}
          title="Back to engagement"
          to={generatePath(ENGAGEMENT_SECTION_ROUTE_TEMPLATE, params)}
        >←
        </CTA>
      </div>

      <Form
        name="engagement-template"
        onDirty={setDirty}
        onPristine={setDirty}
        onSubmit={onSubmit}
      >
        <fieldset className="HollowWrapper" disabled={engagement.isLive || !isEditor}>
          <header className={classes.Header}>
            <h1 className={subpageClasses.Title}>{title}</h1>

            <p>Achieve high opt-in rates and successful matches, get inspired and set up in a few clicks.</p>

            <p>Not seeing what you’re looking for?
              {' '}

              <CTA
                disabled={engagement.isLive}
                guise={CTA.GUISES.LINK}
                to={onBypass}
              >
                Create your own
              </CTA>
            </p>
          </header>

          {Array.from(templates.entries()).map(([sectionName, tmpls]) => (
            <TemplateGroup
              key={sectionName}
              selected={selected}
              setSelected={setSelected}
              templates={tmpls}
              title={sectionName}
            />
          ))}
        </fieldset>

        <Dialog
          backdropped
          className={clsx(classes.TemplateDetails, {
            [classes.AiRecommendedDetails]: selected?.isAiRecommended,
          })}
          open={!!selected}
          setOpen={setSelected}
        >
          {!!selected && (
            <>
              {selected?.isAiRecommended && (
                <div className={classes.AiRecommended}>
                  <IIcon name="AI-powered" />
                  Suggestion
                </div>
              )}

              <TemplateDetails {...selected} />

              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                className={classes.TemplateSelectionCTA}
                disabled={!isDirty || isSaving}
                isSubmitting={isSaving}
                type="submit"
              >Select Template
              </SubmitButton>
            </>
          )}
        </Dialog>
      </Form>

      <div>
        <CTA
          className="muted"
          disabled={engagement.isLive}
          guise={CTA.GUISES.LINK}
          to={onBypass}
        >
          Skip
        </CTA>
      </div>
    </main>
  );
}
EngagementTemplateSetupPage.displayName = 'EngagementTemplateSetupPage';

const handle = {
  title: 'Engagement Setup Template',
};

export {
  EngagementTemplateSetupPage as Component,
  handle,
};

function TemplateGroup({
  selected,
  setSelected,
  templates,
  title,
}: {
  selected?: TopicTemplate | RecentTopicTemplate,
  setSelected: Dispatch<SetStateAction<TopicTemplate | RecentTopicTemplate | undefined>>,
  templates: Set<EngagementTemplate>,
  title: string,
}) {
  return (
    <section className={classes.TemplateGroup}>
      <h2 className={classes.TemplateGroupTitle}>{title}</h2>

      <ul className={classes.Cards}>
        {Array.from(templates).map((tmpl: TopicTemplate | RecentTopicTemplate) => {
          const Icon = templateIcons[tmpl.iconKey] ?? templateIcons.tshirt;

          return (
            <li className={classes.Card} key={tmpl.title}>
              <input
                checked={tmpl.title === selected?.title}
                className={clsx(classes.CardField)}
                id={tmpl.title}
                name="template"
                onChange={() => setSelected(tmpl)}
                type="radio"
                value={tmpl.title}
              />

              <label className={clsx('Card', tmpl?.isAiRecommended ? classes.AiRecommendedDetails : '')} htmlFor={tmpl.title}>
                {tmpl?.isAiRecommended && (
                  <div className={classes.AiRecommended}>
                    <IIcon name="AI-powered" />
                    Suggestion
                  </div>
                )}

                <Icon className={classes.CardIcon} />

                {tmpl?.optInClosesAt
                  ? (
                    <>
                      <p className={classes.Description}>{tmpl.name}</p>

                      <h3 className={classes.CardTitle}>{tmpl.title}</h3>

                      <p className={classes.Description}>{tmpl.description}</p>

                      <p>Closed on {formatDate(tmpl.optInClosesAt, tmpl.timezoneIso)}</p>
                    </>
                  )
                  : (
                    <>
                      <h3 className={classes.CardTitle}>{tmpl.title}</h3>

                      <p className={classes.Description}>{tmpl.description}</p>
                    </>
                  )}
              </label>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
TemplateGroup.displayName = 'TemplateGroup';
