import { UIError } from './UIError.ts';


export function handlePageErrors(
  pageName: string,
  errors: Error[] | null | undefined,
): void {
  if (!errors?.length) { return } // nothing to do

  const { 0: firstErr } = errors;
  let cause;
  let errs = errors;

  if (errors.length === 1 && firstErr instanceof AggregateError) {
    errs = firstErr.errors;
    cause = firstErr;
  }

  throw new UIError(errs, `${pageName} could not load`, {
    cause,
    code: 0,
  });
}
