import type { EngagementRouteParams } from '…/app/route-params.mts';

import type { PaginationProps } from '…/app/common/pagination/pagination.d.ts';

import { fetchMatchGroups } from './fetchMatchGroups.op.mts';
import { fetchUnmatched } from './fetchUnmatched.op.mts';


export async function fetchMatches(
  engagementId: EngagementRouteParams['engagementId'],
  workspaceId: EngagementRouteParams['workspaceId'],
  paginationProps?: PaginationProps,
) {
  const matchesReq = fetchMatchGroups(
    engagementId,
    workspaceId,
    paginationProps,
  );

  const unmatchedReq = fetchUnmatched(engagementId);

  const engagement = await Promise.all([
    matchesReq,
    unmatchedReq,
  ])
    .then(([matches, unmatched]) => ({
      id: engagementId,
      matches,
      unmatched,
    }));

  return { engagement };
}
