._Bureau_kw9cu_1 {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

._Drawer_kw9cu_5 {
  background: var(--neutral-lightest);
  padding: 0;
}

._Table_kw9cu_10 {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

._Table_kw9cu_10 td {
  vertical-align: middle;
}

._NameCell_kw9cu_19 {
  padding: 0.5rem 0;
}

._ExtraMarginBottom_kw9cu_23 {
  margin-bottom: 1rem;
}
