._SetupCard_1n9tz_1 {
  background: linear-gradient(
    195deg,
    color-mix(
      in srgb,
      var(--colourise),
      transparent 74%
    ),
    transparent 10%
  );
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  padding: calc(2 * var(--grid-gutter));
}

._SetupCard_1n9tz_1[data-status="complete"] {
  --colourise: var(--status-success);
}

._SetupCard_1n9tz_1[data-status="incomplete"] {
  --colourise: var(--status-danger);
}

._SetupCard_1n9tz_1[data-status="warn"] {
  --colourise: var(--status-warning);
}

._SetupCardHeader_1n9tz_29:not(:only-child)::after {
  border-top: 1px solid var(--border-colour-normal);
  content: '';
  grid-column: 2 / -1;
}

._SetupCardHeader_1n9tz_29 h3 {
  font-style: italic;
  font-weight: unset;
}

._Status_1n9tz_40 {
  color: var(--colourise);
  text-align: right;
}

._Status_1n9tz_40::before {
  background-color: var(--colourise);
  color: white;
  display: inline-block;
  height: 1.4em;
  margin-right: 1em;
  text-align: center;
  width: 1.4em;
}

._Status_1n9tz_40[data-status="complete"]::before {
  border-radius: 100%;
  content: '✓';
}

._Status_1n9tz_40[data-status="incomplete"]::before {
  clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
  content: '!';
}

._Status_1n9tz_40[data-status="warn"]::before {
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  content: '!';
}

._SetupCardHeader_1n9tz_29,
._SetupCardItems_1n9tz_71,
._SetupCardItem_1n9tz_71 {
  align-items: center;
  display: grid;
  gap: var(--grid-gutter);
}

._SetupCardHeader_1n9tz_29 {
  grid-template-columns: 4ch auto minmax(15ch, max-content);;
}

._SetupCardItems_1n9tz_71 {
  grid-template-columns: 4ch auto;
}

._SetupCardItem_1n9tz_71 {
  grid-column: 2;
  grid-template-columns: auto max-content;
}

._WithIcon_1n9tz_91 ._SetupCardItem_1n9tz_71 {
  grid-template-columns: 2ch auto max-content;
}
