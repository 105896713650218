/* eslint-disable sort-keys */
export const CHIP_PROMINENCES = {
  SUBTLE: 'subtle',
  REGULAR: 'regular',
} as const;
export type Prominence = typeof CHIP_PROMINENCES[keyof typeof CHIP_PROMINENCES];

export const CHIP_VARIANTS = {
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
  INFORMATIVE: 'informative',
  POSITIVE: 'positive',
} as const;
export type Variant = typeof CHIP_VARIANTS[keyof typeof CHIP_VARIANTS];
/* eslint-ensable sort-keys */
