import { ReactNode } from 'react';
import { clsx } from 'clsx';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

import classes from './Cards.module.css';

export function BaseCard({
  className = '',
  disabled = false,
  children,
}: {
  className?: HTMLElement['className'],
  disabled?: boolean,
  children: ReactNode
}) {
  return (
    <section className={clsx('Card', classes.BaseCard, className, disabled ? classes.Disabled : classes.Enabled)}>
      {children}
    </section>
  );
}

BaseCard.displayName = 'BaseCard';

export function EmptyCard({ title, text }: { title: string, text: string }) {
  return (
    <div className={classes.EmptyCard}>
      <h3 className={classes.EmptyCardTitle}>{title}</h3>

      <p className={classes.EmptyCardDescription}>{text}</p>
    </div>
  );
}

EmptyCard.displayName = 'EmptyCard';

export function FeatureFlagDisabledCard({
  description = 'This metric is available on a higher pricing plan.',
  title,
}: {
  description?: string,
  title: string,
}) {
  return (
    <div className={classes.FeatureFlagDisabledCard}>
      <h4 style={{ color: 'var(--pricing-plan-dark)' }}>
        <IIcon label="Disabled Feature" name="diamond" />

        {' '}

        {title}
      </h4>

      <p>{description}</p>
    </div>
  );
}

FeatureFlagDisabledCard.displayName = 'FeatureFlagDisabledCard';
