.EmailEventRow {
  border-top: 1px solid #EEE;
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  padding: 0.5rem 0;
}

.EmailEventRow:first-child {
  border-top: none;
}

.EmailEventRow > :first-child {
  flex: 1;
}

.EmailEventRow > :nth-child(2) {
  flex: 4;
}

.EmailEventRow > :nth-child(3) {
  flex: 3;
  text-align: right;
}
