._FlexCol_1rbrm_1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 0.6em;
  justify-content: flex-start;
}

._Bureau_1rbrm_9 {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches */
}

._Drawer_1rbrm_13 {
  background: var(--neutral-lightest);
  padding: 0;
}

._Table_1rbrm_18 {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

._Table_1rbrm_18 td {
  vertical-align: top;
}

._SubTitle_1rbrm_27 {
  color: var(--neutral-medium);
  font-size: 0.875em;
  line-height: 1.25em;
}

._ExtraMarginBottom_1rbrm_33 {
  margin-bottom: 1rem;
}

._Testimonial_1rbrm_37 {
  font-style: italic;
}

._Testimonial_1rbrm_37::before,
._Testimonial_1rbrm_37::after {
  display: inline-block;
  font-weight: bold;
  vertical-align: middle;
}

._Testimonial_1rbrm_37::before {
  content: '“';
}

._Testimonial_1rbrm_37::after {
  content: '”';
}
