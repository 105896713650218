import {
  Outlet,
  useParams,
} from 'react-router-dom';

import type { WalledRouteParams } from '…/app/route-params.mts';

import { Tabs } from '…/app/common/Tabs/Tabs.tsx';

import classes from './WorkspaceSettingsContainer.module.css';


export function WorkspaceSettingsContainer() {
  const params = useParams() as WalledRouteParams;

  return (
    <>
      <header className={classes.SettingsHeader}>
        <Tabs
          appearance="header"
          className={classes.SettingsTabs}
          params={params}
          routeTemplate="/w/:workspaceId/settings/:section"
          tabs={tabs}
        />
      </header>

      <div className="ContentContainer">
        <Outlet />
      </div>
    </>
  );
}
WorkspaceSettingsContainer.displayName = 'WorkspaceSettingsContainer';

export {
  WorkspaceSettingsContainer as Component,
};

const tabs = new Map([
  [{ section: 'profile' }, { title: 'Your Profile' }],
  [{ section: 'brand' }, { title: 'Brand' }],
  [{ section: 'team' }, { title: 'Team' }],
  [{ section: 'integrations' }, { title: 'Integrations' }],
]);
