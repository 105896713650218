.MetricsCard {
  background-color: var(--brand-light);
  border: 1px solid var(--brand-subtle-light);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow-card);
  box-sizing: border-box;
  display: flex;
  flex: 0 1;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
  margin: 0;
  padding: 1.5rem;
  position: relative;
  text-align: center;
}

/* [1] 'gap' is ignored when calculating available space for flex items */

.MetricsCard[prominence="simple"] {
  /* FIXME: something is off about this calc; no extra space, but it causes horizontal scroll */
  flex-basis: calc(25% - (1rem * 3 / 4)); /* [1] 4 gaps to spread across 5 items */
}

.MetricsCard[prominence="catching"] {
  flex-basis: calc(100% / 3 - (1rem * 2 / 3)); /* [1] 2 gaps to spread across 3 items */
}

.MetricsCard[prominence="front-end-centre"] {
  flex-basis: calc(50% - (1rem * 1 / 2)); /* [1] 1 gap to spread across 2 items */
}

.MetricsCardDisabled {
  background-color: var(--brand-subtle-light);
  border: 1px solid white;
}

.MetricBenchmark {
  font-size: 0.8em;
  margin: 0;
}

.MetricInfoContainer {
  margin: 0.5em;
  opacity: 0.4;
}

.MetricInfoContainer:hover {
  opacity: 0.85;
}

.MetricInfo {
  position: absolute;
  right: 0;
  top: 0;
}

.MetricTitle {
  font-size: 1.125em;
  margin: 0;
}

.MetricValue {
  font-size: 3em;
  font-weight: bolder;
  margin: 0;
}

.MetricsCard[prominence="catching"],
.MetricsCard[prominence="front-end-centre"] {
  text-align: center;
}
