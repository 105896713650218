import { BROWSER, getBrowser } from '…/common/device-info/browser.mts';
import { OS, getPlatform } from '…/common/device-info/platform.mts';
import { DEVICE_TYPE, getDeviceType } from '…/common/device-info/type.mts';


export function ClearCacheKeys() {
  if (getDeviceType(navigator) === DEVICE_TYPE.MOBILE) {
    return <>See site settings in your app’s settings</>;
  }

  const superKey = getPlatform(navigator) === OS.MAC ? '⌘' : '^';
  const modifierKey = getBrowser(navigator) === BROWSER.SAFARI ? 'option' : 'shift';

  return (
    <>
      <kbd>{superKey}</kbd>

      {' + '}

      <kbd>{modifierKey}</kbd>

      {' + '}

      <kbd>R</kbd>
    </>
  );
}
ClearCacheKeys.displayName = 'ClearCacheKeys';
