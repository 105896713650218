.DashboardFiltersContainer {
  background-color: var(--brand-light);
  border: 1px solid var(--brand-subtle-light);
  border-radius: var(--border-radius);
  padding: 1rem;
}

.DashboardFiltersForm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.ButtonGroupButton {
  padding: 0.5rem 1rem;
}

.FilterTextContainer {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.FilterPanelButton {
  appearance: none;
  background-color: var(--brand-light);
  border: 1px solid var(--brand-subtle-light);
  border-radius: var(--border-radius);
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  padding: 0.75rem 1rem;
}

.FilterSpan {
  background: var(--brand-subtle-light);
  border-radius: var(--border-radius);
  font-weight: 500;
  margin: 0 0.375rem;
  padding: 0.5rem 0.75rem;
}
