/* eslint-disable no-console */

/// <reference lib="dom" />

import ky, { HTTPError } from 'ky';
import { uid } from 'uid';

import { browserTimezone } from './device-info/browser.mts';


enum LOG_TYPE {
  DEBUG = 'debug',
  ERROR = 'error',
  INFO = 'info',
  TIME = 'time',
  WARN = 'warn',
}

const RECORDED_LOG_LEVELS = new Set([
  LOG_TYPE.ERROR,
  LOG_TYPE.WARN,
]);


export class Logger {
  #prefix = '';

  doPrint = false;
  sessionId;

  constructor({
    doPrint = false,
    prefix,
    sessionId = uid(24),
  }: {
    doPrint?: boolean,
    prefix: string,
    sessionId?: string,
  }) {
    this.#prefix = `[${prefix}]:`;
    this.doPrint = doPrint;
    this.sessionId = sessionId;
  }

  async #do(type: LOG_TYPE, ...args: Parameters<Console[LOG_TYPE]>) {
    if (
      RECORDED_LOG_LEVELS.has(type)
      && process.env.LOGTAIL_SOURCE_TOKEN
    ) {
      const record = getRecord(args[0]);

      let viewport = '';
      if (typeof document !== 'undefined' && typeof window !== 'undefined') {
        const { clientHeight, clientWidth } = document.documentElement;
        viewport = [
          Math.max(clientWidth ?? 0, window.innerWidth ?? 0),
          Math.max(clientHeight ?? 0, window.innerHeight ?? 0),
        ].join('×');
      }

      await ky
        .post('https://in.logtail.com', {
          headers: {
            'Authorization': `Bearer ${process.env.LOGTAIL_SOURCE_TOKEN}`,
          },
          json: {
            OS: navigator.platform,
            app: {
              commit: process.env.APP_COMMIT,
              version: process.env.APP_VERSION,
            },
            level: type,
            locale: navigator.language ?? navigator?.languages?.[0],
            region: browserTimezone,
            sessionId: this.sessionId,
            source: 'App',
            url: location.href,
            ...(viewport && { viewport }),
            ...record,
          },
        })
        .catch((err) => console.error(...[this.#prefix, err]));
    }

    if (await this.doPrint) {
      return console[type](...[this.#prefix, ...args]);
    }
  }

  debug(...args: Parameters<Console['debug']>) { return this.#do(LOG_TYPE.DEBUG, ...args) }
  error(...args: Parameters<Console['error']>) { return this.#do(LOG_TYPE.ERROR, ...args) }
  info(...args: Parameters<Console['info']>) { return this.#do(LOG_TYPE.INFO, ...args) }
  time(...args: Parameters<Console['time']>) { return this.#do(LOG_TYPE.TIME, ...args) }
  warn(...args: Parameters<Console['warn']>) { return this.#do(LOG_TYPE.WARN, ...args) }
}

function getRecord(log: unknown) {
  if (log instanceof HTTPError) {
    return {
      code: log.response.status,
      endpoint: log.request.url,
      message: log.response.statusText,
    };
  }

  return log != null && typeof log === 'object'
    ? log
    : { message: log };
}
