.OrbiitBackground {
  align-items: center;
  background-color: var(--brand-dark);
  background-image: url("https://storage.googleapis.com/orbiit-assets/images/circles_background_fullcolor_2.png");
  background-position: center;
  background-size: cover;
  color: var(--brand-light);
  height: 100%;
}

.Content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  width: 50ch;
}

@media (width <= 960px) {
  .Content {
    width: 100%;
  }
}

.Card {
  background: var(--background-card);
  border: 1px solid var(--border-colour-subtle);
  border-radius: var(--default-border-radius);
  box-shadow: var(--box-shadow-card);
  color: initial;
  display: flex;
  flex-direction: column;
  gap: inherit;
  padding: 2em;
}
