import { clsx } from 'clsx';
import type {
  BaseHTMLAttributes,
  ReactNode,
} from 'react';

import { IIcon } from '…/app/w/workspace/common/Icon/Icon.tsx';

import {
  CHIP_PROMINENCES,
  CHIP_VARIANTS,
  type Prominence,
  type Variant,
} from './chip-options.mts';
import classes from './Chip.module.css';


export function Chip(props: IBaseChip | ICountChip | IRatingChip) {
  if ('count' in props) return <CountChip {...props} />;
  if ('rating' in props) return <RatingChip {...props} />;
  return <BaseChip {...props} />;
}
Chip.displayName = 'Chip';

Chip.VARIANTS = CHIP_VARIANTS;


interface IBaseChipOwn {
  children: ReactNode,
  className?: string,
  label?: ReactNode,
  prominence?: Prominence,
  variant?: Variant,
}
type IBaseChip = IBaseChipOwn & BaseHTMLAttributes<HTMLSpanElement>;

const BaseChip = ({
  className,
  label,
  children = label,
  prominence = CHIP_PROMINENCES.REGULAR,
  ...rest
}: IBaseChip) => (
  <span
    className={clsx(classes.Chip, className)}
    // @ts-ignore Custom attribute
    prominence={prominence}
    {...rest}
  >
    {children}
  </span>
);
BaseChip.displayName = 'BaseChip';


type IRatingChip = IBaseChip & {
  maxRating: number,
  maxNegative: number,
  minPositive: number,
  rating: number,
}
const RatingChip = ({
  maxRating = 10,
  maxNegative = 6,
  minPositive = 9,
  prominence = CHIP_PROMINENCES.REGULAR,
  rating,
}: IRatingChip) => {
  let variant: Variant = CHIP_VARIANTS.NEUTRAL;
  if (rating >= minPositive) variant = CHIP_VARIANTS.POSITIVE;
  if (rating <= maxNegative) variant = CHIP_VARIANTS.NEGATIVE;

  return (
    <BaseChip
      className={classes.RatingChip}
      prominence={prominence}
      variant={variant}
    >
      <IIcon
        aria-hidden
        className={classes.RatingIcon}
        label={null}
        name="star filled"
      />

      <span>
        <strong>{rating}</strong> out of

        {' '}

        <strong>{maxRating}</strong>
      </span>
    </BaseChip>
  );
};
RatingChip.displayName = 'RatingChip';

type ICountChip = IBaseChip & {
  count: number | string,
}

const CountChip = ({
  count,
  prominence = CHIP_PROMINENCES.REGULAR,
}: ICountChip) => (
  <BaseChip
    className={classes.CountChip}
    prominence={prominence}
  >
    {count}
  </BaseChip>
);
CountChip.displayName = 'CountChip';
