import { clsx } from 'clsx';
import { memo } from 'react';
import {
  NavLink,
  generatePath,
} from 'react-router-dom';

import classes from './Tabs.module.css';


function NavTabs({
  appearance = 'panelled',
  className,
  params,
  routeTemplate,
  tabs,
}: {
  appearance: 'header' | 'panelled',
  className?: HTMLElement['className'],
  params: Record<string, string>,
  routeTemplate: string,
  tabs: Map<{ [key: string]: string }, { end?: boolean, routeTemplate?: string, title: string }>,
}) {
  const output = [];

  for (
    const [
      itemParams, {
        end,
        title,
        ...others
      },
    ]
    of tabs.entries()
  ) {
    output.push(
      <NavLink
        className={classes.Tab}
        end={end}
        key={title}
        role="tab"
        to={generatePath(others.routeTemplate || routeTemplate, {
          ...params,
          ...itemParams,
        })}
      >
        {title}
      </NavLink>,
    );
  }

  return (
    <nav
      // @ts-ignore
      appearance={appearance}
      className={clsx(classes.Tabs, className)}
      role="tablist"
    >
      {output}
    </nav>
  );
}
NavTabs.displayName = 'NavTabs';

export const Tabs = memo(NavTabs);
Tabs.displayName = 'MemoisedTabs';
