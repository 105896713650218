import { clsx } from 'clsx';
import Button from 'form5/react/Button';
import Form from 'form5/react/Form';
import Field from 'form5/react/Field';
import { html2json, json2html } from 'html2json_2';
import _get from 'lodash-es/get.js';
import { useEffect, useState } from 'react';
import {
  unstable_useBlocker as useBlocker,
} from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { toast } from 'sonner';

import { Tabs } from '…/app/common/Tabs/Tabs.tsx';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { useEditorRole } from '…/app/common/permissions/useRoles.mjs';
import { UnsavedChangesWarning } from '…/app/w/workspace/common/UnsavedChangesWarning/UnsavedChangesWarning.tsx';

import { useViewState } from '…/app/w/workspace/engagements/engagement/state.mts';
import type { EngagementConversationGuide } from '…/app/w/workspace/engagements/Engagement.d.ts';

import { setupPageConfig } from '../setupPageConfig.mts';
import editableSectionClasses from '../EngagementSubPageEditableSection.module.css';
import subpageClasses from '../EngagementSubPage.module.css';
import emailClasses from './EngagementEmailSetupPage.module.css';

import {
  TINY_MCE_CONFIG,
  TINY_MCE_KEY,
} from './editor.config.mts';
import { tabs } from './tabs.mts';
import { updateConversationGuide } from './updateConverstaionGuide.op.mjs';

export function EngagementConversationGuidePage() {
  const [
    {
      className,
      engagement,
      params,
    },
    setPageProps,
  ] = useViewState();
  const { subSection } = params;

  const {
    dataPath,
  } = setupPageConfig[subSection];

  const data = _get(engagement, dataPath) ?? {};

  const [isDisabled, setIsDisabled] = useState(data?.isDisabled || false);
  const [isDirty, setDirty] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const { emailFieldName } = setupPageConfig[subSection];
  const conversationGuideBody = json2html(
    data[emailFieldName]
    || 'bodyHtml' in data && html2json(data.bodyHtml)
    || '',
  );
  const isEditor = useEditorRole();
  const blocker = useBlocker(isDirty);

  useEffect(() => {
    if (blocker.state === 'blocked' && !isDirty) {
      blocker.reset();
    }
  }, [blocker, isDirty]);

  const isEditingBlocked = engagement.isLive || !isEditor;

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  async function onSubmit(delta: Record<string, any>) {
    if (!isDirty) { return }

    setSubmitting(true);

    return updateConversationGuide({
      conversationGuide: {
        id: engagement.conversationGuide.id,
        isDisabled,
        // TinyMCE stores its value as HTML in its textarea (but consumes JSON 😑).
        ...(delta?.json && { json: html2json(delta.json) }),
      },
    })
      .then((updatedData: { engagement: { conversationGuide: EngagementConversationGuide }}) => {
        const { engagement: { conversationGuide } } = updatedData;
        setPageProps((prev) => ({
          ...prev,
          // Update the current Engagement data with the new conversation guide.
          engagement: {
            ...engagement,
            conversationGuide,
          },
        }));
        toast.success('Conversation guide saved.');
      })
      .catch((err: AggregateError) => {
        toast.error(err.errors?.[0].message);
      })
      .finally(() => {
        setDirty(false);
        setSubmitting(false);
      });
  }

  return (
    <main
      className={clsx(className, subpageClasses.SubPage)}
      data-testid="EngagementConversationGuidePage"
    >
      <section className={clsx(subpageClasses.Section, subpageClasses.EditableSection)}>
        <Tabs
          params={params}
          routeTemplate="/w/:workspaceId/engagements/:engagementId/:section/:subSection"
          tabs={tabs}
        />

        <Form
          className={editableSectionClasses.Form}
          name={subSection}
          onDirty={() => setDirty(true)}
          onPristine={() => setDirty(false)}
          onSubmit={onSubmit}
        >
          {blocker && <UnsavedChangesWarning blocker={blocker} />}

          <fieldset>
            <div className={subpageClasses.SplitContent}>
              <header>
                <h2>Conversation Guide</h2>

                <h3>(Calendar invite)</h3>
              </header>

              <SubmitButton
                appearance={Button.APPEARANCES.PRIMARY}
                className={emailClasses.SubmitButtonSaving}
                disabled={isEditingBlocked || !isDirty || isSubmitting}
                isSubmitting={isSubmitting}
                type="submit"
              >Save
              </SubmitButton>
            </div>
          </fieldset>

          <fieldset disabled={isEditingBlocked || isDisabled}>
            <Editor
              apiKey={TINY_MCE_KEY}
              disabled={isEditingBlocked || isDisabled}
              id={emailFieldName}
              init={TINY_MCE_CONFIG}
              initialValue={conversationGuideBody}
              onDirty={() => setDirty(true)}
            />
          </fieldset>

          <fieldset className={emailClasses.DisableEmailContainer}>
            <div className={subpageClasses.SplitContent}>
              <h4>Disable Conversation Guide</h4>

              <Field
                checked={isDisabled || false}
                className={editableSectionClasses.InvertedToggle}
                disabled={isEditingBlocked}
                label={null}
                name="isDisabled"
                onChange={({ value }) => {
                  setIsDisabled(value);
                  setDirty(true);
                }}
                type="checkbox"
                variant="toggle"
              />
            </div>

            <p>
              The <strong>conversation guide</strong> is displayed in the calendar invite and is also

              {' '}

              accesible in the Orbiit meeting room during the conversation. If you wish to disable the

              {' '}

              conversation guide it will simply remove it from the invite and not have it as a sidebar

              {' '}

              in the meeting room.
            </p>
          </fieldset>
        </Form>
      </section>
    </main>
  );
}
EngagementConversationGuidePage.displayName = 'EngagementConversationGuidePage';

const handle ={
  title: 'Engagement Setup Conversation Guide',
};

export {
  EngagementConversationGuidePage as Component,
  handle,
};
