fragment memberDetails on Member {
  id
  alternativeEmails
  customFields {
    id
    selectedOptions
  }
  email
  fields {
    company
    firstName
    fullName
    lastName
    linkedin
    role
    url
  }
  flagged {
    isBounced
    isUnsubscribed
    unsubscribedUntil
  }
}
