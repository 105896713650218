import { html2json } from 'html2json_2';


export const INITIAL_CONVERSATION_GUIDE = html2json(`
  <strong>Icebreakers</strong>
  <br/>
  <ul>
    <li>What’s a story—from a book, movie, article, conversation—that's gripped you lately? Why was it impactful?</li>
    <li>What's been the high and low of your week so far?</li>
  </ul>
  <p><strong>Discussion questions:</strong></p>
  <ul>
    <li>What is the biggest professional challenge you're facing right now? What kind of support or input do you need?</li>
    <li>What's a professional accomplishment/project you're proud of right now? What made it great, and what could have made it even better?</li>
    <li>How are you thinking about your own development and growth right now (personally, professionally, or both)?</li>
  </ul>
  <p><strong>Wrap up</strong></p>
  <p>Finish up your chat with any outstanding questions, opportunities to connect further, or share some last bits of advice.</p>
`);
