export function getPlatform({
  platform,
  userAgentData,
}: Navigator) {
  return platformToOS[(userAgentData?.platform ?? platform) as Platform];
}

export const enum OS {
  LINUX = 'linux',
  MAC = 'macOS',
  WINDOWS = 'windows',
  iOS = 'ios',
}

const platformToOS = {
  'Linux x86_64': OS.LINUX,
  MacIntel: OS.MAC,
  Win32: OS.WINDOWS,
  Windows: OS.WINDOWS,
  iPhone: OS.iOS,
  macOS: OS.MAC,
} as const;
type Platform = keyof typeof platformToOS;
