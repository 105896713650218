import { Chip } from '…/app/w/workspace/common/Chip/Chip.tsx';

export function MatchRatingChip({ matchRating }: { matchRating?: number }) {
  if (!matchRating) return null;

  let ratingVariant;
  let ratingLabel;

  switch (matchRating) {
    case 5:
      ratingLabel = 'Perfect';
      ratingVariant = Chip.VARIANTS.POSITIVE;
      break;
    case 4:
      ratingLabel = 'Great';
      ratingVariant = Chip.VARIANTS.POSITIVE;
      break;
    case 3:
      ratingLabel = 'Average';
      ratingVariant = Chip.VARIANTS.NEUTRAL;
      break;
    default:
      ratingLabel = 'Poor';
      ratingVariant = Chip.VARIANTS.NEGATIVE;
  }

  return (
    <Chip variant={ratingVariant}>
      <strong>{ratingLabel}</strong>
    </Chip>
  );
}

MatchRatingChip.displayName = 'MatchRatingChip';
