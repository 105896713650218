import { gqlOperation } from '…/app/common/rpc/gqlOperation.mts';

import { INITIALISE_ENGAGEMENT_TEMPLATE } from './initialiseEngagementTemplate.gql.mjs';
import { INITIAL_CONVERSATION_GUIDE } from './conversationGuide.mjs';


export function setEngagementTemplate({
  areasOfInterest,
  conversationGuide,
  description,
  engagementId,
  oneToOneEmails,
  title,
  workspaceId,
}) {
  return gqlOperation(INITIALISE_ENGAGEMENT_TEMPLATE, {
    areasOfInterest,
    conversationGuide: { json: conversationGuide.json || INITIAL_CONVERSATION_GUIDE },
    conversationGuideId: conversationGuide.id,
    description,
    engagement: { oneToOneEmails },
    engagementId,
    title,
    workspaceId,
  })
    .then((data) => data.engagement);
}
