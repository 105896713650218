._EmptyTable_11w59_1 {
  align-items: center;
  background: var(--neutral-lightest);
  border: 1px solid var(--neutral-light);
  border-radius: var(--border-radius);
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: inherit;
  justify-content: center;
  padding: 3rem 0;
}

._Table_11w59_14:not([minimal]) {
  width: 100%;
}

._Table_11w59_14[sticky] {
  position: relative;
}

/* .Head {
  border-bottom: 1px solid var(--border-colour-normal);
} */

._Table_11w59_14[sticky] ._Head_11w59_22 {
  background-color: var(--brand-light);
  position: sticky;
  top: 0;
}

._Table_11w59_14 td,
._Table_11w59_14 th {
  padding: 0.5em;
  text-align: left;
}

._Table_11w59_14 td[colspan] {
  text-align: center;
}

._Table_11w59_14 td[data-type="controls"] {
  text-align: center;
  white-space: nowrap;
}

._Table_11w59_14 td[wrap] {
  max-width: calc(100vw / (var(--cols) - 2));
  word-wrap: break-word;
}

._Table_11w59_14 tr + tr {
  border-top: 1px solid var(--border-colour-normal);
}

._Table_11w59_14 tr::before {
  content: "";
  display: table-cell;
  width: 0.2ch;
}

._Table_11w59_14 tbody tr:hover::before {
  background-color: var(--brand-accent-primary);
}

._Table_11w59_14 tbody tr:hover {
  background-color: var(--neutral-lightest);
}

._Th_11w59_70 {
  font-weight: bold;
  text-align: left;
}

._Th_11w59_70[data-type="numeric"],
._Table_11w59_14 td[data-type="numeric"] {
  text-align: right;
}

/* For whatever reason, too many th[sortable] causes them to stack, so an inner-wrapper is necessary */
._Th_11w59_70 [sortable] {
  cursor: pointer;
  display: flex; /* prevent icon line-wrapping */
  flex-flow: row nowrap;
  transition-duration: var(--transition-duration-glyphs);
  transition-property: content;
}

._Th_11w59_70[data-type="numeric"] [sortable] {
  place-content: end;
}

._Th_11w59_70 [sortable]::after,
._Th_11w59_70 [sort]::after {
  display: inline-block;
  font-size: 0.8em;
  padding-left: 0.4ch;
}

._Th_11w59_70 [sortable]::after {
  color: var(--text-muted);
  content: "⇅";
}

._Th_11w59_70 [sort]::after {
  color: inherit;
}

._Th_11w59_70 [sort="asc"]::after {
  content: "↑";
}

._Th_11w59_70 [sort="dsc"]::after {
  content: "↓";
}
