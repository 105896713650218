._AppHeader_sgjzq_1 {
  align-items: center;
  background-color: var(--brand-dark);
  box-shadow: var(--box-shadow);
  color: var(--brand-light);
  gap: var(--grid-gutter);
  padding:
    calc(0.75 * var(--grid-gutter))
    calc(2 * var(--grid-gutter));
  z-index: 10;
}

@media (width <= 960px) {
  ._AppHeader_sgjzq_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (width > 960px) {
  ._AppHeader_sgjzq_1 {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    justify-content: space-between;
  }
}

._Masthead_sgjzq_29 {
  max-height: 1.5em;
}

._NavLinks_sgjzq_33 {
  align-items: center;
  background-color: rgb(255 255 255 / 31%);
  border-radius: 2em;
  display: flex;
  flex-direction: row;
  padding: 0.2em;
}

@media (width <= 960px) {
  ._NavLinks_sgjzq_33 {
    order: 1;
    overflow-x: auto;
  }
}

._NavLink_sgjzq_33 {
  border-radius: 2em;
  color: var(--brand-light);
  font-size: 0.875em;
  padding: 0.375em 1em;
}

._NavLink_sgjzq_33:hover {
  background-color: rgb(255 255 255 / 10%);
}

._NavLink_sgjzq_33.active { /* stylelint-disable-line selector-class-pattern */
  background-color: var(--brand-dark);
  font-weight: bold;
}

._UserWidget_sgjzq_65 {
  align-items: center;
  display: flex;
  font-size: 0.875rem;
  gap: 1ch;
  justify-content: end;
}
