import audienceFrag from './audience.frag.gql';
import audienceFilterFrag from './audienceFilter.frag.gql';

export const GET_AUDIENCE = `
query GetAudience(
  $audienceId: ID!
  $includeFilters: Boolean!
) {
  audience(audienceId: $audienceId) {
    ...audienceFields
    ...audienceFilterFields
  }
}

${audienceFrag}
${audienceFilterFrag}
`;
