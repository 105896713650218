import Button from 'form5/react/Button';
import Field from 'form5/react/Field';
import Form from 'form5/react/Form';
import type { ReactNode } from 'react';
import { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { FLAGS } from '…/app/common/permissions/Features/FLAGS.mts';
import { useFeatureFlag } from '…/app/common/permissions/Features/useFeatureFlag.mts';
import { SubmitButton } from '…/app/common/SubmitButton/SubmitButton.jsx';
import { PRODUCT_TO_GROUP_SIZE } from '…/app/w/workspace/engagements/constants.mts';

import {
  PRODUCT_TO_TYPE_DICT,
  type PRODUCT_TYPE,
  PRODUCT_TYPES,
} from '…/app/deprecated/constants.mts';

import type { Engagement } from '../Engagement.d.ts';
import { createEngagement } from './gql/createEngagement.op.mjs';
import classes from './GenericEngagementForm.module.css';


// NOTE: ORB-1633 - This is in some ways a duplicate of the CreateEngagementForm component.
export function CreateEngagementForm(
  {
    workspaceId,
  }: {
    workspaceId: string,
  },
) {
  const [isDirty, setDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const navigate = useNavigate();
  const [product, setProduct] = useState<PRODUCT_TYPE>(PRODUCT_TYPES.ONE_TO_ONE);

  const enableGroup = useFeatureFlag([FLAGS.createEngagementGroup]);
  const enableTopN = useFeatureFlag([FLAGS.createEngagementTopN]);

  const range = PRODUCT_TO_GROUP_SIZE[product];

  return (
    <Form
      name="create-engagement"
      onDirty={() => setDirty(true)}
      onPristine={() => setDirty(false)}
      onSubmit={(_, { groupSize, ...rest }: Engagement & { groupSize: string }) => {
        setIsSaving(true);
        return createEngagement({
          engagement: {
            ...rest,
            ...(groupSize && { groupSize: +groupSize }),
          },
          workspaceId,
        })
          .then(({ id }) => {
            setIsSaving(false);
            return navigate(generatePath('/w/:workspaceId/engagements/:engagementId', {
              engagementId: id,
              workspaceId,
            }));
          });
      }}
    >
      <h1>Create your Engagement</h1>

      <Field
        arrangement={Field.ARRANGEMENTS.STACKED}
        autoFocus
        fluid
        label="Name"
        minLength={3}
        name="name"
        placeholder="e.g. Women's Group Connections (November)"
        required
      />

      <p className="note">This engagement name is internal only (your members will never see it).</p>

      <fieldset className={classes.RelatedFieldset}>
        <legend>Engagement configuration</legend>

        <Button.Group className={classes.RelatedFieldset}>
          <Field
            defaultChecked
            hidden={!enableGroup && !enableTopN}
            id={`product[${PRODUCT_TYPES.ONE_TO_ONE}]`}
            label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.ONE_TO_ONE]}
            name="product"
            onChange={({ value }) => setProduct(value)}
            type="radio"
            value={PRODUCT_TYPES.ONE_TO_ONE}
            variant={Field.VARIANTS.CTA}
          />

          {enableGroup && <Field
            id={`product[${PRODUCT_TYPES.GROUP}]`}
            label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.GROUP]}
            name="product"
            onChange={({ value }) => setProduct(value)}
            type="radio"
            value={PRODUCT_TYPES.GROUP}
            variant={Field.VARIANTS.CTA}
          />}

          {/* {enableTopN && <Field
            id={`product[${PRODUCT_TYPES.TOP_N}]`}
            label={PRODUCT_TO_TYPE_DICT[PRODUCT_TYPES.TOP_N]}
            name="product"
            onChange={({ value }) => setProduct(value)}
            type="radio"
            value={PRODUCT_TYPES.TOP_N}
            variant={Field.VARIANTS.CTA}
          />} */}
        </Button.Group>
      </fieldset>

      {/** NOTE: ORB-1633 - Can this be refactored to a specific component? */}
      {(
        product !== PRODUCT_TYPES.ONE_TO_ONE
        && (enableGroup || enableTopN)
      ) && (
        <fieldset className={classes.RelatedFieldset}>
          <legend>Size</legend>

          <Button.Group className={classes.StackedRadioGroup}>
            {(() => {
              const options: ReactNode[] = [];

              for (let i = range.min; i < range.max; i++) {
                options.push(
                  <Field
                    arrangement={Field.ARRANGEMENTS.STACKED}
                    id={`groupSize[${i}]`}
                    key={`${product}[${i}]`}
                    label={i}
                    name="groupSize"
                    type="radio"
                    value={i}
                    variant={Field.VARIANTS.CTA}
                  />,
                );
              }

              return options;
            })()}
          </Button.Group>

          {product === PRODUCT_TYPES.GROUP && <p className="note">We recommend groups of 5-7 participants.</p>}
        </fieldset>
      )}

      <SubmitButton
        appearance={Button.APPEARANCES.AFFIRMING}
        disabled={!isDirty || isSaving}
        isSubmitting={isSaving}
        type="submit"
      >Create
      </SubmitButton>
    </Form>
  );
}
CreateEngagementForm.displayName = 'CreateEngagementForm';
