._Section_1h1jy_1 {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
}

._SectionHeader_1h1jy_7 {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

._TwoCardGrid_1h1jy_15 {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;
  margin-top: 1rem;
  place-items: stretch;
}

._ExtraPadding_1h1jy_24 {
  padding: 2rem;
}
