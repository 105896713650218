.Bureau {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

.Drawer {
  background: var(--neutral-lightest);
  padding: 0;
}

.Table {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.Table td {
  vertical-align: middle;
}

.NameCell {
  padding: 0.5rem 0;
}

.ExtraMarginBottom {
  margin-bottom: 1rem;
}
