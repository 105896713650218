import { toast } from 'sonner';


export async function copyToClipboard(text: string, targetName: string) {
  try {
    await navigator.clipboard.writeText(text);
    toast.info(`${targetName} copied to clipboard`);
  } catch (error) {
    toast.error('Copying to clipboard', { description: `${error}` });
    throw error;
  }
}
