import { useFeatureFlag } from './useFeatureFlag.mts';
import { FLAGS } from './FLAGS.mts';

type Props = {
  flags: string[];
  children: JSX.Element;
};

export function FeatureFlag({ flags = [], children }: Props): JSX.Element | null {
  const isEnabled = useFeatureFlag(flags);

  if (isEnabled) return children;

  return null;
}

FeatureFlag.displayName = 'FeatureFlag';

FeatureFlag.FLAGS = FLAGS;
