.FlexRow {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: flex-start;
}

.Bureau {
  flex: 1; /* This can't be applied to <Bureau> itself because it breaks other pages (ex Edit Matches) */
}

.TableHeader {
  margin-bottom: 2rem;
}

.Table {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.Table td {
  vertical-align: middle;
}

.Controls {
  align-items: center;
  display: flex;
  gap: 1ch;
}

.Controls a[target="_blank"]::after {
  margin-top: -1em;
}

.ContextualMenu {
  display: flex;
  flex-direction: column;
}

.FiltersDrawer {
  background: var(--neutral-lightest);
  padding: 0;
}
