import { generateUserDisplayName } from '…/app/deprecated/utils/shared/formatting/user.mts';

import type { User } from '../states.d.ts';
import type { OrbiitAPI } from '../api.d.ts';

import { userState } from './user.state.mts';


export function gotMe(
  data: {
    me?: OrbiitAPI.User,
  } = {},
) {
  const fullName = generateUserDisplayName(data.me, data.me?.emails?.[0]);
  const newMe: User = {
    ...data.me,
    isAuthenticated: true,
    profile: {
      ...data.me.profile,
      fullName,
    },
  };

  window?.analytics?.identify(newMe.id, {
    displayName: fullName,
    email: newMe.emails?.[0],
    name: fullName,
    // https://developers.intercom.com/installing-intercom/docs/adding-custom-information
    // ...(workspaceId && { 'Workspace ID': workspaceId }),
  });

  return newMe;
}

export function didntGotMe() {
  const noMe: Partial<User> & { [k: string]: undefined } = {};
  // Set voids for all existing fields in the user object so the subsequent merge overwrites them
  for (const k of Object.keys(userState)) noMe[k] = undefined;
  noMe.isAuthenticated = false;

  return noMe;
}
