 mutation SendEmailPreview(
  $workspaceId: ID!
  $engagementId: ID!
  $type: EngagementOneToOneEmailsStep!
) {
  sent: sendMockEngagementEmail(
    workspaceId: $workspaceId
    engagementId: $engagementId
    type: $type
  )
}
