import memberDetails from './member.frag.gql';


export const GET_AUDIENCE_MEMBERS = `
query GetAudienceMembers(
  $audienceId: ID!
  $firstNMembers: Int
  $membersAfterCursor: String
  $lastNMembers: Int
  $membersBeforeCursor: String
  $search: String
  $sort: MembersSorting,
  $filters: [MembersFilter]
) {
  audience(audienceId: $audienceId) {
    members(
      first: $firstNMembers
      after: $membersAfterCursor
      last: $lastNMembers
      before: $membersBeforeCursor
      search: $search
      sort: $sort
      filters: $filters
    ) {
      nodes {
        ...memberDetails
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
}

${memberDetails}
`;
