.SectionContainer {
  background-color: var(--ai-background-light);
  border: 1px solid var(--ai-border-color);
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: var(--grid-gutter);
  margin: 0 auto;
  overflow-x: hidden;
  padding: 1.5rem;
  position: relative;
  width: 100%;
}

.SectionHeader {
  align-items: center;
  color: var(--ai-text);
  display: flex;
  gap: 0.25rem;
  justify-content: flex-start;
}

.CardsList {
  display: grid;
  grid-auto-columns: 400px;
  grid-auto-flow: column;
  grid-column-gap: var(--grid-gutter);
  list-style: none;
  overflow-x: scroll;
  padding: 0;
  position: relative;
  scroll-snap-type: x mandatory;
}

.CardItem {
  background-color: white;
  border: 1px solid white;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  scroll-snap-align: start;
  transition: all 0.2s;
}

.CardItem:hover {
  border: 1px solid var(--ai-border-color);
  color: var(--ai-text);
}

.CardHeader {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CardHeaderLeft {
  display: flex;
  flex-direction:column;
  gap: 0.5rem;
}
