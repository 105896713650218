._MeterContainer_1fhm2_1 {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 2em;
  gap: 0.4em;
  justify-content: space-between;
  line-height: 1;
  position: relative;
  width: 100%;
}

._MeterBound_1fhm2_14 {
  flex: none;
  font-size: 0.5em;
}

._MeterPopoverContainer_1fhm2_19 {
  flex: 1;
  position: relative;
}
